<template>
  <div class="text-center">
    <v-overlay :value="loading" :absolute="true">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <div v-if="error">
      <v-flex class="text-center error pa-2">
        <h1 class="title">{{error}}</h1>
      </v-flex>
    </div>
    <div v-else-if="!loading">
      <v-overlay :value="$store.getters['loads/getDistanceCalculationLoading']">
        <h4 class="white--text">{{$t('Loads.UpdatingDistancesAndDurations')}}</h4>
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>
      <v-form ref="form" v-model="valid" lazy-validation @input="formInput">
        <v-layout wrap>
          <v-flex xs12 md6 class="pa-2">
            <v-radio-group v-model="fields.type" row center hide-details
                           @change="onLoadTypeChanged"
                           :disabled="isFieldReadOnly('type')"
                           v-if="!isFieldHidden('type')">
              <v-radio label="Truck Load" value="TL"></v-radio>
              <v-radio label="Less than Truck Load" value="LTL" v-if="!isDriverOwnerOperator"></v-radio>
              <v-radio label="Partial" value="PTL" v-if="!isDriverOwnerOperator"></v-radio>
              <v-radio label="Return Truck Load" value="RTL" v-if="!isDriverOwnerOperator"></v-radio>
              <v-radio label="Round Trip" value="TL_RTL" v-if="!isDriverOwnerOperator"></v-radio>
            </v-radio-group>
            <div class="text-left" v-if="fields.type === 'TL'">{{$t('Loads.TruckLoadDescription')}}</div>
            <div class="text-left" v-if="fields.type === 'LTL'">{{$t('Loads.LessThanTruckLoadDescription')}}</div>
            <div class="text-left" v-if="fields.type === 'PTL'">{{$t('Loads.PartialTruckLoadDescription')}}</div>
            <div class="text-left" v-if="fields.type === 'RTL'">{{$t('Loads.ReturnTruckLoadDescription')}}</div>
            <div class="text-left" v-if="fields.type === 'TL_RTL'">{{$t('Loads.RoundTripLoadDescription')}}</div>
          </v-flex>
          <v-flex xs12 md6 class="pa-2 pt-8 text-right">
            <v-btn small color="primary" @click="onSave()" class="mr-1"><v-icon left>mdi-content-save</v-icon>{{$route.params.bidId ? $t('Create') : $t('Save')}}</v-btn>
            <v-btn small @click="onCancel"><v-icon left color="error">mdi-cancel</v-icon>{{$t('Cancel')}}</v-btn>
            <br/>
            <v-btn @click="regenShipper()" class="ma-1 mr-0" :style="$vuetify.breakpoint.xs?'width:100%':null" small><v-icon left>mdi-update</v-icon>{{$t('Loads.RegenShipper')}}</v-btn>
            <v-btn @click="regenShipTo()" class="ma-1 mr-0" :style="$vuetify.breakpoint.xs?'width:100%':null" small><v-icon left>mdi-update</v-icon>{{$t('Loads.RegenShipTo')}}</v-btn>
            <v-btn @click="recalcTime()" class="ma-1 mr-0" :style="$vuetify.breakpoint.xs?'width:100%':null" small><v-icon left>mdi-update</v-icon>{{$t('Loads.RecalcTime')}}</v-btn>
            <!--<v-btn @click="clearAllStatuses()" class="ma-1" :style="$vuetify.breakpoint.xs?'width:100%':null">{{$t('Loads.ResetStatuses')}}</v-btn>-->
            <!--<v-btn @click="save()"  v-if="isBidsEditAllowed || (!loadId && isBidsCreateAllowed)" text class="ma-1" :style="$vuetify.breakpoint.xs?'width:100%':null"><v-icon left>fa-save</v-icon>{{loadId ? $t('Save') : $t('Create')}}</v-btn>-->
          </v-flex>
          <v-flex xs12 md8>
            <v-card class="ma-1">
              <v-card-text class="pa-1">
                <GoogleMapLoader v-if="$root.isUseGoogleMaps"
                                 :height="$root.isMobile ? '200px' : '500px'"
                                 ref="map"
                                 :showTraffic="false"
                                 @change="updateMap"
                                 :showTrucks="false"
                                 :key="$store.getters['loads/getLocationsKey']"
                                 :waypoints="locations"
                                 :loadTruck="!isFinished && truckLat && truckLng ? {lat: truckLat, lng: truckLng} : null"
                                 :truckTelemetry="truckTelemetry"
                                 :truckId="!isFinished && fields.truck > 0 ? fields.truck : null"
                                 :loadId="parseInt(loadId)"
                                 :loadStarted="fields.started"
                                 :loadEnded="fields.ended"
                                 :showCustomIcons="true"
                                 :loadName="truckName + '<hr/>' +fields.uid"/>

                <OSMMapLoader v-else
                              :waypoints="locations"
                              ref="osmMap"
                              :key="osmMapKey"
                              :height="$root.isMobile ? '200px' : '500px'"/>
              </v-card-text>
            </v-card>
            <LoadProgress ref="loadProgress"
                          @updateMap="updateMap(true)"
                          @recalc-timings="recalcTime($event)"
                          @delete-location="locationDeleted"
                          @insertLocation="locationInserted"
                          @move-location-up="locationMovedUp"
                          @move-location-down="locationMovedDown"
                          :isDriverOwnerOperator="isDriverOwnerOperator"
                          :currentLat="currentLat"
                          :currentLng="currentLng"
                          :locationsHidden="isFieldHidden('changeLocationsList')"
                          :locationsReadOnly="isFieldReadOnly('changeLocationsList')"
                          :locationStatusHidden="isFieldHidden('updateLocationsStatus')"
                          :locationStatusReadOnly="isFieldReadOnly('updateLocationsStatus')"
                          :locationDetailsHidden="isFieldHidden('updateLocationsDetails')"
                          :locationDetailsReadOnly="isFieldReadOnly('updateLocationsDetails')"
                          :locationOdometerHidden="isFieldHidden('locationOdometer')"
                          :locationOdometerReadOnly="isFieldReadOnly('locationOdometer')"
                          :odometerUPLOADHidden="isFieldHidden('odometerUPLOAD')"
                          :odometerUPLOADReadOnly="isFieldReadOnly('odometerUPLOAD')"
                          :edit="true"
                          :loadId="loadId"
                          :isLoadFinished="isFinished"
                          :driversInfo="driversInfo"
                          :driversRates="driversRates"
                          :appointmentData="appointmentData"
                          :isSetDriverOORateAllowed="isSetDriverOORateAllowed"
            />

            <!--<v-card v-if="fields.type !== 'RTL' && !isFieldHidden('isReturnToHQ')">
              <v-card-title>
                <v-switch
                  class="ml-10"
                  :disabled="isFieldReadOnly('isReturnToHQ')"
                  style="margin-top:-5px;"
                  data-cy="isReturnToHQ"
                  v-model="isReturnToHQ"
                  :loading="isReturnToHQLoading"
                  @change="setReturnToHQSwitchChange"
                  inset
                  :label="$t('Loads.AddReturnToHQLocation')"></v-switch>
              </v-card-title>-->
              <!--<v-card-text v-if="isReturnToHQ">
                <v-text-field id="hqPlaceId"
                              :disabled="isFieldReadOnly('isReturnToHQ')"
                              v-model="defaultHQPlace"
                              :label="$t('Loads.HQAddress')"
                              :placeholder="$t('Loads.EnterHQAddress')"
                              @click="initHQPlaceAutocomplete"
                              dense outlined hide-details></v-text-field>

                <v-layout wrap>
                  <v-flex xs12 :md6="fields.codriver">
                    <v-text-field
                      class="mr-1"
                      data-cy="driverReturnRate"
                      :disabled="isFieldReadOnly('isReturnToHQ') || isFieldReadOnly('driverReturnRate')"
                      v-model="fields.driverReturnRate"
                      :label="$t('Loads.driverReturnToHQRatePerMile')"
                      prefix="$"
                      type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense>
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 :md6="fields.codriver">
                    <v-text-field
                      class="ml-1"
                      v-if="fields.codriver"
                      data-cy="codriverReturnRate"
                      :disabled="isFieldReadOnly('isReturnToHQ') || isFieldReadOnly('codriverReturnRate')"
                      v-model="fields.codriverReturnRate"
                      :label="$t('Loads.codriverReturnToHQRatePerMile')"
                      prefix="$"
                      type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense>
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </v-card-text>-->
            <!--</v-card>-->

            <v-tabs v-model="driversTab" v-if="fields.driver || fields.codriver" grow class="mt-2" show-arrows active-class="tabs-active-color">
              <v-tab key="driver" v-if="fields.driver">{{ $t('Trucks.Driver') }}</v-tab>
              <v-tab key="codriver" v-if="fields.codriver">{{ $t('Trucks.CoDriver') }}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="driversTab" :key="HOSTabsKey" v-if="fields.driver || fields.codriver">
              <v-tab-item key="driver" v-if="fields.driver">
                <DriverHOSCard :userId="fields.driver" v-if="fields.driver" :recap="true"></DriverHOSCard>
              </v-tab-item>
              <v-tab-item key="codriver" v-if="fields.codriver">
                <DriverHOSCard :userId="fields.codriver" v-if="fields.codriver" :recap="true"></DriverHOSCard>
              </v-tab-item>
            </v-tabs-items>
          </v-flex>

          <v-flex xs12 md4 class="pl-2 pr-2">
            <!--<SelectLoad @change="parentLoadChanged"
                        v-if="!isFieldHidden('parent')"
                        :disabled="isFieldReadOnly('parent')"
                        :key="$store.getters['loads/getLocationsKey']"
                        :trucksIds="$store.getters['loads/getTrucksIds']"
                        :excludeLoad="loadId ? this.loadId : null"
                        :value="fields.parent"
            ></SelectLoad>-->
            <v-card class="mt-1 pa-1 pt-2">
              <SelectUser
                ref="dispatcherSelect"
                :rules="$root.fieldRequiredRules"
                :disabled="isFieldReadOnly('dispatcher')"
                v-if="!isFieldHidden('dispatcher')"
                :label="$t('Loads.SelectDispatcher')"
                role="Dispatcher"
                :key="fields.dispatcher ? 'dispatcher'+fields.dispatcher : null"
                :value="fields.dispatcher"
                @change="dispatcherChanged">
              </SelectUser>
              <SelectUser
                :multiple="true"
                ref="selectDispatcherToShare"
                :disabled="isFieldReadOnly('dispatchersToShare')"
                v-if="!isFieldHidden('dispatchersToShare')"
                :label="$t('Loads.SelectDispatcherToShare')"
                role="Dispatcher"
                :value="dispatchersToShare"
                @change="dispatchersToShareChanged">
              </SelectUser>
              <SelectUser
                :multiple="true"
                ref="selectDispatchersToShareAccess"
                :disabled="isFieldReadOnly('dispatchersSharedAccess')"
                v-if="!isFieldHidden('dispatchersSharedAccess')"
                :label="$t('Loads.selectDispatchersToShareAccess')"
                role="Dispatcher"
                :value="dispatchersSharedAccess"
                @change="dispatchersSharedAccessChanged">
              </SelectUser>
              <SelectUser
                ref="brokerSelect"
                :rules="$root.fieldRequiredRules"
                :disabled="isFieldReadOnly('broker')"
                v-if="!isFieldHidden('broker')"
                :label="$t('Loads.SelectBroker')"
                role="Broker"
                :key="fields.broker ? 'broker'+fields.broker : null"
                :value="fields.broker"
                @change="brokerChanged">
              </SelectUser>
              <v-divider class="pt-5"></v-divider>

              <v-text-field
                :key="'started'+$store.getters['loads/getLocationsKey']"
                data-cy="startDateTime"
                class="mb-2 ml-4"
                :disabled="isFieldReadOnly('started')"
                v-if="!isFieldHidden('started')"
                :value="$root.getDateTimeStringFromTimestamp($store.getters['loads/getStarted'])"
                :label="$t('Loads.StartPlan')"
                prepend-icon="fa-play"
                :rules="[rules.datetimeUSA]"
                @change="startChanged"
                outlined hide-details dense></v-text-field>
              <v-text-field
                :key="'ended'+$store.getters['loads/getLocationsKey']"
                data-cy="endDateTime"
                class="mb-2 ml-4"
                :disabled="isFieldReadOnly('ended')"
                v-if="!isFieldHidden('ended')"
                :value="$root.getDateTimeStringFromTimestamp($store.getters['loads/getEnded'])"
                :label="$t('Loads.EndPlan')"
                prepend-icon="fa-stop"
                :rules="[rules.datetimeUSA]"
                @change="endChanged"
                outlined hide-details dense></v-text-field>

              <v-text-field
                data-cy="loadRatePerMile"
                class="mb-2 ml-4"
                :disabled="isFieldReadOnly('RatePerMile')"
                v-if="!isFieldHidden('RatePerMile')"
                v-model="fields.RatePerMile"
                :label="$t('Loads.RatePerMile')"
                prepend-icon="fa-dollar-sign"
                @input="calcRatePerMileChanged"
                @change="calcRatePerMileChanged"
                prefix="$"
                type="number"
                step="0.1"
                @mousewheel.prevent=""
                outlined
                hide-details
                dense/>

              <v-text-field
                data-cy="loadRate"
                class="mb-2 ml-4"
                :disabled="isFieldReadOnly('Rate')"
                v-if="!isFieldHidden('Rate')"
                v-model="fields.Rate"
                :label="fields.type === 'TL_RTL' ? $t('Loads.TLRate') : $t('Loads.Rate')"
                prepend-icon="fa-dollar-sign"
                @input="calcTotalRateChanged"
                @change="calcTotalRateChanged"
                prefix="$"
                type="number"
                step="1"
                @mousewheel.prevent=""
                outlined
                hide-details
                dense/>

              <v-text-field
                data-cy="loadRTLRate"
                class="mb-2 ml-4"
                :disabled="isFieldReadOnly('RTLRate')"
                v-if="!isFieldHidden('RTLRate') && fields.type === 'TL_RTL'"
                v-model="fields.RTLRate"
                :label="$t('Loads.RTLRate')"
                prepend-icon="fa-dollar-sign"
                @input="calcTotalRateChanged"
                @change="calcTotalRateChanged"
                prefix="$"
                type="number" step="1" @mousewheel.prevent="" outlined hide-details dense></v-text-field>



              <v-text-field
                data-cy="loadRateTONU"
                class="mb-2 ml-4"
                :disabled="isFieldReadOnly('RateTONU')"
                v-if="!isFieldHidden('RateTONU')"
                v-model="fields.RateTONU"
                :label="$t('Loads.RateTONU')"
                prepend-icon="fa-dollar-sign"
                prefix="$"
                type="number" step="1" @mousewheel.prevent="" outlined hide-details dense></v-text-field>

              <v-text-field
                data-cy="loadDistanceInMiles"
                class="mb-2 ml-4"
                :disabled="isFieldReadOnly('distanceInMiles')"
                v-if="!isFieldHidden('distanceInMiles')"
                v-model="fields.distanceInMiles"
                :label="$t('Loads.distanceInMiles')"
                prepend-icon="fa-route"
                :suffix="$t('milesShort')"
                @input="calcTotalMilesChanged"
                @change="calcTotalMilesChanged"
                type="number" step="1" @mousewheel.prevent="" outlined hide-details dense></v-text-field>

            </v-card>

            <v-card class="mt-1 pa-1 pt-2">
              <v-layout wrap>
                <!--<v-flex xs12>
                  <v-switch
                    :disabled="isFieldReadOnly('isLocalLoad')"
                    v-if="!isFieldHidden('isLocalLoad')"
                    style="margin-top:-5px;"
                    data-cy="isLocalLoadSWITCH"
                    @change="onLocalLoadSwitchChange"
                    class="ml-10"
                    v-model="isLocalLoad"
                    inset
                    :label="$t('Loads.isLocalLoad')"></v-switch>
                </v-flex>
                <v-flex xs12>
                  <v-switch
                    :disabled="isFieldReadOnly('isAllMiles')"
                    v-if="!isFieldHidden('isAllMiles')"
                    style="margin-top:-5px;"
                    data-cy="isAllMilesSWITCH"
                    @change="onAllMilesSwitchChange"
                    class="ml-10"
                    v-model="isAllMiles"
                    inset
                    :label="$t('Loads.isAllMiles')"></v-switch>
                </v-flex>
                <v-flex xs12>
                  <v-switch
                    :disabled="isFieldReadOnly('isZipToZipMiles')"
                    v-if="!isFieldHidden('isZipToZipMiles')"
                    style="margin-top:-5px;"
                    data-cy="isZipToZipMilesSWITCH"
                    @change="onZipToZipMilesSwitchChange"
                    class="ml-10"
                    v-model="isZipToZipMiles"
                    inset
                    :label="$t('Loads.isZipToZipMiles')"></v-switch>
                </v-flex>
                <v-flex xs12>
                  <v-switch
                    :disabled="isFieldReadOnly('isEmptyMiles')"
                    v-if="!isFieldHidden('isEmptyMiles')"
                    style="margin-top:-5px;"
                    data-cy="isEmptyMilesSWITCH"
                    @change="onEmptyMilesSwitchChange"
                    class="ml-10"
                    v-model="isEmptyMiles"
                    inset
                    :label="$t('Loads.isEmptyMiles')"></v-switch>
                </v-flex>
                <v-flex xs12>
                  <v-switch
                    :disabled="isFieldReadOnly('isPickupMiles')"
                    v-if="!isFieldHidden('isPickupMiles')"
                    style="margin-top:-5px;"
                    data-cy="isPickupMilesSWITCH"
                    @change="onPickupMilesSwitchChange"
                    class="ml-10"
                    v-model="isPickupMiles"
                    inset
                    :label="$t('Loads.isPickupMiles')"></v-switch>
                </v-flex>-->

                <v-flex xs12>
                  <v-switch
                    :disabled="isFieldReadOnly('isEasyMode')"
                    v-if="!isFieldHidden('isEasyMode')"
                    style="margin-top:-5px;"
                    data-cy="isEasyModeSWITCH"
                    @change="onIsEasyModeSwitchChange"
                    class="ml-10"
                    v-model="isEasyMode"
                    inset
                    :label="$t('Loads.isEasyMode')"/>
                </v-flex>
                <v-flex xs12>
                  <v-switch
                    :disabled="isFieldReadOnly('isDedicatedTruck')"
                    v-if="!isFieldHidden('isDedicatedTruck')"
                    style="margin-top:-5px;"
                    data-cy="isDedicatedTruckSWITCH"
                    @change="onIsDedicatedTruckSwitchChange"
                    class="ml-10"
                    v-model="isDedicatedTruck"
                    inset
                    :label="$t('Loads.isDedicatedTruck')"/>
                </v-flex>
                <v-flex xs12>
                  <v-switch
                    :disabled="isFieldReadOnly('isExcludeDispatcherPayment')"
                    v-if="!isFieldHidden('isExcludeDispatcherPayment')"
                    style="margin-top:-5px;"
                    data-cy="dispatcherExcludePaymentSWITCH"
                    class="ml-10"
                    v-model="isExcludeDispatcherPayment"
                    @change="onIsExcludeDispatcherPaymentSwitchChange"
                    inset
                    :label="$t('Loads.isExcludeDispatcherPayment')"></v-switch>
                </v-flex>
                <template  v-if="$root.isFullFinancialDataAllowedToView">
                  <v-flex :xs4="isFactoring" :xs10="!isFactoring">
                    <v-switch
                      :disabled="isFieldReadOnly('isFactoring')"
                      v-if="!isFieldHidden('isFactoring')"
                      data-cy="brokerWithFactoringSWITCH"
                      style="margin-top: -5px;"
                      class="ml-10"
                      v-model="isFactoring"
                      @change="onIsFactoringSwitchChange"
                      inset
                      :label="isFactoring ? '' : $t('User.Fields.brokerWithFactoringSWITCH')"></v-switch>
                  </v-flex>
                  <v-flex xs8>
                    <v-text-field
                      :disabled="isFieldReadOnly('factoringPercent')"
                      v-if="isFactoring && !isFieldHidden('factoringPercent')"
                      class="ml-8"
                      data-cy="brokerFactoringPercent"
                      v-model="fields.factoringPercent"
                      :label="$t('User.Fields.brokerFactoringPercent')"
                      prefix="%"
                      type="number" min="0.0" step="0.1" @mousewheel.prevent="" outlined hide-details dense>
                    </v-text-field>
                  </v-flex>
                </template>
                <v-flex xs12>
                  <v-switch
                    :disabled="isFieldReadOnly('isDriverOwnerOperator')"
                    v-if="!isFieldHidden('isDriverOwnerOperator')"
                    style="margin-top:-5px;"
                    data-cy="isDriverOwnerOperatorSWITCH"
                    @change="onIsDriverOwnerOperatorSwitchChange"
                    class="ml-10"
                    v-model="isDriverOwnerOperator"
                    inset
                    :label="$t('Loads.isDriverOwnerOperator')"></v-switch>
                </v-flex>
                <v-flex xs12>
                  <v-switch
                    :disabled="isFieldReadOnly('isDriverAssist')"
                    v-if="!isFieldHidden('isDriverAssist')"
                    style="margin-top:-5px;"
                    data-cy="isDriverAssistSWITCH"
                    @change="onIsDriverAssistChange"
                    class="ml-10"
                    v-model="isDriverAssist"
                    inset
                    :label="$t('Loads.isDriverAssist')"></v-switch>
                </v-flex>
                <v-flex xs12>
                  <v-switch
                    :disabled="isFieldReadOnly('isDriverAllowedReturnToHQ')"
                    v-if="!isFieldHidden('isDriverAllowedReturnToHQ')"
                    style="margin-top:-5px;"
                    data-cy="isDriverAllowedReturnToHQSWITCH"
                    @change="onIsDriverAllowedReturnToHQChange"
                    class="ml-10"
                    v-model="isDriverAllowedReturnToHQ"
                    inset
                    :label="$t('Loads.isDriverAllowedReturnToHQ')"></v-switch>
                </v-flex>
              </v-layout>
            </v-card>


            <v-card class="mt-1 pa-1 pt-2" v-if="isDriverOwnerOperator">
              <!--With Driver Owner Operator switch-->
              <v-card-title>{{$t('Loads.isDriverOwnerOperator')}}</v-card-title>
              <v-layout wrap>
                <v-flex xs12 class="pb-2">
                  <SelectTruck
                    ref="trucksSelect"
                    :disabled="isFieldReadOnly('truckOO')"
                    v-if="!isFieldHidden('truckOO')"
                    :label="$t('Loads.SelectTruck')"
                    :key="fields.truck ? 'truckOO'+fields.truckOO : null"
                    :value="fields.truckOO"
                    :showInfoButton="false"
                    @change="($event) => {fields.truckOO = $event;}">
                  </SelectTruck>
                  <SelectUser
                    :disabled="isFieldReadOnly('driverOO')"
                    v-if="!isFieldHidden('driverOO')"
                    :label="$t('Loads.SelectDriver')"
                    role="DriverOwnerOperator"
                    :key="fields.driverOO ? 'driverOO'+fields.driverOO : null"
                    :includeDriverOwnerOperator="false"
                    :value="fields.driverOO"
                    @change="driverOOChanged($event)">
                  </SelectUser>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    data-cy="driverOwnerOperatorBonus"
                    class="mb-2 ml-4"
                    :disabled="isFieldReadOnly('driverOwnerOperatorBonus')"
                    v-if="!isFieldHidden('driverOwnerOperatorBonus')"
                    v-model="fields.driverOwnerOperatorBonus"
                    :label="$t('Loads.driverOwnerOperatorBonus')"
                    prefix="$"
                    prepend-icon="fa-dollar-sign"
                    type="number" step="1" @mousewheel.prevent="" outlined hide-details dense>
                  </v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    data-cy="driverOwnerOperatorPercent"
                    class="mb-2 ml-4"
                    :disabled="isFieldReadOnly('driverOwnerOperatorPercent')"
                    v-if="!isFieldHidden('driverOwnerOperatorPercent')"
                    v-model="fields.driverOwnerOperatorPercent"
                    :label="$t('Loads.driverOwnerOperatorPercent')"
                    prefix="%"
                    prepend-icon="fa-percent"
                    type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense>
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-card>

            <v-card class="mt-1 pa-1 pt-2" v-if="isDispatcherPercentsVisible">
              <v-card-title>{{$t('Loads.DispatcherPercents')}}</v-card-title>
              <v-layout wrap>
                <v-flex xs12 v-if="!isDriverOwnerOperator && fields.type !== 'PTL' && fields.type !== 'LTL' && fields.type !== 'RTL'">
                  <v-text-field
                    data-cy="dispatcherPaymentPercent"
                    class="mb-2 ml-3"
                    :disabled="isFieldReadOnly('dispatcherPaymentPercent')"
                    v-if="!isFieldHidden('dispatcherPaymentPercent')"
                    v-model="fields.dispatcherPaymentPercent"
                    :label="$t('Loads.dispatcherPaymentPercent')"
                    prepend-icon="fa-percent"
                    prefix="%"
                    type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense></v-text-field>
                </v-flex>
                <v-flex xs12 v-if="isDriverOwnerOperator && fields.type !== 'PTL' && fields.type !== 'LTL' && fields.type !== 'RTL'">
                  <v-text-field
                    data-cy="dispatcherPaymentPercentWithOO"
                    class="mb-2 ml-3"
                    :disabled="isFieldReadOnly('dispatcherPaymentPercentWithOO')"
                    v-if="!isFieldHidden('dispatcherPaymentPercentWithOO')"
                    v-model="fields.dispatcherPaymentPercentWithOO"
                    :label="$t('Loads.dispatcherPaymentPercentWithOO')"
                    prepend-icon="fa-percent"
                    prefix="%"
                    type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense></v-text-field>
                </v-flex>
                <v-flex xs12 v-if="fields.type === 'PTL'">
                  <v-text-field
                    data-cy="dispatcherPartialRate"
                    class="mb-2 ml-3"
                    :disabled="isFieldReadOnly('dispatcherPartialRate')"
                    v-if="!isFieldHidden('dispatcherPartialRate')"
                    v-model="fields.dispatcherPartialRate"
                    :label="$t('Loads.dispatcherPartialRate')"
                    prepend-icon="fa-percent"
                    prefix="%"
                    type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense>
                  </v-text-field>
                </v-flex>
                <v-flex xs12 v-if="fields.type === 'LTL'">
                  <v-text-field
                    data-cy="dispatcherLTLRate"
                    class="mb-2 ml-3"
                    :disabled="isFieldReadOnly('dispatcherLTLRate')"
                    v-if="!isFieldHidden('dispatcherLTLRate')"
                    v-model="fields.dispatcherLTLRate"
                    :label="$t('Loads.dispatcherLTLRate')"
                    prepend-icon="fa-percent"
                    prefix="%"
                    type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense>
                  </v-text-field>
                </v-flex>
                <v-flex xs12 v-if="fields.type === 'RTL' || fields.type === 'TL_RTL'">
                  <v-text-field
                    data-cy="dispatcherReturnRate"
                    class="mb-2 ml-3"
                    :disabled="isFieldReadOnly('dispatcherReturnRate')"
                    v-if="!isFieldHidden('dispatcherReturnRate')"
                    v-model="fields.dispatcherReturnRate"
                    :label="$t('Loads.dispatcherReturnRate')"
                    prepend-icon="fa-percent"
                    prefix="%"
                    type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense>
                  </v-text-field>
                </v-flex>
                <v-flex xs12 v-if="isDriverOwnerOperator">
                  <v-text-field
                    data-cy="dispatcherPaymentPercentFromBonus"
                    class="mb-2 ml-3"
                    :disabled="isFieldReadOnly('dispatcherPaymentPercentFromBonus')"
                    v-if="!isFieldHidden('dispatcherPaymentPercentFromBonus')"
                    v-model="fields.dispatcherPaymentPercentFromBonus"
                    :label="$t('Loads.dispatcherPaymentPercentFromBonus')"
                    prepend-icon="fa-percent"
                    prefix="%"
                    type="number" step="0.1" @mousewheel.prevent="" outlined hide-details dense></v-text-field>
                </v-flex>
              </v-layout>
            </v-card>

            <v-card class="mt-1 pa-2 pt-0">
              <!-- Additional info -->
              <v-card-title>{{$t('Loads.AdditionalInfo')}}</v-card-title>

              <v-text-field
                data-cy="loadNumber"
                class="mb-1 ml-3"
                :disabled="isFieldReadOnly('loadNumber')"
                v-if="!isFieldHidden('loadNumber')"
                v-model="fields.loadNumber"
                @change="onLoadNumberChanged"
                :label="$t('Loads.loadNumber')"
                :error-messages="isLoadNumberExist ? $root.t('Loads.LoadWithNumberExist') : null"
                prepend-icon="mdi-pound"
                outlined dense></v-text-field>

              <v-textarea
                data-cy="shipper"
                class="mb-2 ml-3"
                :disabled="isFieldReadOnly('shipper')"
                v-if="!isFieldHidden('shipper')"
                v-model="fields.shipper"
                :label="$t('Loads.shipper')"
                prepend-icon="mdi-account-box"
                outlined hide-details dense></v-textarea>

              <v-textarea
                data-cy="consignee"
                class="mb-2 ml-3"
                :disabled="isFieldReadOnly('consignee')"
                v-if="!isFieldHidden('consignee')"
                v-model="fields.consignee"
                :label="$t('Loads.shipTo')"
                prepend-icon="mdi-account-box"
                outlined hide-details dense></v-textarea>

              <v-textarea
                data-cy="loadDescription"
                class="mb-2 ml-3"
                :disabled="isFieldReadOnly('loadDescription')"
                v-if="!isFieldHidden('loadDescription')"
                :label="$t('Loads.loadDescription')"
                v-model="fields.loadDescription"
                prepend-icon="mdi-information"
                outlined hide-details dense></v-textarea>

            </v-card>

            <!-- Fines -->
            <v-card class="mt-1 pa-2 pt-0"
                    v-if="!isFieldHidden('Detention') || !isFieldHidden('Layover') || !isFieldHidden('Fine') || !isFieldHidden('Idle') || !isFieldHidden('DriverAssist')">
              <v-card-title>{{$t('Loads.ForfeitsTitle')}}</v-card-title>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field class="mb-2"
                                :disabled="isFieldReadOnly('Detention')"
                                v-if="!isFieldHidden('Detention')"
                                v-model="fields.Detention"
                                :label="$t('Loads.Detention')"
                                prefix="$"
                                :rules="$root.fieldRequiredRules"
                                type="number" min="0.0" step="1" @mousewheel.prevent="" outlined hide-details dense></v-text-field>
                </v-flex>
                <v-flex xs12 v-if="fields.Detention > 0">
                  <v-textarea :label="$t('Notes')"
                              dense outlined v-model="fields.notes"/>
                </v-flex>
                <v-flex xs12>
                  <v-text-field class="mb-2"
                                :disabled="isFieldReadOnly('Layover')"
                                v-if="!isFieldHidden('Layover')"
                                v-model="fields.Layover"
                                :label="$t('Loads.Layover')"
                                prefix="$"
                                :rules="$root.fieldRequiredRules"
                                type="number" min="0.0" step="1" @mousewheel.prevent="" outlined hide-details dense></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field class="mb-2"
                                :disabled="isFieldReadOnly('Fine')"
                                v-if="!isFieldHidden('Fine')"
                                v-model="fields.Fine"
                                :label="$t('Loads.Fine')"
                                prefix="$"
                                :rules="$root.fieldRequiredRules"
                                type="number" min="0.0" step="1" @mousewheel.prevent="" outlined hide-details dense></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field class="mb-2"
                                :disabled="isFieldReadOnly('Idle')"
                                v-if="!isFieldHidden('Idle')"
                                v-model="fields.Idle"
                                :label="$t('Loads.Idle')"
                                prefix="$"
                                :rules="$root.fieldRequiredRules"
                                type="number" min="0.0" step="1" @mousewheel.prevent="" outlined hide-details dense></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field class="mb-2"
                                :disabled="isFieldReadOnly('DriverAssist')"
                                v-if="!isFieldHidden('DriverAssist')"
                                v-model="fields.DriverAssist"
                                :label="$t('Loads.DriverAssist')"
                                prefix="$"
                                :rules="$root.fieldRequiredRules"
                                type="number" min="0.0" step="1" @mousewheel.prevent="" outlined hide-details dense></v-text-field>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>

        <!--<v-layout>
          <v-flex xs12 class="text-right">
            <v-btn @click="regenShipper()" class="ma-1" :style="$vuetify.breakpoint.xs?'width:100%':null"><v-icon left>mdi-update</v-icon>{{$t('Loads.RegenShipper')}}</v-btn>
            <v-btn @click="regenShipTo()" class="ma-1" :style="$vuetify.breakpoint.xs?'width:100%':null"><v-icon left>mdi-update</v-icon>{{$t('Loads.RegenShipTo')}}</v-btn>
            <v-btn @click="recalcTime()" class="ma-1" :style="$vuetify.breakpoint.xs?'width:100%':null"><v-icon left>mdi-update</v-icon>{{$t('Loads.RecalcTime')}}</v-btn>
            &lt;!&ndash;<v-btn @click="clearAllStatuses()" class="ma-1" :style="$vuetify.breakpoint.xs?'width:100%':null">{{$t('Loads.ResetStatuses')}}</v-btn>&ndash;&gt;
            &lt;!&ndash;<v-btn @click="save()"  v-if="isBidsEditAllowed || (!loadId && isBidsCreateAllowed)" text class="ma-1" :style="$vuetify.breakpoint.xs?'width:100%':null"><v-icon left>fa-save</v-icon>{{loadId ? $t('Save') : $t('Create')}}</v-btn>&ndash;&gt;
          </v-flex>
        </v-layout>-->
        <v-layout>
          <v-flex xs12 class="pa-2 pt-8 text-right">
            <v-btn small color="primary" @click="onSave()" class="mr-1"><v-icon left>mdi-content-save</v-icon>{{$route.params.bidId ? $t('Create') : $t('Save')}}</v-btn>
            <v-btn small @click="$route.params.bidId ? $router.go(-1) : $root.route('/loads/page/' + loadId)"><v-icon left color="error">mdi-cancel</v-icon>{{$t('Cancel')}}</v-btn>
          </v-flex>
        </v-layout>
      </v-form>
    </div>
  </div>
</template>

<script>

import GoogleMapLoader from './../../components/GoogleMapLoader.vue';
import SelectTruck from '../../components/SelectTruck.vue';
import SelectUser from '../../components/SelectUser.vue';
import DriverHOSCard from '../../components/DriverHOSCard.vue';
import LoadProgress from '../../components/LoadProgress.vue';

import userFormsCommonMixin from '../../mixins/userFormsCommonMixin';
import fieldLoadingMixin from '../../mixins/fieldLoadingMixin';
import fieldAccessRightMixin from '../../mixins/fieldAccessRightsMixin';
import loadsMixin from '../../mixins/loadsMixin';
import OSMMapLoader from "../../components/OSMMapLoader";

export default {
  components: {OSMMapLoader, GoogleMapLoader, SelectTruck, SelectUser, DriverHOSCard, LoadProgress },
  mixins: [userFormsCommonMixin, fieldLoadingMixin, fieldAccessRightMixin, loadsMixin],
  props: ['id', 'isOpenedInModal'],
  data: function () {
    return {
      fields: {
        uid: '',
        title: '',
        type: 'TL',
        bid: 0,
        parent: 0,
        /* started: 0,
        ended: 0, */
        finished: 0,
        dispatcher: 0,
        dispatchersToShare: '[]',
        dispatchersToSharePercent: '{}',
        dispatchersSharedAccess: '[]',
        broker: 0,
        truckOO: 0,
        driverOO: 0,
        /* truck: 0,
        driver: 0,
        codriver: 0, */
        isFactoring: 1,
        factoringPercent: 1.5,
        isDriverOwnerOperator: 0,
        driverOwnerOperatorBonus: 0,
        driverOwnerOperatorPercent: 0,
        Rate: 0,
        RTLRate: 0,
        RateTONU: 0,
        RatePerMile: 0,
        distanceInMiles: 0,
        Detention: 0,
        Layover: 0,
        Fine: 0,
        DriverAssist: 0,
        Idle: 0,
        isLocalLoad: 0,
        isAllMiles: 0,
        isZipToZipMiles: 0,
        isEmptyMiles: 0,
        isPickupMiles: 0,
        isExcludeDispatcherPayment: 0,
        driverLocalRate: 0,
        codriverLocalRate: 0,
        driverRate: 0,
        codriverRate: 0,
        driverEmptyRate: 0,
        codriverEmptyRate: 0,
        driverPickupMilesRate: 0,
        codriverPickupMilesRate: 0,
        driverZipToZipRate: 0,
        codriverZipToZipRate: 0,
        driverReturnRate: 0,
        codriverReturnRate: 0,
        driverAllMilesRate: 0,
        codriverAllMilesRate: 0,
        isDedicatedTruck: 0,
        isEasyMode: 0,
        dispatcherPaymentPercent: 0,
        dispatcherPaymentPercentWithOO: 0,
        dispatcherPaymentPercentFromBonus: 0,
        dispatcherPartialRate: 0,
        dispatcherLTLRate: 0,
        dispatcherReturnRate: 0,
        // loadPhotosUPLOAD: '[]',
        // rateConUPLOAD: '[]',
        // publicRateConUPLOAD: '[]',
        // doUPLOAD: '[]',
        // bolUPLOAD: '[]',
        // podUPLOAD: '[]',
        locationsJSON: '',
        isReturnToHQ: 0,
        loadDescription: '',
        shipper: '',
        consignee: '',
        loadNumber: '',
        isDriverAssist: 0,
        isDriverAllowedReturnToHQ: 1
      },
      loading: true,
      error: false,
      valid: true,

      alphabet: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'],
      totalDuration: 0,
      totalDistance: 0,

      dispatcherInitials: '',
      isFactoring: true,
      isLocalLoad: false,
      isAllMiles: false,
      isZipToZipMiles: false,
      isEmptyMiles: false,
      isPickupMiles: false,
      isExcludeDispatcherPayment: false,
      isDriverAssist: false,
      isDriverAllowedReturnToHQ: false,
      driversTab: 0,
      HOSTabsKey: 'HOSTabsKey' + new Date().getTime(),
      pickupDATETIME: '',
      deliveryDATETIME: '',
      currentLat: 0,
      currentLng: 0,
      dispatchersToShare: [],
      dispatchersToSharePercent: {},
      dispatchersSharedAccess: [],
      isDriverOwnerOperator: false,
      isDedicatedTruck: false,
      isEasyMode: false,
      rules: {
        datetimeUSA: value => new Date(value).getTime() ? true : false || this.$root.t('Loads.DateFormatError')
      },
      isLoadSearchLoading: false,
      loadsItems: [],
      documentsTab: 0,

      loadPhotosUPLOADCount: 0,
      rateConUPLOADCount: 0,
      publicRateConUPLOADCount: 0,
      doUPLOADCount: 0,
      bolUPLOADCount: 0,
      podUPLOADCount: 0,

      isReturnToHQ: false,
      isReturnToHQLoading: false,
      // defaultHQPlace: '',
      // defaultHQPlaceCoords: {},
      driversRates: {},
      driversInfo: {},
      isLoadNumberExist: false,
      truckTelemetry: {},
      truckName: '',
      truckLat: 0,
      truckLng: 0,
      appointmentData: {},
      isSetDriverOORateAllowed: false,
      osmMapKey: 'osmMap' + new Date().getTime()
    };
  },
  computed: {
    loadId () {
      return this.id || this.$route.params.id;
    },
    locations () {
      return this.$store.getters['loads/getLocations'];
    },
    isDispatcherPercentsVisible () {
      return !this.isExcludeDispatcherPayment &&
                (!this.isFieldHidden('dispatcherPaymentPercent') ||
                  !this.isFieldHidden('dispatcherPaymentPercentWithOO') ||
                    !this.isFieldHidden('dispatcherPartialRate') ||
                    !this.isFieldHidden('dispatcherLTLRate') ||
                    !this.isFieldHidden('dispatcherReturnRate') ||
                    !this.isFieldHidden('dispatcherPaymentPercentFromBonus'));
    },
    isFinished () {
      return this.fields.finished > 0;
    }
  },
  watch: {
  },
  mounted () {
    this.$root.contextSave = () => {
      if (this.$refs.form.validate()) {
        this.save();
      } else {
        this.$dialog.alert(this.$root.t('Errors.FormIsNotValid'), {
          okText: 'Ok'
        });
      }
    };

    this.$root.getGlobalSettingsField('defaultFactoringPercent').then((response) => {
      if (response.status === 'ok') {
        this.fields.factoringPercent = parseFloat(response.result);

        this.$nextTick(() => {
          this.fetchData();
        });
      }
    });
    if (this.$route.params.bidId) {
      this.$root.getCompanySettings().then((response) => {
        if (response.status === 'ok') {
          this.isEasyMode = parseInt(response.results.easyLoadModeByDefault) === 1;
        }
      });
    }
  },
  methods: {
    onCancel () {
      if (this.isOpenedInModal) {
        this.$emit('on-close');
      } else if (this.$route.params.bidId) {
        this.$router.go(-1);
      } else {
        this.$root.route('/loads/page/' + this.loadId);
      }
    },
    onSave () {
      if (this.$refs.form.validate()) {
        this.save();
      } else {
        this.$dialog.alert(this.$root.t('Errors.FormIsNotValid'), {
          okText: 'Ok'
        });
      }
    },
    onLoadNumberChanged () {
      this.isLoadNumberExist = false;
      this.$root.getLoadByNumber(this.fields.loadNumber).then((response) => {
        if (response.status === 'ok') {
          if (response.result && response.result.length > 0) {
            this.isLoadNumberExist = true;
          }
        }
      });
    },
    /*setReturnToHQSwitchChange () {
      this.onReturnToHQSwitchChange().then((retLocation) => {
        // add or subtract return path distance
        if (this.isReturnToHQ && retLocation.hasOwnProperty('distance')) {
          this.fields.distanceInMiles += this.$root.metersToMiles(retLocation.distance);
        } else if (!this.isReturnToHQ && retLocation.hasOwnProperty('distance')) {
          this.fields.distanceInMiles -= this.$root.metersToMiles(retLocation.distance);
        }
      });
    },*/
    formInput (event) {
      this.$store.commit('setIsCurrentFormUpdated', true);
    },
    driverOOChanged (value) {
      this.fields.driverOO = value;
      if (this.fields.driverOO > 0) {
        this.$root.loadUserFields(this.fields.driverOO).then((response) => {
          if (response.status === 'ok') {
            if (response.fields['role'] === 'DriverOwnerOperator') {
              this.fields.driverOwnerOperatorPercent = response.fields['driverOwnerOperatorPercent'];
            }
          }
        });
      }
    },

    onLoadTypeChanged () {
      if (this.fields.type === 'TL') {
        this.parentLoadChanged(0);
      }
      if (this.fields.type === 'PTL') {
        // this.updateTrucksIds();
      }
      if (this.fields.type === 'RTL') {
        for (let i = 0; i < this.locations.length; i++) {
          if (this.locations[i].driver > 0) {
            this.$store.dispatch('loads/setLocationField', {
              index: i,
              field: 'driverRateType',
              value: 'Return Miles'
            });
          }
          if (this.locations[i].codriver > 0) {
            this.$store.dispatch('loads/setLocationField', {
              index: i,
              field: 'codriverRateType',
              value: 'Return Miles'
            });
          }
        }
        this.updateDriversRates();
      } else if (!this.isLocalLoad && !this.isAllMiles) {
        for (let i = 0; i < this.locations.length; i++) {
          if (this.locations[i].driver > 0) {
            this.$store.dispatch('loads/setLocationField', {
              index: i,
              field: 'driverRateType',
              value: 'Default Miles'
            });
          }
          if (this.locations[i].codriver > 0) {
            this.$store.dispatch('loads/setLocationField', {
              index: i,
              field: 'codriverRateType',
              value: 'Default Miles'
            });
          }
        }
        this.updateDriversRates();
      }
    },
    calcTotalMilesChanged (value) {
      this.fields.Rate = Math.round(this.fields.distanceInMiles * this.fields.RatePerMile);
    },
    calcRatePerMileChanged (value) {
      this.fields.Rate = Math.round(this.fields.distanceInMiles * this.fields.RatePerMile);
    },
    calcTotalRateChanged () {
      let rtlRate = parseFloat(this.fields.RTLRate) || 0;
      this.fields.RatePerMile = parseFloat(parseFloat(parseFloat(this.fields.Rate) + rtlRate) / parseFloat(this.fields.distanceInMiles)).toFixed(2);
    },
    locationInserted (place, index) {
      this.$store.dispatch('loads/locationInserted', { index: index, place: place });
      // const fromTimeTS = this.locations[index]['targetDatetimes'][0] ? this.locations[index]['targetDatetimes'][0] : 0;
      // const endIndex = this.$store.getters['loads/getLastDeliveryLocationIndex'];
      this.updateMap(true);
    },
    locationDeleted (index) {
      this.$store.dispatch('loads/locationDeleted', { index: index });
      this.updateMap(true);
    },
    locationMovedUp (index) {
      if (index === 0) return;
      this.$store.dispatch('loads/locationMovedUp', { index: index });
      this.updateMap(true);
    },
    locationMovedDown (index) {
      if (this.locations[this.locations.length - 1].returnToHQ && index > this.locations.length - 2) {
        return;
      }
      if (index >= this.locations.length - 1) {
        return;
      }
      this.$store.dispatch('loads/locationMovedDown', { index: index });
      this.updateMap(true);
    },
    parentLoadChanged (value) {
      this.fields.parent = value;
    },
    startChanged (value) {
      const startedTS = this.$root.getTimestampFromDateTimeString(value);
      if (startedTS) {
        this.$store.dispatch('loads/setStarted', startedTS);
        // this.recalcTime(startedTS);
      }
    },
    endChanged (value) {
      const endedTS = this.$root.getTimestampFromDateTimeString(value);
      if (endedTS) {
        this.$store.dispatch('loads/setEnded', endedTS);
      }
    },
    /* clearAllStatuses () {
      for (let i = 0; i < this.locations.length; i++) {
        this.$store.dispatch('loads/setLocationField', { index: i, field: 'dispatcherOnTheWayCheck', value: 0 });
        this.$store.dispatch('loads/setLocationField', { index: i, field: 'driverOnSiteCheck', value: 0 });
        this.$store.dispatch('loads/setLocationField', { index: i, field: 'driverDoneCheck', value: 0 });
        this.$store.dispatch('loads/setLocationField', { index: i, field: 'driverOnTheWayCheck', value: 0 });
      }
    }, */
    regenShipTo () {
      this.fields.consignee = this.generateShipTo(this.locations).toUpperCase();
    },
    regenShipper () {
      this.fields.shipper = this.generateShipper(this.locations).toUpperCase();
    },
    recalcTime (startTime = 0, startIndex = 1, endIndex = 0) {
      this.calculateTimings(this.locations, startTime, 0, startIndex, endIndex);
    },
    dispatcherChanged (value, doNotUpdateData = false) {
      this.$set(this.fields, 'dispatcher', parseInt(value));
      if (doNotUpdateData) return;
      this.$root.loadUserFields(value).then((response) => {
        if (response.status === 'ok') {
          this.dispatcherInitials = '';
          if (response.fields.firstName) {
            this.dispatcherInitials += response.fields.firstName.charAt(0);
          }
          if (response.fields.lastName) {
            this.dispatcherInitials += response.fields.lastName.charAt(0);
          }
          this.$set(this.fields, 'dispatcherPaymentPercent', response.fields.dispatcherPaymentPercent);
          this.$set(this.fields, 'dispatcherPaymentPercentWithOO', response.fields.dispatcherPaymentPercentWithOO);
          this.$set(this.fields, 'dispatcherPaymentPercentFromBonus', response.fields.dispatcherPaymentPercentFromBonus);
          this.$set(this.fields, 'dispatcherPartialRate', response.fields.dispatcherPaymentPercentForPartialLoad);
          this.$set(this.fields, 'dispatcherLTLRate', response.fields.dispatcherPaymentPercentForLTLLoad);
          this.$set(this.fields, 'dispatcherReturnRate', response.fields.dispatcherPaymentPercentForReturnLoad);
        }
      });
    },
    dispatchersToShareChanged (value) {
      let i = 0;
      let dispatcherId = 0;
      this.dispatchersToShare = value;
      this.fields.dispatchersToShare = JSON.stringify(value);

      this.dispatchersToSharePercent = {};
      this.fields.dispatchersToSharePercent = '{}';

      for (i = 0; i < this.dispatchersToShare.length; i++) {
        dispatcherId = this.dispatchersToShare[i];
        this.dispatchersToSharePercent[dispatcherId] = {};
        this.fields.dispatchersToSharePercent = JSON.stringify(this.dispatchersToSharePercent);
      }
    },
    dispatchersSharedAccessChanged (value) {
      this.dispatchersSharedAccess = value;
      this.fields.dispatchersSharedAccess = JSON.stringify(value);
    },
    brokerChanged (value, doNotUpdateData = false) {
      this.fields.broker = parseInt(value);
      if (doNotUpdateData) return;
      this.$root.loadUserFields(value).then((response) => {
        if (response.status === 'ok') {
          if (response.fields.brokerWithFactoringSWITCH) {
            this.isFactoring = parseInt(response.fields.brokerWithFactoringSWITCH) !== 0;
            this.fields.isFactoring = parseInt(response.fields.brokerWithFactoringSWITCH);
          }

          if (response.fields.brokerFactoringPercent) {
            this.fields.factoringPercent = response.fields.brokerFactoringPercent;
          }

          /* if (response.fields.brokerCompanyName) {
            this.fields.shipToCompanyName = response.fields.brokerCompanyName;
          }

          if (response.fields.phoneNumber) {
            this.fields.shipToPhone = response.fields.phoneNumber;
          }

          if (response.fields.brokerContactPerson) {
            this.fields.shipToContactPerson = response.fields.brokerContactPerson;
          } */
        }
      });
    },
    /* driverChanged (value, doNotUpdateData = false) {
      // check is new driver value equals to codriver
      if (parseInt(this.fields.codriver) === parseInt(value)) {
        this.$root.toast(this.$i18n.t('Loads.DriversAreEqualError'), { color: 'error' });
        this.fields.driver = 0;
        return;
      }
      this.fields.driver = value;
      if (doNotUpdateData) {
        return;
      }
      this.$root.loadUserFields(this.fields.driver).then((response) => {
        if (response.status === 'ok') {
          if (response.fields.role === 'DriverOwnerOperator') {
            this.isDriverOwnerOperator = true;
            this.fields.isDriverOwnerOperator = 1;
            this.fields.driverOwnerOperatorPercent = response.fields.driverOwnerOperatorPercent;
          } else {
            this.isDriverOwnerOperator = false;
            this.fields.isDriverOwnerOperator = 0;
            this.fields.driverOwnerOperatorBonus = 0.0;
            this.HOSTabsKey = new Date().getTime();
            this.updateDriversRates();
          }
        }
      });
    },
    codriverChanged (value, doNotUpdateData = false) {
      // check is new driver value equals to codriver
      if (parseInt(this.fields.driver) === parseInt(value)) {
        this.$root.toast(this.$i18n.t('Loads.DriversAreEqualError'), { color: 'error' });
        this.fields.codriver = 0;
        return;
      }
      this.fields.codriver = value;
      this.HOSTabsKey = new Date().getTime();
      if (!doNotUpdateData) {
        this.updateDriversRates();
      }
    }, */
    getDriverAvailableTime (driverId) {

    },
    /* setDefaultReturnRates () {
      if (parseInt(this.fields.isAllMiles) === 1) {
        this.fields.driverReturnRate = this.fields.driverAllMilesRate;
        this.fields.codriverReturnRate = this.fields.codriverAllMilesRate;
      } else {
        this.fields.driverReturnRate = this.fields.driverEmptyRate;
        this.fields.codriverReturnRate = this.fields.codriverEmptyRate;
      }
    }, */
    updateDriversRates () {
      let i = 0;
      let location = {};
      let isTeam = false;
      let driverRate = false;
      let coDriverRate = false;
      for (i = 0; i < this.locations.length; i++) {
        location = this.locations[i];
        isTeam = false;
        if (location.driver && location.driver > 0 &&
            location.codriver && location.codriver > 0) {
          isTeam = true;
        }
        // Driver
        if (location.driver && location.driver > 0) {
          if (this.driversRates.hasOwnProperty(location.driver)) {
            driverRate = this.getDriverRate(this.driversRates[location.driver], location.driverRateType, location.driverRateName, isTeam);
            if (driverRate !== false) {
              this.$store.dispatch('loads/setLocationField', { index: i, field: 'driverRate', value: driverRate.rate });
              this.$store.dispatch('loads/setLocationField', { index: i, field: 'driverRateFixed', value: driverRate.rateFixed });
              this.$store.dispatch('loads/setLocationField', { index: i, field: 'driverRateMinMiles', value: driverRate.rateMiles });
              this.$forceUpdate();
              this.$store.commit('loads/updateLocationsKey');
            }
          }
        }

        // CoDriver
        if (location.coDriver && location.coDriver > 0) {
          if (this.driversRates.hasOwnProperty(location.coDriver)) {
            coDriverRate = this.getDriverRate(this.driversRates[location.coDriver], location.coDriverRateType, location.coDriverRateName, isTeam);
            if (coDriverRate !== false) {
              this.$store.dispatch('loads/setLocationField', { index: i, field: 'driverRate', value: coDriverRate.rate });
              this.$store.dispatch('loads/setLocationField', { index: i, field: 'driverRateFixed', value: coDriverRate.rateFixed });
              this.$store.dispatch('loads/setLocationField', { index: i, field: 'driverRateMinMiles', value: coDriverRate.rateMiles });
              this.$forceUpdate();
              this.$store.commit('loads/updateLocationsKey');
            }
          }
        }
      }
    },
    /*initHQPlaceAutocomplete () {
      let autocomplete = new google.maps.places.Autocomplete(document.getElementById('hqPlaceId'));
      autocomplete.setFields(['address_components', 'formatted_address', 'geometry', 'icon', 'name']);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        this.defaultHQPlace = this.$root.getAddressStrFromComponentsObject(place.address_components);

        this.$store.dispatch('loads/setLocationField', { index: this.locations.length - 1, field: 'name', value: place.formatted_address });
        this.$store.dispatch('loads/setLocationField', { index: this.locations.length - 1, field: 'lat', value: place.geometry.location.lat() });
        this.$store.dispatch('loads/setLocationField', { index: this.locations.length - 1, field: 'lng', value: place.geometry.location.lng() });
      });
    },*/
    /*

    onLocalLoadSwitchChange () {
      this.fields.isLocalLoad = this.isLocalLoad ? 1 : 0;
      if (this.isLocalLoad) {
        this.fields.isAllMiles = 0;
        this.isAllMiles = false;
        this.fields.isZipToZipMiles = 0;
        this.isZipToZipMiles = false;
        this.fields.isEmptyMiles = 0;
        this.isEmptyMiles = false;
        this.fields.isPickupMiles = 0;
        this.isPickupMiles = false;
      }

      let startLocation = this.isZipToZipMiles || this.isEmptyMiles || this.isPickupMiles ? 1 : 0;

      for (let i = startLocation; i < this.locations.length; i++) {
        if (this.locations[i].driver > 0) {
          this.$store.dispatch('loads/setLocationField', {
            index: i,
            field: 'driverRateType',
            value: this.isLocalLoad ? 'Local Miles' : 'Default Miles'
          });
        }
        if (this.locations[i].codriver > 0) {
          this.$store.dispatch('loads/setLocationField', {
            index: i,
            field: 'codriverRateType',
            value: this.isLocalLoad ? 'Local Miles' : 'Default Miles'
          });
        }
      }
      this.updateDriversRates();
    },

    onAllMilesSwitchChange () {
      this.fields.isAllMiles = this.isAllMiles ? 1 : 0;
      if (this.isAllMiles) {
        this.fields.isLocalLoad = 0;
        this.isLocalLoad = false;
        this.fields.isZipToZipMiles = 0;
        this.isZipToZipMiles = false;
        this.fields.isEmptyMiles = 0;
        this.isEmptyMiles = false;
        this.fields.isPickupMiles = 0;
        this.isPickupMiles = false;
      }
      let startLocation = !this.isAllMiles && (this.isZipToZipMiles || this.isEmptyMiles || this.isPickupMiles) ? 1 : 0;

      for (let i = startLocation; i < this.locations.length; i++) {
        if (this.locations[i].driver > 0) {
          this.$store.dispatch('loads/setLocationField', {
            index: i,
            field: 'driverRateType',
            value: this.isAllMiles ? 'All Miles' : 'Default Miles'
          });
        }
        if (this.locations[i].codriver > 0) {
          this.$store.dispatch('loads/setLocationField', {
            index: i,
            field: 'codriverRateType',
            value: this.isAllMiles ? 'All Miles' : 'Default Miles'
          });
        }
      }
      this.updateDriversRates();
    },

    onZipToZipMilesSwitchChange () {
      this.fields.isZipToZipMiles = this.isZipToZipMiles ? 1 : 0;
      if (this.isZipToZipMiles) {
        this.fields.isEmptyMiles = 0;
        this.isEmptyMiles = false;
        this.fields.isPickupMiles = 0;
        this.isPickupMiles = false;
      }
      if (this.locations[0].driver > 0) {
        this.$store.dispatch('loads/setLocationField', {
          index: 0,
          field: 'driverRateType',
          value: this.isZipToZipMiles ? 'Zip To Zip Miles' : 'Default Miles'
        });
      }
      if (this.locations[0].codriver > 0) {
        this.$store.dispatch('loads/setLocationField', {
          index: 0,
          field: 'codriverRateType',
          value: this.isZipToZipMiles ? 'Zip To Zip Miles' : 'Default Miles'
        });
      }
      this.updateDriversRates();
    },

    onEmptyMilesSwitchChange () {
      this.fields.isEmptyMiles = this.isEmptyMiles ? 1 : 0;
      if (this.isEmptyMiles) {
        this.fields.isZipToZipMiles = 0;
        this.isZipToZipMiles = false;
        this.fields.isPickupMiles = 0;
        this.isPickupMiles = false;
      }

      if (this.locations[0].driver > 0) {
        this.$store.dispatch('loads/setLocationField', {
          index: 0,
          field: 'driverRateType',
          value: this.isEmptyMiles ? 'Empty Miles' : 'Default Miles'
        });
      }
      if (this.locations[0].codriver > 0) {
        this.$store.dispatch('loads/setLocationField', {
          index: 0,
          field: 'codriverRateType',
          value: this.isEmptyMiles ? 'Empty Miles' : 'Default Miles'
        });
      }
      this.updateDriversRates();
    },

    onPickupMilesSwitchChange () {
      this.fields.isPickupMiles = this.isPickupMiles ? 1 : 0;
      if (this.isPickupMiles) {
        this.fields.isZipToZipMiles = 0;
        this.isZipToZipMiles = false;
        this.fields.isEmptyMiles = 0;
        this.isEmptyMiles = false;
      }

      if (this.locations[0].driver > 0) {
        this.$store.dispatch('loads/setLocationField', {
          index: 0,
          field: 'driverRateType',
          value: this.isPickupMiles ? 'Pickup Miles' : 'Default Miles'
        });
      }
      if (this.locations[0].codriver > 0) {
        this.$store.dispatch('loads/setLocationField', {
          index: 0,
          field: 'codriverRateType',
          value: this.isPickupMiles ? 'Pickup Miles' : 'Default Miles'
        });
      }
      this.updateDriversRates();
    },
*/
    onIsExcludeDispatcherPaymentSwitchChange () {
      this.fields.isExcludeDispatcherPayment = this.isExcludeDispatcherPayment ? 1 : 0;
    },

    onIsDedicatedTruckSwitchChange () {
      this.fields.isDedicatedTruck = this.isDedicatedTruck ? 1 : 0;
    },
    onIsEasyModeSwitchChange () {
      this.fields.isEasyMode = this.isEasyMode ? 1 : 0;
    },
    onIsDriverOwnerOperatorSwitchChange () {
      this.fields.isDriverOwnerOperator = this.isDriverOwnerOperator ? 1 : 0;
      // Clear driver if driver owner operator switch is turn off
      if (!this.isDriverOwnerOperator) {
        this.fields.truckOO = 0;
        this.fields.driverOO = 0;
      } else {
        // If load with driver Owner-Operator than switch to Truck Load type
        this.fields.type = 'TL';
      }
      this.$forceUpdate();
    },
    onIsFactoringSwitchChange () {
      this.fields.isFactoring = this.isFactoring ? 1 : 0;
    },
    onIsDriverAssistChange () {
      this.fields.isDriverAssist = this.isDriverAssist ? 1 : 0;
    },
    onIsDriverAllowedReturnToHQChange () {
      this.fields.isDriverAllowedReturnToHQ = this.isDriverAllowedReturnToHQ ? 1 : 0;
    },
    validate () {
      let i = 0;
      let lastLocationTS = 0;
      if (this.locations.length === 0) {
        this.$root.toast('Locations list is empty.', { color: 'error' });
        return false;
      }

      if (!this.loadId && (!this.locations[0].details || this.locations[0].details.length === 0)) {
        this.$root.toast('Appointment details on the first location is required.', { color: 'error' });
        return false;
      }

      for (i = 1; i < this.locations.length; i++) {
        if (parseInt(this.locations[i].targetDatetimes[0]) > lastLocationTS) {
          lastLocationTS = parseInt(this.locations[i].targetDatetimes[0]);
        } else {
          this.$root.toast(this.$root.t('Loads.LocationsDatesAreNotConsecutive'), { color: 'error' });
          return false;
        }
      }
      return true;
    },
    save () {
      return new Promise((resolve, reject) => {
        if (!this.isLocationsTypesValid(this.locations)) {
          this.$root.toast('Some locations don`t have a type.', { color: 'error' });
          resolve();
          return;
        }

        if (!this.validate()) {
          resolve();
          return;
        }

        this.$dialog.confirm(this.$root.t('Loads.SaveConfirmationMsg')).then(dialog => {
          let timezoneOffsetMinutes = 0;
          let curTimestamp = 0;
          if (this.loadId) {
            // this.fields.finished = this.$root.isLoadFinished(this.locations) ? 1 : 0;
            this.fields.locationsJSON = JSON.stringify(this.locations);
            this.fields.isReturnToHQ = this.isReturnToHQ ? 1 : 0;
            this.fields.started = this.$store.getters['loads/getStarted'];
            this.fields.ended = this.$store.getters['loads/getEnded'];

            // Enable driver assist if value is added
            if (parseFloat(this.fields.DriverAssist) > 0) {
              this.isDriverAssist = true;
              this.fields.isDriverAssist = this.isDriverAssist ? 1 : 0;
            }

            this.$root.saveLoad(this.fields, this.loadId).then((response) => {
              if (response.status === 'ok') {
                this.$store.commit('setIsCurrentFormUpdated', false);
                if (this.isOpenedInModal) {
                  this.$emit('on-close');
                } else {
                  this.$root.route('/loads/page/' + this.loadId);
                }
              }
            });
          } else {
            /*timezoneOffsetMinutes = this.$root.$moment.momentTZ.tz('America/Los_Angeles').utcOffset();
            curTimestamp = new Date().getTime() / 1000;
            curTimestamp -= timezoneOffsetMinutes * 60; // set -8 UTC timezone

            const dateTime = new Date(curTimestamp * 1000);
            const uidDateTime = String(dateTime.getUTCMonth() + 1).padStart(2, '0') +
                                String(dateTime.getUTCDate()).padStart(2, '0') +
                                dateTime.getUTCFullYear() +
                                String(dateTime.getUTCHours()).padStart(2, '0') +
                                String(dateTime.getUTCMinutes()).padStart(2, '0') +
                                String(dateTime.getUTCSeconds()).padStart(2, '0');*/

            this.fields.uid = ''; // this.dispatcherInitials + uidDateTime;
            this.fields.locationsJSON = JSON.stringify(this.locations);
            this.fields.started = this.$store.getters['loads/getStarted'];
            this.fields.ended = this.$store.getters['loads/getEnded'];

            // Enable driver assist if value is added
            if (parseFloat(this.fields.DriverAssist) > 0) {
              this.isDriverAssist = true;
              this.fields.isDriverAssist = this.isDriverAssist ? 1 : 0;
            }

            this.$root.createLoad(this.fields).then((response) => {
              if (response.status === 'ok') {
                this.$store.commit('setIsCurrentFormUpdated', false);
                this.$root.route('/loads/page/' + response.createdId);
              }
              resolve();
            });
          }
        }).catch((e) => {
          console.error(e);
          this.$root.toast('Load saving error', { color: 'error' });
        });
      });
    },
    updateMap (force = false, skipTotalsCalculations = false) {
      let runSyncLoop = () => {};
      let runSyncFunc = () => {};
      // get distances
      if (this.locations.length > 1) {
        this.totalDistance = 0;
        this.totalDuration = 0;
        runSyncLoop = async () => {
          let iw = 0;
          for (iw = 0; iw < this.locations.length - 1; iw++) {
            await new Promise((resolve, reject) => {
              const wpts = [{ lat: this.locations[iw].lat, lng: this.locations[iw].lng }, { lat: this.locations[iw + 1].lat, lng: this.locations[iw + 1].lng }];

              this.$root.calcDistance(wpts, null, false,
                this.$root.isUseGoogleMaps && this.$refs.map ? this.$refs.map.trafficModel : null
              ).then((response) => {
                if (response.hasOwnProperty('routes') && response.routes.length > 0 &&
                    response.routes[0].hasOwnProperty('legs') && response.routes[0].legs.length > 0) {
                  const legs = response.routes[0].legs;
                  if (legs && legs.length > 0 && legs[0].hasOwnProperty('duration') && legs[0].hasOwnProperty('distance')) {
                    const _duration = this.$root.isUseGoogleMaps ? (legs[0].duration_in_traffic ? legs[0].duration_in_traffic.value : legs[0].duration.value) : legs[0].duration;
                    const _distance = this.$root.isUseGoogleMaps ? legs[0].distance.value : legs[0].distance;

                    this.totalDistance += _distance;
                    this.totalDuration += _duration;

                    this.$store.dispatch('loads/setLocationField', { index: iw, field: 'distance', value: _distance, dont_update_key: true });
                    this.$store.dispatch('loads/setLocationField', { index: iw, field: 'duration', value: _duration, dont_update_key: true });
                    setTimeout(() => {
                      resolve();
                    }, 1000);
                  } else {
                    this.$root.toast(this.$i18n.t('ErrorCantCalcTheDistance'), { color: 'error' });
                    reject(this.$i18n.t('ErrorCantCalcTheDistance'));
                  }
                } else {
                  this.$root.toast(this.$i18n.t('ErrorCantCalcTheDistance'), { color: 'error' });
                  reject(this.$i18n.t('ErrorCantCalcTheDistance'));
                }
              });
            });
          }
        };

        runSyncFunc = async () => {
          this.$store.commit('loads/setDistanceCalculationLoading');

          await runSyncLoop();

          this.fields.distanceInMiles = this.$root.metersToMiles(this.totalDistance);
          if (!skipTotalsCalculations) {
            this.calcTotalRateChanged();
          }

          this.$nextTick(() => {
            this.step = this.locations.length;
            if (this.$root.isUseGoogleMaps) {
              if (this.$refs.map)this.$refs.map.init();
            } else {
              this.osmMapKey = 'osmMap' + new Date().getTime();
            }

            // Calc timings automatically only when creating job
            // When job edited timings will load from locationsObject
            if (this.$route.params.bidId) {
              if (this.locations[1].targetDatetimes[0] === 0 &&
                  this.locations[this.locations.length - 1].targetDatetimes[1] === 0) {
                this.calculateTimings(this.locations);
                // set started and ended dates
                const startedTS = this.locations[1].targetDatetimes[0] > 0 ? this.locations[1].targetDatetimes[0] : new Date().getTime() / 1000;
                this.$store.dispatch('loads/setStarted', startedTS);

                const endedTS = this.locations[this.locations.length - 1].targetDatetimes[1];
                this.$store.dispatch('loads/setEnded', endedTS);
              }
            }

            this.$store.commit('loads/updateLocationsKey');
            this.$store.commit('loads/clearDistanceCalculationLoading');
            this.$forceUpdate();
            if (this.$refs.loadProgress) {
              this.$refs.loadProgress.$forceUpdate();
            }
          });
        };

        // check is all distances and durations are set
        let isDistAndDurSet = true;
        for (let iw = 0; iw < this.locations.length - 1; iw++) {
          if (!this.locations[iw].distance || !this.locations[iw].duration) {
            isDistAndDurSet = false;
            break;
          }
        }

        if (this.$route.params.bidId || !isDistAndDurSet || force) {
          runSyncFunc();
        }
      }
    },
    fetchData () {
      let responseProcess = () => {};
      let runSyncLoop = () => {};
      if (!this.loadId && !this.isLoadsCreateAllowed) {
        this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
        this.loading = false;
        return;
      }

      if (this.loadId) {
        if (!this.isLoadsViewAllowed || !this.isLoadsEditAllowed) {
          this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
          this.loading = false;
          return;
        }
      }

      responseProcess = (response) => {
        let d = 0;
        if (response.status === 'ok') {
          this.isSetDriverOORateAllowed = response.isSetDriverOORateAllowed;

          if (!response.isEditAllowed) {
            this.error = 'Access Denied';
          }

          if (response.result['truckKeeptruckindata']) {
            this.truckTelemetry = JSON.parse(response.result['truckKeeptruckindata']);
          }

          if (response.result['truckName']) {
            this.truckName = response.result['truckName'];
          }

          if (response.result['truckLat']) {
            this.truckLat = response.result['truckLat'];
          }

          if (response.result['truckLng']) {
            this.truckLng = response.result['truckLng'];
          }

          // Set drivers rates
          if (response.hasOwnProperty('drivers')) {
            for (d = 0; d < response.drivers.length; d++) {
              this.driversInfo[response.drivers[d].id] = response.drivers[d];
              this.driversRates[response.drivers[d].id] = [];
              if (response.drivers[d].driverRates) {
                this.driversRates[response.drivers[d].id] = response.drivers[d].driverRates;
              }
              this.driversRates[response.drivers[d].id].unshift({
                name: 'Current Rate',
                type: this.$store.getters.RateTypes.TYPE_BY_MILES,
                minMiles: 0,
                soloRate: parseFloat(response.drivers[d].driverCurrentSoloRate),
                teamRate: parseFloat(response.drivers[d].driverCurrentTeamRate),
                soloRateFixed: 0,
                teamRateFixed: 0
              });
            }
          }
          // If bid id passed create a load
          if (this.$route.params.bidId) {
            this.dispatcherChanged(parseInt(response.result['dispatcher']));
            // Set broker id from parameter if available
            if (this.$route.params.brokerId) {
              if (this.$route.params.newEmail) {
                this.$root.addEmailToBroker(this.$route.params.brokerId, this.$route.params.newEmail);
              }
              this.brokerChanged(parseInt(this.$route.params.brokerId));
            } else {
              this.brokerChanged(parseInt(response.result['broker']));
            }
            // this.truckChanged(response.result['truck']);

            this.$root.getGlobalSettingsField('defaultFactoringPercent').then((response) => {
              if (response.status === 'ok') {
                this.fields.factoringPercent = parseFloat(response.result);
              }
            });

            this.fields.isEasyMode = this.isEasyMode ? 1 : 0;
            this.fields.title = response.result['subject'];
            this.fields.type = 'TL';
            this.fields.Rate = response.result['totalRate'];
            this.fields.RatePerMile = response.result['ratePerMile'];
            this.fields.distanceInMiles = this.$root.metersToMiles(response.result['totalDistance']);
            this.fields.bid = response.result['id'];
            this.currentLat = response.result['truckLat'];
            this.currentLng = response.result['truckLng'];

            // this.updateDriversRates();

            // Detect load type
            /* this.$root.listLoadsByTruck(response.result['truck'], 0, 'TL').then((response) => {
                if (response.status === 'ok') {
                    if (response.result.length > 0) {
                        this.fields.type = 'LTL';
                    }
                }
            }); */
          } else if (this.loadId) {
            this.dispatcherChanged(parseInt(response.result['dispatcher']), true);
            this.fields.dispatcherPaymentPercent = response.result['dispatcherPaymentPercent'];
            this.fields.dispatcherPaymentPercentWithOO = response.result['dispatcherPaymentPercentWithOO'];
            this.fields.dispatcherPaymentPercentFromBonus = response.result['dispatcherPaymentPercentFromBonus'];
            this.fields.dispatcherPartialRate = response.result['dispatcherPartialRate'];
            this.fields.dispatcherLTLRate = response.result['dispatcherLTLRate'];
            this.fields.dispatcherReturnRate = response.result['dispatcherReturnRate'];

            this.brokerChanged(response.result['broker'], true);
            this.fields.isFactoring = response.result['isFactoring'];
            this.fields.factoringPercent = response.result['factoringPercent'];
            this.isFactoring = parseInt(response.result['isFactoring']) !== 0;

            /* this.truckChanged(response.result['truck']); */

            this.appointmentData = response.appointmentData;
            this.fields.parent = response.result['parent'];
            this.fields.uid = response.result['uid'];
            this.fields.title = response.result['title'];
            this.fields.type = response.result['type'];
            this.$store.dispatch('loads/setStarted', response.result['started']);
            this.$store.dispatch('loads/setEnded', response.result['ended']);
            // this.fields.started = response.result['started'];
            // this.fields.ended = response.result['ended'];
            this.fields.finished = response.result['finished'];
            this.fields.Rate = response.result['Rate'];
            this.fields.RTLRate = response.result['RTLRate'];
            this.fields.RateTONU = response.result['RateTONU'];
            this.fields.RatePerMile = response.result['RatePerMile'];
            this.fields.distanceInMiles = response.result['distanceInMiles'];
            this.fields.bid = response.result['bid'];
            this.fields.dispatchersToShare = response.result['dispatchersToShare'];
            this.fields.dispatchersToSharePercent = response.result['dispatchersToSharePercent'];
            this.fields.dispatchersSharedAccess = response.result['dispatchersSharedAccess'];

            this.fields.Detention = response.result['Detention'];
            this.fields.Layover = response.result['Layover'];
            this.fields.Fine = response.result['Fine'];
            this.fields.DriverAssist = response.result['DriverAssist'];
            this.fields.Idle = response.result['Idle'];

            this.fields.rateConUPLOAD = response.result['rateConUPLOAD'];
            this.fields.publicRateConUPLOAD = response.result['publicRateConUPLOAD'];
            this.fields.doUPLOAD = response.result['doUPLOAD'];
            this.fields.bolUPLOAD = response.result['bolUPLOAD'];
            this.fields.podUPLOAD = response.result['podUPLOAD'];
            this.fields.loadPhotosUPLOAD = response.result['loadPhotosUPLOAD'];

            this.rateConUPLOADCount = response.result['rateConUPLOAD'] ? JSON.parse(response.result['rateConUPLOAD']).length : 0;
            this.publicRateConUPLOADCount = response.result['publicRateConUPLOAD'] ? JSON.parse(response.result['publicRateConUPLOAD']).length : 0;
            this.doUPLOADCount = response.result['doUPLOAD'] ? JSON.parse(response.result['doUPLOAD']).length : 0;
            this.bolUPLOADCount = response.result['bolUPLOAD'] ? JSON.parse(response.result['bolUPLOAD']).length : 0;
            this.podUPLOADCount = response.result['podUPLOAD'] ? JSON.parse(response.result['podUPLOAD']).length : 0;
            this.loadPhotosUPLOADCount = response.result['loadPhotosUPLOAD'] ? JSON.parse(response.result['loadPhotosUPLOAD']).length : 0;

            /* this.fields.shipToCompanyName = response.result['shipToCompanyName'];
            this.fields.shipToPhone = response.result['shipToPhone'];
            this.fields.shipToContactPerson = response.result['shipToContactPerson']; */
            this.fields.loadDescription = response.result['loadDescription'];
            this.fields.shipper = response.result['shipper'];
            this.fields.consignee = response.result['consignee'];
            this.fields.loadNumber = response.result['loadNumber'];

            /* this.currentLat = response.result['truckLat'];
            this.currentLng = response.result['truckLng']; */

            this.fields.truckOO = response.result['truckOO'];
            this.fields.driverOO = response.result['driverOO'];

            /*
            this.fields.driver = response.result['driver'];
            this.fields.codriver = response.result['codriver']; */

            /* this.driverChanged(response.result['driver']);
            this.codriverChanged(response.result['codriver']);

            this.fields.driverRate = response.result['driverRate'];
            this.fields.codriverRate = response.result['codriverRate']; */

            this.fields.isEmptyMiles = response.result['isEmptyMiles'];
            this.isEmptyMiles = parseInt(this.fields.isEmptyMiles) === 1;
            /* this.fields.driverEmptyRate = response.result['driverEmptyRate'];
            this.fields.codriverEmptyRate = response.result['codriverEmptyRate']; */

            this.fields.isAllMiles = response.result['isAllMiles'];
            this.isAllMiles = parseInt(this.fields.isAllMiles) === 1;
            /* this.fields.driverAllMilesRate = response.result['driverAllMilesRate'];
            this.fields.codriverAllMilesRate = response.result['codriverAllMilesRate']; */

            /* this.fields.driverReturnRate = response.result['driverReturnRate'];
            this.fields.codriverReturnRate = response.result['codriverReturnRate']; */

            this.fields.isZipToZipMiles = response.result['isZipToZipMiles'];
            this.isZipToZipMiles = parseInt(this.fields.isZipToZipMiles) === 1;
            /* this.fields.driverZipToZipRate = response.result['driverZipToZipRate'];
            this.fields.codriverZipToZipRate = response.result['codriverZipToZipRate']; */
            this.fields.isPickupMiles = response.result['isPickupMiles'];
            this.isPickupMiles = parseInt(this.fields.isPickupMiles) === 1;

            this.fields.isLocalLoad = response.result['isLocalLoad'];
            this.isLocalLoad = parseInt(this.fields.isLocalLoad) === 1;
            /* this.fields.driverLocalRate = response.result['driverLocalRate'];
            this.fields.codriverLocalRate = response.result['codriverLocalRate']; */
            this.fields.isReturnToHQ = response.result['isReturnToHQ'];
            this.isReturnToHQ = parseInt(this.fields.isReturnToHQ) === 1;

            this.fields.isFactoring = response.result['isFactoring'];
            this.isFactoring = parseInt(this.fields.isFactoring) === 1;
            this.fields.factoringPercent = response.result['factoringPercent'];

            this.fields.isDriverOwnerOperator = response.result['isDriverOwnerOperator'];
            this.isDriverOwnerOperator = parseInt(this.fields.isDriverOwnerOperator) === 1;
            this.fields.isDedicatedTruck = response.result['isDedicatedTruck'];
            this.isDedicatedTruck = parseInt(this.fields.isDedicatedTruck) === 1;

            this.fields.isEasyMode = response.result['isEasyMode'];
            this.isEasyMode = parseInt(this.fields.isEasyMode) === 1;

            this.fields.isDriverAssist = response.result['isDriverAssist'];
            this.isDriverAssist = parseInt(this.fields.isDriverAssist) === 1;
            this.fields.isDriverAllowedReturnToHQ = response.result['isDriverAllowedReturnToHQ'];
            this.isDriverAllowedReturnToHQ = parseInt(this.fields.isDriverAllowedReturnToHQ) === 1;

            this.fields.driverOwnerOperatorBonus = response.result['driverOwnerOperatorBonus']; ;
            this.fields.driverOwnerOperatorPercent = response.result['driverOwnerOperatorPercent']; ;
            this.fields.dispatcherPartialRate = response.result['dispatcherPartialRate'];
            this.fields.dispatcherLTLRate = response.result['dispatcherLTLRate'];
            this.fields.dispatcherReturnRate = response.result['dispatcherReturnRate'];
            this.fields.isExcludeDispatcherPayment = parseInt(response.result['isExcludeDispatcherPayment']);
            this.isExcludeDispatcherPayment = this.fields.isExcludeDispatcherPayment === 1;
            this.fields.notes = response.result['notes'];
          }

          this.fields.locationsJSON = response.result['locationsJSON'];

          if (response.result['dispatchersToShare']) {
            this.dispatchersToShare = JSON.parse(response.result['dispatchersToShare']);
          }

          if (response.result['dispatchersToSharePercent']) {
            this.dispatchersToSharePercent = JSON.parse(response.result['dispatchersToSharePercent']);
          }

          if (response.result['dispatchersSharedAccess']) {
            this.dispatchersSharedAccess = JSON.parse(response.result['dispatchersSharedAccess']);
          }

          let locationsObject = [];
          let waypoints = [];

          if (response.result['waypointsJSON']) {
            waypoints = JSON.parse(response.result['waypointsJSON']);
          }

          if (response.result['locationsJSON']) {
            locationsObject = JSON.parse(response.result['locationsJSON'].replace(/\n/g, '\\n').replace(/\r/g, '\\r').replace(/\t/g, '\\t'));

            /*if (this.isReturnToHQ) {
              this.defaultHQPlace = locationsObject[locationsObject.length - 1].name;
            }*/
          }

          // initialize locations object
          if (locationsObject.length === 0) {
            for (let i = 0; i < waypoints.length; i++) {
              locationsObject.push({
                lat: waypoints[i].lat,
                lng: waypoints[i].lng,
                name: '',
                /* pickup: false,
                delivery: false, */
                timestamp: 0,
                details: null,
                status: 0,
                distance: 0,
                duration: 0,
                targetDatetimes: [0, 0]
              });
            }
          }

          for (let i = 0; i < locationsObject.length; i++) {
            if (locationsObject[i] && locationsObject[i]['name'] && locationsObject[i]['name'] !== '') {
              continue;
            }
            // Detect pickup and delivery types
            if (i === locationsObject.length - 1) {
              if (!locationsObject[i].hasOwnProperty('pickup') && !locationsObject[i].hasOwnProperty('delivery')) {
                locationsObject[i].pickup = false;
                locationsObject[i].delivery = true;
              }
            } else if (i > 0) {
              if (!locationsObject[i].hasOwnProperty('pickup') && !locationsObject[i].hasOwnProperty('delivery')) {
                locationsObject[i].pickup = true;
                locationsObject[i].delivery = false;
              }
            }
            runSyncLoop = async () => {
              await new Promise((resolve, reject) => {
                this.$root.getAddressByCoords(locationsObject[i].lat, locationsObject[i].lng).then((responseAddr) => {
                  locationsObject[i]['name'] = responseAddr.formatted_address.replace(', USA', '');
                  setTimeout(() => {
                    resolve();
                  }, 1000);
                });
              });
            };
            runSyncLoop();
          }
          this.$store.dispatch('loads/setLocations', locationsObject);
          // Skip rate per mile calculation if load id is presented in url
          this.updateMap(false, this.loadId > 0);
        } else {
          this.error = response.msg;
        }
        this.loading = false;
        this.$nextTick(() => {
          setTimeout(() => {
            this.$store.commit('setIsCurrentFormUpdated', false);
          }, 5000);
        });
      };

      this.updatingEnabled = true;
      this.applyRights('', 'loads', {
        started: 0,
        ended: 0,
        // rateType: 0,
        changeLocationsList: 0,
        updateLocationsStatus: 0,
        updateLocationsDetails: 0,
        locationOdometer: 0,
        odometerUPLOAD: 0,
        deleteLoadPhotos: 0,
        deleteDocuments: 0
      }).then(() => {
        this.loading = true;
        if (this.$route.params.bidId) {
          this.$root.getBid(this.$route.params.bidId).then(responseProcess);
        } else if (this.loadId) {
          this.$root.getLoad(this.loadId).then(responseProcess);
        }
      });
    }
  }
};
</script>
