<template>
  <div class="text-center">
    <div v-if="error">
      <v-flex class="text-center error pa-2">
        <h1 class="title">{{error}}</h1>
      </v-flex>
    </div>
    <div v-else class="pl-2">
      <v-overlay :value="loading" :absolute="true">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>

      <v-layout>
        <!--<v-flex shrink class="pa-2 pt-8 text-left">
          <v-btn icon v-if="$route.path !== '/'" @click="$router.go(-1)" small text>
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>
        </v-flex>-->
        <v-flex grow class="pt-8 text-left text-no-wrap">
          <h2 class="text-left pl-2">
            {{ fields.name }}
          </h2>
        </v-flex>
        <v-flex grow class="pa-2 pt-8 text-right">
          <v-btn small color="primary" @click="onSave()" class="mr-1"><v-icon left>mdi-content-save</v-icon>{{!truckId ? $t('Create') : $t('Save')}}</v-btn>
          <v-btn small @click="onCancel"><v-icon left color="error">mdi-cancel</v-icon>{{$t('Cancel')}}</v-btn>
        </v-flex>
      </v-layout>
      <v-form ref="form" v-model="valid" lazy-validation @input="formInput">
        <v-layout wrap>
          <v-flex xs12 md4 class="pa-1">
            <v-card>
              <!--<v-card-title class="subtitle-1">
                <v-switch v-model="isTrailer"
                          inset dense
                          class="ml-5"
                          @change="isTrailerChange"
                          :label="$root.t('Trucks.Fields.isTrailer')"
                />
              </v-card-title>-->
              <v-card-text>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field
                      data-cy="truckName"
                      :disabled="isFieldReadOnly('name')"
                      v-if="!isFieldHidden('name')"
                      v-model="fields.name"
                      :rules="$root.fieldRequiredRules"
                      :label="$t('Trucks.Fields.name')"
                      type="text"
                      class="ma-1"
                      dense outlined hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-if="isTrailer && !isFieldHidden('linkedTruckId')"
                      data-cy="truckType"
                      :disabled="isFieldReadOnly('linkedTruckId')"
                      v-model="fields.linkedTruckId"
                      :label="$t('Trucks.Fields.linkedTruckId')"
                      :items="tractorsTrucks"
                      class="ma-1"
                      item-text="name"
                      item-value="id"
                      dense outlined hide-details
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-if="isTrailer && !isFieldHidden('trailerType')"
                      data-cy="truckType"
                      :disabled="isFieldReadOnly('trailerType')"
                      v-model="fields.trailerType"
                      :rules="$root.fieldRequiredRules"
                      :label="$t('Trucks.Fields.trailerType')"
                      :items="trailerTypes"
                      class="ma-1"
                      dense outlined hide-details
                    />
                    <v-select
                      v-if="!isTrailer && !isFieldHidden('type')"
                      data-cy="truckType"
                      :disabled="isFieldReadOnly('type')"
                      v-model="fields.type"
                      :rules="$root.fieldRequiredRules"
                      :label="$t('Trucks.Fields.type')"
                      :items="truckTypes"
                      class="ma-1"
                      dense outlined hide-details
                    />
                  </v-flex>
                  <v-flex xs12 v-if="!$store.getters.isUserRoleDriverOO">
                    <SelectUser
                      :disabled="isFieldReadOnly('driverOO')"
                      v-if="!isFieldHidden('driverOO')"
                      :label="$t('Trucks.DriverOO')"
                      role="DriverOwnerOperator"
                      :key="fields.driverOO ? 'driverOO'+fields.driverOO : null"
                      :includeDriverOwnerOperator="false"
                      :value="fields.driverOO"
                      :hide-prepend-icon="true"
                      class="ma-1"
                      @change="driverOOChanged($event)">
                    </SelectUser>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      data-cy="truckFleetNumber"
                      :disabled="isFieldReadOnly('truckFleetNumber')"
                      v-if="!isFieldHidden('truckFleetNumber')"
                      class="ma-1"
                      v-model="fields.truckFleetNumber"
                      :rules="$root.fieldRequiredRules"
                      :label="$t('Trucks.Fields.truckFleetNumber')"
                      type="text"
                      dense outlined hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      data-cy="truckLicensePlate"
                      :disabled="isFieldReadOnly('licensePlate')"
                      v-if="!isFieldHidden('licensePlate')"
                      class="ma-1"
                      v-model="fields.licensePlate"
                      :rules="$root.fieldRequiredRules"
                      :label="$t('Trucks.Fields.licensePlate')"
                      type="text"
                      dense outlined hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      data-cy="year"
                      :disabled="isFieldReadOnly('year')"
                      v-if="!isFieldHidden('year')"
                      class="ma-1"
                      v-model="fields.year"
                      :label="$t('Trucks.Fields.year')"
                      type="text"
                      dense outlined hide-details/>
                  </v-flex>

                  <v-flex xs12>
                    <v-text-field
                      data-cy="model"
                      :disabled="isFieldReadOnly('model')"
                      v-if="!isFieldHidden('model')"
                      class="ma-1"
                      v-model="fields.model"
                      :label="$t('Trucks.Fields.model')"
                      type="text"
                      dense outlined hide-details/>
                  </v-flex>

                  <v-flex xs12>
                    <v-text-field
                      data-cy="usdot"
                      :disabled="isFieldReadOnly('usdot')"
                      v-if="!isFieldHidden('usdot')"
                      class="ma-1"
                      v-model="fields.usdot"
                      :label="$t('Trucks.Fields.usdot')"
                      type="text"
                      dense outlined hide-details/>
                  </v-flex>

                  <v-flex xs12>
                    <v-text-field
                      data-cy="owner"
                      :disabled="isFieldReadOnly('owner')"
                      v-if="!isFieldHidden('owner')"
                      class="ma-1"
                      v-model="fields.owner"
                      :label="$t('Trucks.Fields.owner')"
                      type="text"
                      dense outlined hide-details/>
                  </v-flex>

                  <v-flex xs12>
                    <v-text-field
                      data-cy="truckVIN"
                      :disabled="isFieldReadOnly('vin')"
                      v-if="!isFieldHidden('vin')"
                      v-model="fields.vin"
                      :rules="$root.fieldRequiredRules"
                      :label="$t('Trucks.Fields.vin')"
                      type="text"
                      prefix="#"
                      class="ma-1"
                      dense outlined hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-menu v-model="registrationCardExpiredDATEMenu"
                            v-if="!isFieldHidden('registrationCardExpiredDATE')"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          data-cy="truckRegistrationCardExpiredDATE"
                          :disabled="isFieldReadOnly('registrationCardExpiredDATE')"
                          class="ma-1"
                          :loading="isFieldLoading('registrationCardExpiredDATE')"
                          v-model="registrationCardExpiredDATEFormated"
                          @blur="fields.registrationCardExpiredDATE = parseDate(registrationCardExpiredDATEFormated)"
                          :label="$t('Trucks.Fields.registrationCardExpiredDATE')"
                          persistent-hint v-on="on" dense outlined hide-details></v-text-field>
                        <!--hint="MM/DD/YYYY" -->
                      </template>
                      <v-date-picker
                        @change="registrationCardExpiredDateChange"
                        v-model="fields.registrationCardExpiredDATE"
                        no-title
                        @input="registrationCardExpiredDATEMenu = false"></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12>
                    <v-menu v-model="insuranceExpiredDATEMenu"
                            v-if="!isFieldHidden('insuranceExpiredDATE')"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          data-cy="truckInsuranceExpiredDATE"
                          :disabled="isFieldReadOnly('insuranceExpiredDATE')"
                          class="ma-1"
                          :loading="isFieldLoading('insuranceCardExpiredDATE')"
                          v-model="insuranceExpiredDATEFormated"
                          @blur="fields.insuranceExpiredDATE = parseDate(insuranceExpiredDATEFormated)"
                          :label="$t('Trucks.Fields.insuranceExpiredDATE')"
                          persistent-hint v-on="on" dense outlined hide-details></v-text-field>
                        <!--hint="MM/DD/YYYY" -->
                      </template>
                      <v-date-picker
                        @change="insuranceExpiredDateChange"
                        v-model="fields.insuranceExpiredDATE"
                        no-title
                        @input="insuranceExpiredDATEMenu = false"></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12>
                    <v-menu v-model="lastAnnualInspectionDATEMenu"
                            v-if="!isFieldHidden('lastAnnualInspectionDATE')"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          data-cy="truckInsuranceExpiredDATE"
                          :disabled="isFieldReadOnly('lastAnnualInspectionDATE')"
                          class="ma-1"
                          :loading="isFieldLoading('lastAnnualInspectionDATE')"
                          v-model="lastAnnualInspectionDATEFormated"
                          @blur="fields.lastAnnualInspectionDATE = parseDate(lastAnnualInspectionDATEFormated)"
                          :label="$t('Trucks.Fields.lastAnnualInspectionDATE')"
                          persistent-hint v-on="on" dense outlined hide-details></v-text-field>
                        <!--hint="MM/DD/YYYY" -->
                      </template>
                      <v-date-picker
                        @change="lastAnnualInspectionDateChange"
                        v-model="fields.lastAnnualInspectionDATE"
                        no-title
                        @input="lastAnnualInspectionDATEMenu = false"></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 v-if="!isTrailer">
                    <v-text-field
                      data-cy="nextOilReplacementIntervalMiles"
                      :disabled="isFieldReadOnly('nextOilReplacementIntervalMiles')"
                      v-if="!isFieldHidden('nextOilReplacementIntervalMiles')"
                      class="ma-1"
                      v-model="fields.nextOilReplacementIntervalMiles"
                      :label="$t('Trucks.Fields.nextOilReplacementIntervalMiles')"
                      type="number"
                      suffix="mi."
                      dense outlined hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12 v-if="!isTrailer">
                    <v-text-field
                      :disabled="isFieldReadOnly('miles')"
                      v-if="!isTrailer && !isFieldHidden('miles')"
                      class="ma-1"
                      :loading="isFieldLoading('miles')"
                      v-model="fields.miles"
                      :label="$t('Trucks.Fields.miles')"
                      type="number"
                      suffix="mi" dense outlined hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      :disabled="isFieldReadOnly('maxLoadWeight')"
                      v-if="!isFieldHidden('maxLoadWeight')"
                      class="ma-1"
                      :loading="isFieldLoading('maxLoadWeight')"
                      v-model="fields.maxLoadWeight"
                      :label="$t('Trucks.Fields.maxLoadWeight')"
                      type="number"
                      suffix="lbs" dense outlined hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      :disabled="isFieldReadOnly('emptyScaleWeight')"
                      v-if="!isFieldHidden('emptyScaleWeight')"
                      class="ma-1"
                      :loading="isFieldLoading('emptyScaleWeight')"
                      v-model="fields.emptyScaleWeight"
                      :label="$t('Trucks.Fields.emptyScaleWeight')"
                      type="number"
                      suffix="lbs" dense outlined hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-switch
                      data-cy="syncDriversWithKT"
                      :disabled="isFieldReadOnly('syncDriversWithKT')"
                      v-if="!isFieldHidden('syncDriversWithKT') && fields.keeptruckinid"
                      v-model="syncDriversWithKT"
                      @change="syncDriversWithKTChange"
                      inset
                      prepend-icon="mdi-sync-circle"
                      :label="$t('Trucks.Fields.syncDriversWithKT')"></v-switch>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                        data-cy="samsaraKey"
                        :disabled="isFieldReadOnly('samsaraKey')"
                        v-if="!isFieldHidden('samsaraKey')"
                        class="ma-1"
                        v-model="fields.samsaraKey"
                        :label="$t('Trucks.Fields.samsaraKey')"
                        type="text"
                        :hint="$t('Trucks.APIKeyHint')"
                        dense outlined persistent-hint/>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                        data-cy="keeptruckinKey"
                        :disabled="isFieldReadOnly('keeptruckinKey')"
                        v-if="!isFieldHidden('keeptruckinKey')"
                        class="ma-1"
                        v-model="fields.keeptruckinKey"
                        :label="$t('Trucks.Fields.keeptruckinKey')"
                        type="text"
                        :hint="$t('Trucks.APIKeyHint')"
                        dense outlined persistent-hint/>
                  </v-flex>

                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 md4 class="pa-1">
            <v-card>
              <v-card-title class="subtitle-1 pt-8 pb-8">Sizes</v-card-title>
              <v-card-text>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field
                      data-cy="truckLength"
                      :disabled="isFieldReadOnly('length')"
                      v-if="!isFieldHidden('length')"
                      class="ma-1"
                      v-model="fields.length"
                      :label="$t('Trucks.Fields.length')"
                      type="number"
                      suffix="ft."
                      dense outlined hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      data-cy="truckDimensionsL"
                      :disabled="isFieldReadOnly('dimensionsL')"
                      v-if="!isFieldHidden('dimensionsL')"
                      class="ma-1"
                      v-model="fields.dimensionsL"
                      :label="$t('Trucks.Fields.dimensionsL')"
                      type="number"
                      suffix="in."
                      dense outlined hide-details></v-text-field>
                  </v-flex>
                  <!--
                  <v-flex xs12 md6>
                    <v-text-field
                      data-cy="truckDimensionsDetailL"
                      :disabled="isFieldReadOnly('dimensionsDetailL')"
                      v-if="!isFieldHidden('dimensionsDetailL')"
                      class="mr-2 mb-2"
                      v-model="fields.dimensionsDetailL"
                      :label="$t('Trucks.Fields.dimensionsDetailL')"
                      type="text"
                      prepend-icon="fa-ruler-combined"
                      suffix="in."
                      dense outlined hide-details></v-text-field>
                  </v-flex>
                  -->
                  <v-flex xs12>
                    <v-text-field
                      data-cy="truckDimensionsW"
                      :disabled="isFieldReadOnly('dimensionsW')"
                      v-if="!isFieldHidden('dimensionsW')"
                      class="ma-1"
                      v-model="fields.dimensionsW"
                      :label="$t('Trucks.Fields.dimensionsW')"
                      type="number"
                      suffix="in."
                      dense outlined hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      data-cy="truckDimensionsDetailW"
                      :disabled="isFieldReadOnly('dimensionsDetailW')"
                      v-if="!isFieldHidden('dimensionsDetailW')"
                      class="ma-1"
                      v-model="fields.dimensionsDetailW"
                      :label="$t('Trucks.Fields.dimensionsDetailW')"
                      type="text"
                      suffix="in."
                      dense outlined hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      data-cy="truckDimensionsH"
                      :disabled="isFieldReadOnly('dimensionsH')"
                      v-if="!isFieldHidden('dimensionsH')"
                      v-model="fields.dimensionsH"
                      :label="$t('Trucks.Fields.dimensionsH')"
                      type="number"
                      suffix="in."
                      class="ma-1"
                      dense outlined hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      data-cy="truckDimensionsDetailH"
                      :disabled="isFieldReadOnly('dimensionsDetailH')"
                      v-if="!isFieldHidden('dimensionsDetailH')"
                      v-model="fields.dimensionsDetailH"
                      :label="$t('Trucks.Fields.dimensionsDetailH')"
                      type="text"
                      suffix="in."
                      class="ma-1"
                      dense outlined hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      :disabled="isFieldReadOnly('distanceBetween')"
                      v-if="!isFieldHidden('distanceBetween')"
                      v-model="fields.distanceBetween"
                      :label="$t('Trucks.Fields.distanceBetween')"
                      type="number"
                      suffix="in."
                      class="ma-1"
                      dense outlined hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      data-cy="tireSize"
                      :disabled="isFieldReadOnly('tireSize')"
                      v-if="!isFieldHidden('tireSize')"
                      class="ma-1"
                      v-model="fields.tireSize"
                      :label="$t('Trucks.Fields.tireSize')"
                      type="text"
                      dense outlined hide-details/>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 md4 class="pa-1">
            <v-card>
              <v-card-title class="subtitle-1 pt-8 pb-8">Equipment</v-card-title>
              <v-card-text>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field
                      :disabled="isFieldReadOnly('eTrackQuantity')"
                      v-if="!isFieldHidden('eTrackQuantity')"
                      class="ma-1"
                      v-model="fields.eTrackQuantity"
                      :label="$t('Trucks.Fields.eTrackQuantity')"
                      type="number"
                      dense outlined hide-details/>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      :disabled="isFieldReadOnly('loadBarsQuantity')"
                      v-if="!isFieldHidden('loadBarsQuantity')"
                      class="ma-1"
                      v-model="fields.loadBarsQuantity"
                      :label="$t('Trucks.Fields.loadBarsQuantity')"
                      type="number"
                      dense outlined hide-details/>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      :disabled="isFieldReadOnly('strapsQuantity')"
                      v-if="!isFieldHidden('strapsQuantity')"
                      class="ma-1"
                      v-model="fields.strapsQuantity"
                      :label="$t('Trucks.Fields.strapsQuantity')"
                      type="number"
                      dense outlined hide-details/>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      :disabled="isFieldReadOnly('blanketsQuantity')"
                      v-if="!isFieldHidden('blanketsQuantity')"
                      class="ma-1"
                      v-model="fields.blanketsQuantity"
                      :label="$t('Trucks.Fields.blanketsQuantity')"
                      type="number"
                      dense outlined hide-details/>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      :disabled="isFieldReadOnly('snowChains')"
                      v-if="!isFieldHidden('snowChains')"
                      class="ma-1"
                      v-model="fields.snowChains"
                      :label="$t('Trucks.Fields.snowChains')"
                      type="number"
                      dense outlined hide-details/>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      :disabled="isFieldReadOnly('spareWheels')"
                      v-if="!isFieldHidden('spareWheels')"
                      class="ma-1"
                      v-model="fields.spareWheels"
                      :label="$t('Trucks.Fields.spareWheels')"
                      type="number"
                      dense outlined hide-details/>
                  </v-flex>
                  <v-flex xs12 v-if="!isTrailer">
                    <v-switch
                      class="ml-2"
                      :disabled="isFieldReadOnly('sleeperSpace')"
                      v-if="!isFieldHidden('sleeperSpace')"
                      v-model="sleeperSpace"
                      @change="sleeperSpaceChange"
                      inset
                      :label="$t('Trucks.Fields.sleeperSpace')"></v-switch>
                  </v-flex>
                  <v-flex xs12 class="text-center">
                    <v-switch
                      class="ml-2"
                      :disabled="isFieldReadOnly('liftGate')"
                      v-if="!isFieldHidden('liftGate')"
                      v-model="liftGate"
                      @change="liftGateChange"
                      inset
                      :label="$t('Trucks.Fields.liftGate')"></v-switch>
                  </v-flex>
                  <v-flex xs12>
                    <v-switch
                      class="ml-2"
                      :disabled="isFieldReadOnly('palletJack')"
                      v-if="!isFieldHidden('palletJack')"
                      v-model="palletJack"
                      @change="palletJackChange"
                      inset
                      :label="$t('Trucks.Fields.palletJack')"></v-switch>
                  </v-flex>
                  <v-flex xs12>
                    <v-switch
                      class="ml-2"
                      :disabled="isFieldReadOnly('airRide')"
                      v-if="!isFieldHidden('airRide')"
                      v-model="airRide"
                      @change="airRideChange"
                      inset
                      :label="$t('Trucks.Fields.airRide')"></v-switch>
                  </v-flex>
                  <v-flex xs12>
                    <v-switch
                      class="ml-2"
                      :disabled="isFieldReadOnly('iccBars')"
                      v-if="!isFieldHidden('iccBars')"
                      v-model="iccBars"
                      @change="iccBarsChange"
                      inset
                      :label="$t('Trucks.Fields.iccBars')"></v-switch>
                  </v-flex>
                  <v-flex xs12>
                    <v-switch
                      class="ml-2"
                      :disabled="isFieldReadOnly('reefer')"
                      v-if="!isFieldHidden('reefer')"
                      v-model="reefer"
                      @change="reeferChange"
                      inset
                      :label="$t('Trucks.Fields.reefer')"></v-switch>
                  </v-flex>
                  <v-flex xs12>
                    <v-switch
                      class="ml-2"
                      :disabled="isFieldReadOnly('isVented')"
                      v-if="!isFieldHidden('isVented')"
                      v-model="isVented"
                      @change="isVentedChange"
                      inset
                      :label="$t('Trucks.Fields.isVented')"></v-switch>
                  </v-flex>
                  <v-flex xs12>
                    <v-switch
                      class="ml-2"
                      :disabled="isFieldReadOnly('isSwingDoors')"
                      v-if="!isFieldHidden('isSwingDoors')"
                      v-model="isSwingDoors"
                      @change="isSwingDoorsChange"
                      inset
                      :label="$t('Trucks.Fields.isSwingDoors')"></v-switch>
                  </v-flex>
                  <v-flex xs12>
                    <v-switch
                      class="ml-2"
                      :disabled="isFieldReadOnly('isRollupDoors')"
                      v-if="!isFieldHidden('isRollupDoors')"
                      v-model="isRollupDoors"
                      @change="isRollupDoorsChange"
                      inset
                      :label="$t('Trucks.Fields.isRollupDoors')"></v-switch>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 class="pa-1">
            <v-card>
              <v-card-text>
                <v-textarea
                  data-cy="truckNotes"
                  :disabled="isFieldReadOnly('notes')"
                  v-if="!isFieldHidden('notes')"
                  class="ma-1"
                  :loading="isFieldLoading('notes')"
                  v-model="fields.notes"
                  :label="$t('Trucks.Fields.notes')"
                  dense outlined hide-details/>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 class="pa-1">
            <v-card>
              <v-card-text>
                <v-textarea
                  data-cy="truckMaintenanceNote"
                  :disabled="isFieldReadOnly('maintenanceNote')"
                  v-if="!isFieldHidden('maintenanceNote')"
                  class="ma-1"
                  :loading="isFieldLoading('maintenanceNote')"
                  v-model="fields.maintenanceNote"
                  :label="$t('Trucks.Fields.maintenanceNote')"
                  dense outlined hide-details/>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12 class="pa-1">
            <v-card>
              <v-card-text>
                <v-tabs v-model="documentsTab" grow class="mt-2" show-arrows active-class="tabs-active-color">
                  <v-tab key="photos"            v-if="!isFieldHidden('truckAvatarUPLOAD')">{{ $t('Trucks.Fields.truckAvatarUPLOAD') }}</v-tab>
                  <v-tab key="emptyScaleTicket"  v-if="!isFieldHidden('emptyScaleTicketUPLOAD')">{{ $t('Trucks.Fields.emptyScaleTicketUPLOAD') }}</v-tab>
                  <v-tab key="truckInsurance"    v-if="!isFieldHidden('truckInsuranceUPLOAD')">{{ $t('Trucks.Fields.truckInsuranceUPLOAD') }}</v-tab>
                  <v-tab key="truckRegistration" v-if="!isFieldHidden('truckRegistrationUPLOAD')">{{ $t('Trucks.Fields.truckRegistrationUPLOAD') }}</v-tab>
                  <v-tab key="annualInspection"  v-if="!isFieldHidden('annualInspectionUPLOAD')">{{ $t('Trucks.Fields.annualInspectionUPLOAD') }}</v-tab>
                </v-tabs>
                <v-tabs-items v-model="documentsTab">
                  <v-tab-item key="photos">
                    <v-card class="pa-1">
                      <MultiFileUpload :field="fields.truckAvatarUPLOAD"
                                       @change="fileChange('truckAvatarUPLOAD', $event)"
                                       fieldName="truckAvatarUPLOAD"
                                       :label="$t('Trucks.Fields.truckAvatarUPLOAD')"
                                       uploadFolder="truckAvatar"
                                       :downloadAllowed="isFieldDownloadAllowed('truckAvatarUPLOAD')"
                                       :deleteAllowed="!isFieldReadOnly('truckAvatarUPLOAD')"
                                       :height="650"
                                       :readonly="isFieldReadOnly('truckAvatarUPLOAD')"
                                       v-if="!isFieldHidden('truckAvatarUPLOAD')"
                                       :isImageEditing="true"
                      />
                    </v-card>
                  </v-tab-item>
                  <v-tab-item key="emptyScaleTicket">
                    <v-card class="pa-1">
                      <MultiFileUpload :field="fields.emptyScaleTicketUPLOAD"
                                       @change="fileChange('emptyScaleTicketUPLOAD', $event)"
                                       fieldName="emptyScaleTicketUPLOAD"
                                       :label="$t('Trucks.Fields.emptyScaleTicketUPLOAD')"
                                       uploadFolder="emptyScaleTicket"
                                       :downloadAllowed="isFieldDownloadAllowed('emptyScaleTicketUPLOAD')"
                                       :height="650"
                                       :deleteAllowed="!isFieldReadOnly('truckAvatarUPLOAD')"
                                       :readonly="isFieldReadOnly('emptyScaleTicketUPLOAD')"
                                       v-if="!isFieldHidden('emptyScaleTicketUPLOAD')" ></MultiFileUpload>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item key="truckInsurance">
                    <v-card class="pa-1">
                      <MultiFileUpload :field="fields.truckInsuranceUPLOAD"
                                       @change="fileChange('truckInsuranceUPLOAD', $event)"
                                       fieldName="truckInsuranceUPLOAD"
                                       :label="$t('Trucks.Fields.truckInsuranceUPLOAD')"
                                       uploadFolder="truckInsurance"
                                       :downloadAllowed="isFieldDownloadAllowed('truckInsuranceUPLOAD')"
                                       :height="650"
                                       :deleteAllowed="!isFieldReadOnly('truckAvatarUPLOAD')"
                                       :readonly="isFieldReadOnly('truckInsuranceUPLOAD')"
                                       v-if="!isFieldHidden('truckInsuranceUPLOAD')" ></MultiFileUpload>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item key="truckRegistration">
                    <v-card class="pa-1">
                      <MultiFileUpload :field="fields.truckRegistrationUPLOAD"
                                       @change="fileChange('truckRegistrationUPLOAD', $event)"
                                       fieldName="truckRegistrationUPLOAD"
                                       :label="$t('Trucks.Fields.truckRegistrationUPLOAD')"
                                       uploadFolder="truckRegistration"
                                       :downloadAllowed="isFieldDownloadAllowed('truckRegistrationUPLOAD')"
                                       :height="650"
                                       :deleteAllowed="!isFieldReadOnly('truckAvatarUPLOAD')"
                                       :readonly="isFieldReadOnly('truckRegistrationUPLOAD')"
                                       v-if="!isFieldHidden('truckRegistrationUPLOAD')" ></MultiFileUpload>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item key="annualInspection">
                    <v-card class="pa-1">
                      <MultiFileUpload :field="fields.annualInspectionUPLOAD"
                                       @change="fileChange('annualInspectionUPLOAD', $event)"
                                       fieldName="annualInspectionUPLOAD"
                                       :label="$t('Trucks.Fields.annualInspectionUPLOAD')"
                                       uploadFolder="annualInspection"
                                       :downloadAllowed="isFieldDownloadAllowed('annualInspectionUPLOAD')"
                                       :height="650"
                                       :deleteAllowed="!isFieldReadOnly('truckAvatarUPLOAD')"
                                       :readonly="isFieldReadOnly('annualInspectionUPLOAD')"
                                       v-if="!isFieldHidden('annualInspectionUPLOAD')" ></MultiFileUpload>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>

  <!--

        <v-btn v-if="truckId"
               style='width:100%;margin-top: 10px;'
               :loading="isFieldLoading('saveBtn')"
               data-cy="truckSaveBtn"
               v-confirm="{ok: () => save(), cancel: () => {}, message: $t('Trucks.SaveConfirmation')}"
               color="success"><v-icon left>fa-save</v-icon>{{$t('Save')}}</v-btn>

        <v-btn v-else
               style='width:100%;margin-top: 10px;'
               :loading="isFieldLoading('createBtn')"
               v-confirm="{ok: () => create(), cancel: () => {}, message: $t('Trucks.CreateConfirmation')}"
               data-cy="truckCreateBtn"
               color="success"><v-icon left>fa-save</v-icon>{{$t('Trucks.Create')}}</v-btn>
  -->

      </v-form>
    </div>
  </div>
</template>

<script>
import MultiFileUpload from '../../components/MultiFileUpload.vue';
import userFormsCommonMixin from '../../mixins/userFormsCommonMixin';
import fieldLoadingMixin from '../../mixins/fieldLoadingMixin';
import fieldAccessRightMixin from '../../mixins/fieldAccessRightsMixin';
import SelectUser from '../../components/SelectUser';

export default {
  components: { SelectUser, MultiFileUpload },
  mixins: [userFormsCommonMixin, fieldLoadingMixin, fieldAccessRightMixin],
  props: ['id', 'isOpenedInModal'],
  data: () => ({
    fields: {
      isTrailer: 0,
      linkedTruckId: 0,
      truckAvatarUPLOAD: '',
      truckFleetNumber: '',
      name: '',
      type: '',
      trailerType: '',
      licensePlate: '',
      year: '',
      model: '',
      usdot: '',
      owner: '',
      tireSize: '',
      vin: '',
      registrationCardExpiredDATE: null,
      insuranceExpiredDATE: null,
      lastAnnualInspectionDATE: null,
      nextOilReplacementIntervalMiles: 0,
      length: 0,
      dimensionsL: 0,
      dimensionsW: 0,
      dimensionsH: 0,
      dimensionsDetailL: '',
      dimensionsDetailW: '',
      dimensionsDetailH: '',
      strapsQuantity: 0,
      blanketsQuantity: 0,
      eTrackQuantity: 0,
      loadBarsQuantity: 0,
      spareWheels: 0,
      snowChains: 0,
      distanceBetween: 0,

      emptyScaleWeight: 0,
      emptyScaleTicketUPLOAD: '',
      truckInsuranceUPLOAD: '',
      truckRegistrationUPLOAD: '',
      annualInspectionUPLOAD: '',
      maxLoadWeight: 0,
      sleeperSpace: 0,

      liftGate: 0,
      palletJack: 0,
      airRide: 0,
      iccBars: 0,
      reefer: 0,
      isVented: 0,
      isSwingDoors: 0,
      isRollupDoors: 0,
      miles: 0,
      notes: '',
      maintenanceNote: '',
      driverOO: 0,
      syncDriversWithKT: 0,
      samsaraKey: '',
      keeptruckinKey: ''
    },
    loading: false,
    error: false,
    truckAvatarUPLOADUrl: '',
    valid: true,
    menuStart: false,
    menuEnd: false,
    documentsTab: 0,

    registrationCardExpiredDATEMenu: false,
    registrationCardExpiredDATEFormated: '',

    insuranceExpiredDATEMenu: false,
    insuranceExpiredDATEFormated: '',

    lastAnnualInspectionDATEMenu: false,
    lastAnnualInspectionDATEFormated: '',

    isRegistrationCardExpiredDATECorrect: true,
    isInsuranceExpiredDATECorrect: true,
    isLastAnnualInspectionDATEMenuCorrect: true,

    sleeperSpace: false,
    liftGate: false,
    palletJack: false,
    airRide: false,
    iccBars: false,
    reefer: false,
    isVented: false,
    isSwingDoors: false,
    isRollupDoors: false,
    syncDriversWithKT: false,
    isTrailer: false,
    truckTypes: [],
    trailerTypes: [],
    tractorsTrucks: []

  }),
  computed: {
    truckId () {
      return this.id || this.$route.params.id;
    }
  },
  watch: {
  },
  mounted () {
    this.$root.contextSave = () => {
      if (this.$refs.form.validate()) {
        if (this.truckId) {
          this.save();
        } else {
          this.create();
        }
      }
    };
    this.fetchData();
    this.$root.getGlobalSettingsField('trucksTypes').then((response) => {
      if (response.status === 'ok') {
        this.truckTypes = JSON.parse(response.result);
      }
    });
    this.$root.getGlobalSettingsField('trailersTypes').then((response) => {
      if (response.status === 'ok') {
        this.trailerTypes = JSON.parse(response.result);
      }
    });

    this.$root.getTrucksList().then((response) => {
      let i = 0;
      if (response.status === 'ok') {
        this.tractorsTrucks = [{
          id: 0,
          name: this.$root.t('Trucks.NoTruck')
        }];
        for (i = 0; i < response.result.length; i++) {
          this.tractorsTrucks.push({
            id: response.result[i].id,
            name: response.result[i].name
          });
        }
      }
    });

    if (this.$route.params.isTrailer) {
      this.isTrailer = true;
      this.isTrailerChange();
    }
    if (!this.truckId && this.$store.getters.isUserRoleDriverOO) {
      this.fields.driverOO = this.$store.getters.currentUserId;
    }
  },
  methods: {
    onCancel () {
      if (this.isOpenedInModal) {
        this.$emit('on-close');
      } else {
        this.$root.route('/trucks/page/' + this.truckId);
      }
    },
    onSave () {
      if (this.truckId) {
        this.save();
      } else {
        this.create();
      }
    },
    driverOOChanged (value) {
      this.fields.driverOO = value;
    },
    formInput (event) {
      this.$store.commit('setIsCurrentFormUpdated', true);
    },
    isTrailerChange () {
      this.fields.isTrailer = this.isTrailer ? 1 : 0;
    },
    sleeperSpaceChange () {
      this.fields.sleeperSpace = this.sleeperSpace ? 1 : 0;
    },
    liftGateChange () {
      this.fields.liftGate = this.liftGate ? 1 : 0;
    },
    palletJackChange () {
      this.fields.palletJack = this.palletJack ? 1 : 0;
    },
    airRideChange () {
      this.fields.airRide = this.airRide ? 1 : 0;
    },
    iccBarsChange () {
      this.fields.iccBars = this.iccBars ? 1 : 0;
    },
    reeferChange () {
      this.fields.reefer = this.reefer ? 1 : 0;
    },
    isVentedChange () {
      this.fields.isVented = this.isVented ? 1 : 0;
    },
    isSwingDoorsChange () {
      this.fields.isSwingDoors = this.isSwingDoors ? 1 : 0;
    },
    isRollupDoorsChange () {
      this.fields.isRollupDoors = this.isRollupDoors ? 1 : 0;
    },
    syncDriversWithKTChange () {
      this.fields.syncDriversWithKT = this.syncDriversWithKT ? 1 : 0;
    },
    save () {
      this.$dialog.confirm(this.$root.t('Trucks.SaveConfirmation')).then(dialog => {
        this.$store.commit('setIsCurrentFormUpdated', false);
        this.$root.saveTruck(this.fields, this.truckId).then(() => {
          if (this.isOpenedInModal) {
            this.$emit('on-close');
          } else {
            this.$root.route('/trucks/page/' + this.truckId);
          }
        });
      }).catch(() => {
      });
    },
    create () {
      this.$dialog.confirm(this.$root.t('Trucks.CreateConfirmation')).then(dialog => {
        this.$store.commit('setIsCurrentFormUpdated', false);
        this.$root.createTruck(this.fields).then((newId) => {
          this.$root.route('/trucks/page/' + newId);
        });
      }).catch(() => {
      });
    },
    fetchData () {
      if (!this.truckId && !this.isTrucksCreateAllowed) {
        this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
        this.loading = false;
        return;
      }

      if (this.truckId) {
        this.updatingEnabled = true;
        if (!this.isTrucksEditAllowed) {
          this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
          this.loading = false;
          return;
        }
        // Apply fields rights
        this.applyRights('', 'trucks');

        this.loading = true;
        this.$root.getTruck(this.truckId).then((response) => {
          if (response.status === 'ok') {
            for (let k in response.result) {
              if (this.fields.hasOwnProperty(k)) {
                this.fields[k] = response.result[k];
              }
            }
            this.truckAvatarUPLOADUrl = this.$root.getImageUrl('truckAvatar', this.fields.truckAvatarUPLOAD);
            this.isTrailer = this.fields.isTrailer === 1;
            this.sleeperSpace = this.fields.sleeperSpace === 1;
            this.liftGate = this.fields.liftGate === 1;
            this.palletJack = this.fields.palletJack === 1;
            this.airRide = this.fields.airRide === 1;
            this.iccBars = this.fields.iccBars === 1;
            this.reefer = this.fields.reefer === 1;
            this.isVented = this.fields.isVented === 1;
            this.isSwingDoors = this.fields.isSwingDoors === 1;
            this.isRollupDoors = this.fields.isRollupDoors === 1;

            this.syncDriversWithKT = this.fields.syncDriversWithKT === 1;
            // Parse Dates
            this.registrationCardExpiredDATEFormated = this.formatDate(this.fields.registrationCardExpiredDATE);
            this.insuranceExpiredDATEFormated = this.formatDate(this.fields.insuranceExpiredDATE);
            this.lastAnnualInspectionDATEFormated = this.formatDate(this.fields.lastAnnualInspectionDATE);
          } else {
            this.error = response.msg;
          }
          this.$forceUpdate();
          this.loading = false;
          this.$nextTick(() => {
            setTimeout(() => {
              this.$store.commit('setIsCurrentFormUpdated', false);
            }, 1000);
          });
        });
      }
    },
    registrationCardExpiredDateChange () {
      this.registrationCardExpiredDATEFormated = this.formatDate(this.fields.registrationCardExpiredDATE);
    },
    insuranceExpiredDateChange () {
      this.insuranceExpiredDATEFormated = this.formatDate(this.fields.insuranceExpiredDATE);
    },
    lastAnnualInspectionDateChange () {
      this.lastAnnualInspectionDATEFormated = this.formatDate(this.fields.lastAnnualInspectionDATE);
    },
    formatDate (date) {
      if (!date) return null;
      let [year, month, day] = date.substr(0, 10).split('-');
      return `${month}/${day}/${year}`;
    },
    parseDate (date) {
      if (!date) return null;
      const [month, day, year] = date.split('/');
      if (year && month && day) {
        return year + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
      }
      return null;
    }
  }
};
</script>
