<template>
  <v-container fluid>
    <div v-if="error">
      <v-flex class="text-center error pa-2">
        <h1 class="title">{{error}}</h1>
      </v-flex>
    </div>
    <div v-if="!$root.isSettingsAllowed()">
      <v-flex class="text-center error pa-2">
        <h1 class="title">{{$t('Errors.AccessDeniedAuthorizationFailed')}}</h1>
      </v-flex>
    </div>
    <v-layout v-else align-center justify-center wrap>
      <v-flex xs12>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar size="80" :color="(isFullName && !avatarUPLOAD) ? '#015aaa' : null">
                <v-img :src="$root.getImageUrl('avatar', avatarUPLOAD)" v-if="avatarUPLOAD"/>
                <span class="white--text" v-else-if="isFullName">{{avatarLetters}}</span>
                <v-icon large v-else>account_circle</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{firstName}} {{lastName}}
              </v-list-item-title>
              <v-list-item-subtitle v-if="role">
                {{$t('Roles.' + role)}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-flex>

      <v-flex>
        <v-switch
          v-if="(isDriverSwitchToFullUI && $store.getters.isUserRoleDriver) || (isDriverOOSwitchToFullUI && $store.getters.isUserRoleDriverOO)"
          v-model="$root.isFullUI"
          :label="$t('ShowFullUI')"
          inset
          @change="driverSwitchToFullUIChanged"
        ></v-switch>
        <!--<v-btn text class="ma-1" @click="$router.push('/user/page/'+userId)" v-if="userId">
          <v-icon left dark>fa-eye</v-icon>
          {{$t('User.Page')}}
        </v-btn>
        <v-btn text class="ma-1" @click="$router.push('/user/page')" v-else>
          <v-icon left dark>fa-eye</v-icon>
          {{$t('User.MyPage')}}
        </v-btn>-->

        <!-- Driver specific settings-->
        <template v-if="($store.getters.isUserRoleDriver || $store.getters.isUserRoleDriverOO) && !$root.isFullUI">
          <v-list-item v-for="lang in $store.getters.languages" :key="lang.locale" link @click="$root.setLanguageLocale(lang.locale)">
            <v-list-item-icon>
              <v-img style="width:32px;height:16px;" :src="'/img/flags/' + lang.flag + '.svg'"></v-img>
            </v-list-item-icon>
            <v-list-item-title v-text="lang.name"></v-list-item-title>
          </v-list-item>

          <v-list-item @click="$root.toggleDarkTheme">
            <v-list-item-icon>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">fa-adjust</v-icon>
                </template>
                {{$t('LeftMenu.InvertColors')}}
              </v-tooltip>
            </v-list-item-icon>
            <v-list-item-title>{{$t('LeftMenu.InvertColors')}}</v-list-item-title>
          </v-list-item>
        </template>
        <v-divider/>
        <template v-if="$root.isInvoicesViewAllowed && $store.getters.isUserRoleDriverOO">
          <v-list-item @click="$root.route('/invoices/list')">
            <v-list-item-icon>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">fa-file-invoice-dollar</v-icon>
                </template>
                {{$t('LeftMenu.Trucks')}}
              </v-tooltip>
            </v-list-item-icon>
            <v-list-item-title>{{$t('LeftMenu.Invoices')}}</v-list-item-title>
          </v-list-item>
        </template>
        <template v-if="$root.isTrucksViewAllowed && $store.getters.isUserRoleDriverOO">
          <v-list-item @click="$root.route('/trucks/list')">
            <v-list-item-icon>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">fa-truck-moving</v-icon>
                </template>
                {{$t('LeftMenu.Trucks')}}
              </v-tooltip>
            </v-list-item-icon>
            <v-list-item-title>{{$t('LeftMenu.Trucks')}}</v-list-item-title>
          </v-list-item>
        </template>
        <v-tabs v-model="tab" align-with-title center-active show-arrows active-class="tabs-active-color">
          <v-tab key="basicSettings"
                 v-if="isBasicFieldsAvailable">
            <v-icon left>mdi-account</v-icon>{{ $t('CreateUser.BasicInformation') }}
          </v-tab>
          <v-tab key="adminSettings"
                 v-if="isAdminFieldsAvailable">
            <v-icon left>mdi-account-details</v-icon>{{ $t('CreateUser.AdministratorInformation') }}
          </v-tab>
          <v-tab key="engineerSettings"
                 v-if="isEngineerFieldsAvailable">
            <v-icon left>mdi-account-details</v-icon>{{ $t('CreateUser.EngineerInformation') }}
          </v-tab>
          <v-tab key="dispatcherSettings"
                 v-if="isDispatcherFieldsAvailable">
            <v-icon left>mdi-account-details</v-icon>{{ $t('CreateUser.DispatcherInformation') }}
          </v-tab>
          <v-tab key="accountingManagerSettings"
                 v-if="isAccountingManagerFieldsAvailable">
            <v-icon left>mdi-account-details</v-icon>{{ $t('CreateUser.AccountingManagerInformation') }}
          </v-tab>
          <v-tab key="brokerSettings"
                 v-if="isBrokerFieldsAvailable">
            <v-icon left>mdi-account-details</v-icon>{{ $t('CreateUser.BrokerInformation') }}
          </v-tab>
          <v-tab key="driverSettings"
                 v-if="isDriverFieldsAvailable || isDriverOwnerOperatorFieldsAvailable">
            <v-icon left>mdi-account-details</v-icon>{{ ownerOperator ? $t('CreateUser.DriverOwnerOperatorInformation') : $t('CreateUser.DriverInformation') }}
          </v-tab>

          <!--<v-tab key="driverRates" v-if="isDriverFieldsAvailable">
            <v-icon left>mdi-currency-usd</v-icon>{{$t('User.Fields.driverRates')}}
          </v-tab>-->

          <v-tab key="accessRights"
                 v-if="!isOwnSettings && role !== 'SuperAdministrator' && $root.isAccessRightsAllowed()">
            <!--<v-icon left>mdi-account-lock</v-icon>{{ $t('User.Settings.AccessRightForUser', { firstName: firstName, lastName: lastName }) }}-->
            <v-icon left>mdi-account-lock</v-icon>{{ $t('LeftMenu.AccessRights') }}
          </v-tab>
          <!--<v-tab key="accessRightsForRole"
                 v-if="!isOwnSettings && role !== 'SuperAdministrator' && $root.isAccessRightsAllowed()">
            <v-icon left>mdi-account-lock</v-icon>{{ $t('User.Settings.AccessRightForRole', { role: role }) }}
          </v-tab>-->
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item key="basicSettings"
                      v-if="isBasicFieldsAvailable">
            <v-card>
              <v-card-text>
                <BasicFields v-if="role"
                             :updatingEnabled="true"
                             :userId="userId"
                             :role="role"
                             :isOwnSettings="isOwnSettings"
                             :key="basicFieldsModalKey"/>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="adminSettings"
                      v-if="isAdminFieldsAvailable">
            <v-card>
              <v-card-text>
                <AdminFields :updatingEnabled="true"
                             :userId="userId"
                             :isOwnSettings="isOwnSettings"
                             :key="administratorFieldsModalKey"/>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="engineerSettings"
                      v-if="isEngineerFieldsAvailable">
            <v-card>
              <v-card-text>
                <EngineerFields :updatingEnabled="true"
                                :userId="userId"
                                :isOwnSettings="isOwnSettings"
                                :key="engineerFieldsModalKey"/>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="dispatcherSettings"
                 v-if="isDispatcherFieldsAvailable">
            <v-card>
              <v-card-text>
                <DispatcherFields :updatingEnabled="true"
                                  :userId="userId"
                                  :isOwnSettings="isOwnSettings"
                                  :key="dispatcherFieldsModalKey"/>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="accountingManagerSettings"
                      v-if="isAccountingManagerFieldsAvailable">
            <v-card>
              <v-card-text>
                <AccountingManagerFields :updatingEnabled="true"
                                         :userId="userId"
                                         :isOwnSettings="isOwnSettings"
                                         :key="accountingManagerFieldsModalKey"/>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="brokerSettings"
                      v-if="isBrokerFieldsAvailable">
            <v-card>
              <v-card-text>
                <BrokerFields :updatingEnabled="true"
                              :userId="userId"
                              :isOwnSettings="isOwnSettings"
                              :key="brokerFieldsModalKey"/>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="driverSettings"
                      v-if="isDriverFieldsAvailable || isDriverOwnerOperatorFieldsAvailable">
            <v-card>
              <v-card-text>
                <DriverOwnerOperatorFields ref="driverOOFields"
                                           v-if="ownerOperator"
                                           :ownerOperator="ownerOperator"
                                           @ownerOperatorDisable="switchDriverType(false)"
                                           :updatingEnabled="true"
                                           :userId="userId"
                                           :isOwnSettings="isOwnSettings"
                                           :key="driverFieldsModalKey"/>

                <DriverFields ref="driverFields"
                              v-if="!ownerOperator"
                              :ownerOperator="ownerOperator"
                              @ownerOperatorEnable="switchDriverType(true)"
                              :updatingEnabled="true"
                              :userId="userId"
                              :isOwnSettings="isOwnSettings"
                              :key="driverFieldsModalKey"/>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!--<v-tab-item key="driverRates" v-if="isDriverFieldsAvailable">
            <DriverRates
              v-if="isDriverFieldsAvailable && $root.isFullFinancialDataAllowedToView && (!isFieldHidden('driverRates') || !isFieldReadOnly('driverRates'))"
              :readonly="isFieldReadOnly('driverRates')"
              :userId="userId"
            />
          </v-tab-item>-->

          <v-tab-item key="accessRights"
                      v-if="!isOwnSettings && role !== 'SuperAdministrator' && $root.isAccessRightsAllowed()">
            <v-card>
              <v-card-text>
                <AccessRightsList v-if="role"
                                  :role="role"
                                  :type="'User'"
                                  :selectedUser="isOwnSettings ? $store.getters.currentUser.id : userId"
                                  :key="accessRightsModalKey"/>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!--<v-tab-item key="accessRightsForRole"
                 v-if="!isOwnSettings && role !== 'SuperAdministrator' && $root.isAccessRightsAllowed()">
            <AccessRightsList :role="role"
                              :type="'Role'"
                              :selectedUser="0"
                              :key="accessRightsRoleModalKey"/>
          </v-tab-item>-->
        </v-tabs-items>

        <v-list outlined v-if="($store.getters.role === 'Driver' || $store.getters.role === 'DriverOwnerOperator') && !$root.isFullUI">
            <v-divider/>
            <v-list-item @click="$root.logout">
              <v-list-item-title>{{$t('Logout')}}</v-list-item-title>
              <v-list-item-action><v-icon>mdi-exit-run</v-icon></v-list-item-action>
            </v-list-item>
        </v-list>

        <template v-if="($store.getters.role === 'Driver' || $store.getters.role === 'DriverOwnerOperator') && !$root.isFullUI">
          {{$t('User.Settings.DriverAppVersion')}} v{{driverVersion}}
          <br/>
          <v-btn v-if="($store.getters.isUserRoleDriver || $store.getters.isUserRoleDriverOO) && $store.getters.appDriverVersion !== null && $store.getters.appDriverVersion !== driverVersion"
                 small
                 width="100%"
                 color="success"
                 @click="reloadNewVersion">{{$t('User.Settings.NewVersionAvailable')}}</v-btn>
        </template>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import BasicFields from './BasicFields.vue';
import AdminFields from './AdminFields.vue';
import EngineerFields from './EngineerFields.vue';
import DriverFields from './DriverFields.vue';
import DriverOwnerOperatorFields from './DriverOwnerOperatorFields.vue';
import DispatcherFields from './DispatcherFields.vue';
import AccountingManagerFields from './AccountingManagerFields.vue';
import BrokerFields from './BrokerFields.vue';
import AccessRightsList from './../../components/AccessRightsList.vue';
import DispatcherReport from './../../views/Reports/DispatcherReport.vue';
import DriverAppReport from './../Reports/DriverAppReport.vue';
import { version, driverVersion } from '../../../package.json';

export default {
  props: ['id'],
  components: { BasicFields, AdminFields, EngineerFields, DriverFields, DriverOwnerOperatorFields, DispatcherFields, AccountingManagerFields, BrokerFields, AccessRightsList, DriverAppReport, DispatcherReport },
  data: () => ({
    loading: false,
    version: version,
    driverVersion: driverVersion,
    error: '',
    tab: null,
    role: '',
    firstName: '',
    lastName: '',
    avatarUPLOAD: '',
    statusIsActive: 0,
    ownerOperator: false,
    isDriverSwitchToFullUI: false,
    isDriverOOSwitchToFullUI: false,
    dateRange: [],
    offset: 0,
    mode: 'day',
    timeframe: 'day',
    dateRangePickerKey: new Date().getTime(),
    dateRangeOptions: {},
    reportKey: new Date().getTime(),
    panels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    publicPath: process.env.BASE_URL,
    basicFieldsModal: false,
    basicFieldsModalKey: 'basicFieldsModal',
    administratorFieldsModal: false,
    administratorFieldsModalKey: 'administratorFieldsModal',
    engineerFieldsModal: false,
    engineerFieldsModalKey: 'engineerFieldsModal',
    dispatcherFieldsModal: false,
    dispatcherFieldsModalKey: 'dispatcherFieldsModal',
    accountingManagerFieldsModal: false,
    accountingManagerFieldsModalKey: 'accountingManagerFieldsModal',
    brokerFieldsModal: false,
    brokerFieldsModalKey: 'brokerFieldsModal',
    driverFieldsModal: false,
    driverFieldsModalKey: 'driverFieldsModal',
    reportsModal: false,
    reportsModalKey: 'reportsModalKey',
    accessRightsModal: false,
    accessRightsModalKey: 'accessRightsModalKey',
    accessRightsRoleModal: false,
    accessRightsRoleModalKey: 'accessRightsRoleModalKey',
    isBasicFieldsAvailable: false,
    isAdminFieldsAvailable: false,
    isEngineerFieldsAvailable: false,
    isDriverFieldsAvailable: false,
    isDriverOwnerOperatorFieldsAvailable: false,
    isDispatcherFieldsAvailable: false,
    isAccountingManagerFieldsAvailable: false,
    isBrokerFieldsAvailable: false
  }),
  computed: {
    userId () {
      return this.id || this.$route.params.id;
    },
    isOwnSettings () {
      return !this.userId;
    },
    isFullName () {
      return this.firstName && this.lastName;
    },
    fullName () {
      return this.firstName + ' ' + this.lastName;
    },
    avatarLetters () {
      return (this.firstName && this.lastName) ? this.firstName.charAt(0) + this.lastName.charAt(0) : '';
    },
    substractIntervalCode () {
      if (this.mode === 'day') {
        return 'd';
      } else if (this.mode === 'week') {
        return 'w';
      } else if (this.mode === 'month') {
        return 'M';
      } else if (this.mode === 'year') {
        return 'Y';
      }
      return 'd';
    }
  },
  watch: {
  },
  mounted () {
    this.$nextTick(() => {
      if (this.$root.getLocalStorageItem('userReportsTimeFrame')) {
        this.timeframe = this.$root.getLocalStorageItem('userReportsTimeFrame');
        this.mode = this.timeframe;
      }

      if (!this.dateRange[0] || !this.dateRange[1]) {
        this.updateDatesRange();
      }

      this.$root.getAccessRightsByField('settings', 'driverSwitchToFullUI').then((response) => {
        if (response.status === 'ok') {
          const rights = response.rights;
          let _r = this.$root.getAccessRightsArrayFromNumber(rights);
          if (_r[0] === '0' && _r[1] === '1' && _r[2] === '1') {
            this.isDriverSwitchToFullUI = true;
          }
        }
      });

      this.$root.getAccessRightsByField('settings', 'driverOwnerOperatorSwitchToFullUI').then((response) => {
        if (response.status === 'ok') {
          const rights = response.rights;
          let _r = this.$root.getAccessRightsArrayFromNumber(rights);
          if (_r[0] === '0' && _r[1] === '1' && _r[2] === '1') {
            this.isDriverOOSwitchToFullUI = true;
          }
        }
      });

      this.fetchData().then(() => {
        this.tab = 1;
      });
    });
  },
  methods: {
    reloadNewVersion () {
      this.$dialog.confirm(this.$root.t('User.Settings.UpdateMsg')).then(dialog => {
        this.$root.reloadNewVersion();
      });
    },
    onAccessRightsModal () {
      this.accessRightsModal = true;
      this.accessRightsModalKey = 'accessRightsModal' + new Date().getTime();
    },
    onAccessRightsRoleModal () {
      this.accessRightsRoleModal = true;
      this.accessRightsRoleModalKey = 'accessRightsRoleModal' + new Date().getTime();
    },
    onBasicFieldsModal () {
      this.basicFieldsModal = true;
      this.basicFieldsModalKey = 'basicFieldsModal' + new Date().getTime();
    },
    onAdminFieldsModal () {
      this.administratorFieldsModal = true;
      this.administratorFieldsModalKey = 'administratorFieldsModal' + new Date().getTime();
    },
    onEngineerFieldsModal () {
      this.engineerFieldsModal = true;
      this.engineerFieldsModalKey = 'engineerFieldsModal' + new Date().getTime();
    },
    onDispatcherFieldsModal () {
      this.dispatcherFieldsModal = true;
      this.dispatcherFieldsModalKey = 'dispatcherFieldsModal' + new Date().getTime();
    },
    onAccountingManagerFieldsModal () {
      this.accountingManagerFieldsModal = true;
      this.accountingManagerFieldsModalKey = 'accountingManagerFieldsModal' + new Date().getTime();
    },
    onBrokerFieldsModal () {
      this.brokerFieldsModal = true;
      this.brokerFieldsModalKey = 'brokerFieldsModal' + new Date().getTime();
    },
    onDriverFieldsModal () {
      this.driverFieldsModal = true;
      this.driverFieldsModalKey = 'driverFieldsModal' + new Date().getTime();
    },
    onReportsModal () {
      this.$set(this, 'reportsModal', true);
      this.$set(this, 'reportsModalKey', 'reportsModalKey' + new Date().getTime());
    },
    driverSwitchToFullUIChanged (value) {
      this.$set(this.$root, 'isFullUI', value);
      this.$root.setLocalStorageItem('isFullUI', this.$root.isFullUI);
    },
    setFields (fields, userRole, rights = null) {
      let i = 0;
      const _fields = Object.keys(fields);
      this.$set(this, 'role', this.isOwnSettings ? this.$store.getters.role : userRole);
      this.$set(this, 'ownerOperator', parseInt(fields.driverOwnerOperatorSWITCH) === 1);

      for (i = 0; i < _fields.length; i++) {
        if (BasicFields.data().fields.hasOwnProperty(_fields[i])) {
          this.isBasicFieldsAvailable = true;
          break;
        }
      }

      if (this.role === this.$store.getters.roleAdministrator) {
        for (i = 0; i < _fields.length; i++) {
          if (AdminFields.data().fields.hasOwnProperty(_fields[i])) {
            this.isAdminFieldsAvailable = true;
            break;
          }
        }
      } else if (this.role === this.$store.getters.roleDispatcher) {
        for (i = 0; i < _fields.length; i++) {
          if (DispatcherFields.data().fields.hasOwnProperty(_fields[i])) {
            this.isDispatcherFieldsAvailable = true;
            break;
          }
        }
      } else if (this.role === this.$store.getters.roleBroker) {
        for (i = 0; i < _fields.length; i++) {
          if (BrokerFields.data().fields.hasOwnProperty(_fields[i])) {
            this.isBrokerFieldsAvailable = true;
            break;
          }
        }
      } else if (this.role === this.$store.getters.roleAccountingManager) {
        for (i = 0; i < _fields.length; i++) {
          if (AccountingManagerFields.data().fields.hasOwnProperty(_fields[i])) {
            this.isAccountingManagerFieldsAvailable = true;
            break;
          }
        }
      } else if (this.role === this.$store.getters.roleEngineer) {
        for (i = 0; i < _fields.length; i++) {
          if (EngineerFields.data().fields.hasOwnProperty(_fields[i])) {
            this.isEngineerFieldsAvailable = true;
            break;
          }
        }
      } else if (this.role === this.$store.getters.roleDriver) {
        for (i = 0; i < _fields.length; i++) {
          if (DriverFields.data().fields.hasOwnProperty(_fields[i])) {
            this.isDriverFieldsAvailable = true;
            break;
          }
        }
      } else if (this.role === this.$store.getters.roleDriverOwnerOperator) {
        for (i = 0; i < _fields.length; i++) {
          if (DriverOwnerOperatorFields.data().fields.hasOwnProperty(_fields[i])) {
            this.isDriverOwnerOperatorFieldsAvailable = true;
            break;
          }
        }
      }

      if (this.$root.getPagePath() === '/user/settings') {
        //this.panels = [];
      } else {
        //this.panels = [1, 2, 3, 4, 5, 6, 7];
        this.panels = [];
      }
    },
    switchDriverType (isOwnerOperator) {
      this.ownerOperator = isOwnerOperator;
      if (this.ownerOperator) {
        this.$refs.driverOOFields.initData();
        this.$refs.driverOOFields.loadImages();
        this.$refs.driverOOFields.applyRights(this.isOwnSettings ? '' : this.role);
      } else {
        this.$refs.driverFields.initData();
        this.$refs.driverFields.loadImages();
        this.$refs.driverFields.applyRights(this.isOwnSettings ? '' : this.role);
      }
    },
    fetchData () {
      this.loading = true;
      // TODO handle reject
      if (!this.$root.isSettingsAllowed()) {
        return new Promise((resolve, reject) => { resolve(''); this.loading = false; });
      }
      return new Promise((resolve, reject) => {
        if (this.isOwnSettings) {
          this.$root.loadSettingsFields().then((response) => {
            if (response.status === 'ok') {
              this.$nextTick(() => {
                this.setFields(response.fields, response.role, response.rights);
                this.firstName = response.fields['firstName'] || '';
                this.lastName = response.fields['lastName'] || '';
                this.avatarUPLOAD = response.fields['avatarUPLOAD'] || '';
                this.statusIsActive = response.fields['statusIsActive'] || 0;
              });
            } else if (response.status === 'error') {
              this.error = response.msg;
            }
            resolve(response);
          }).finally(() => {
            this.loading = false;
          });
        } else {
          this.$root.loadUserFields(this.userId).then((response) => {
            if (response.status === 'ok') {
              this.$nextTick(() => {
                this.setFields(response.fields, response.role, response.rights);
                this.firstName = response.fields['firstName'] || '';
                this.lastName = response.fields['lastName'] || '';
                this.avatarUPLOAD = response.fields['avatarUPLOAD'] || '';
                this.statusIsActive = response.fields['statusIsActive'] || 0;
              });
            } else if (response.status === 'error') {
              this.error = response.msg;
            }
            resolve(response);
          }).finally(() => {
            this.loading = false;
          });
        }
      });
    },
    clear () {
      this.fetchData();
    },
    remountReports () {
      this.reportKey = new Date().getTime();
    },
    onTimeFrameChanged () {
      this.timeframe = this.mode;
      this.offset = 0;
      this.updateDatesRange();
      this.$forceUpdate();
      this.$root.setLocalStorageItem('userReportsTimeFrame', this.timeframe);
    },
    updateDatesRange () {
      if (this.mode === 'all') {
        this.dateRange[0] = '01/01/1900';
        this.dateRange[1] = '01/01/3000';
      } else {
        this.dateRange[0] = this.$moment.moment().utcOffset(this.$store.getters.timezoneOffsetMinutes).subtract(this.offset, this.substractIntervalCode).startOf(this.mode).format('MM/DD/YYYY');
        this.dateRange[1] = this.$moment.moment().utcOffset(this.$store.getters.timezoneOffsetMinutes).subtract(this.offset, this.substractIntervalCode).endOf(this.mode).format('MM/DD/YYYY');
      }
      this.dateRangeOptions.startDate = this.dateRange[0];
      this.dateRangeOptions.endDate = this.dateRange[1];
      this.dateRangePickerKey = new Date().getTime();
    },
    prevInterval () {
      this.offset++;
      this.updateDatesRange();
      this.remountReports();
      this.$forceUpdate();
    },
    nextInterval () {
      if (this.offset === 0) return;
      this.offset--;
      this.updateDatesRange();
      this.remountReports();
      this.$forceUpdate();
    },
    dateRangeChange () {
      this.remountReports();
      this.$forceUpdate();
    }
  }
};
</script>
