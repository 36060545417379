<template>
  <div>
    <div v-if="error !== ''">
      <v-flex class="text-center error pa-2">
        <h1 class="title">{{error}}</h1>
      </v-flex>
    </div>
    <div v-else>
      <v-card class="ma-1 mb-0" :loading="loading" style="border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;">
        <v-card-title>
          {{$t('Trucks.AnnualInspectionsList')}}
          <v-btn class="ml-1 success" @click="onOpenReportModal()" small><v-icon left small>mdi-chart-tree</v-icon>{{$t('Trucks.NewAnnualInspectionReport')}}</v-btn>
        </v-card-title>
        <v-card-text  style="max-height: 200px; overflow-y: auto;">
          <v-list dense>
            <template v-for="(item,index) in items">
              <v-list-item :key="index" @click="onOpenReportModal(item.id, item.fileUID, item.reportNumber)">
                <v-list-item-title>#{{item.reportNumber}}&nbsp;<b>{{item.reportDATE}}</b><br/>
                  {{$t('CreatedBy')}}:
                    <UserSnippet :key="item.reportDATE + item.creator.id"
                                 :avatar-only="true"
                                 :user-data="item.creator"
                                 :small="true"
                                 :override-tooltip="item.creator.firstName + ' ' + item.creator.lastName"
                    />
                    <a :key="'name' + item.reportDATE + item.creator.id"
                        href="#"
                        @click.stop="$root.onShowUserPreview(item.creator.id, item.creator.firstName + ' ' + item.creator.lastName)"
                    >
                      {{item.creator.firstName}} {{item.creator.lastName}}
                    </a>
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-btn small @click.stop="onDownload(item.id, item.truckId, item.reportNumber)">
                      <v-icon :left="!$root.isMobile">mdi-download</v-icon><span v-if="!$root.isMobile">{{$t('Download')}}</span>
                    </v-btn>
                  </v-list-item-action>
              </v-list-item>
              <v-divider :key="'divider' + index"/>
            </template>
          </v-list>
        </v-card-text>
      </v-card>

      <v-dialog v-model="annualInspectionModal" scrollable fullscreen transition="dialog-bottom-transition">
        <v-card tile>
          <v-toolbar fixed dense dark :color="$root.currentTheme.primary" style="flex: 0;">
            <v-toolbar-items>
              <v-btn icon @click="annualInspectionModal = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar-items>
            <v-toolbar-title>
              {{$t('Trucks.AnnualInspectionReport')}}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pt-5">
            <AnnualInspectionTable :truck-id="truckData.id"
                                   :report-id="selectedReportId"
                                   :reportNumber="selectedReportNumber"
                                   :fileUID="selectedFileUID"
                                   :is-read-only="selectedReportId > 0"
                                   :last-report-number="lastReportNumber || 0"
                                   :key="'AnnualInspectionTable' + selectedReportId + selectedReportKey"
                                   :companyId="$root.companyId"
                                   @close="() => {fetchData(); annualInspectionModal = false;}"
                                   @download="() => {onDownload(selectedReportId, truckId, selectedReportNumber);}"
                                   @print="onPrint"
                                   @save-as-pdf="saveAsPdf"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { Buffer } from 'buffer';
import trucksMixin from './../../mixins/trucksMixin';
import UserSnippet from './../../components/UserSnippet';
import AnnualInspectionTable from './AnnualInspectionTable';

export default {
  props: ['truckId', 'truckName', 'truckData'],
  components: { AnnualInspectionTable, UserSnippet },
  mixins: [ trucksMixin ],
  data () {
    return {
      loading: false,
      error: '',
      valid: false,
      items: {},
      fetchKey: new Date().getTime(),
      addDialog: false,
      annualInspectionModal: false,
      selectedReportId: null,
      selectedFileUID: null,
      selectedReportNumber: null,
      selectedReportKey: 0,
      lastReportNumber: 0
    };
  },
  mounted () {
    this.$nextTick(() => {
      this.fetchData();
    });
  },
  watch: {
  },
  beforeDestroy () {
  },
  methods: {
    onPrint (truckId, selectedReportId) {
      this.$root.route('/reports/print/truckAnnualInspection/' + this.$root.companyId + '/' + truckId + '/' + '0' + '/' + '0' + '/' + '0' + '/0/' + selectedReportId + '/0/0');
    },
    onDownload (id, truckId, reportNumber) {
      const userId = parseInt(this.$store.getters.currentUser.id);
      const filename = this.truckName.replace(/\s+/g, '_') + '_AnnualInspection_' + reportNumber + '.pdf';
      const url = this.$store.getters.host + '/api/reports/pdf/' + this.$store.getters.token + '/' + this.$root.companyId + '/' + userId + '/truckAnnualInspection/' + truckId + '/0/0/0/0/' + id + '/' + encodeURIComponent(filename) + '/0/0';
      window.open(url, '_blank');
    },
    onOpenReportModal (id = null, fileUID = null, reportNumber = null) {
      this.selectedReportId = id;
      this.selectedFileUID = fileUID;
      this.selectedReportNumber = reportNumber;
      this.selectedReportKey = new Date().getTime();
      this.annualInspectionModal = true;
    },
    downloadAnnualInspectionReport (fileUID, reportNumber) {
      const url = this.$root.getImageUrl('annualInspection', fileUID, this.truckName.replace(/\s+/g, '_') + '_AnnualInspection_' + reportNumber + '.pdf');
      window.open(url, '_blank');
    },
    saveAsPdf (reportId, truckId, truckName, date) {
      this.$root.getReportPdfData('truckAnnualInspection', truckId, 0, 0, 0, 0, reportId).then(response => {
        let dst = Buffer.from(response.data);
        const truckNameStr = truckName.replace(/\s+/g, '_');
        const dateStr = this.$moment.moment(date).format('YYYY-MM-DD');
        let _filename = 'AnnualInspectionReport_' + truckNameStr + '_' + dateStr;
        const file = new File([dst], _filename + '.pdf', {
          type: 'application/pdf',
          lastModified: new Date().getTime()
        });
        let list = new DataTransfer();
        list.items.add(file);
        this.$emit('annual-inspection-files', list.files, reportId);
        this.fetchData();
        this.selectedReportKey = new Date().getTime();
        this.annualInspectionModal = false;
      });
    },
    fetchData (showLoading = true) {
      let i = 0;
      if (!this.$root.isTrucksViewAllowed) {
        this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
        this.loading = false;
        return;
      }
      if (showLoading) {
        this.loading = true;
      }
      this.$root.getAnnualInspectionsList(this.truckId).then((response) => {
        if (response.status === 'ok') {
          this.items = [];
          this.lastReportNumber = parseInt(response.lastReportNumber);
          for (i = 0; i < response.results.length; i++) {
            let item = response.results[i];
            item.creator = {
              id: response.results[i].userCreatedId,
              firstName: response.results[i].userCreatedFirstName,
              lastName: response.results[i].userCreatedLastName,
              avatarUPLOAD: response.results[i].userCreatedAvatarUPLOAD
            };
            this.items.push(item);
          }
        }
      }).finally(() => {
        this.fetchKey = new Date().getTime();
        this.loading = false;
      });
    }
  }
};
</script>
