export default {
  data: function () {
    return {
      filtersKey: 'filtersKey' + new Date().getTime(),
      isFilterInitialized: false,
      filtersItems: [
        {
          name: 'filterDispatcher',
          label: 'Dispatcher',
          field: '',
          multiple: true,
          list: [
          ]
        },
        {
          name: 'filterDriver',
          label: 'Driver',
          field: '',
          multiple: true,
          list: [
          ]
        },
        {
          name: 'filterTruck',
          label: 'Truck',
          field: '',
          multiple: true,
          list: [
          ]
        },
        {
          name: 'filterBroker',
          label: 'Broker',
          field: '',
          multiple: true,
          list: [
          ]
        }
      ]
    };
  },
  computed: {
    isReportsViewAllowed () {
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.menuRights['Reports']);
      }
      return false;
    },
    isReportsEditAllowed () {
      if (this.$store.getters.menuRights) {
        return this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['Reports']);
      }
      return false;
    },
    isTrucksReportsViewAllowed () {
      if (this.$store.getters.reportsRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.reportsRights['trucksReport']);
      }
      return false;
    },
    isLoadsReportsViewAllowed () {
      if (this.$store.getters.reportsRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.reportsRights['loadsReport']);
      }
      return false;
    },
    isInvoicesReportsViewAllowed () {
      if (this.$store.getters.reportsRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.reportsRights['invoicesReport']);
      }
      return false;
    },
    isDriversReportsViewAllowed () {
      if (this.$store.getters.reportsRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.reportsRights['driversReport']);
      }
      return false;
    },
    isDriversOwnerOperatorsReportsViewAllowed () {
      if (this.$store.getters.reportsRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.reportsRights['driversOwnerOperatorsReport']);
      }
      return false;
    },
    isDispatchersReportsViewAllowed () {
      if (this.$store.getters.reportsRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.reportsRights['dispatchersReport']);
      }
      return false;
    },
    isLoadsFeedReportsViewAllowed () {
      if (this.$store.getters.reportsRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.reportsRights['loadsFeedReport']);
      }
      return false;
    },
    isLoadsVerificationReportsViewAllowed () {
      if (this.$store.getters.reportsRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.reportsRights['loadsVerificationReport']);
      }
      return false;
    },
    isTrucksMaintenanceReportsViewAllowed () {
      if (this.$store.getters.reportsRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.reportsRights['trucksMaintenanceReport']);
      }
      return false;
    },
    isExpensesReportsViewAllowed () {
      if (this.$store.getters.reportsRights) {
        return this.$root.isAccessRightAllowView(this.$store.getters.reportsRights['expensesReport']);
      }
      return false;
    },
    isSelectedHavePayed () {
      let i = 0;
      for (i = 0; i < this.selectedItems.length; i++) {
        if (this.selectedItems[i].isPaid) {
          return true;
        }
      }
      return false;
    },
    isSelectedHaveNotPayed () {
      let i = 0;
      for (i = 0; i < this.selectedItems.length; i++) {
        if (!this.selectedItems[i].isPaid) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    filterBrokerSearch (val) {
      if (!val || val.length === 0) return;
      this.$root.loadUsersList(this.$store.getters.roleBroker, false, true, 1, val).then(response => {
        if (response.status === 'ok') {
          this.filterBrokersList = response.result.data.map((item) => {
            return {
              id: item.id,
              name: item.firstName + ' ' + item.lastName
            };
          });
          this.$refs.filtersPanel.filters.forEach(el => {
            if (el.name === 'filterBroker') {
              el.list = this.filterBrokersList;
            }
          });
        }
      });
    },
    formatLoadType (type) {
      if (type === 'PTL') return 'PL';
      if (type === 'RTL') return 'RL';
      if (type === 'LTL') return 'LL';
      return type;
    },
    getLoadsFeedReportData (from, to) {
      return this.$root.get('/api/reports/getReportLoadsFeed/' + from + '/' + to);
    },
    getDriverShiftsReportData (from, to, driverId = 0) {
      return this.$root.get('/api/reports/getReportShifts/' + from + '/' + to + '/' + driverId);
    },
    getDriverShiftsReportDataById (driverId = 0, shiftId = 0) {
      return this.$root.get('/api/reports/getReportShifts/0/0/' + driverId + '/' + shiftId);
    },
    getLoadsReportData (from, to, truckId = 0, driverId = 0, dispatcherId = 0, isDriverOO = false) {
      return this.$root.get('/api/reports/getReportLoads/' + from + '/' + to + '/' + truckId + '/' + driverId + '/' + dispatcherId + '/' + isDriverOO);
    },
    getDriversReportTotalData (from, to, driverId = 0) {
      return this.$root.get('/api/reports/getReportDriversLoadsTotal/' + from + '/' + to + '/' + driverId);
    },
    getTrucksReportTotalData (from, to, truckId = 0) {
      return this.$root.get('/api/reports/getReportTrucksTotal/' + from + '/' + to + '/' + truckId);
    },
    getDispatchersReportTotalData (from, to, dispatcherId = 0) {
      return this.$root.get('/api/reports/getReportDispatchersTotal/' + from + '/' + to + '/' + dispatcherId);
    },
    getInvoicesReportData (from, to, truckId = '', driverId = '', dispatcherId = '', status = 'All', brokerId = '') {
      return this.$root.get('/api/reports/getReportInvoices/' + from + '/' + to + '/' + (truckId || 'All') + '/' + (driverId || 'All') + '/' + (dispatcherId || 'All') + '/' + (status || 'All') + '/' + (brokerId || 'All'));
    },
    getInvoicesReportDataByCompany (companyId, from, to, truckId = 'All', driverId = 'All', dispatcherId = 'All', status = 'All', brokerId = 'All') {
      return this.$root.get('/api/reports/getReportInvoices/' + from + '/' + to + '/' + (truckId || 'All') + '/' + (driverId || 'All') + '/' + (dispatcherId || 'All') + '/' + (status || 'All') + '/' + (brokerId || 'All'), companyId);
    },
    getTrucksMilesReportData (from, to) {
      return this.$root.get('/api/reports/getReportTruckMiles/' + from + '/' + to);
    },
    getReportsTrucksList (from, to) {
      return this.$root.get('/api/reports/getReportsTrucksList/' + from + '/' + to);
    },
    getReportsDriversList (from, to) {
      return this.$root.get('/api/reports/getReportsDriversList/' + from + '/' + to);
    },
    getReportsDriversOOList (from, to) {
      return this.$root.get('/api/reports/getReportsDriversOOList/' + from + '/' + to);
    },
    getReportsDispatchersList (from, to) {
      return this.$root.get('/api/reports/getReportsDispatchersList/' + from + '/' + to);
    },
    getReportRefined (from, to) {
      return this.$root.get('/api/reports/getReportRefined/' + from + '/' + to);
    },
    getReportTrucksMaintenance (from, to, user = 0, truck = 0, type = 'All', truckTrailer = 'All') {
      return this.$root.get('/api/reports/getReportTruckMaintenance/' + from + '/' + to + '/' + user + '/' + truck + '/' + type + '/' + truckTrailer);
    },
    getReportExpenses (from, to, user, truck, product, type, method) {
      return this.$root.get('/api/reports/getReportExpenses/' + from + '/' + to + '/' + user + '/' + truck + '/' + product + '/' + type + '/' + method);
    },
    /*setReportLoadsStatuses (loadsIds, userId, statusText, statusDT = '', table = '') {
      return new Promise((resolve, reject) => {
        // this.$dialog.confirm(this.$root.t('Reports.MarkAsPayedConfirmation')).then(dialog => {
        let body = {
          loads_ids: loadsIds,
          user_id: userId,
          status_text: statusText,
          table_name: table
        };

        if (statusDT !== '') {
          body['status_dt'] = statusDT;
        }

        this.$root.post('/api/reports/setReportStatuses', body).then(response => {
          resolve(response);
        });
      });
    },*/
    removeReportLoadsStatuses (loadsIds, userId, statusText, table = '') {
      return new Promise((resolve, reject) => {
        this.$root.post('/api/reports/removeReportStatuses', {
          loads_ids: loadsIds,
          user_id: userId,
          status_text: statusText,
          table_name: table
        }).then(response => {
          resolve(response);
        });
      });
    },
    getReportsGroupList (userId, type, showDeleted = false, overridePaidField = '', truckId = 0) {
      return this.$root.post('/api/reports/getReportsGroupsList/' + type + '/' + userId + '/' + (showDeleted ? 'true' : 'false'), {
        overridePaidField: overridePaidField,
        truckId: truckId
      });
    },
    getLastReportsGroupNum (userId, reportType) {
      return this.$root.post('/api/reports/getLastReportsGroupNumber', {
        user_id: userId,
        type_value: reportType
      });
    },
    markAsVerified (userId, loadId, status = 1, note = '') {
      return this.$root.post('/api/reports/markAsVerified', {
        userId: userId,
        loadId: loadId,
        status: status,
        note: note
      });
    },
    createReportsGroup (loadsIDs, totalsCounts, userId, typeValue, fromDate, toDate, isDOT = false) {
      return new Promise((resolve, reject) => {
        this.$dialog.confirm(this.$root.t('Reports.CreateReportsGroupConfirmation')).then(dialog => {
          this.$root.post('/api/reports/groupReport', {
            loads_ids: loadsIDs,
            totals_counts: totalsCounts,
            user_id: userId,
            type_value: typeValue,
            from_date: fromDate,
            to_date: toDate,
            isDOT: isDOT ? 1 : 0
          }).then(response => {
            resolve(response);
          });
        }).catch(() => {
          resolve({ status: 'cancelled' });
        });
      });
    },
    restoreReport (id) {
      return new Promise((resolve, reject) => {
        this.$dialog.confirm(this.$root.t('RestoreConfirmation')).then(dialog => {
          this.$root.post('/api/reports/reportsGroupSave/' + id, { deleted: 0 }).then((response) => {
            if (response.status === 'ok') {
              this.$root.toast(this.$i18n.t('Reports.ReportRestored'), { color: 'success' });
              resolve();
            } else {
              this.$root.toast(this.$i18n.t('Reports.ReportRestoreError'), { color: 'error' });
              reject(response.sgm);
            }
          }, (error) => {
            console.error('ERROR', error);
            reject(error);
          });
        }).catch(() => {
          resolve({ status: 'cancelled' });
        });
      });
    },
    deleteReport (id) {
      return new Promise((resolve, reject) => {
        this.$dialog.confirm(this.$root.t('DeleteConfirmation')).then(dialog => {
          this.$root.post('/api/reports/reportsGroupSave/' + id, { deleted: 1 }).then((response) => {
            if (response.status === 'ok') {
              this.$root.toast(this.$i18n.t('Reports.ReportDeleted'), { color: 'success' });
              resolve();
            } else {
              this.$root.toast(this.$i18n.t('Reports.ReportDeleteError'), { color: 'error' });
              reject(response.sgm);
            }
          }, (error) => {
            console.error('ERROR', error);
            reject(error);
          });
        }).catch(() => {
          resolve({ status: 'cancelled' });
        });
      });
    },
    markSelectedAsPaid (selectedItems, userId, itemType, fieldName) {
      this.$root.paidLoadsUpdateData = {
        selectedItems: selectedItems,
        userId: userId,
        itemType: itemType,
        fieldName: fieldName
      };
      this.$root.changePaidDateTime = selectedItems[0].currentDateTime;
      this.$root.isChangePaidDateModal = true;
    },
    updateSelectedAsPaid () {
      return new Promise(async resolve => {
        let i = 0;
        for (i = 0; i < this.$root.paidLoadsUpdateData.selectedItems.length; i++) {
          if (!this.$root.paidLoadsUpdateData.selectedItems[i].isPaid && this.$root.paidLoadsUpdateData.selectedItems[i].isVerified) {
            await this.$root.setOverriddenFieldValue(
              this.$root.paidLoadsUpdateData.userId,
              this.$root.paidLoadsUpdateData.itemType,
              this.$root.paidLoadsUpdateData.selectedItems[i].id,
              this.$root.paidLoadsUpdateData.fieldName,
              this.$root.paidLoadsUpdateData.selectedItems[i].toPayAmount,
              this.$root.changePaidDateTime,
              '');
          }
        }
        // this.fetchData(true);
        this.$root.refresh();
        this.$root.paidLoadsUpdateData = {};
        this.$root.isChangePaidDateModal = false;
        resolve();
      });
    },
    markSelectedAsNotPaid (selectedItems, userId, itemType, fieldName) {
      return new Promise(async resolve => {
        let i = 0;
        for (i = 0; i < selectedItems.length; i++) {
          if (selectedItems[i].isPaid) {
            await this.$root.removeOverriddenFieldValue(userId, itemType, selectedItems[i].id, fieldName);
          }
        }
        this.fetchData(true);
        resolve();
      });
    },
    markSelectedAsPayedAndCreateReportGroup (selectedItems, userId, itemType, fieldName, reportNumber, typeValue) {
      let i = 0;
      return new Promise(async resolve => {
        for (i = 0; i < selectedItems.length; i++) {
          if (!selectedItems[i].isPaid) {
            await this.$root.setOverriddenFieldValue(userId, itemType, selectedItems[i].id, fieldName, selectedItems[i].toPayAmount, selectedItems[i].currentDateTime, '');
          }
        }
        this.createReport(selectedItems, userId, reportNumber, typeValue);
        resolve();
      });
    },
    markAsNotPayed (loadId, userId, table) {
      this.$dialog.confirm(this.$root.t('Reports.MarkAsNotPayedConfirmation')).then(dialog => {
        this.removeReportLoadsStatuses([loadId], userId, 'payed', table).then((response) => {
          if (response.status === 'ok') {
            this.removeReportLoadsStatuses([loadId], userId, 'amount', table).then((response2) => {
              if (response2.status === 'ok') {
                this.fetchData(true);
              }
            });
          }
        });
      });
    },
    resetPayment (userId, itemType, itemId, fieldName) {
      this.$dialog.confirm(this.$root.t('Reports.MarkAsNotPayedConfirmation')).then(dialog => {
        this.$root.removeOverriddenFieldValue(userId, itemType, itemId, fieldName).then((response) => {
          if (response.status === 'ok') {
            this.fetchData(true);
          }
        });
      });
    },
    createReport (selectedItems, userId, typeValue, isDOT = false) {
      let i = 0;
      let selected = [];
      for (i = 0; i < selectedItems.length; i++) {
        selected.push(parseInt(selectedItems[i]['id']));
      }

      let fromDate = this.$root.parseDate((selectedItems[0].startedPST || selectedItems[0].createdPST).substring(0, 10));
      let toDate = this.$root.parseDate((selectedItems[selectedItems.length - 1].endedPST || selectedItems[selectedItems.length - 1].createdPST).substring(0, 10));

      this.fetchData(false, selected).then((reportData) => {
        this.createReportsGroup(selected, reportData, userId, typeValue, fromDate, toDate, isDOT).then((response) => {
          if (response.status === 'ok') {
            // this.updateLastReportsGroupNumber(userId, this.$route.params.type);
            this.reportsGroupsListKey = 'reportsGroupsListKey' + new Date().getTime();
            this.fetchData(true);
            this.$root.toast(this.$i18n.t('Reports.ReportCreatedSuccessfully'), { color: 'success' });
          } else {
            this.$root.toast(this.$i18n.t('Reports.ReportCreatedError'), { color: 'error' });
          }
        });
      });
    },
    /*updateLastReportsGroupNumber (userId, reportType) {
      this.getLastReportsGroupNum(userId, reportType).then((response) => {
        if (response.status === 'ok') {
          this.lastReportsGroupNumber = response.result ? parseInt(response.result) : 0;
        }
      });
    },*/
    getTotalRate (fields, noLocaleString = false) {
      const result = parseFloat(parseFloat(parseFloat(fields.Rate || '0') + parseFloat(fields.RTLRate || '0') + parseFloat(fields.additionalPayments || '0')).round(2));
      return noLocaleString ? result : result.toLocaleString();
    },
    getReportFiltersData () {
      return this.$root.get('/api/reports/getReportFiltersData');
    },
    initReportsFilters () {
      let isFilterDataUpdated = false;
      this.getReportFiltersData().then(response => {
        let f = 0;
        // Init filters
        isFilterDataUpdated = false;
        for (f = 0; f < this.filtersItems.length; f++) {
          if (this.filtersItems[f].name === 'filterDispatcher') {
            if (!this.filtersItems[f].list || this.filtersItems[f].list.length === 0) {
              this.$set(this.filtersItems[f], 'list', response.dispatchersList);
              isFilterDataUpdated = true;
            }
          }
          if (this.filtersItems[f].name === 'filterDriver') {
            if (!this.filtersItems[f].list || this.filtersItems[f].list.length === 0) {
              this.$set(this.filtersItems[f], 'list', response.driversList);
              isFilterDataUpdated = true;
            }
          }
          if (this.filtersItems[f].name === 'filterTruck') {
            if (!this.filtersItems[f].list || this.filtersItems[f].list.length === 0) {
              this.$set(this.filtersItems[f], 'list', response.trucksList);
              isFilterDataUpdated = true;
            }
          }
        }
        if (isFilterDataUpdated) {
          this.isFilterInitialized = false;
          this.filtersKey = new Date().getTime();
        }
      });
    },
    onPaid (itemId, userId, fieldName, type, amount, dateTime = '', note = '') {
      this.$root.overrideFieldReportId = 0;
      this.$root.overrideFieldUser = userId;
      this.$root.overrideFieldName = fieldName;
      this.$root.overrideFieldItemType = type;
      this.$root.overrideFieldItemId = itemId;
      this.$root.overrideFieldPrefix = '$';
      this.$root.overrideFieldValue = this.$root.moneyNumber(amount, 2);
      this.$root.overrideFieldDate = dateTime === '' ? this.currentDateTime : dateTime;
      this.$root.overrideFieldNote = note;
      this.$root.overrideFieldHaveDate = true;

      this.$root.overrideFieldDialog = true;
      this.$root.overrideFieldKey = new Date().getTime();
    },

    onPaidReport (reportId, userId, fieldName, type, dateTime = '', note = '') {
      this.$root.overrideFieldReportId = reportId;
      this.$root.overrideFieldUser = userId;
      this.$root.overrideFieldName = fieldName;
      this.$root.overrideFieldItemType = type;
      this.$root.overrideFieldItemId = 0;
      this.$root.overrideFieldPrefix = '$';
      this.$root.overrideFieldValue = 0;
      this.$root.overrideFieldDate = dateTime === '' ? this.currentDateTime : dateTime;
      this.$root.overrideFieldNote = note;
      this.$root.overrideFieldHaveDate = true;

      this.$root.overrideFieldDialog = true;
      this.$root.overrideFieldKey = new Date().getTime();
    },
    onReportToggleChecked (item, itemId, userId, type, status = 0) {
      this.$set(item, 'loading', true);
      if (status === 0) {
        this.$set(item, 'isChecked', !item.isChecked);
      }
      if (status === 100) {
        this.$set(item, 'isVerified', !item.isVerified);
      }
      this.$root.post('/api/reports/toggleReportItemCheck', {
        userId: userId,
        itemId: itemId,
        itemType: type,
        itemStatus: status
      }).then((response) => {
        this.$set(item, 'loading', false);
        this.$root.toast(this.$i18n.t('Checked'), { color: 'success' });
      });
    },
    expensesColor (item, driverId = 0, truckId = 0) {
      const expensesArray = JSON.parse(item.expensesJSON || '[]');

      if (driverId > 0) {
        return item.isPendingExpenses ? 'warning' : expensesArray.filter((e => parseInt(e.driver) === parseInt(driverId))).length > 0 ? 'success' : null;
      }
      if (truckId > 0) {
        return item.isPendingExpenses ? 'warning' : expensesArray.filter((e => parseInt(e.truck) === parseInt(truckId))).length > 0 ? 'success' : null;
      }
      return item.isPendingExpenses ? 'warning' : expensesArray.length > 0 ? 'success' : null;
    },
    addNewExpense (item) {
      return this.$root.post('/api/loads/addNewExpense', item);
    },
    setDispatchersTotalsHeaders () {
      this.$store.dispatch('reports/totalsCounts', {
        loadsCount: 0,
        totalLoadsRate: 0,
        totalAdditionalPayments: 0,
        totalLoadsRateWithAdditionalPayments: 0,
        totalAvgLoadsRatePerMile: 0,
        totalDispatcherPaymentFromRate: 0,
        totalDispatcherPaymentFromBonus: 0,
        totalDispatcherRate: 0
      });
      this.$store.dispatch('reports/totalsCountsNames', {
        loadsCount: 'Loads',
        totalLoadsRate: 'Initial Loads Rate',
        totalAdditionalPayments: 'Additional Payments',
        totalLoadsRateWithAdditionalPayments: 'Total Loads Rate',
        totalAvgLoadsRatePerMile: 'Avg. Rate Per Mile',
        totalDispatcherPaymentFromRate: 'Salary From Loads Rate',
        totalDispatcherPaymentFromBonus: 'Salary From Bonus',
        totalDispatcherRate: 'Salary'
      });
      this.$store.dispatch('reports/totalsCountsTypes', {
        loadsCount: 'Number',
        totalLoadsRate: 'Money',
        totalAdditionalPayments: 'Money',
        totalLoadsRateWithAdditionalPayments: 'Money',
        totalAvgLoadsRatePerMile: 'Money',
        totalDispatcherPaymentFromRate: 'Money',
        totalDispatcherPaymentFromBonus: 'Money',
        totalDispatcherRate: 'Money'
      });
    },
    setLoadsTotalsHeaders () {
      this.$store.dispatch('reports/totalsCounts', {
        loadsCount: 0,
        totalLoadsDistance: 0,
        totalLoadsRate: 0,
        totalAvgLoadsRatePerMile: 0,
        totalAdditionalPayments: 0,
        totalLoadsExpenses: 0,
        totalLoadsGross: 0,
        totalLoadsNet: 0
      });
      this.$store.dispatch('reports/totalsCountsNames', {
        loadsCount: 'Loads',
        totalLoadsDistance: 'Distance',
        totalLoadsRate: 'Total Loads Rate',
        totalAvgLoadsRatePerMile: 'Avg. Rate Per Mile',
        totalAdditionalPayments: 'Additional Payments',
        totalLoadsExpenses: 'Expenses',
        totalLoadsGross: 'Gross',
        totalLoadsNet: 'Net'
      });
      this.$store.dispatch('reports/totalsCountsTypes', {
        loadsCount: 'Number',
        totalLoadsDistance: 'Miles',
        totalLoadsRate: 'Money',
        totalAvgLoadsRatePerMile: 'Money',
        totalAdditionalPayments: 'Money',
        totalLoadsExpenses: 'Money',
        totalLoadsGross: 'Money',
        totalLoadsNet: 'Money'
      });
    },
    setInvoicesTotalsHeaders () {
      this.$store.dispatch('reports/totalsCounts', {
        totalCount: 0,
        totalPaid: 0,
        totalAmount: 0,
        totalReceived: 0,
        totalExpected: 0,
        totalFactoring: 0,
        totalExpectedFactoring: 0,
        totalReceivedAdv: 0
      });
      this.$store.dispatch('reports/totalsCountsNames', {
        totalCount: 'Invoices Count',
        totalPaid: 'Invoices Paid',
        totalAmount: 'Amount',
        totalReceived: 'Total Received',
        totalExpected: 'Expected',
        totalFactoring: 'Factoring Fees',
        totalExpectedFactoring: 'Expected Factoring Fees',
        totalReceivedAdv: 'Received Adv.'
      });
      this.$store.dispatch('reports/totalsCountsTypes', {
        totalCount: 'Number',
        totalPaid: 'Number',
        totalAmount: 'Money',
        totalReceived: 'Money',
        totalExpected: 'Money',
        totalFactoring: 'Money',
        totalExpectedFactoring: 'Money',
        totalReceivedAdv: 'Money'
      });
    },
    setDriversTotalsHeaders () {
      this.$store.dispatch('reports/totalsCounts', {
        shiftsCount: 0,
        totalMiles: 0,
        totalDuration: 0,
        totalDetention: 0,
        totalCompensations: 0,
        totalDeductions: 0,
        totalBonuses: 0,
        totalRate: 0,
        totalSalary: 0
      });
      this.$store.dispatch('reports/totalsCountsNames', {
        shiftsCount: 'Trips',
        totalMiles: 'Miles Driven',
        totalDuration: 'Duration',
        totalDetention: 'Detention',
        totalCompensations: 'Compensations',
        totalDeductions: 'Deductions',
        totalBonuses: 'Bonuses',
        totalRate: 'Total Rate',
        totalSalary: 'Total Salary'
      });
      this.$store.dispatch('reports/totalsCountsTypes', {
        shiftsCount: 'Number',
        totalMiles: 'Miles',
        totalDuration: 'Time',
        totalDetention: 'Money',
        totalCompensations: 'Money',
        totalDeductions: 'Money',
        totalBonuses: 'Money',
        totalRate: 'Money',
        totalSalary: 'Money'
      });
    },
    setTrucksTotalsHeaders () {
      this.$store.dispatch('reports/totalsCounts', {
        loadsCount: 0,
        totalTruckMilesByMaps: 0,
        totalTruckMilesByOdometer: 0,
        totalLoadsRate: 0,
        totalAdditionalPayments: 0,
        avgTruckRatePerMileByMaps: 0,
        avgTruckRatePerMileByOdometer: 0,
        totalGrossIncome: 0,
        dispatchersSalary: 0,
        driversSalary: 0,
        totalExpenses: 0,
        totalNetIncome: 0
      });
      this.$store.dispatch('reports/totalsCountsNames', {
        loadsCount: 'Loads',
        totalTruckMilesByMaps: 'Planned Miles',
        totalTruckMilesByOdometer: 'Actual Miles',
        totalLoadsRate: 'Loads Rate',
        totalAdditionalPayments: 'Additional Rate',
        avgTruckRatePerMileByMaps: 'Per Mile Planned',
        avgTruckRatePerMileByOdometer: 'Per Mile Actual',
        totalGrossIncome: 'Gross',
        dispatchersSalary: 'Dispatchers Salary',
        driversSalary: 'Drivers Salary',
        totalExpenses: 'Expenses',
        totalNetIncome: 'Net'
      });
      this.$store.dispatch('reports/totalsCountsTypes', {
        loadsCount: 'Number',
        totalTruckMilesByMaps: 'Miles',
        totalTruckMilesByOdometer: 'Miles',
        totalLoadsRate: 'Money',
        totalAdditionalPayments: 'Money',
        avgTruckRatePerMileByMaps: 'MoneyDecimal',
        avgTruckRatePerMileByOdometer: 'MoneyDecimal',
        totalGrossIncome: 'Money',
        dispatchersSalary: 'Money',
        driversSalary: 'Money',
        totalExpenses: 'Money',
        totalNetIncome: 'Money'
      });
    },
    setExpensesTotalsHeaders () {
      this.$store.dispatch('reports/totalsCounts', {
        count: 0,
        loadsCount: 0,
        trucksCount: 0,
        expensesByTrucks: 0,
        toCompensate: 0,
        bonuses: 0,
        deductions: 0,
        total: 0
      });
      this.$store.dispatch('reports/totalsCountsNames', {
        count: 'Expenses Count',
        loadsCount: 'Loads Count',
        trucksCount: 'Trucks Count',
        expensesByTrucks: 'By Trucks',
        toCompensate: 'To Compensate',
        bonuses: 'Bonuses',
        deductions: 'Deductions',
        total: 'Total'
      });
      this.$store.dispatch('reports/totalsCountsTypes', {
        count: 'Number',
        loadsCount: 'Number',
        trucksCount: 'Number',
        expensesByTrucks: 'Money',
        toCompensate: 'Money',
        bonuses: 'Money',
        deductions: 'Money',
        total: 'Money'
      });
    },
    setTruckMaintenanceTotalsHeaders () {
      this.$store.dispatch('reports/totalsCounts', {
        count: 0,
        trucksCount: 0
      });
      this.$store.dispatch('reports/totalsCountsNames', {
        count: 'Maintenances',
        trucksCount: 'Trucks'
      });
      this.$store.dispatch('reports/totalsCountsTypes', {
        count: 'Number',
        trucksCount: 'Number'
      });
    },
  }
};
