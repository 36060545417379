<template>
  <div>
    <div v-if="error">
      <v-flex class="text-center error pa-2">
        <h1 class="title">{{error}}</h1>
      </v-flex>
    </div>
    <div v-else>
      <v-overlay :value="loading" :absolute="true">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>
      <v-layout class="ml-1 mt-5 mb-1" wrap>
        <v-flex shrink>
          <v-btn icon v-if="$route.path !== '/'" @click="fields.isTrailer ? $root.route('/trucks/list/1') : $root.route('/trucks/list')" small text class="mt-1">
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>
        </v-flex>
        <v-flex grow class="text-left text-no-wrap">
          <h2 class="text-left pl-2">
            {{ fields.name }}
            <template v-if="fields.truckLinkedToTruckId"><v-icon>mdi-link</v-icon>
              <v-chip class="ma-1" @click="$root.route('/trucks/page/' + fields.truckLinkedToTruckId)"><v-icon>mdi-truck-trailer</v-icon>{{fields.truckLinkedToTruckName}}</v-chip>
            </template>
            <template v-if="fields.linkedTruckId"><v-icon>mdi-link</v-icon>
              <v-chip class="ma-1" @click="$root.route('/trucks/page/' + fields.linkedTruckId)"><v-icon>fa-truck-moving</v-icon>{{fields.linkedTruckName}}</v-chip>
            </template>
            <v-btn  v-if="!$root.isHideTopEditBtn"
                   class="ml-1"
                   color="success"
                   :icon="$root.isMobile"
                   @click="$root.onShowTruckEditPreview(truckId, fields.name)"
                   :small="!$root.isMobile">
                     <v-icon :left="!$root.isMobile">mdi-pen</v-icon>
                     {{$root.isMobile ? '' : $t('Edit')}}
            </v-btn>
            <!--<v-btn class="ml-1" :icon="$root.isMobile" @click="showDynamicMap = !showDynamicMap;" small><v-icon :left="!$root.isMobile" :small="!$root.isMobile">mdi-map</v-icon>{{$root.isMobile ? '' : $t('ShowDynamicMap')}}</v-btn>
            <v-btn class="ml-1" :icon="$root.isMobile" @click="showTruckPhotos = !showTruckPhotos;" small><v-icon :left="!$root.isMobile" :small="!$root.isMobile">mdi-image</v-icon>{{$root.isMobile ? '' : $t('Trucks.ShowTrucksImages')}}</v-btn>-->
          </h2>
        </v-flex>
        <v-flex xs12 md6 class="text-right text-no-wrap" grow>
          <v-layout>
            <v-spacer/>
            <v-flex shrink class="text-right">
              <v-select
                class="mr-1"
                v-model="fields.status"
                :label="$root.t('Trucks.Status')"
                @change="onTruckStatus"
                dense outlined hide-details
                :items="truckStatusesItems"
              />
            </v-flex>
            <v-flex shrink>
              <v-btn class="mr-1 mb-1" :disabled="prevTruckId === 0" @click="$root.route('/trucks/page/' + prevTruckId)"><v-icon>mdi-chevron-left</v-icon></v-btn>
              <v-btn class="mr-1 mb-1" :disabled="nextTruckId === 0" @click="$root.route('/trucks/page/' + nextTruckId)"><v-icon>mdi-chevron-right</v-icon></v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-tabs v-model="tabs" grow :show-arrows="true" centered @change="tabChanged" active-class="tabs-active-color">
        <v-tab key="maintenance" href="#maintenance" ><v-icon left>mdi-hammer-wrench</v-icon>{{ $t('Trucks.Maintenance') }}</v-tab>
        <v-tab key="information" href="#information" ><v-icon left>mdi-information</v-icon>{{ $t('Trucks.Information') }}</v-tab>
        <v-tab key="documents" href="#documents" ><v-icon left>mdi-file-multiple</v-icon>{{ $t('Trucks.Documents') }}</v-tab>
        <v-tab key="report" href="#report" v-if="!fields.isTrailer && $root.isFullFinancialDataAllowedToView"><v-icon left>mdi-file-chart</v-icon>{{ $t('Trucks.ReportLoads') }}</v-tab>
        <v-tab key="reportMaintenance" href="#reportMaintenance" v-if="$root.isFullFinancialDataAllowedToView"><v-icon left>mdi-file-chart</v-icon>{{ $t('Trucks.ReportMaintenance') }}</v-tab>
        <v-tab key="photos" href="#photos" ><v-icon left>mdi-image-multiple</v-icon>{{ $t('Trucks.Photos') }}</v-tab>
        <v-tab key="map" href="#map" ><v-icon left>mdi-map</v-icon>{{ $t('Trucks.Map') }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item v-if="isMaintenanceInformationAllowedToView" key="maintenance" value="maintenance">
          <!--<v-card class="ma-1" v-if="oilReplacementHistory.length > 0">
            <v-card-title>
              {{$t('Trucks.OilReplacementHistory')}}
              <v-btn class="ml-1 mr-1 success" small @click="onOilChange">
                <v-icon left small>fa-oil-can</v-icon>{{$t('Trucks.UpdateOilChange')}}
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-simple-table width="100%" height="300px" fixed-header>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-center">{{$t('Trucks.Fields.lastOilReplacementDATE')}}</th>
                    <th class="text-center">{{$t('Trucks.Fields.lastOilReplacementMiles')}}</th>
                    <th class="text-center">{{$t('Trucks.Fields.lastOilReplacementUser')}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, key) in oilReplacementHistory" :key="item.replaceDateMDY + key">
                    <td class="text-center" style="white-space:nowrap;">{{item.replaceDateMDY}}</td>
                    <td class="text-center" style="white-space:nowrap;">{{parseInt(item.miles).toLocaleString()}}</td>
                    <td class="text-center" style="white-space:nowrap;">
                      <a v-if="item.userId > 0" @click="$root.route('/user/page/'+item.userId)">{{item.userFullName}}</a>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>-->
          <v-layout wrap>
            <v-flex md6 xs12>
              <MaintenanceList v-if="isMaintenanceInformationAllowedToView"
                               :truck-id="truckId"
                               :truck-name="fields.name"
                               ref="maintenanceList"
                               @fetch="fetchData()"/>
              <template v-if="!isFieldHidden('annualInspectionUPLOAD')">
                <AnnualInspectionsList :truck-id="truckId"
                                       :truck-name="fields.name"
                                       :truck-data="fields"
                                       @annual-inspection-files="annualInspectionFiles"/>
                <v-card v-show="1 === 0" class="mt-0 ml-1 mr-1" style="border-top-left-radius: 0px; border-top-right-radius: 0px;">
                  <v-card-text>
                    <MultiFileUpload :field="fields.annualInspectionUPLOAD"
                                     ref="annualInspectionFiles"
                                     :height="400"
                                     @change="fileChange('annualInspectionUPLOAD', $event)"
                                     fieldName="annualInspectionUPLOAD"
                                     :label="$t('Trucks.Fields.annualInspectionUPLOAD')"
                                     uploadFolder="annualInspection"
                                     :downloadAllowed="isFieldDownloadAllowed('annualInspectionUPLOAD')"
                                     :readonly="true"
                    />
                  </v-card-text>
                </v-card>
              </template>
            </v-flex>
            <v-flex md6 xs12>
              <v-card class="ma-1" v-if="isMaintenanceInformationAllowedToView">
                <v-card-title>{{$t('Trucks.MaintenanceStatus')}}</v-card-title>
                <v-list>
                  <v-list-item v-for="(truckNotification,index) in truckNotifications" :key="truckNotification.name+truckNotification.type+index" @click.stop="onMaintenanceTypeInfo(truckNotification)">
                    <v-list-item-icon>
                      <v-icon :color="getNotificationColor(truckNotification)" v-html="getNotificationIcon(truckNotification)"/>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{truckNotification.name}}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="truckNotification.hasOwnProperty('parts') && truckNotification.parts.length > 0">
                        <b>{{$t('Trucks.LastUsedParts')}}:</b>
                        <template v-for="part in truckNotification.parts">
                          {{part.partAmount ? 'x' + part.partAmount : ''}}&nbsp;{{part.partName}}&nbsp;{{part.partCode ? '[' + part.partCode + ']' : ''}},&nbsp;
                        </template>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="truckNotification.note">
                        {{truckNotification.note}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <template v-if="truckNotification.hasOwnProperty('scheduledMiles') && truckNotification.scheduledMiles">
                        <v-chip :color="truckNotification.scheduledOverdueMiles < 0 ? 'error' : 'info'">{{$t('scheduledFor')}}:&nbsp;{{truckNotification.scheduledMiles.toLocaleString()}}{{$t('milesShort')}}</v-chip>
                        <span v-if="truckNotification.hasOwnProperty('scheduledMiles') && truckNotification.scheduledMiles && truckNotification.scheduledOverdueMiles > 0">({{$tc('User.MilesLeft', Math.abs(truckNotification.scheduledOverdueMiles))}})</span>
                        <span v-if="truckNotification.hasOwnProperty('scheduledMiles') && truckNotification.scheduledMiles && truckNotification.scheduledOverdueMiles <= 0">({{$tc('User.MilesOverdue', Math.abs(truckNotification.scheduledOverdueMiles))}})</span>
                      </template>
                      <template v-if="truckNotification.hasOwnProperty('scheduledDATE') && truckNotification.scheduledDATE">
                        <v-chip :color="truckNotification.scheduledOverdueDays > 0 ? 'error' : 'info'">{{$t('scheduledFor')}}:&nbsp;{{$root.formatDate(truckNotification.scheduledDATE)}}</v-chip>
                        <span v-if="truckNotification.hasOwnProperty('scheduledDATE') && truckNotification.scheduledDATE && truckNotification.scheduledOverdueDays <= 0">({{$tc('User.DaysLeft', Math.abs(truckNotification.scheduledOverdueDays))}})</span>
                        <span v-if="truckNotification.hasOwnProperty('scheduledDATE') && truckNotification.scheduledDATE && truckNotification.scheduledOverdueDays > 0">({{$tc('User.DaysOverdue', Math.abs(truckNotification.scheduledOverdueDays))}})</span>
                      </template>
                      <template v-if="truckNotification.hasOwnProperty('overdueMiles') && truckNotification.overdueMiles">
                        <v-chip v-if="truckNotification.type === 'error'" color="error">
                          {{$t('User.MilesOverdue', { n: parseInt(truckNotification.overdueMiles).toLocaleString() })}}
                        </v-chip>
                        <v-chip v-else :color="truckNotification.type">
                          {{$t('User.MilesLeft', { n: parseInt(truckNotification.overdueMiles).toLocaleString() })}}
                        </v-chip>
                      </template>
                      <template v-if="truckNotification.hasOwnProperty('overdueDays')">
                        <v-chip v-if="truckNotification.overdueDays < 0" color="error">
                          {{$t('User.DaysOverdue', { n: Math.abs(truckNotification.overdueDays) })}}
                        </v-chip>
                        <v-chip v-else :color="truckNotification.type">
                          {{$t('User.DaysLeft', { n: truckNotification.overdueDays })}}
                        </v-chip>
                      </template>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
              <div class="pa-1" v-if="isMaintenanceInformationAllowedToView">
                <TruckFaultCodes :fault-codes="faultCodes" :truck-data="fields"/>
              </div>
              <div class="pa-1" v-if="isMaintenanceInformationAllowedToView">
                <TruckOriginalParts :original-parts="originalParts"/>
              </div>
            </v-flex>
          </v-layout>
        </v-tab-item>
        <v-tab-item key="information" value="information">
          <TruckInformation v-if="fields && fields.id" :fields="fields"></TruckInformation>
        </v-tab-item>
        <v-tab-item key="photos" value="photos">
          <v-carousel v-if="fields.truckAvatarUPLOAD" height="auto">
            <template v-for="(doc, index) in $root.getDocumentsFromField(fields.truckAvatarUPLOAD, 'truckAvatar','')">
              <v-carousel-item :key="index" v-if="doc.url">
                <img class="v-responsive" v-img:images :src="doc.url" style="max-height: 500px; margin: auto;">
              </v-carousel-item>
            </template>
          </v-carousel>
        </v-tab-item>
        <v-tab-item key="map" value="map">
          <v-alert color="error" v-if="!fields.keeptruckinid">
            Motive (KeepTruckin) vehicle ID is not set, please check that vehicle VIN number is the same as in Motive profile.
          </v-alert>
          <GoogleMapLoader v-if="$root.isUseGoogleMaps"
                           ref="map" :truck="true"
                           :truckId="truckId"
                           :height="$root.isMobile ? '200px' : '500px'"
                           :zoom="15"
                           :marker="{lat: fields.lat, lng: fields.lng}"
                           :truckTelemetry="truckTelemetry"
                           :markerName="fields.name"/>
          <OSMMapLoader v-else
                        :height="$root.isMobile ? '300px' : '500px'" ref="map"
                        :showTraffic="false"
                        :showTrucks="false"
                        :truckId="truckId"
                        :marker="{lat: fields.lat, lng: fields.lng}"
                        :truckTelemetry="truckTelemetry"
                        :markerName="fields.name"
                        />
        </v-tab-item>
        <v-tab-item key="documents" value="documents">
          <v-card class="ma-1">
            <v-card-title>
              {{$t('Trucks.Documents')}}
            </v-card-title>
            <v-card-text>
              <v-tabs v-model="documentsTab" grow class="mt-2" show-arrows active-class="tabs-active-color">
                <v-tab key="truckRegistration" v-if="!isFieldHidden('truckRegistrationUPLOAD')">{{ fields.isTrailer ? $t('Trucks.Fields.trailerRegistrationUPLOAD') : $t('Trucks.Fields.truckRegistrationUPLOAD') }}</v-tab>
                <v-tab key="emptyScaleTicket"  v-if="!isFieldHidden('emptyScaleTicketUPLOAD')">{{ $t('Trucks.Fields.emptyScaleTicketUPLOAD') }}</v-tab>
                <v-tab key="truckInsurance"    v-if="!isFieldHidden('truckInsuranceUPLOAD')">{{ fields.isTrailer ? $t('Trucks.Fields.trailerInsuranceUPLOAD') : $t('Trucks.Fields.truckInsuranceUPLOAD') }}</v-tab>
              </v-tabs>
              <v-tabs-items v-model="documentsTab">
                <v-tab-item key="truckRegistration">
                  <v-card class="pa-1">
                    <MultiFileUpload :field="fields.truckRegistrationUPLOAD"
                                     @change="fileChange('truckRegistrationUPLOAD', $event)"
                                     fieldName="truckRegistrationUPLOAD"
                                     :label="$t('Trucks.Fields.truckRegistrationUPLOAD')"
                                     uploadFolder="truckRegistration"
                                     :downloadAllowed="isFieldDownloadAllowed('truckRegistrationUPLOAD')"
                                     :height="650"
                                     :readonly="true"
                                     v-if="!isFieldHidden('truckRegistrationUPLOAD')" />
                  </v-card>
                </v-tab-item>
                <v-tab-item key="emptyScaleTicket">
                  <v-card class="pa-1">
                    <MultiFileUpload :field="fields.emptyScaleTicketUPLOAD"
                                     @change="fileChange('emptyScaleTicketUPLOAD', $event)"
                                     fieldName="emptyScaleTicketUPLOAD"
                                     :label="$t('Trucks.Fields.emptyScaleTicketUPLOAD')"
                                     uploadFolder="emptyScaleTicket"
                                     :downloadAllowed="isFieldDownloadAllowed('emptyScaleTicketUPLOAD')"
                                     :height="650"
                                     :readonly="true"
                                     v-if="!isFieldHidden('emptyScaleTicketUPLOAD')" />
                  </v-card>
                </v-tab-item>
                <v-tab-item key="truckInsurance">
                  <v-card class="pa-1">
                    <MultiFileUpload :field="fields.truckInsuranceUPLOAD"
                                     @change="fileChange('truckInsuranceUPLOAD', $event)"
                                     fieldName="truckInsuranceUPLOAD"
                                     :label="$t('Trucks.Fields.truckInsuranceUPLOAD')"
                                     uploadFolder="truckInsurance"
                                     :downloadAllowed="isFieldDownloadAllowed('truckInsuranceUPLOAD')"
                                     :height="650"
                                     :readonly="true"
                                     v-if="!isFieldHidden('truckInsuranceUPLOAD')" />
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="!fields.isTrailer && $root.isFullFinancialDataAllowedToView" key="report" value="report">
          <v-card class="ma-1" v-if="$root.isAccessRightAllowView($store.getters.menuRights['Reports'])">
            <v-card-title>
              {{$t('Trucks.ReportLoads')}}
            </v-card-title>
            <v-card-text>
              <v-card>
                <v-layout>
                  <v-flex xs12 md4>

                  </v-flex>
                  <v-flex xs12 md4 class="text-center">
                    <template v-if="mode !== 'all'">
                      <v-btn text class="mr-1" @click="prevInterval">
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>
                      <date-range-picker :key="dateRangePickerKey"
                                         v-model="dateRange"
                                         :options="dateRangeOptions"
                                         format="MM/DD/YYYY"
                                         :style="$vuetify.theme.dark ? {color:'#fff'} : null"
                                         @input="dateRangeChange"/>
                      <v-btn text class="ml-1" :disabled="offset <= 0" @click="nextInterval">
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </template>
                  </v-flex>
                  <v-flex xs12 md4 class="text-right">
                    <v-btn-toggle v-model="mode" style="margin-top:-10px;" tile dense group mandatory @change="onTimeFrameChanged">
                      <v-btn value="day">1d</v-btn>
                      <v-btn value="week">1w</v-btn>
                      <v-btn value="month">1m</v-btn>
                      <v-btn value="year">1y</v-btn>
                      <v-btn value="all">all</v-btn>
                    </v-btn-toggle>
                  </v-flex>
                </v-layout>
                <TruckReport :key="reportKey" :from="$root.parseDate(dateRange[0])" :to="$root.parseDate(dateRange[1])" :truckId="truckId"></TruckReport>
              </v-card>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="$root.isFullFinancialDataAllowedToView" key="reportMaintenance" value="reportMaintenance">
          <v-card class="ma-1" v-if="$root.isAccessRightAllowView($store.getters.menuRights['Reports'])">
            <v-card-title>
              {{$t('Trucks.ReportMaintenance')}}
            </v-card-title>
            <v-card-text>
              <v-card>
                <v-layout>
                  <v-flex xs12 md4>

                  </v-flex>
                  <v-flex xs12 md4 class="text-center">
                    <template v-if="mode !== 'all'">
                      <v-btn text class="mr-1" @click="prevInterval">
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>
                      <date-range-picker :key="dateRangePickerKey"
                                         v-model="dateRange"
                                         :options="dateRangeOptions"
                                         format="MM/DD/YYYY"
                                         :style="$vuetify.theme.dark ? {color:'#fff'} : null"
                                         @input="dateRangeChange"/>
                      <v-btn text class="ml-1" :disabled="offset <= 0" @click="nextInterval">
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </template>
                  </v-flex>
                  <v-flex xs12 md4 class="text-right">
                    <v-btn-toggle v-model="mode" style="margin-top:-10px;" tile dense group mandatory @change="onTimeFrameChanged">
                      <v-btn value="day">1d</v-btn>
                      <v-btn value="week">1w</v-btn>
                      <v-btn value="month">1m</v-btn>
                      <v-btn value="year">1y</v-btn>
                      <v-btn value="all">all</v-btn>
                    </v-btn-toggle>
                  </v-flex>
                </v-layout>
                <TruckMaintenanceReport
                  :key="reportKey"
                  :from="$root.parseDate(dateRange[0])"
                  :to="$root.parseDate(dateRange[1])"
                  :truckId="truckId"
                  type="truckMaintenance"
                  :item="truckId"/>
              </v-card>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <v-dialog v-model="maintenanceTypeInfoModal" width="500">
      <v-card>
        <v-card-title>
          {{maintenanceTypeInfoType}}
          <v-spacer></v-spacer>
          <v-btn icon @click="maintenanceTypeInfoModal = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <p v-if="maintenanceTypeInfoLastParts.length > 0">
            <v-card-title>{{$t('Trucks.LastUsedParts')}}:</v-card-title>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>{{$t('Trucks.maintenancePartName')}}</th>
                    <th>{{$t('Trucks.maintenancePartCode')}}</th>
                    <th>{{$t('Trucks.maintenancePartAmount')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="part in maintenanceTypeInfoLastParts" :key="part.id">
                    <td>{{part.partName}}</td>
                    <td>{{part.partCode ? '[' + part.partCode + ']' : ''}}</td>
                    <td>{{part.partAmount ? 'x' + part.partAmount : ''}}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </p>
          <v-textarea v-model="maintenanceTypeInfoNote" dense outlined hide-details/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/><v-btn color="primary" @click="onMaintenanceTypeInfoSave">{{$t('Save')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import GoogleMapLoader from './../../components/GoogleMapLoader.vue';
import OSMMapLoader from './../../components/OSMMapLoader.vue';
import TruckInformation from './../../components/TruckInformation.vue';
import fieldAccessRightMixin from '../../mixins/fieldAccessRightsMixin';
import MultiFileUpload from '../../components/MultiFileUpload.vue';
import TruckReport from '../../views/Reports/TruckReport.vue';
import TruckMaintenanceReport from '../../views/Reports/TruckMaintenanceReport.vue';
import MaintenanceList from './MaintenanceList';
import AnnualInspectionsList from './AnnualInspectionsList';
import TruckOriginalParts from '../../components/TruckOriginalParts';
import TruckFaultCodes from '../../components/TruckFaultCodes';
import { readonly } from 'vue';

export default {
  props: ['id'],
  components: {
    TruckOriginalParts,
    AnnualInspectionsList,
    MaintenanceList,
    GoogleMapLoader,
    TruckInformation,
    MultiFileUpload,
    TruckReport,
    TruckMaintenanceReport,
    TruckFaultCodes,
    OSMMapLoader
  },
  mixins: [fieldAccessRightMixin],
  data () {
    return {
      mounted: false,
      loading: true,
      error: '',
      fields: {},
      step: 0,
      truckTelemetry: {},
      documentsTab: 0,
      dateRange: [],
      offset: 0,
      tabs: null,
      mode: 'day',
      timeframe: 'day',
      dateRangePickerKey: new Date().getTime(),
      dateRangeOptions: {},
      reportKey: new Date().getTime(),
      panels: [0],
      prevTruckId: 0,
      nextTruckId: 0,
      showDynamicMap: false,
      showTruckPhotos: false,
      oilChangeModal: false,
      annualInspectionModal: false,
      lastOilReplacementDATE: null,
      lastOilReplacementMiles: 0,
      lastOilReplacementDATEMenu: false,
      lastOilReplacementDATEFormatted: '',
      oilReplacementHistory: {},
      maintenanceTypeInfoModal: false,
      maintenanceTypeInfoType: '',
      maintenanceTypeInfoLastParts: [],
      maintenanceTypeInfoNote: '',
      originalParts: {},
      truckStatusesItems: [
        { value: 0, text: this.$root.t('Trucks.Statuses.Active') },
        { value: 10, text: this.$root.t('Trucks.Statuses.Abandoned') },
        { value: 20, text: this.$root.t('Trucks.Statuses.Maintenance') },
        { divider: true },
        { value: -1, text: this.$root.t('Trucks.Statuses.Deactivated') }
      ],
      truckNotifications: [],
      faultCodes: [],
      isMaintenanceInformationAllowedToView: false
    };
  },
  computed: {
    truckId () {
      return this.id || this.$route.params.id;
    },
    substractIntervalCode () {
      if (this.mode === 'day') {
        return 'd';
      } else if (this.mode === 'week') {
        return 'w';
      } else if (this.mode === 'month') {
        return 'M';
      } else if (this.mode === 'year') {
        return 'Y';
      }
      return 'd';
    }
  },
  watch: {
    panels: function () {
      this.$root.setLocalStorageItem('truck_panels_' + this.truckId, JSON.stringify(this.panels));
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.$root.getLocalStorageItem('trucksReportsTimeFrame')) {
        this.timeframe = this.$root.getLocalStorageItem('trucksReportsTimeFrame');
        this.mode = this.timeframe;
      }
      if (this.$root.getLocalStorageItem('truck_panels_' + this.truckId)) {
        let _val = JSON.parse(this.$root.getLocalStorageItem('truck_panels_' + this.truckId));
        if (_val) {
          this.panels = _val;
        }
      }

      if (!this.dateRange[0] || !this.dateRange[1]) {
        this.updateDatesRange();
      }
      this.fetchData();
      if (this.$root.getLocalStorageItem('truck_tab')) {
        this.tabs = this.$root.getLocalStorageItem('truck_tab');
      }
      this.mounted = true;
    });
  },

  methods: {
    tabChanged (value) {
      if (!this.mounted) return;
      this.$root.setLocalStorageItem('truck_tab', this.tabs);
    },
    getNotificationColor (notification) {
      if (notification.type === 'success') return 'success';
      if (notification.type === 'error') return 'error';
      if (notification.type === 'scheduled') return 'info';
      return 'warning';
    },
    getNotificationIcon (notification) {
      if (notification.icon) return notification.icon;

      if (notification.type === 'error') return 'mdi-alert';
      if (notification.type === 'scheduled') return 'mdi-wrench-clock';
      return 'mdi-alert-circle';
    },

    onMaintenanceTypeInfo (item) {
      this.$nextTick(() => {
        if (!item.hasOwnProperty('note')) return;
        if (item.type === 'scheduled') {
          this.$refs.maintenanceList.onAddNewModal('', item.name, item.id);
          return;
        }
        this.maintenanceTypeInfoModal = true;
        this.maintenanceTypeInfoType = item.name;
        if (item.hasOwnProperty('parts')) {
          this.maintenanceTypeInfoLastParts = item.parts;
        }
        this.maintenanceTypeInfoNote = item.note;
      });
    },
    onMaintenanceTypeInfoSave () {
      this.$root.updateMaintenanceNote(this.truckId, this.maintenanceTypeInfoType, this.maintenanceTypeInfoNote).then((response) => {
        this.maintenanceTypeInfoModal = false;
        this.fetchData();
      });
    },
    onTruckStatus (val) {
      this.$dialog.confirm(this.$i18n.t('Trucks.ChangeTruckStatusConfirmation')).then(dialog => {
        this.$root.setTruckStatus(this.truckId, val);
        this.fields.status = val;
      }).catch(e => {
        this.$root.refresh();
      });
    },
    onNewOilChange () {
      this.$dialog.confirm(this.$i18n.t('PleaseConfirmYourChoice')).then(dialog => {
        this.$root.updateOilChange(this.truckId, this.lastOilReplacementDATE, this.lastOilReplacementMiles).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Trucks.OilChangeSavedMsg'), { color: 'success' });
            this.oilChangeModal = false;
            this.$root.truckModalKey = new Date().getTime();
            this.$root.refresh();
          }
        });
      });
    },
    onOilChange () {
      this.oilChangeModal = true;
    },
    lastOilReplacementDateChange () {
      this.lastOilReplacementDATEFormatted = this.$root.formatDate(this.lastOilReplacementDATE);
    },
    remountReports () {
      this.reportKey = new Date().getTime();
    },
    onTimeFrameChanged () {
      this.timeframe = this.mode;
      this.offset = 0;
      this.updateDatesRange();
      this.$forceUpdate();
      this.$root.setLocalStorageItem('trucksReportsTimeFrame', this.timeframe);
    },
    updateDatesRange () {
      if (this.mode === 'all') {
        this.dateRange[0] = '01/01/1900';
        this.dateRange[1] = '01/01/3000';
      } else {
        this.dateRange[0] = this.$moment.moment().utcOffset(this.$store.getters.timezoneOffsetMinutes).subtract(this.offset, this.substractIntervalCode).startOf(this.mode).format('MM/DD/YYYY');
        this.dateRange[1] = this.$moment.moment().utcOffset(this.$store.getters.timezoneOffsetMinutes).subtract(this.offset, this.substractIntervalCode).endOf(this.mode).format('MM/DD/YYYY');
      }
      this.dateRangeOptions.startDate = this.dateRange[0];
      this.dateRangeOptions.endDate = this.dateRange[1];
      this.dateRangePickerKey = new Date().getTime();
    },
    prevInterval () {
      this.offset++;
      this.updateDatesRange();
      this.remountReports();
      this.$forceUpdate();
    },
    nextInterval () {
      if (this.offset === 0) return;
      this.offset--;
      this.updateDatesRange();
      this.remountReports();
      this.$forceUpdate();
    },
    dateRangeChange () {
      this.remountReports();
      this.$forceUpdate();
    },
    driverChanged (value, id) {
      this.$root.updateUserFields({ driverTruck: id }, value);
      this.$root.saveTruck({ driver: value }, id);
    },
    codriverChanged (value, id) {
      this.$root.updateUserFields({ driverTruck: id }, value);
      this.$root.saveTruck({ codriver: value }, id);
    },
    fileChange (fieldName, fieldValue) {
      this.fields[fieldName] = fieldValue;
      if (fieldValue && JSON.parse(fieldValue)) {
        let newFieldValueArr = [];
        let fieldValueArr = JSON.parse(fieldValue);
        fieldValueArr.forEach(el => {
          if (el.name.length > 0 && el.hash.length > 0) {
            newFieldValueArr.push(el);
          }
        });
        this.fields[fieldName] = JSON.stringify(newFieldValueArr);
      }
      console.warn('fileChange', this.fields.annualInspectionUPLOAD);
      this.$root.saveTruck({ annualInspectionUPLOAD: this.fields.annualInspectionUPLOAD }, this.truckId).then(() => {
        const files = JSON.parse(fieldValue);
        const lastFile = files[files.length - 1];
        if (lastFile && Object.prototype.hasOwnProperty.call(lastFile, 'hash')) {
          this.$root.saveLastAnnualInspectionReportFile(this.truckId, { fields: { fileUID: lastFile.hash } });
        }
        this.$root.refresh();
      });
    },
    annualInspectionFiles (files) {
      const filesObject = JSON.parse(this.fields.annualInspectionUPLOAD);
      this.$refs.annualInspectionFiles.addAndUpload(files, filesObject && filesObject.length > 0 ? filesObject.length - 1 : 0, false);
      this.$root.refresh();
    },
    fetchData (show_loading = true) {
      if (!this.isTrucksViewAllowed) {
        this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
        this.loading = false;
        return;
      }
      /* this.$root.getTruckOilReplacement(parseInt(this.truckId)).then((response) => {
        if (response.status === 'ok') {
          this.oilReplacementHistory = [];
          for (let i = 0; i < response.results.length; i++) {
            this.oilReplacementHistory.push(response.results[i]);
            this.oilReplacementHistory.push(response.results[i]);
            this.oilReplacementHistory.push(response.results[i]);
            this.oilReplacementHistory.push(response.results[i]);
          }
        }
      }); */

      this.$root.getTruck(this.truckId).then(async (response) => {
        if (response.status === 'ok') {
          this.isMaintenanceInformationAllowedToView = response.isMaintenanceInformationAllowedToView;
          this.prevTruckId = parseInt(response.prev_truck);
          this.nextTruckId = parseInt(response.next_truck);
          for (let k in response.result) {
            this.fields[k] = response.result[k];
          }

          if (this.fields['keeptruckindata']) {
            this.truckTelemetry = JSON.parse(this.fields['keeptruckindata']);
          }

          this.truckNotifications = await this.$root.getTuckNotifications(this.fields, this.$store.getters['globalSettingsFields']);

          this.lastOilReplacementMiles = this.fields.miles;
          this.lastOilReplacementDATE = new Date().toISOString().split('T')[0];
          this.lastOilReplacementDATEFormatted = this.$root.formatDate(this.lastOilReplacementDATE);

          this.originalParts = {};
          for (let i = 0; i < response.originalParts.length; i++) {
            const part = response.originalParts[i];
            if (!this.originalParts.hasOwnProperty(part.maintenaceType)) {
              this.originalParts[part.maintenaceType] = { maintenanceType: part.maintenanceType, parts: [] };
            }
            this.originalParts[part.maintenaceType].parts.push(part);
          }

          if (this.fields.hasOwnProperty('keeptruckingFaultCodes') && this.fields.keeptruckingFaultCodes) {
            const codes = JSON.parse(this.fields.keeptruckingFaultCodes);
            if (codes) {
              this.faultCodes = codes;
            }
          }

          this.$nextTick(() => {
            this.$forceUpdate();
          });
        }
        this.loading = false;
      });
    }
  }
};
</script>
