<template>
  <div>
    <div v-if="itemsLoading">
      <v-skeleton-loader
        v-for="i in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]"
        type="list-item"
        style="width:100%"
      />
    </div>
    <div v-else>
      <v-list dense v-if="items">
        <template v-for="(item_rights, index) in filteredItems">
          <v-list-item  @click="selectGroup(index, item_rights)" :key="index">
            <v-list-item-title>
              <v-layout>
                <v-flex grow>
                  <h3>{{$t('AccessRightsGroup.'+ index)}}</h3>
                </v-flex>
                <!--<v-flex shrink>
                  <v-btn-toggle>
                    <v-btn text small :data-cy="'AccessRightsGroup.' + index + 'Block'" @click.stop="setAllInGroup(index, 0)">
                      <v-icon small :color="isAllInGroup(index, 0) ? 'red' : null">fa-ban</v-icon>
                    </v-btn>
                    <v-btn text small :data-cy="'AccessRightsGroup.' + index + 'View'" @click.stop="setAllInGroup(index, 1)">
                      <v-icon small :color="isAllInGroup(index, 1) ? 'green' : null">fa-eye</v-icon>
                    </v-btn>
                    <v-btn text small :data-cy="'AccessRightsGroup.' + index + 'Download'" @click.stop="setAllInGroup(index, 2)">
                      <v-icon small :color="isAllInGroup(index, 2) ? 'green' : null">fa-file-download</v-icon>
                    </v-btn>
                    <v-btn text small :data-cy="'AccessRightsGroup.' + index + 'Edit'" @click.stop="setAllInGroup(index, 3)">
                      <v-icon small :color="isAllInGroup(index, 3) ? 'green' : null">fa-edit</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </v-flex>-->
              </v-layout>
            </v-list-item-title>
          </v-list-item>
          <v-divider/>
        </template>
      </v-list>
    </div>

    <v-dialog v-model="modal" scrollable :fullscreen="$root.isMobile" persistent>
      <v-card :key="listKey" class="pa-0">
        <v-card-title v-if="selectedIndex">
          {{$t('AccessRightsGroup.' + selectedIndex)}}
          <v-spacer/>
          <v-btn icon @click="modal=false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text style="height:80%">
          <!-- <SelectUser v-if="role"
                      :value="selectedUserInModal"
                      :role="role"
                      @change="userChanged"
                      :label="$t('SelectUser')"
                      :hidePrependIcon="true"/> -->
          <v-text-field dense outlined class="mt-5"
                        prepend-inner-icon="mdi-magnify"
                        placeholder="Start typing to filter by name"
                        v-model="search"/>
          <div v-if="itemsLoading">
            <v-skeleton-loader
              type="table"
              style="width:100%"
            />
          </div>
          <v-list v-else>
            <!-- <v-list-item dense>
              <v-layout>
                <v-flex xs12 class="text-center">
                  {{$t('ApplyToAllGroup')}}<br/>
                  <v-btn-toggle>
                    <v-btn text small :data-cy="'AccessRightsGroup.' + selectedIndex + 'Block'" @click.stop="setAllInGroup(selectedIndex, 0)">
                      <v-icon small :color="isAllInGroup(selectedIndex, 0) ? 'red' : null">fa-ban</v-icon>
                    </v-btn>
                    <v-btn text small :data-cy="'AccessRightsGroup.' + selectedIndex + 'View'" @click.stop="setAllInGroup(selectedIndex, 1)">
                      <v-icon small :color="isAllInGroup(selectedIndex, 1) ? 'green' : null">fa-eye</v-icon>
                    </v-btn>
                    <v-btn text small :data-cy="'AccessRightsGroup.' + selectedIndex + 'Download'" @click.stop="setAllInGroup(selectedIndex, 2)">
                      <v-icon small :color="isAllInGroup(selectedIndex, 2) ? 'green' : null">fa-file-download</v-icon>
                    </v-btn>
                    <v-btn text small :data-cy="'AccessRightsGroup.' + selectedIndex + 'Edit'" @click.stop="setAllInGroup(selectedIndex, 3)">
                      <v-icon small :color="isAllInGroup(selectedIndex, 3) ? 'green' : null">fa-edit</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </v-flex>
              </v-layout>
            </v-list-item> -->
            <v-list-item dense>
              <v-list dense style="width: 100%;" class="mr-2" two-line>
                <template v-for="item in filteredSelectedItems">
                  <v-list-item :key="item.tableName + item.name" dense  @click="() => {}" class="pa-0">
                    <v-list-item-content>
                      <v-list-item-title>
                        <h4>{{ $root.getAccessRightsDisplayName(item.tableName, item.name) }}</h4>
                      </v-list-item-title>
                      <v-slide-group :show-arrows="!$root.isMobile" v-if="selectedUser === 0">
                        <template v-for="userId in Object.keys(item.allUsersRights)">
                          <v-slide-item>
                            <v-menu offset-y class="pa-2">
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip v-bind="attrs" v-on="on" class="mr-1 mb-1" pill :small="!$root.isMobile">
                                  <v-avatar color="#015aaa" left>
                                    <span :style="{color: '#fff'}">
                                      {{item.allUsersRights[userId].userData.firstName[0].toString().toUpperCase()}}{{item.allUsersRights[userId].userData.lastName[0].toString().toUpperCase()}}
                                    </span>
                                  </v-avatar>
                                  <template v-if="item.name.toLowerCase().indexOf('create') >= 0">
                                    <v-icon x-small right color="error" v-if="item.allUsersRights[userId].rights !== 3">fa-ban</v-icon>
                                    <v-icon x-small right color="success" v-else-if="item.allUsersRights[userId].rights === 3">fa-check</v-icon>
                                  </template>
                                  <template v-else>
                                    <v-icon x-small right color="error" v-if="item.allUsersRights[userId].rights === 0">fa-ban</v-icon>
                                    <v-icon x-small right color="warning" v-else-if="item.allUsersRights[userId].rights === 1">fa-eye</v-icon>
                                    <v-icon x-small right color="success" v-else-if="item.allUsersRights[userId].rights === 3">fa-edit</v-icon>
                                  </template>
                                </v-chip>
                              </template>
                              <v-list dense v-if="item.name.toLowerCase().indexOf('create') >= 0">
                                <v-list-item key="deny" @click="updateUserRights(item, userId, 0)" class="pl-2 pr-2">
                                  <v-list-item-icon><v-icon>fa-ban</v-icon></v-list-item-icon>
                                  <v-list-item-title>No</v-list-item-title>
                                </v-list-item>
                                <v-list-item key="write" @click="updateUserRights(item, userId, 3)" class="pl-2 pr-2">
                                  <v-list-item-icon><v-icon>fa-check</v-icon></v-list-item-icon>
                                  <v-list-item-title>Yes</v-list-item-title>
                                </v-list-item>
                              </v-list>
                              <v-list dense v-else>
                                <v-list-item key="deny" @click="updateUserRights(item, userId, 0)" class="pl-2 pr-2">
                                  <v-list-item-icon><v-icon>fa-ban</v-icon></v-list-item-icon>
                                  <v-list-item-title>Deny</v-list-item-title>
                                </v-list-item>
                                <v-list-item key="read" @click="updateUserRights(item, userId, 1)" class="pl-2 pr-2">
                                  <v-list-item-icon><v-icon>fa-eye</v-icon></v-list-item-icon>
                                  <v-list-item-title>Read</v-list-item-title>
                                </v-list-item>
                                <v-list-item key="write" @click="updateUserRights(item, userId, 3)" class="pl-2 pr-2">
                                  <v-list-item-icon><v-icon>fa-edit</v-icon></v-list-item-icon>
                                  <v-list-item-title>Write</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-slide-item>
                        </template>
                      </v-slide-group>
                    </v-list-item-content>
                    <v-list-item-action class="pt-3">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" pill :small="!$root.isMobile">
                            <v-avatar color="#015aaa" left tile v-if="selectedUser === 0">
                              <span :style="{color: '#fff'}">
                                {{ $t('All') }}
                              </span>
                            </v-avatar>
                            <template v-if="selectedUser > 0">
                              <template v-if="item.name.toLowerCase().indexOf('create') >= 0">
                                <v-icon x-small color="error" v-if="item.rights !== 3">fa-ban</v-icon>
                                <v-icon x-small color="success" v-else-if="item.rights === 3">fa-check</v-icon>
                              </template>
                              <template v-else>
                                <v-icon x-small color="error" v-if="item.rights === 0">fa-ban</v-icon>
                                <v-icon x-small color="warning" v-else-if="item.rights === 1">fa-eye</v-icon>
                                <v-icon x-small color="success" v-else-if="item.rights === 3">fa-edit</v-icon>
                              </template>
                            </template>
                            <v-icon x-small right v-else>mdi-chevron-down</v-icon>
                          </v-chip>
                        </template>
                        <v-list dense v-if="item.name.toLowerCase().indexOf('create') >= 0">
                          <v-list-item key="deny" @click="updateAllUsersRights(item, 0)" class="pl-2 pr-2">
                            <v-list-item-icon><v-icon>fa-ban</v-icon></v-list-item-icon>
                            <v-list-item-title>No</v-list-item-title>
                          </v-list-item>
                          <v-list-item key="write" @click="updateAllUsersRights(item, 3)" class="pl-2 pr-2">
                            <v-list-item-icon><v-icon>fa-check</v-icon></v-list-item-icon>
                            <v-list-item-title>Yes</v-list-item-title>
                          </v-list-item>
                        </v-list>
                        <v-list dense v-else>
                          <v-list-item key="deny" @click="updateAllUsersRights(item, 0)" class="pl-2 pr-2">
                            <v-list-item-icon><v-icon>fa-ban</v-icon></v-list-item-icon>
                            <v-list-item-title>Deny</v-list-item-title>
                          </v-list-item>
                          <v-list-item key="read" @click="updateAllUsersRights(item, 1)" class="pl-2 pr-2">
                            <v-list-item-icon><v-icon>fa-eye</v-icon></v-list-item-icon>
                            <v-list-item-title>Read</v-list-item-title>
                          </v-list-item>
                          <v-list-item key="write" @click="updateAllUsersRights(item, 3)" class="pl-2 pr-2">
                            <v-list-item-icon><v-icon>fa-edit</v-icon></v-list-item-icon>
                            <v-list-item-title>Write</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="item.tableName + item.name + 'divider'"/>
                </template>
              </v-list>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <!-- Role save buttons-->
          <v-spacer/>
          <v-btn v-if="!itemsLoading && parseInt(selectedUserInModal) === 0 && $root.isAccessRightsEditAllowed()"
                 color="primary" class="ma-1" :loading="saveLoading1"
                 v-confirm="{ok: dialog => saveAccessRights(selectedIndex, 'Role'), cancel: () => {}, message: $t('CreateUser.SaveRoleAccessRightsConfirmMsg',{role: $t('Roles.'+role)})}"
          >{{$t('Save')}}</v-btn>
          <!--<v-btn v-if="!itemsLoading && parseInt(selectedUserInModal) === 0 && $root.isAccessRightsEditAllowed()"
                 color="warning" class="ma-1" :loading="saveLoading2"
                 v-confirm="{ok: dialog => saveAccessRights(selectedIndex, 'RoleWithUsers'), cancel: () => {}, message: $t('CreateUser.SaveRoleWithUsersAccessRightsConfirmMsg',{role: $t('Roles.'+role)})}"
          >{{$t('SaveWithUsers')}}</v-btn>-->
          <!-- User save buttons-->
          <v-btn v-if="!itemsLoading  && parseInt(selectedUserInModal) > 0 && $root.isAccessRightsEditAllowed()"
                 color="primary" class="ma-1" :loading="saveLoading1"
                 v-confirm="{ok: dialog => saveAccessRights(selectedIndex, 'User'), cancel: () => {}, message: $t('CreateUser.SaveUserAccessRightsConfirmMsg')}"
          >{{$t('Save')}}</v-btn>
          <v-btn v-if="!itemsLoading  && parseInt(selectedUserInModal) > 0 && $root.isAccessRightsEditAllowed()"
                  class="ma-1" :loading="saveLoading2"
                 v-confirm="{ok: dialog => saveAccessRights(selectedIndex, 'ResetUser'), cancel: () => {}, message: $t('CreateUser.ResetToRoleRightsConfirmMsg', { user: parseInt(selectedUserInModal), role: $t('Roles.'+role)})}"
          >{{$t('ResetToRole')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SelectUser from './SelectUser';
export default {
  name: 'AccessRightsList',
  props: ['role', 'selectedUser', 'type'],
  components: {
    SelectUser
  },
  data: function () {
    return {
      items: null,
      itemsLoading: false,
      saveLoading1: false,
      saveLoading2: false,
      search: '',
      modal: false,
      selectedIndex: null,
      selectedItems: [],
      selectedUserInModal: null,
      usersToUpdate: {},
      listKey: new Date().getTime()
    };
  },
  computed: {
    filteredSelectedItems () {
      if (this.search.length === 0) {
        return this.selectedItems;
      }
      return this.selectedItems.filter(el => el.name.toLowerCase().indexOf(this.search.toLowerCase()) >= 0)
    },
    filteredItems () {
      if (this.search.length === 0) {
        return this.items;
      }
      let results = {};
      for (const [key, value] of Object.entries(this.items)) {
        results[key] = [];
        const values = Object.values(value);
        for (let i = 0; i < values.length; i++) {
          let item = values[i];
          if (item.hasOwnProperty('tableName') && item.hasOwnProperty('name')) {
            const name = this.$root.getAccessRightsDisplayName(item.tableName, item.name);
            const found = name.toLowerCase().match(this.search);
            if (found) {
              this.$set(item, 'active', true);
              results[key].push(item);
            }
          }
        }
      }
      return results;
    }
  },
  mounted () {
    this.$set(this, 'selectedUserInModal', this.selectedUser);
    this.fetchData(this.type, this.type === 'User' ? this.selectedUserInModal : this.role);
  },
  methods: {
    updateUserRights(item, userId, rightsNumber) {
      if (!this.usersToUpdate.hasOwnProperty(userId)) {
        this.usersToUpdate[userId] = {}
      }
      this.usersToUpdate[userId][item.name] = parseInt(this.getRightsValueFromButtonGroupNumber(rightsNumber), 2);
      this.$set(item.allUsersRights[userId], 'rights', rightsNumber);
    },
    updateAllUsersRights(item, rightsNumber) {
      item.rights = rightsNumber
      Object.keys(item.allUsersRights).forEach(userId => {
        this.updateUserRights(item, userId, rightsNumber);
      });
    },
    userChanged (value) {
      this.$set(this, 'selectedUserInModal', value);
      this.fetchData(parseInt(value) > 0 ? 'User' : 'Role', parseInt(value) > 0 ? this.selectedUserInModal : this.role).then((response) => {
        this.$set(this, 'selectedItems', this.items[this.selectedIndex]);
      });
      this.listKey = new Date().getTime();
      this.$emit('user-changed', value);
    },
    selectGroup (index, items) {
      this.$set(this, 'selectedIndex', index);
      this.$set(this, 'selectedItems', items);
      this.modal = true;
    },
    getRightsValueFromButtonGroupNumber (value) {
      let rightValue = '0000000';
      switch (value) {
      case 1:
        rightValue = '0100000';
        break;
      case 2:
        rightValue = '0101000';
        break;
      case 3:
        rightValue = '0111000';
        break;
      }
      return rightValue;
    },
    setAllInGroup (index, value) {
      let k = 0;
      for (k = 0; k < this.items[index].length; k++) {
        this.items[index][k]['rights'] = value;
      }
    },
    isAllInGroup (index, value) {
      if (!this.items || !this.items.hasOwnProperty(index)) return false;
      for (let k = 0; k < this.items[index].length; k++) {
        if (this.items[index][k]['rights'] !== value) {
          return false;
        }
      }
      return true;
    },
    saveAccessRights (index, type) {
      if (type === 'Role' || type === 'User') {
        this.saveLoading1 = true;
      } else {
        this.saveLoading2 = true;
      }

      let itemsToSave = [];
      let tableName = '';
      for (let key in this.items[index]) {
        if (!this.items[index][key].category) continue;
        let item = this.items[index][key];
        tableName = this.getTableFromCategory(item.category);
        itemsToSave.push({
          name: item.name,
          rights: parseInt(this.getRightsValueFromButtonGroupNumber(item.rights), 2)
        });
      }

      this.$root.updateAccessRightsBatch(
        tableName,
        type,
        parseInt(this.selectedUserInModal) === 0 ? this.role : this.selectedUserInModal,
        itemsToSave).then((response) => {
        if (response.status === 'ok') {
          this.saveLoading1 = false;
          this.saveLoading2 = false;
          this.$root.toast(this.$i18n.t('AccessRightsChanged'), { color: 'success' });
          this.fetchData(
            parseInt(this.selectedUserInModal) === 0 ? 'Role' : 'User',
            parseInt(this.selectedUserInModal) === 0 ? this.role : this.selectedUserInModal,
            false);
        } else {
          this.$root.toast(this.$i18n.t('AccessRightsChangeError'), { color: 'error' });
        }
      });

      Object.keys(this.usersToUpdate).forEach(userId => {
        if (parseInt(userId) > 0) {
          let usersRightsToSave = [];
          Object.keys(this.usersToUpdate[userId]).forEach(name => {
            usersRightsToSave.push({
              name: name,
              rights: this.usersToUpdate[userId][name]
            });
            if (usersRightsToSave.length > 0) {
              console.error(usersRightsToSave);
              this.$root.updateAccessRightsBatch(tableName, 'User', userId, usersRightsToSave);
            }
          });
        };
      });
      this.modal = false;
    },
    getTableFromCategory (category) {
      if (category === 'driverOwnerOperator') return 'users';
      if (category === 'driver') return 'users';
      if (category === 'dispatcher') return 'users';
      if (category === 'broker') return 'users';
      if (category === 'accountingManager') return 'users';
      return category;
    },
    fetchData (type, name, showLoading = true) {
      if (!type || !name) return;
      return new Promise((resolve, reject) => {
        let k = 0;
        let _rights = 0;
        let _allUsersRights = {};
        let _items = {};
        if (!this.$root.isAccessRightAllowView(this.$store.getters.menuRights['AccessRights'])) {
          this.$root.toast(this.$i18n.t('AccessError'), { color: 'error' });
        } else {
          if (showLoading) this.itemsLoading = true;
          _items = {};
          this.$root.getAccessRights(type, name).then((response) => {
            if (response.status === 'ok') {
              for (k = 0; k < response.data.length; k++) {
                if (!response.data[k].fieldName) continue;
                _rights = 0;
                if (type === 'Role') {
                  _rights = this.$root.rightNumberToUIRightsNumber(response.data[k].roleRights);
                  _allUsersRights = response.data[k].allUsersRights;
                } else if (type === 'User') {
                  if (response.data[k].userRights) {
                    _rights = this.$root.rightNumberToUIRightsNumber(response.data[k].userRights);
                  } else {
                    _rights = this.$root.rightNumberToUIRightsNumber(response.data[k].roleRights);
                  }
                }
                let _category = this.$root.genCategoryByTable(response.data[k].tableName, response.data[k].fieldName);
                if (!_items.hasOwnProperty(_category)) {
                  _items[_category] = [];
                }
                let _pushFlag = true;
                if (_category === 'settings') {
                  for (let i = 0; i < this.$store.getters.rolesList.length; i++) {
                    const _role = this.$store.getters.rolesList[i];
                    if (_role.toLowerCase() !== this.role.toLowerCase()) {
                      if (this.role.toLowerCase() === 'driver') {
                        if (response.data[k].fieldName.toLowerCase().indexOf('driverOwnerOperator') === 0) {
                          _pushFlag = false;
                        }
                      }
                      if (response.data[k].fieldName.toLowerCase().indexOf(_role.toLowerCase()) === 0) {
                        _pushFlag = false;
                        break;
                      }
                    }
                  }
                }
                if (_pushFlag) {
                  _items[_category].push({
                    name: response.data[k].fieldName,
                    tableName: response.data[k].tableName,
                    category: _category,
                    rights: _rights,
                    allUsersRights: _allUsersRights
                  });
                }
              }
            }
            this.$set(this, 'items', _items);

            /* if (type === "Role") {
              let _users = [];
              for (let k in response.users) {
                _users.push({
                  name: response.users[k].firstName + ' ' + response.users[k].lastName,
                  type: 'User',
                  id: response.users[k].id,
                  firstName: response.users[k].firstName,
                  lastName: response.users[k].lastName,
                  avatar: response.users[k].avatarUPLOAD,
                  email: response.users[k].email
                });
              }
              this.users = _users;
            } */

            if (showLoading) this.itemsLoading = false;
            resolve({ response });
          });
        }
      });
    }
  }
};
</script>
