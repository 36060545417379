<template>
  <v-layout v-if="loading">
    <v-flex xs12>
    <v-skeleton-loader
      type="table"
      style="width:100%"
    ></v-skeleton-loader>
    </v-flex>
  </v-layout>
  <div v-else-if="error">
    <v-flex class="text-center error pa-2">
      <h1 class="title">{{error}}</h1>
    </v-flex>
  </div>
  <v-container fluid v-else>
    <v-row v-if="!isNotModal">
      <v-col class="shrink">
        <v-btn icon @click="$emit('on-close')"><v-icon>mdi-close</v-icon></v-btn>
      </v-col>
      <v-col class="grow">
        <h3 class="mb-5">{{threadSubject}}</h3>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" width="100%">
      <v-expansion-panels v-model="panel" multiple accordion dense>
        <v-expansion-panel
          v-for="(msg,i) in mails"
          :key="i"
        >
          <v-expansion-panel-header class="text-left pb-0 pt-0 pl-1 pr-1">
            <v-layout class="ma-0 pa-0" wrap>
              <v-flex xs12 md3 xl2>
                <i>{{msg.createdPST}}</i>
                <br/>
                <v-icon v-if="msg.files && msg.files !== '[]'">mdi-paperclip</v-icon>
                <v-icon v-if="msg.SENT === 1" color="success">mdi-send-check</v-icon>
                <v-icon v-else-if="msg.UNREAD === 1" color="warning">mdi-email</v-icon>
                <v-icon v-else>mdi-email-open</v-icon>
              </v-flex>
              <v-flex xs12 md9 xl10 v-html="generateMsgSnippet(msg)"/>
              <v-flex xs12 md9 xl10 v-if="msg.forwarded">
                <v-chip small><v-icon left>mdi-email-send</v-icon> Fwd to:{{msg.forwarded}}</v-chip>
              </v-flex>
            </v-layout>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-left pa-0">
            <MailMessage :msgId="msg.id" :key="msg.id" :msgStatus="msg.status" @statusUpdated="onStatusUpdated(msg, $event)" @change="fetchData"></MailMessage>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <br/>
    <template v-if="mails.length > 0">
      <v-btn @click.stop="onReply" class="mt-1" v-if="!isReplyForm || !isReplyAll">
        <v-icon left v-if="isReplyForm">mdi-close</v-icon>
        <v-icon left v-else>mdi-reply</v-icon>
        {{$t("Mails.Reply")}}
      </v-btn>
      <template v-if="isHaveMultiplyRecipients">
        <v-btn @click.stop="onReplyAll" class="ml-1 mt-1" v-if="!isReplyForm || isReplyAll">
          <v-icon left v-if="isReplyForm">mdi-close</v-icon>
          <v-icon left v-else>mdi-reply-all</v-icon>
          {{$t("Mails.ReplyAll")}}
        </v-btn>
      </template>
      <v-btn @click.stop="isForwardForm = !isForwardForm" class="ml-1 mt-1">
        <v-icon left v-if="isForwardForm">mdi-close</v-icon>
        <v-icon left v-else>mdi-forward</v-icon>
        {{$t("Mails.Forward")}}
      </v-btn>
    </template>
    <v-dialog v-model="isForwardForm"  width="500px">
      <v-card class="mx-auto">
        <v-card-title>
          <h4>{{$t('Mails.ForwardingThread')}}</h4>
          <v-spacer></v-spacer>
          <v-btn icon class="mr-4" @click="isForwardForm = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-layout>
            <v-flex xs10>
              <v-autocomplete
                width="100%"
                dense outlined
                item-text="name"
                item-value="name"
                multiple hide-details
                :search-input.sync="recipientsSearch"
                :disabled="isForwarding"
                v-model="forwardRecipient"
                :items="recipientsList"
                :label="$t('Mails.To')"
                :rules="[$root.rules.required]"
                autocomplete="nope"
                :no-data-text="$t('TypeToSearch')"
              ></v-autocomplete>

              <!--<v-combobox :label="$t('Mails.To')"
                    v-model="forwardRecipient"
                    width="100%"
                    :items="recipientsList"
                    :rules="[$root.rules.required]"
                    :disabled="isForwarding"
                    outlined dense multiple/>-->
            </v-flex>
            <v-flex xs2>
              <v-btn color="primary" class="ml-2" :loading="isForwarding" :disabled="isForwarding" @click="forwardThread(forwardRecipient, threadId, loadId)"><v-icon>mdi-send</v-icon></v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ReplyForm v-if="isReplyForm"
               :msgId="mails[mails.length-1].id"
               :replySelectedSender="replySelectedSender"
               :senders_list="senders_list"
               :replyEmailRecipient="replyEmailRecipient"
               :replyEmailSubject="threadSubject"
               @change="() => { isReplyForm = false;$root.refresh(); }"
    >

    </ReplyForm>
  </v-container>
</template>

<style scoped>
.v-expansion-panel-content__wrap {
  padding: 0px !important;
}
</style>

<script>
import MailMessage from './MailMessage';
import ReplyForm from './ReplyForm';
export default {
  name: 'MailsThread',
  props: ['bidId', 'loadId', 'invoiceId', 'threadId', 'openOnlyFirst', 'isNotModal'],
  components: { ReplyForm, MailMessage },
  data: function () {
    return {
      loading: false,
      error: null,
      pooling: null,
      panel: [],
      mails: [],
      threadSubject: '',
      isReplyForm: false,
      isReplyAll: false,
      senders_list: [],
      recipientsSearch: null,
      recipientsList: [],
      replySelectedSender: '',
      replyEmailRecipient: [],
      isForwardForm: false,
      isForwarding: false,
      forwardRecipient: ''
    };
  },
  watch: {
    recipientsSearch (val) {
      if (!val || val.length === 0) return;
      this.$root.recipientsSearch(val).then(response => {
        if (response.status === 'ok') {
          this.recipientsList = response.result;
        }
      });
    }
  },
  computed: {
    isHaveMultiplyRecipients () {
      return this.generateSenderAndRecipients(true).length > 1;
    }
  },
  mounted () {
    this.$nextTick(() => {
      let i = 0;
      this.$root.getBidsRecepientsList().then((response) => {
        if (response.status === 'ok') {
          this.recipientsList = response.result;
        }
      });

      this.$root.getLinkedEmailsByUser(this.$store.getters.currentUser['id']).then(response => {
        const linkedAccountsList = response.result;
        for (i = 0; i < linkedAccountsList.length; i++) {
          /*if (!this.$route.params.id && this.$store.getters.currentUser['email'] === linkedAccountsList[i].email) {
            this.selectedSender = linkedAccountsList[i].email;
          }*/
          this.senders_list.push(linkedAccountsList[i].email);
        }
      });

      this.fetchData();
      this.polling = setInterval(() => {
        // Do not call interval methods if page not visible
        if (!this.$store.getters.isPageVisible) return;
        this.fetchData();
      }, this.$store.getters.pullIntervalList);
    });
  },
  beforeDestroy () {
    clearInterval(this.polling);
  },
  methods: {
    forwardThread (to, threadId, loadId) {
      this.isForwarding = true;
      this.$root.forwardThread(to, threadId, loadId || 0).then((response) => {
        if (response.status === 'ok') {
          this.$root.toast(this.$i18n.t('Mails.ForwardedSuccessfully'), { color: 'success' });
          this.isForwardForm = false;
          this.forwardRecipient = '';
        } else {
          this.$root.toast(this.$i18n.t('Mails.ForwardedError'), { color: 'error' });
        }
        this.isForwarding = false;
      }).catch(e => {
        this.isForwarding = false;
      });
    },
    onReply () {
      this.isReplyAll = false;
      this.generateSenderAndRecipients(this.isReplyAll);
      this.isReplyForm = !this.isReplyForm;
    },
    onReplyAll () {
      this.isReplyAll = true;
      this.generateSenderAndRecipients(this.isReplyAll);
      this.isReplyForm = !this.isReplyForm;
    },
    generateMsgSnippet (msg) {
      //const from = '<b>' + this.$root.t('fromEmail') + ':&nbsp;</b><u>' + msg.from_field + '</u>&nbsp;';
      //const to = '<b>' + this.$root.t('toEmail') + ':&nbsp;</b><u>' + msg.to_field + '</u>&nbsp;';
      let cc = '';
      if (msg.cc_field) {
        cc = '<b>' + this.$root.t('ccEmail') + ':&nbsp;</b><u>' + msg.cc_field + '</u><br/>';
      }
      const lines = msg.snippet.split(/\r?\n|\r|\n/g);
      let snippet = '';
      if (lines.length === 0) {
        snippet = msg.snippet
      }
      if (lines.length > 0) {
        snippet += lines[0] + '<br/>';
      }
      if (lines.length > 1) {
        snippet += lines[1] + '<br/>';
      }
      return cc + snippet;
    },
    onStatusUpdated (msg, event) {
      this.$set(msg, 'status', event);
      this.$emit('statusUpdated', event);
    },
    generateSenderAndRecipients (isReplyAll = false) {
      let i = 0;
      let j = 0;
      let recipients = {};
      let _emails = [];
      let _email = '';
      for (i = this.mails.length - 1; i >= 0; i--) {
        this.replySelectedSender = this.mails[i].userEmail;
        _emails = this.mails[i].from_field.split(',');
        for (j = 0; j < _emails.length; j++) {
          if (_emails[j].trim() !== '' && _emails[j].indexOf(this.mails[i].userEmail) < 0) {
            _email = this.$root.extractEmailFromEmailHeaderFormat(_emails[j]);
            if (_email) {
              recipients[_email.toLowerCase()] = _email;
              if (!isReplyAll) break;
            }
          }
        }

        _emails = this.mails[i].to_field.split(',');
        for (j = 0; j < _emails.length; j++) {
          if (_emails[j].trim() !== '' && _emails[j].indexOf(this.mails[i].userEmail) < 0) {
            _email = this.$root.extractEmailFromEmailHeaderFormat(_emails[j]);
            if (_email) {
              recipients[_email.toLowerCase()] = _email;
              if (!isReplyAll) break;
            }
          }
        }
      }
      this.replyEmailRecipient = [];
      for (let key in recipients) {
        this.replyEmailRecipient.push(recipients[key]);
      }
      return this.replyEmailRecipient;
    },
    fetchData () {
      // this.panel = [];
      if (this.bidId) {
        this.$root.getMailsListByBidId(this.bidId).then((response) => {
          if (response.status === 'ok') {
            this.$set(this, 'mails', response.result);
            if (response.result.length > 0) {
              this.threadSubject = response.result[0].subject_field;
            }
            this.generateSenderAndRecipients();
            // this.panel = [0];
            // this.panel = [...Array(this.mails.length).keys()].map((k, i) => i)
          }

          this.loading = false;
        });
      } else if (this.loadId) {
        this.$root.getMailsListByLoadId(this.loadId).then((response) => {
          if (response.status === 'ok') {
            this.$set(this, 'mails', response.result);
            if (response.result.length > 0) {
              this.threadSubject = response.result[0].subject_field;
            }
            this.generateSenderAndRecipients();
            // this.panel = [0];
            // this.panel = [...Array(this.mails.length).keys()].map((k, i) => i)
          }

          this.loading = false;
        });
      } else if (this.invoiceId) {
        this.$root.getMailsListByInvoiceId(this.invoiceId).then((response) => {
          if (response.status === 'ok') {
            this.$set(this, 'mails', response.result);
            if (response.result.length > 0) {
              this.threadSubject = response.result[0].subject_field;
            }
            this.generateSenderAndRecipients();
            // this.panel = [0];
            // this.panel = [...Array(this.mails.length).keys()].map((k, i) => i)
          }

          this.loading = false;
        });
      } else if (this.threadId) {
        this.$root.getMailsListByThreadId(this.threadId).then((response) => {
          if (response.status === 'ok') {
            this.$set(this, 'mails', response.result);
            if (response.result.length > 0) {
              this.threadSubject = response.result[0].subject_field;
            }
            this.generateSenderAndRecipients();
            // this.panel = [0];
            // this.panel = [...Array(this.mails.length).keys()].map((k, i) => i)
          }
          this.loading = false;
        });
      }
    }
  }
};

</script>
