<template>
  <div>
    <v-select
      class="mb-2"
      :rules="rules"
      :disabled="disabled"
      :items="trucks"
      v-model="selectedTruck"
      item-text="id"
      :height="height ? height: '50px'"
      :label="label"
      clearable
      :prepend-icon="!hideIcon ? 'fa-truck-moving' : null"
      @click="$emit('click')"
      @change="selectTruck"
      @click:prepend="prependClick()"
      :filled="isFilter"
      :outlined="!isFilter"
      hide-details dense>

      <template v-slot:prepend>
        <v-icon v-if="!hideIcon" style="width:40px; height:26px;" class="mt-1" @click="prependClick()">fa-truck-moving</v-icon>
      </template>

      <template v-slot:selection="data">
        <template>
          <v-list-item-avatar>
            <v-avatar tile :size="26" v-if="data.item.truckAvatarUPLOAD && data.item.id > 0">
              <v-img :src="$root.getTruckFirstUrl(data.item.truckAvatarUPLOAD)"></v-img>
            </v-avatar>
            <v-icon v-else-if="data.item.id === 0">fa-times</v-icon>
            <v-icon v-else>fa-truck</v-icon>
          </v-list-item-avatar>
          <v-list-item-content v-if="data.item.id === 0">
            {{data.item.name}}
          </v-list-item-content>
          <v-list-item-content v-if="data.item.id > 0">
            <v-list-item-title>
              {{data.item.name}}
              <template v-if="data.item.truckLinkedToTruckId">
                <v-icon x-small>mdi-link</v-icon>
                <v-chip class="ma-1" x-small><v-icon x-small>mdi-truck-trailer</v-icon>{{data.item.truckLinkedToTruckName}}</v-chip>
              </template>
              <template v-if="data.item.linkedTruckId">
                <v-icon x-small>mdi-link</v-icon>
                <v-chip class="ma-1" x-small><v-icon x-small>fa-truck-moving</v-icon>{{data.item.linkedTruckName}}</v-chip>
              </template>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{data.item.type}}<v-chip class="ml-1" :color="$root.getTruckStatusColorByValue(data.item.status)" x-small>{{$root.getTruckStatusNameByValue(data.item.status)}}</v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>

      <template v-slot:item="data">
        <template>
          <v-list-item-avatar>
            <v-avatar :size="26" tile v-if="data.item.truckAvatarUPLOAD">
              <v-img :src="$root.getTruckFirstUrl(data.item.truckAvatarUPLOAD)"></v-img>
            </v-avatar>
            <v-icon v-else-if="data.item.id === 0">fa-times</v-icon>
            <v-icon v-else>fa-truck</v-icon>
          </v-list-item-avatar>
          <v-list-item-content v-if="data.item.id === 0">
            {{data.item.name}}
          </v-list-item-content>
          <v-list-item-content v-if="data.item.id > 0">
            <v-list-item-title>
              {{data.item.name}}
              <template v-if="data.item.truckLinkedToTruckId"><v-icon x-small>mdi-link</v-icon>
                <v-chip class="ma-1" x-small><v-icon x-small>mdi-truck-trailer</v-icon>{{data.item.truckLinkedToTruckName}}</v-chip>
              </template>
              <template v-if="data.item.linkedTruckId"><v-icon x-small>mdi-link</v-icon>
                <v-chip class="ma-1" x-small><v-icon x-small>fa-truck-moving</v-icon>{{data.item.linkedTruckName}}</v-chip>
              </template>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{data.item.type}}<v-chip class="ml-1" :color="$root.getTruckStatusColorByValue(data.item.status)" x-small>{{$root.getTruckStatusNameByValue(data.item.status)}}</v-chip>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-html="data.item.location"/>
          </v-list-item-content>
        </template>
      </template>

      <template v-slot:append-outer>
        <div v-if="selectedTruck && showInfoButton">
          <v-btn @click="dialog=true" icon><v-icon>mdi-information</v-icon></v-btn>
        </div>
      </template>
    </v-select>
    <v-dialog v-model="dialog" width="500">
      <TruckInformation :fields="truckInfo"></TruckInformation>
    </v-dialog>
  </div>
</template>

<script>
import TruckInformation from './../components/TruckInformation.vue';

export default {
  name: 'SelectTruck',
  props: ['disabled', 'label', 'showInfoButton', 'value', 'get_location', 'rules', 'height', 'hideIcon', 'isFilter', 'driverOO', 'noNoTruck', 'preselectFirst', 'perOwnerOperator'],
  components: { TruckInformation },
  data: function () {
    return {
      truckInfo: {},
      selectedTruck: 0,
      trucks: [],
      dialog: false
    };
  },
  watch: {
  },
  mounted () {
    this.loadTrucks();
  },
  methods: {
    prependClick () {
      this.$root.addNewPageTab('/trucks/page/' + this.selectedTruck);
      // window.open(window.location.origin + '/trucks/page/' + this.selectedTruck, '_blank');
    },
    selectTruck (value) {
      if (value > 0) {
        this.loadTruckInfo(value);
      } else {
        this.$emit('change', value);
      }
    },
    loadTruckInfo (truckId) {
      this.$root.getTruck(truckId).then((response) => {
        if (response.status === 'ok') {
          for (let k in response.result) {
            if (k === 'keeptruckindata') {
              this.truckInfo[k] = JSON.parse(response.result[k]);
              continue;
            }
            this.truckInfo[k] = response.result[k];
          }

          if (this.get_location) {
            if (this.truckInfo.lat !== 0 || this.truckInfo.lng !== 0) {
              this.$root.getAddressByCoords(this.truckInfo.lat, this.truckInfo.lng).then((response) => {
                this.truckInfo['location_address'] = response.formatted_address.replace(', USA', '');
                this.$emit('updated');
              }).catch(() => {
              });
            }
          }

          this.$emit('change', truckId);
        }
      });
    },
    async loadTrucks () {
      let ownerOperator = 0;
      if (this.$store.getters.isUserRoleDriverOO) {
        ownerOperator = this.$store.getters.currentUserId;
      }
      if (this.perOwnerOperator > 0) {
        ownerOperator = this.perOwnerOperator;
      }

      this.$root.getTrucksList(true, ownerOperator).then((response) => {
        if (response.status === 'ok') {
          // if (!this.noNoTruck) {
          //   this.trucks.push({
          //     name: this.$root.t('Trucks.NoTruck', this.driverOO || 0),
          //     id: 0,
          //     type: ''
          //   });
          // }
          for (let k in response.result) {
            if (response.result[k].id) {
              this.trucks.push(response.result[k]);
            }
          }
          if (this.value) {
            this.$set(this, 'selectedTruck', parseInt(this.value));
            this.loadTruckInfo(this.selectedTruck);
          }
        } else {
          this.trucks.push({
            name: this.$root.t('Error'),
            id: 0,
            type: response.msg
          });
        }
        if (this.preselectFirst) {
          if (this.trucks.length > 0) {
            this.selectedTruck = this.trucks[0].id;
            this.$emit('change', this.trucks[0].id);
          }
        }
      });
    }
  }

};
</script>
