<template>
  <v-container grid-list-md style="max-width:3840px;" class="ma-0 pa-0">
    <div v-if="error">
      <v-flex class="text-center error pa-2">
        <h1 class="title">{{error}}</h1>
      </v-flex>
    </div>
    <div v-else>
      <v-simple-table  v-if="notEndedResult.length > 0 && !hideNotEndedLoad"
                       class="mb-2 elevation-2"
                       dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th colspan="8"><h2>Not Ended Loads</h2></th>
            </tr>
            <tr>
              <th>#</th>
              <th></th>
              <th>{{$t('Loads.StartedEnded')}}</th>
              <th>{{$t('Loads.Locations')}}</th>
              <th>{{$t('Loads.DispatcherBroker')}}</th>
              <th>{{$t('Loads.TruckDrivers')}}</th>
              <th>{{$t('Loads.Rate')}}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="item in notEndedResult">
              <tr :key="'notEndedResult'+item.id" @click="$root.onShowLoadPreview(item.id)">
                <td>{{item.id}}</td>
                <td>
                  <v-icon v-if="item.status.phaseIcon" v-html="item.status.phaseIcon" :color="item.status.phaseColor" class="mr-1"></v-icon>
                  <v-icon v-if="item.status.stageIcon" small v-html="item.status.stageIcon" :color="item.status.stageColor" class="mr-1"></v-icon>
                  <v-icon v-if="item.isReturnToHQ === 1" color="success" class="mr-1">mdi-redo-variant</v-icon>
                  <v-tooltip bottom v-if="item.isCloned > 0">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" @click="$root.onShowLoadPreview(item.isCloned)" class="mr-1" small>mdi-content-copy</v-icon>
                    </template>
                    <span>{{$t('Load')}}&nbsp;#{{item.isCloned}}</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.isDriverOwnerOperator === 1">
                    <template v-slot:activator="{ on }">
                      <v-chip v-on="on" x-small>Owner</v-chip>
                    </template>
                    <span>{{$t('Loads.isDriverOwnerOperator')}}</span>
                  </v-tooltip>
                </td>
                <td>
                  <div class="text-no-wrap">{{item.startedPST}}</div>
                  <div class="text-no-wrap">{{item.endedPST}}</div>
                </td>
                <td>
                  <div class="text-no-wrap">{{item.pickup}}</div>
                  <div class="text-no-wrap">{{item.delivery}}</div>
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-avatar v-on="on"
                                @click.stop="$root.isAccessRightAllowView($store.getters.menuRights['Dispatcher']) ? $root.onShowUserPreview(item.dispatcher, item.dispatcherName) : null"
                                style="cursor: pointer;"
                                class="mr-1"
                                :size="24"
                                :color="(item.dispatcherInitials && !item.dispatcherAvatar)?'#015aaa':null">
                        <v-img :src="$root.getImageUrl('avatar', item.dispatcherAvatar)" v-if="item.dispatcherAvatar"></v-img>
                        <span class="white--text" v-else-if="item.dispatcherInitials">{{item.dispatcherInitials}}</span>
                        <v-icon large v-else>account_circle</v-icon>
                      </v-avatar>
                    </template>
                    <span>{{item.dispatcherName}}</span>
                  </v-tooltip>
                  <a @click.stop="$root.route('/bids/edit/' + item.bid)">{{$t('Bids.Bid')}}&nbsp;#{{item.bid}}</a>
                  <br/>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <p v-on="on" style="width:100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                        <a v-if="$root.isAccessRightAllowView($store.getters.menuRights['Broker'])"
                           @click.stop="$root.onShowUserPreview(item.broker, item.brokerName)">{{item.brokerName}}
                        </a>
                        <span v-else>{{item.brokerName}}</span>
                      </p>
                    </template>
                    <span>{{item.brokerName}}</span>
                  </v-tooltip>
                </td>
                <td>
                  <TruckDriversSnippet :trucks="item.trucks" :isDriverOO="item.isDriverOwnerOperator === 1" :truckOO="item.truckOOData" :driverOO="item.driverOOData" :load-id="item.id"/>
                </td>
                <td>
                  <template v-if="item.additionalPayments !== 0 || item.RTLRate !== 0">
                    <div style="white-space: nowrap;">
                      {{parseFloat(item.Rate.toFixed(2)).toLocaleString()}}
                      <template v-if="item.RTLRate > 0">
                        +{{parseFloat(item.RTLRate.toFixed(2)).toLocaleString()}}
                      </template>
                      <template v-if="item.additionalPayments !== 0">
                        {{item.additionalPayments > 0?'+':'-'}}{{parseFloat(item.additionalPayments.toFixed(2)).toLocaleString()}}
                      </template>
                      =</div>
                  </template>
                  <v-chip small :color="item.isLoadInvoiceNotMatch ? 'error' : null" style="white-space: nowrap;">
                    <v-icon small class="mr-1">fa-dollar-sign</v-icon>{{parseFloat(parseFloat(item.Rate+(item.additionalPayments || 0)+(item.RTLRate || 0)).toFixed(2)).toLocaleString()}}
                  </v-chip>
                  <!--<div class="text-no-wrap"><v-icon small class="mr-1">fa-dollar-sign</v-icon>{{item.rate}}</div>-->
                </td>
                <td>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn icon small v-on="on" @click.stop="$root.addNewPageTab('/loads/page/' + item.id)" v-if="$root.isLoadsViewAllowed">
                            <v-icon small>mdi-open-in-new</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t('OpenExternal')}}</span>
                      </v-tooltip>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>

      <v-layout v-if="!driverId && !hideFilters">
        <v-flex xs12>
          <FiltersPanel
            ref="filtersPanel"
            prefix="loadsList"
            outlined
            :isExpansionPanel="$root.isMobile"
            :loading="loading"
            :items="filtersItems"
            :key="filtersKey"
            @filterBrokerKeyUp="filterBrokerSearch"
            @change="onFilterChanged"/>

        </v-flex>
      </v-layout>

      <v-card v-if="!hideTitle">
        <v-card-title>
          <v-layout wrap>
            <v-flex :shrink="!$root.isMobile">
              <h4 v-if="$root.isMobile">
                {{$t('Loads.ListTitle')}}
                <v-btn
                  v-if="$root.isMobile"
                  small
                  class="float-right"
                  @click="$refs.filtersPanel.showFiltersDrawer()">
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </h4>
              <h3 v-else class="mt-1">
                {{$t('Loads.ListTitle')}}
              </h3>
            </v-flex>
            <v-flex :grow="!$root.isMobile" :xs12="$root.isMobile">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                dense
                outlined
                hide-details
              />
            </v-flex>
          </v-layout>
        </v-card-title>
      </v-card>

      <v-progress-linear v-if="loading && !error && $root.isMobile" indeterminate/>
      <v-list dense v-if="!error && $root.isMobile">
        <v-list-item v-if="itemsCount > itemsPerPage">
          <v-list-item-content>
            <Pagination v-if="itemsCount > itemsPerPage"
                        :key="'Pagination' + page"
                        :value="page"
                        :itemsCount="itemsCount"
                        :itemsPerPage="itemsPerPage"
                        @page-changed="pageChanged"/>
          </v-list-item-content>
        </v-list-item>
        <template v-for="item in items">
          <v-list-item
            :key="'load' + item.id"
            @click="$root.onShowLoadPreview(item.id)"
          >
            <v-list-item-content>
              <v-list-item-icon>
                <div class="mt-1 ml-1 mr-1" style="white-space: nowrap;">#{{item.id}}</div>
                <UserSnippet v-if="item.dispatcherName && item.dispatcherName.split(' ').length > 1"
                             :userData="{ id: item.dispatcher, avatarUPLOAD: item.dispatcherAvatar, firstName: item.dispatcherName.split(' ')[0], lastName: item.dispatcherName.split(' ')[1], role: 'Dispatcher' }"
                             :avatarOnly="true" :small="false"/>
                <v-spacer/>
                <v-chip class="ml-1" style="height: 26px;">
                  <v-icon v-if="item.status.phaseIcon" v-html="item.status.phaseIcon" :color="item.status.phaseColor" class="mr-1"></v-icon>
                  <v-icon v-if="item.status.stageIcon" small v-html="item.status.stageIcon" :color="item.status.stageColor" class="mr-1"></v-icon>
                  <v-icon v-if="item.isReturnToHQ === 1" color="success" class="mr-1">mdi-redo-variant</v-icon>
                  &nbsp;#{{item.id}}
                </v-chip>
                <v-tooltip bottom v-if="item.isDriverOwnerOperator === 1">
                  <template v-slot:activator="{ on }">
                    <v-chip v-on="on" x-small>Owner</v-chip>
                  </template>
                  <span>{{$t('Loads.isDriverOwnerOperator')}}</span>
                </v-tooltip>
                <v-btn icon @click.stop="onShowDocuments(item)">
                  <v-icon class="mb-3" :color="item.isAllRequiredDocsUploaded ? 'success' : 'warning'">mdi-file-multiple</v-icon>
                </v-btn>
                <v-btn v-if="$root.isOnline && $root.isInvoicesCreateAllowed && item.invoicesArray.length === 0"
                       icon
                       @click.stop="$root.route('/invoices/create/' + item.id)">
                  <v-icon class="mb-3">fa-file-invoice-dollar</v-icon>
                </v-btn>
                <template v-for="invoiceItem in item.invoicesArray">
                      <v-btn v-if="invoiceItem.invoiceReceivedDate > 0 && $root.isInvoicesViewAllowed"
                             icon
                             :key="'inv'+invoiceItem.id"
                             :color="$root.getInvoiceTypeColorByStatus(invoiceItem.invoiceStatus)"
                             @click.stop="$root.onShowInvoicePreview(invoiceItem.invoiceId, invoiceItem.invoiceNumber)"><v-icon class="mb-3">fa-file-invoice-dollar</v-icon>
                      </v-btn>
                      <v-btn v-else-if="!invoiceItem.invoiceReceivedDate && $root.isInvoicesViewAllowed"
                             icon
                             :key="'inv'+invoiceItem.id"
                             :color="invoiceItem.invoiceDueLeftSeconds < 0 ? 'red' : 'warning'"
                             @click.stop="$root.onShowInvoicePreview(invoiceItem.invoiceId, invoiceItem.invoiceNumber)"><v-icon class="mb-3">fa-file-invoice-dollar</v-icon></v-btn>
                </template>
              </v-list-item-icon>
              <v-list-item-title>
                <span>{{$root.getDateTimeStringFromTimestamp(item.started).substr(0,$root.getDateTimeStringFromTimestamp(item.started).indexOf(' '))}}</span>
                <v-chip class="mt-1 ml-1 success float-right" style="height: 26px;">${{item.ratePerMile}}/${{item.rate}}</v-chip>
                <div class="float-right">
                  <LoadVerificationButtons :item="item" @fetch-data="fetchData" v-if="$root.isAccessRightAllowEdit($store.getters.loadsRights['isVerifyAllowed'])"/>
                </div>
              </v-list-item-title>
              <v-list-item-title style="white-space: normal;" v-html="item.pickup + '<hr/>' + item.delivery"></v-list-item-title>
              <v-list-item-subtitle v-html="!item.loadNumber ? item.uid : 'Load number:' + item.loadNumber + '<br/>' + item.uid"/>
              <TruckDriversSnippet :trucks="item.trucks" :isDriverOO="item.isDriverOwnerOperator === 1" :truckOO="item.truckOOData" :driverOO="item.driverOOData" :load-id="item.id"/>
            </v-list-item-content>
          </v-list-item>
          <v-divider/><v-divider/><v-divider/><v-divider/>
        </template>
        <v-list-item v-if="itemsCount > itemsPerPage">
          <v-list-item-content>
            <Pagination v-if="itemsCount > itemsPerPage"
                        :key="'Pagination' + page"
                        :value="page"
                        :itemsCount="itemsCount"
                        :itemsPerPage="itemsPerPage"
                        @page-changed="pageChanged"/>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-data-table v-if="!error && !$root.isMobile"
                    disable-sort
                    :headers="headers"
                    :items="items"
                    :options.sync="options"
                    :server-items-length="itemsCount"
                    :footer-props="footer_props"
                    :loading="loading"
                    :items-per-page="itemsPerPage"
                    item-key="id"
                    class="elevation-1"
                    :hide-default-footer="hidePagination"
                    dense
                    :no-results-text="$t('Loads.ListEmpty')"
                    @click:row="driverId ? null : $root.onShowLoadPreview($event.id)">
        >
          <template v-slot:top>
              <v-card flat>
                <v-spacer></v-spacer>
                <Pagination v-if="itemsCount > options.itemsPerPage && !hidePagination"
                            :key="'Pagination' + page"
                            :value="page"
                            :itemsCount="itemsCount"
                            :itemsPerPage="options.itemsPerPage"
                            @page-changed="pageChanged"/>
                <v-spacer></v-spacer>
            </v-card>
          </template>

          <template v-slot:footer="{ props: { options: { page }} }">
            <Pagination v-if="itemsCount > options.itemsPerPage && !hidePagination"
                        :key="'Pagination' + page"
                        :value="page"
                        :itemsCount="itemsCount"
                        :itemsPerPage="options.itemsPerPage"
                        @page-changed="pageChanged"/>
          </template>

          <template v-slot:item.open_action="{ item }">
            <v-btn class="primary" width="100%" @click="$root.route('/loads/driverPage/' + item.id)">Open {{item.uid}}</v-btn>
          </template>
          <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
            <v-btn icon @click.stop="expand(true)" v-if="item.subItems.length > 0 && !isExpanded"><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-btn icon @click.stop="expand(false)" v-if="item.subItems.length > 0 && isExpanded"><v-icon>mdi-chevron-up</v-icon></v-btn>
          </template>

          <template v-slot:item.phase="{ item }">
            <v-layout>
              <v-flex shrink>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div v-on="on" class="text-no-wrap">
                      <v-icon v-if="item.status.phaseIcon" small v-html="item.status.phaseIcon" :color="item.status.phaseColor" class="mr-1"></v-icon>
                      <v-icon v-if="item.status.stageIcon && item.isEasyMode === 0" small v-html="item.status.stageIcon" :color="item.status.stageColor" class="mr-1"></v-icon>
                      <v-icon v-if="item.isReturnToHQ === 1" small color="success" class="mr-1">mdi-redo-variant</v-icon>
                      <v-chip color="success" small v-if="item.isEasyMode === 1">{{ $t('Loads.isEasyMode')}}</v-chip>
                      <v-chip color="error" small v-if="item.deleted === 1">{{$t('Deleted')}}</v-chip>
                    </div>
                  </template>
                  <h4>{{item.status.status}}</h4>
                  <span>{{item.statusLocation}}</span>
                </v-tooltip>
              </v-flex>
              <v-flex shrink>
                <v-tooltip bottom v-if="item.isCloned > 0">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="$root.onShowLoadPreview(item.isCloned)" class="mr-1" small>mdi-content-copy</v-icon>
                  </template>
                  <span>{{$t('Load')}}&nbsp;#{{item.isCloned}}</span>
                </v-tooltip>
              </v-flex>
              <v-flex shrink>
                <v-tooltip bottom v-if="item.isDriverOwnerOperator === 1">
                  <template v-slot:activator="{ on }">
                    <v-chip v-on="on" x-small>Owner</v-chip>
                  </template>
                  <span>{{$t('Loads.isDriverOwnerOperator')}}</span>
                </v-tooltip>
              </v-flex>
              <v-flex v-if="item.isReturnToHQ === 0 && item.finished === 3 && item.isEasyMode === 0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" x-small :loading="returnToHQInListLoading[item.id]" @click.stop="onReturnToHQInList(item)">
                      <v-icon color="$root.returnColor" small>mdi-redo-variant</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('Loads.ReturnToHQ')}}</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
            <v-layout v-if="item.isEasyMode === 0">
              <v-flex v-if="Object.keys(item.nextAction).length > 0 && item.finished !== 3 && !hideNextActions">
                <v-btn x-small @click.stop="onNextAction(item.id, item.nextAction)" :loading="nextActionLoading[item.id]" class="mb-1" width="100%">
                  {{$t('Loads.'+item.nextAction.msg)}}
                  <v-icon right small :color="$root.getLocationColor(item.nextAction, item.nextAction.number)">{{$root.getLocationIcon(item.nextAction)}}</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </template>
          <template v-slot:item.status="{ item }">
            {{item.status}}
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <p v-on="on" style="max-width:200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                  {{item.statusLocation}}
                </p>
              </template>
              <span>{{item.statusLocation}}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.locations="{ item }">
            <v-layout class="text-no-wrap">
              <div>
                <LoadLocationsTooltip :load-fields="item"/>
              </div>
              <div class="pl-1">
                <LoadLocationsStartEnd :load-name="item.pickup+'--'+item.delivery"
                                       :max-width="'200px'"
                />
              </div>
            </v-layout>
          </template>
          <template v-slot:item.uid="{ item }">
            <span class="text-no-wrap">
              {{item.loadNumber || ''}}
              <v-btn v-if="item.loadNumber" small icon @click.stop="$root.copyToClipboard(item.loadNumber)"><v-icon small>mdi-content-copy</v-icon></v-btn>
            </span>
            <br/>
            <span class="text-no-wrap">
              {{item.uid}}
              <v-btn v-if="item.uid" small icon @click.stop="$root.copyToClipboard(item.uid)"><v-icon small>mdi-content-copy</v-icon></v-btn>
            </span>
            <span class="text-no-wrap">
              <template v-for="subItem in item.subItems">
                <v-chip @click.stop="$root.route('/loads/page/'+subItem.id)" :key="'subItem' + subItem.id">
                  <v-icon x-small>mdi-subdirectory-arrow-right</v-icon>
                  {{subItem.uid}}
                  <v-btn small icon @click.stop="$root.addNewPageTab('/loads/page/'+subItem.id)"><v-icon small class="ml-1">mdi-open-in-new</v-icon></v-btn>
                  <v-btn small icon @click.stop="$root.copyToClipboard(subItem.uid)"><v-icon small>mdi-content-copy</v-icon></v-btn>
                </v-chip>
              </template>
            </span>
          </template>
          <!--<template v-slot:item.date="{ item }">
            <div class="text-no-wrap">{{item.date.substr(0,item.date.indexOf(' '))}}</div>
            <div class="text-no-wrap">{{item.date.substr(item.date.indexOf(' ')+1)}}</div>-->
            <!--<div class="text-no-wrap" v-for="dt in item.date.split(' ')">{{dt}}</div>-->
          <!--</template>-->
          <template v-slot:item.disp_broker="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-avatar v-on="on"
                          @click.stop="$root.isAccessRightAllowView($store.getters.menuRights['Dispatcher']) ? $root.onShowUserPreview(item.dispatcher, item.dispatcherName) : null"
                          style="cursor: pointer;"
                          class="mr-1"
                          :size="24"
                          :color="(item.dispatcherInitials && !item.dispatcherAvatar)?'#015aaa':null">
                  <v-img :src="$root.getImageUrl('avatar', item.dispatcherAvatar)" v-if="item.dispatcherAvatar"></v-img>
                  <span class="white--text" v-else-if="item.dispatcherInitials">{{item.dispatcherInitials}}</span>
                  <v-icon large v-else>account_circle</v-icon>
                </v-avatar>
              </template>
              <span>{{item.dispatcherName}}</span>
            </v-tooltip>
            <a @click.stop="$root.route('/bids/edit/' + item.bid)">{{$t('Bids.Bid')}}&nbsp;#{{item.bid}}</a>
            <br/>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <p v-on="on" style="width:100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                  <a v-if="$root.isAccessRightAllowView($store.getters.menuRights['Broker'])"
                     @click.stop="$root.onShowUserPreview(item.broker, item.brokerName)">{{item.brokerName}}
                  </a>
                  <span v-else>{{item.brokerName}}</span>
                </p>
              </template>
              <span>{{item.brokerName}}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.trucks_drivers="{ item }">
            <TruckDriversSnippet :trucks="item.trucks" :isDriverOO="item.isDriverOwnerOperator === 1" :truckOO="item.truckOOData" :driverOO="item.driverOOData" :load-id="item.id"/>
          </template>
          <!--<template v-slot:item.pickup="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <p v-on="on" style="max-width:100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                  {{item.pickup}}
                </p>
              </template>
              <span>{{item.pickup}}</span>
            </v-tooltip>

          </template>-->
          <template v-slot:item.started_ended="{ item }">
            <div class="text-no-wrap">{{item.startedPST}}</div>
            <div class="text-no-wrap">{{item.endedPST}}</div>
          </template>
          <!--<template v-slot:item.started="{ item }">
            <div class="text-no-wrap">{{$root.getDateTimeStringFromTimestamp(item.started).substr(0,$root.getDateTimeStringFromTimestamp(item.started).indexOf(' '))}}</div>
            <div class="text-no-wrap">{{$root.getDateTimeStringFromTimestamp(item.started).substr($root.getDateTimeStringFromTimestamp(item.started).indexOf(' ')+1)}}</div>
          </template>-->
          <!--<template v-slot:item.delivery="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <p v-on="on" style="max-width:100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                  {{item.delivery}}
                </p>
              </template>
              <span>{{item.delivery}}</span>
            </v-tooltip>
          </template>-->
          <!--<template v-slot:item.ended="{ item }">
            <div class="text-no-wrap">{{$root.getDateTimeStringFromTimestamp(item.ended).substr(0,$root.getDateTimeStringFromTimestamp(item.ended).indexOf(' '))}}</div>
            <div class="text-no-wrap">{{$root.getDateTimeStringFromTimestamp(item.ended).substr($root.getDateTimeStringFromTimestamp(item.ended).indexOf(' ')+1)}}</div>
          </template>-->
          <template v-slot:item.rate="{ item }">
            <template v-if="item.additionalPayments !== 0 || item.RTLRate !== 0">
              <div style="white-space: nowrap;">
                {{parseFloat(item.rate.toFixed(2)).toLocaleString()}}
                <template v-if="item.RTLRate > 0">
                  +{{parseFloat(item.RTLRate.toFixed(2)).toLocaleString()}}
                </template>
                <template v-if="item.additionalPayments !== 0">
                {{item.additionalPayments > 0?'+':'-'}}{{parseFloat(item.additionalPayments.toFixed(2)).toLocaleString()}}
                </template>
              =</div>
            </template>
            <v-chip small :color="item.isLoadInvoiceNotMatch ? 'error' : item.invoicesArray.length > 0 ? 'success' : null" style="white-space: nowrap;">
              <v-icon small class="mr-1">fa-dollar-sign</v-icon>{{parseFloat(parseFloat(item.rate+(item.additionalPayments || 0)+(item.RTLRate || 0)).toFixed(2)).toLocaleString()}}
            </v-chip>
            <!--<div class="text-no-wrap"><v-icon small class="mr-1">fa-dollar-sign</v-icon>{{item.rate}}</div>-->
          </template>

          <template v-slot:item.miles="{ item }">
            <div class="text-no-wrap">{{item.miles}}&nbsp;{{$t('milesShort')}}</div>
          </template>
          <template v-slot:item.ratePerMile="{ item }">
            <div class="text-no-wrap"><v-icon small class="mr-1">fa-dollar-sign</v-icon>{{item.ratePerMile}}</div>
          </template>
          <template v-slot:item.actions="{ item }">
            <!--<v-tooltip bottom v-if="$root.isLoadsViewAllowed">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click.stop="$root.route('/loads/page/' + item.id)"><v-icon small class="mr-1">fa-eye</v-icon></v-btn>
              </template>
              <span>{{$t('Loads.View')}}</span>
            </v-tooltip>-->

            <v-layout class="pt-1">
              <v-flex class="pb-0" shrink v-if="$root.isAccessRightAllowEdit($store.getters.loadsRights['isVerifyAllowed'])">
                <LoadVerificationButtons :item="item" small @fetch-data="fetchData"/>
              </v-flex>
              <v-flex class="pb-0" shrink v-if="item.isOverrideEditLock === 1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon  v-on="on" class="ma-1" small color="error">mdi-lock-open-variant</v-icon>
                  </template>
                  <span>{{$t('Loads.EditingIsUnlocked')}}</span>
                </v-tooltip>
              </v-flex>
              <v-flex v-else-if="!item.isEditAllowed || item.isOverrideEditLock !== 0" class="pb-0" shrink>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small class="ma-1" :color="item.isOverrideEditLock === 0?'success':'error'">mdi-lock</v-icon>
                  </template>
                  <span v-if="item.isOverrideEditLock === 0">{{$t('Loads.EditingIsLocked')}}</span>
                  <span v-if="item.isOverrideEditLock === -1">{{$t('Loads.EditingIsLockedForAll')}}</span>
                  <span v-if="item.isOverrideEditLock === 10">{{$t('Loads.EditingIsLockedForDrivers')}}</span>
                  <span v-if="item.isOverrideEditLock === 20">{{$t('Loads.EditingIsLockedForDispatchers')}}</span>
                </v-tooltip>
              </v-flex>
              <v-flex shrink>
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn small icon v-on="on" @click.stop="onShowFinancialData(item)">
                        <v-icon small class="mr-1">mdi-finance</v-icon>
                      </v-btn>
                    </template>
                    <span>{{$t('Loads.FinancialData')}}</span>
                  </v-tooltip>
                </template>
              </v-flex>
              <v-flex shrink v-if="item.expensesJSON && item.expensesJSON !== '[]'">
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn small icon v-on="on" @click.stop="onShowExpenses(item.expensesJSON, item.finished, item.id)">
                        <v-icon small class="mr-1" :color="item.isPendingExpenses ? 'warning':'success'">fa-coins</v-icon>
                      </v-btn>
                    </template>
                    <span>{{!item.isPendingExpenses ? $t('Loads.ExpensesTitle') : $t('Loads.ExpensesHasPending')}}</span>
                  </v-tooltip>
                </template>
              </v-flex>
              <v-flex class="pb-0" shrink v-for="invoiceItem in item.invoicesArray" :key="'inv'+invoiceItem.id">
                <template v-if="invoiceItem.invoiceReceivedDate > 0 && $root.isInvoicesViewAllowed">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon small
                             v-on="on"
                             :color="$root.getInvoiceTypeColorByStatus(invoiceItem.invoiceStatus)"
                             @click.stop="$root.onShowInvoicePreview(invoiceItem.invoiceId, invoiceItem.invoiceNumber)"><v-icon small>fa-file-invoice-dollar</v-icon>
                      </v-btn>
                    </template>
                    <span>{{$t('Loads.Invoice')}} #{{invoiceItem.invoiceNumber}} ${{invoiceItem.invoiceTotal}}&nbsp;<strong>{{invoiceItem.invoiceStatus}}</strong></span>
                  </v-tooltip>
                </template>
                <template v-else-if="!invoiceItem.invoiceReceivedDate && $root.isInvoicesViewAllowed">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon small
                             v-on="on"
                             :color="invoiceItem.invoiceDueLeftSeconds < 0 ? 'red' : 'warning'"
                             @click.stop="$root.onShowInvoicePreview(invoiceItem.invoiceId, invoiceItem.invoiceNumber)"><v-icon small>fa-file-invoice-dollar</v-icon></v-btn>
                    </template>
                    <span v-if="invoiceItem.invoiceDueLeftSeconds < 0">{{$t('Loads.Invoice')}} #{{invoiceItem.invoiceNumber}} ${{invoiceItem.invoiceTotal}}&nbsp;{{$t('Invoices.Overdue')}}:&nbsp;-{{Math.ceil($root.secondsToHms(invoiceItem.invoiceDueLeftSeconds).hours / 24)}} d.</span>
                    <span v-if="invoiceItem.invoiceDueLeftSeconds > 0">{{$t('Loads.Invoice')}} #{{invoiceItem.invoiceNumber}} ${{invoiceItem.invoiceTotal}}&nbsp;{{$t('Invoices.DueDate')}}:&nbsp;+{{Math.ceil($root.secondsToHms(invoiceItem.invoiceDueLeftSeconds).hours / 24)}} d.</span>
                  </v-tooltip>
                </template>
              </v-flex>
              <!-- Create invoice btn -->
              <v-flex class="pb-0" shrink v-if="item.invoicesArray.length === 0">
                <template v-if="$root.isOnline && $root.isInvoicesCreateAllowed">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon small v-on="on" @click.stop="$root.route('/invoices/create/' + item.id)"><v-icon small>fa-file-invoice-dollar</v-icon></v-btn>
                    </template>
                    <span>{{$t('Loads.createInvoice')}}</span>
                  </v-tooltip>
                </template>
              </v-flex>
              <v-flex class="pb-0" shrink v-if="item.mailsCount > 0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn small icon v-on="on" @click.stop="onShowMails(item)">
                      <v-icon small class="mr-1" :color="item.mailsUnreadCount ? 'warning' : 'success'">mdi-email</v-icon></v-btn>
                  </template>
                  <span v-if="item.mailsUnreadCount > 0">{{item.mailsUnreadCount}}&nbsp;{{$t('Loads.MailsUnread')}}</span>
                  <span v-else>{{item.mailsCount}}&nbsp;{{$t('Loads.Mails')}}</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
            <v-layout class="pb-1">
              <v-flex class="pa-0 pt-1" v-if="item.notes" shrink>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on" @click.stop="">
                      <v-icon small color="warning">mdi-alert</v-icon>
                    </v-btn>
                  </template>
                  <span>{{item.notes}}</span>
                </v-tooltip>
              </v-flex>
              <v-flex class="pb-0" shrink>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn small icon v-on="on" @click.stop="onShowDocuments(item)">
                      <v-icon small class="mr-1" :color="item.isAllRequiredDocsUploaded ? 'success' : 'warning'">mdi-file-multiple</v-icon>
                    </v-btn>
                  </template>
                  <span v-if="item.isAllRequiredDocsUploaded">{{$t('Loads.uploadDocuments')}}</span>
                  <span v-else>
                    <template v-for="(md, index) in item.missingDocs">
                      <span :key="'missingDocs_'+item.id+index" v-if="index === 'loadPhotos'">{{$t('Loads.LoadPhotos')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                      <span :key="'missingDocs_'+item.id+index" v-if="index === 'rateCon'">{{$t('Loads.RateCon')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                      <span :key="'missingDocs_'+item.id+index" v-if="index === 'do'">{{$t('Loads.DeliveryOrder')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                      <span :key="'missingDocs_'+item.id+index" v-if="index === 'pod'">{{$t('Loads.POD')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                      <span :key="'missingDocs_'+item.id+index" v-if="index === 'bol'">{{$t('Loads.BOL')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}</span>
                    </template>
                  </span>
                </v-tooltip>
              </v-flex>
              <v-flex class="pt-0" shrink>
                <v-tooltip bottom v-if="$root.isOnline && $root.isLoadsEditAllowed && item.isEditAllowed">
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on" @click.stop="$root.onShowLoadEditPreview(item.id)"><v-icon small>mdi-pen</v-icon></v-btn>
                  </template>
                  <span>{{$t('Loads.Edit')}}</span>
                </v-tooltip>
              </v-flex>
              <v-flex class="pt-0" shrink>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on" @click.stop="$root.addNewPageTab('/loads/page/' + item.id)" v-if="$root.isLoadsViewAllowed">
                      <v-icon small>mdi-open-in-new</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('OpenExternal')}}</span>
                </v-tooltip>
              </v-flex>
              <v-flex class="pt-0" shrink>
                <v-tooltip bottom v-if="$root.isOnline && $root.isLoadsEditAllowed">
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on" @click.stop="parseInt(item.deleted) === 0 ? deleteLoad(item.id) : restoreLoad(item.id)">
                      <v-icon small :color="parseInt(item.deleted) === 0 ? 'error' : 'warning'" v-html="parseInt(item.deleted) === 0 ? 'mdi-delete' : 'mdi-restore'">mdi-trash-can</v-icon>
                    </v-btn>
                  </template>
                  <span>{{parseInt(item.deleted) === 0 ? $t('Loads.deleteLoad') : $t('Loads.restoreLoad')}}</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </template>
      </v-data-table>
      <v-dialog v-model="documentsModal" scrollable>
        <v-card>
          <v-card-title>
            <h2>{{$t('Loads.uploadDocuments')}}</h2><v-spacer></v-spacer><v-btn icon @click="documentsModal=false;"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <LoadDocuments :key="documentsModalKey" :fields="selectedLoadFields" :readonly="true"></LoadDocuments>
        </v-card>
      </v-dialog>
      <v-dialog v-model="mailsModal" scrollable>
        <v-card>
          <v-card-title>
            <h2>{{$t('Loads.Mails')}}</h2><v-spacer></v-spacer><v-btn icon @click="mailsModal=false;"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <MailsThread :key="mailsModalKey" :loadId="selectedLoadFields['id']"></MailsThread>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-dialog v-model="expensesModal" scrollable max-width="1000px">
      <v-card>
        <v-card-title>
          <h2>{{$t('Loads.ExpensesTitle')}}</h2><v-spacer></v-spacer><v-btn icon @click="expensesModal=false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <LoadExpenses :key="expensesModalKey"
                      :expensesArray="selectedExpenses"
                      :readOnly="!$root.isLoadsEditAllowed"
                      :isFinished="selectedFinishedField !== 0"
                      :loadId="selectedExpensesLoadId"
                      @expensesSaved="fetchData()"
        ></LoadExpenses>
      </v-card>
    </v-dialog>

    <v-dialog v-model="financesModal" scrollable max-width="1000px">
      <v-card>
        <v-card-title>
          <h2>{{$t('Loads.FinancialData')}}</h2><v-spacer></v-spacer><v-btn icon @click="financesModal=false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <FinancialDataForLoad :loadId="selectedFinancesLoadId"
                              :expenses="selectedExpenses"
                              :key="financesModalKey"
                              :locations="selectedLoadLocations"
                              :isFullFinancialData="$root.isFullFinancialDataAllowedToView"
                              :isDispatcherFinancialData="$root.isDispatcherFinancialDataAllowedToView"
                              :isDriverFinancialData="$root.isDriverFinancialDataAllowedToView"
                              :excludeDispatcherPayment="parseInt(selectedLoadExcludeDispatcherPayment) === 1 ? true : false"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import fieldAccessRightMixin from './../../mixins/fieldAccessRightsMixin';
import Pagination from '../../components/Pagination';
import LoadDocuments from '../../components/LoadDocuments';
import MailsThread from '../../components/MailsThread';
import LoadLocationsTooltip from '../../components/LoadLocationsTooltip';
import TruckDriversSnippet from '../../components/TruckDriversSnippet';
import LoadVerificationButtons from '../../components/LoadVerificationButtons';
import LoadLocationsStartEnd from '../../components/LoadLocationsStartEnd';
import UserSnippet from '../../components/UserSnippet';
import FiltersPanel from '../../components/FiltersPanel';
import LoadExpenses from '../../components/LoadExpenses.vue';
import FinancialDataForLoad from '../../components/FinancialDataForLoad.vue';

export default {
  props: ['driverId', 'responseOverride', 'hideFilters', 'hideTitle', 'hidePagination', 'hideNextActions', 'hideNotEndedLoad'],
  components: { FiltersPanel, UserSnippet, TruckDriversSnippet, LoadLocationsTooltip, Pagination, LoadDocuments, LoadVerificationButtons, MailsThread, LoadLocationsStartEnd, LoadExpenses, FinancialDataForLoad },
  mixins: [fieldAccessRightMixin],
  data () {
    return {
      polling: null,
      loading: false,
      isMounted: false,
      error: '',
      itemsPerPage: 25,
      page: 1,
      search: '',
      options: {},
      headers: [
        { text: '#', value: 'id', sortable: false, class: 'sticky-header' },
        // { text: this.$root.t('Loads.Created'), value: 'date', sortable: false, class: 'sticky-header' },
        { text: '', value: 'phase', sortable: false, class: 'sticky-header' },
        // { text: this.$root.t('Loads.Status'), value: 'status', sortable: false, class: 'sticky-header' },
        { text: this.$root.t('Loads.StartedEnded'), value: 'started_ended', sortable: false, class: 'sticky-header' },
        { text: this.$root.t('Loads.Locations'), value: 'locations', sortable: false, class: 'sticky-header' },
        { text: this.$root.t('Loads.DispatcherBroker'), value: 'disp_broker', sortable: false, class: 'sticky-header' },
        // { text: this.$root.t('Loads.Bid'), value: 'bid', sortable: false, class: 'sticky-header' },
        { text: this.$root.t('Loads.UID'), value: 'uid', sortable: false, class: 'sticky-header' },
        // { text: this.$root.t('Loads.Dispatcher'), value: 'dispatcher', sortable: false, class: 'sticky-header' },
        // { text: this.$root.t('Loads.Broker'), value: 'broker', sortable: false, class: 'sticky-header' },
        { text: this.$root.t('Loads.TruckDrivers'), value: 'trucks_drivers', sortable: false, class: 'sticky-header' },
        // { text: this.$root.t('Loads.Truck'), value: 'truck', sortable: false, class: 'sticky-header' },
        // { text: this.$root.t('Loads.Drivers'), value: 'driver', sortable: false, class: 'sticky-header' },
        // { text: this.$root.t('Loads.Pickup'), value: 'pickup', sortable: false, class: 'sticky-header' },
        // { text: this.$root.t('Loads.Delivery'), value: 'delivery', sortable: false, class: 'sticky-header' },
        // { text: this.$root.t('Loads.Started'), value: 'started', sortable: false, class: 'sticky-header' },
        // { text: this.$root.t('Loads.Ended'), value: 'ended', sortable: false, class: 'sticky-header' },
        { text: this.$root.t('Loads.Miles'), value: 'miles', sortable: false, class: 'sticky-header' },
        { text: this.$root.t('Loads.RatePerMile'), value: 'ratePerMile', sortable: false, class: 'sticky-header' },
        { text: this.$root.t('Loads.Rate'), value: 'rate', sortable: false, class: 'sticky-header' },
        { text: '', value: 'actions' }
      ],
      footer_props: {
        'items-per-page-options': [10, 25, 50],
        'items-per-page-text': '',
        'disable-items-per-page': false
      },
      itemsCount: 0,
      items: [],
      filterDispatcher: 0,
      filterBroker: 0,
      filterDriver: 0,
      filterTruck: 0,
      filterStatus: 'all',
      hideDeactivated: true,
      deletedFilter: 0,
      deletedFilterItems: [
        { value: 0, text: 'Not deleted' },
        { value: 1, text: 'Deleted' },
        { value: 2, text: 'All' }],
      statusFilterItems: [
        { value: 'all', text: 'All' },
        { value: 'notStarted', text: 'Not Started' },
        { value: 'inProgress', text: 'In Progress' },
        { value: 'finished', text: 'Finished' },
        { value: 'finishedWithTONU', text: 'Finished with TONU' },
        { value: 'closed', text: 'Closed' },
        { value: 'unClosed', text: 'Not closed' },
        { value: 'withoutInvoice', text: 'Without invoice' },
        { value: 'notPaidInvoice', text: 'Invoice not paid' },
        { value: 'cloned', text: 'Cloned' },
        { value: 'unlockedEditing', text: 'Editing unlocked' },
        { value: 'withReturn', text: 'With return to Home Parking' },
        { value: 'withOO', text: 'With Owner Operator' },
        { value: 'withDriverAssist', text: 'With driver assist' },
        { value: 'withExpenses', text: 'With expenses' }
        /* { value: 'onTheWayToPickup', text: 'On The Way To Pickup' },
        { value: 'onTheWayToDelivery', text: 'On The Way To Delivery' },
        { value: 'onTheWayToExchange', text: 'On The Way To Exchange' },
        { value: 'onTheWayToParking', text: 'On The Way To Parking' },
        { value: 'onTheWayToHQ', text: 'On The Way To Home Terminal' },
        { value: 'onPickup', text: 'On Pickup' },
        { value: 'onDelivery', text: 'On Delivery' },
        { value: 'onExchange', text: 'On Exchange' },
        { value: 'onParking', text: 'On Parking' }, */
      ],
      awaitingSearch: false,
      documentsModal: false,
      documentsModalKey: new Date().getTime(),
      expensesModal: false,
      expensesModalKey: new Date().getTime(),
      financesModal: false,
      financesModalKey: new Date().getTime(),
      selectedExpenses: [],
      selectedLoadLocations: [],
      selectedExpensesLoadId: 0,
      selectedFinancesLoadId: 0,
      selectedFinishedField: 0,
      selectedLoadExcludeDispatcherPayment: 0,
      mailsModal: false,
      mailsModalKey: new Date().getTime(),
      selectedLoadFields: {},
      allowedFromOthers: false,
      notEndedResult: [],
      nextActionLoading: {},
      returnToHQInListLoading: {},
      isFilterInitialized: false,
      filtersKey: new Date().getTime(),
      filtersItems: [
        {
          name: 'deletedFilter',
          label: 'Deleted',
          field: 0,
          list: [
            { id: 0, name: 'Not deleted' },
            { id: 1, name: 'Deleted' },
            { od: 2, name: 'All' }
          ]
        },
        {
          name: 'filterStatus',
          label: 'Status',
          field: '',
          multiple: true,
          list: [
            { id: 'notStarted', name: 'Not Started' },
            { id: 'inProgress', name: 'In Progress' },
            { id: 'finished', name: 'Finished' },
            { id: 'finishedWithTONU', name: 'Finished with TONU' },
            { id: 'closed', name: 'Closed' },
            { id: 'unClosed', name: 'Not closed' },
            { id: 'withoutInvoice', name: 'Without invoice' },
            { id: 'notPaidInvoice', name: 'Invoice not paid' },
            { id: 'cloned', name: 'Cloned' },
            { id: 'unlockedEditing', name: 'Editing unlocked' },
            { id: 'withReturn', name: 'With return to Home Parking' },
            { id: 'withOO', name: 'With Owner Operator' },
            { id: 'withDriverAssist', name: 'With driver assist' },
            { id: 'withExpenses', name: 'With expenses' }
          ]
        },
        {
          name: 'filterDispatcher',
          label: 'Dispatcher',
          fields: '',
          multiple: true,
          list: [
          ]
        },
        {
          name: 'filterDriver',
          label: 'Driver',
          fields: '',
          multiple: true,
          list: [
          ]
        },
        {
          name: 'filterTruck',
          label: 'Truck',
          fields: '',
          multiple: true,
          list: [
          ]
        },
        {
          name: 'filterBroker',
          label: 'Broker',
          fields: '',
          isSearch: true,
          multiple: true,
          list: [
          ]
        }
      ]
    };
  },
  computed: {
    getSubItemColor () {
      return '#222';
    },
  },
  watch: {
    search () {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.options.page = 1;
          this.page = 1;
          this.fetchData();
          this.awaitingSearch = false;
        }, 1000);
      }
      this.awaitingSearch = true;
    },
    options: {
      handler () {
        let { page, itemsPerPage } = this.options;
        if (!this.isMounted) return;
        if (itemsPerPage) {
          this.$root.setLocalStorageItem('loadsItemsPerPage', itemsPerPage);
          this.itemsPerPage = itemsPerPage;
        }
        if (page) {
          this.$root.setLocalStorageItem('loadsPage', page);
        }
        this.fetchData().then(data => {
          this.items = data.items;
          this.itemsCount = data.total;
        });
      },
      deep: true
    }
  },
  mounted () {
    this.$root.setLastListPath(this.$route.path);
    this.$nextTick(() => {
      // this.fetchData();
      // this.loading = false;
      if (this.driverId > 0) {
        this.headers = [
          { text: '', value: 'open_action', sortable: false },
          { text: this.$root.t('Loads.UID'), value: 'uid', sortable: false },
          { text: this.$root.t('Loads.Status'), value: 'status', sortable: false },
          { text: this.$root.t('Loads.Dispatcher'), value: 'dispatcher', sortable: false },
          { text: this.$root.t('Loads.Truck'), value: 'truck', sortable: false },
          { text: this.$root.t('Loads.Pickup'), value: 'pickup', sortable: false },
          { text: this.$root.t('Loads.Delivery'), value: 'delivery', sortable: false },
          { text: this.$root.t('Loads.Started'), value: 'started', sortable: false },
          { text: this.$root.t('Loads.Ended'), value: 'ended', sortable: false }
        ];
        this.$forceUpdate();
      } else {
        // set saved items per page if exist
        if (this.$root.getLocalStorageItem('loadsItemsPerPage')) {
          this.itemsPerPage = parseInt(this.$root.getLocalStorageItem('loadsItemsPerPage'));
        }

        // set saved page if exist
        if (this.$root.getLocalStorageItem('loadsPage')) {
          this.options.page = parseInt(this.$root.getLocalStorageItem('loadsPage'));
          this.page = parseInt(this.$root.getLocalStorageItem('loadsPage'));
        }
      }

      // do not call fetch here and disable loading sinse in will called when data-table initialize and options changed in watch
      // Only for small screen since we use simple list there
      this.fetchData().then(() => {
        this.isFilterInitialized = true;
      });

      this.polling = setInterval(() => {
        // Do not call interval methods if page not visible
        if (!this.$store.getters.isPageVisible) return;
        this.fetchData(false).then(() => {
          this.isFilterInitialized = true;
        });
      }, this.$store.getters.pullIntervalList);

      this.isMounted = true;
    });
  },
  beforeDestroy () {
    clearInterval(this.polling);
  },
  methods: {
    onShowExpenses (expensesJSON, finishedField, loadId) {
      if (expensesJSON) {
        this.selectedExpensesLoadId = loadId;
        this.selectedExpenses = JSON.parse(expensesJSON);
        this.selectedFinishedField = finishedField;
        this.expensesModalKey = new Date().getTime();
        this.expensesModal = true;
      }
    },
    onShowFinancialData (loadData) {
      this.selectedFinancesLoadId = loadData.id;
      this.selectedExpenses = JSON.parse(loadData.expensesJSON);
      this.selectedLoadLocations = loadData.locationsJSON;
      this.selectedLoadExcludeDispatcherPayment = loadData.isExcludeDispatcherPayment
      this.financesModalKey = new Date().getTime();
      this.financesModal = true;
    },
    filterBrokerSearch (val) {
      if (!val || val.length === 0) return;
      this.$root.loadUsersList(this.$store.getters.roleBroker, false, true, 1, val).then(response => {
        if (response.status === 'ok') {
          this.filterBrokersList = response.result.data.map((item) => {
            return {
              id: item.id,
              name: item.firstName + ' ' + item.lastName
            };
          });
          this.$refs.filtersPanel.filters.forEach(el => {
            if (el.name === 'filterBroker') {
              el.list = this.filterBrokersList;
            }
          });
        }
      });
    },
    onFilterChanged (e) {
      let i = 0;
      let field = '';
      let value = '';
      const filtersFields = Object.keys(e);
      for (i = 0; i < filtersFields.length; i++) {
        field = filtersFields[i];
        value = Object.prototype.hasOwnProperty.call(e, field) ? e[field] : null;
        if (value !== '') {
          this[field] = Array.isArray(value) ? value.join(',') : value;
        }
      }
      if (this.isFilterInitialized) {
        this.options.page = 1;
        this.page = 1;
        this.fetchData();
      }

      this.isFilterInitialized = true;
    },
    onReturnToHQInList (item) {
      this.$dialog.confirm(this.$root.t('Loads.AddReturnToHQConfirmMsg')).then(dialog => {
        this.returnToHQInListLoading[item.id] = true;
        this.$root.setDefaultHQ = async (place) => {
          this.$root.addReturnToHQToLoad(item.id, place).then((response) => {
            if (response.status === 'ok') {
              this.fetchData();
            }
            this.returnToHQInListLoading[item.id] = false;
          }).catch((e) => {
            this.returnToHQInListLoading[item.id] = false;
          });
        }
        this.$root.getDefaultHQ();
      });
    },
    onNextAction (loadId, nextAction) {
      this.$dialog.confirm(this.$i18n.t('PleaseConfirmYourChoice')).then(dialog => {
        this.nextActionLoading[loadId] = true;
        this.$root.processToNextLoadStatus(loadId, nextAction).then(response => {
          if (response.status === 'ok') {
            this.fetchData(false).then(() => {
              this.nextActionLoading[loadId] = false;
            });
          }
        });
      });
    },
    onShowMails (fields) {
      if (fields) {
        this.selectedLoadFields = fields;
        this.mailsModalKey = new Date().getTime();
        this.mailsModal = true;
      }
    },
    onShowDocuments (fields) {
      if (fields) {
        this.selectedLoadFields = fields;
        this.documentsModalKey = new Date().getTime();
        this.documentsModal = true;
      }
    },
    pageChanged (event) {
      this.options.page = event;
      this.page = event;
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
        this.fetchData();
      }
    },
    deleteLoad (id) {
      this.$dialog.confirm(this.$root.t('Loads.LoadDeleteConfirmationMessage')).then(dialog => {
        this.$root.deleteLoad(id);
        this.fetchData();
      });
    },
    restoreLoad (id) {
      this.$dialog.confirm(this.$root.t('Loads.LoadRestoreConfirmationMessage')).then(dialog => {
        this.$root.saveLoad({ deleted: 0 }, id).then((response) => {});
        this.fetchData();
      }).catch(() => {
      });
    },
    fetchData (showLoading = true) {
      let itemData = {};
      let response = {};
      let isFilterDataUpdated = false;
      if (!this.isLoadsViewAllowed) {
        this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
        this.loading = false;
        return;
      }
      return new Promise(async (resolve, reject) => {
        if (showLoading) this.loading = true;
        let { page, itemsPerPage } = this.options;
        // Set page and itemsPerPage from values, not component until page is mounted
        if (!this.isMounted) {
          page = this.page;
          itemsPerPage = this.itemsPerPage;
          this.options.page = page;
          this.options.itemsPerPage = itemsPerPage;
        }

        this.error = null;
        let _newItems = [];

        let itemsPage = this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ? this.page - 1 : page - 1;
        let itemsPP = itemsPerPage < 0 ? 0 : itemsPerPage;
        let isShowOnlyToday = this.$route.params.showOnlyToday ? 1 : 0;

        // Override filters if driverId prop are passed
        if (this.driverId) {
          this.filterDispatcher = 0;
          this.filterBroker = 0;
          this.filterDriver = this.driverId;
        }
        if (this.deletedFilter === '') this.deletedFilter = 0;
        if (this.filterStatus === '') this.filterStatus = 'all';
        if (this.filterDispatcher === '') this.filterDispatcher = 0;
        if (this.filterDriver === '') this.filterDriver = 0;
        if (this.filterTruck === '') this.filterTruck = 0;

        if (this.responseOverride) {
          response = this.responseOverride;
        } else {
          response = await this.$root.getLoadList(
            this.deletedFilter,
            itemsPage || 0,
            itemsPP || this.itemsPerPage,
            this.filterDispatcher,
            this.filterBroker,
            this.filterDriver,
            this.filterTruck,
            -1,
            true,
            this.filterStatus,
            isShowOnlyToday,
            this.search);
        }

        if (response.status && response.status === 'error') {
          console.error('ERROR', response.msg);
          reject(response.msg);
        }

        if (response.status === 'ok') {
          this.allowedFromOthers = response.allowedFromOthers;

          // Setup not ended loads
          this.notEndedResult = response.notEndedResult;
          for (let i = 0; i < this.notEndedResult.length; i++) {
            const locations = this.notEndedResult[i].locationsJSON;

            let pickupLocationName = 'N/A';
            let deliveryLocationName = 'N/A';
            for (let j = 1; j < locations.length; j++) {
              if (locations[j].pickup === 1) {
                pickupLocationName = locations[j].name;
                break;
              }
            }
            for (let j = locations.length - 1; j > 0; j--) {
              if (locations[j].delivery === 1) {
                deliveryLocationName = locations[j].name;
                break;
              }
            }

            pickupLocationName = pickupLocationName.replace(/, USA/gi, '');
            if (pickupLocationName.split(',').length > 2) {
              pickupLocationName = pickupLocationName.substring(pickupLocationName.indexOf(',') + 1);
            }
            deliveryLocationName = deliveryLocationName.replace(/, USA/gi, '');
            if (deliveryLocationName.split(',').length > 2) {
              deliveryLocationName = deliveryLocationName.substring(deliveryLocationName.indexOf(',') + 1);
            }

            this.notEndedResult[i].pickup = pickupLocationName;
            this.notEndedResult[i].delivery = deliveryLocationName;
            this.notEndedResult[i].status = this.$root.getLoadStatus(locations, response.notEndedResult[i].finished);
            this.notEndedResult[i].statusLocation = this.$root.getLoadStatusLocation(locations, response.notEndedResult[i].finished);
          }

          /*this.$root.loadUsersList(null, false, true).then(usersResponse => {
            if (usersResponse.status === 'ok') {
              this.filterDispatchersList = [];
              this.filterBrokersList = [];
              this.filterDriversList = [];
              for (let u = 0; u < usersResponse.result.data.length; u++) {
                const userData = usersResponse.result.data[u];
                if (userData.role === 'Dispatcher') {
                  this.filterDispatchersList.push({
                    id: userData.id,
                    name: userData.firstName + ' ' + userData.lastName
                  });
                } else if (userData.role === 'Broker') {
                  this.filterBrokersList.push({
                    id: userData.id,
                    name: userData.firstName
                  });
                } else if (userData.role === 'Driver') {
                  this.filterDriversList.push({
                    id: userData.id,
                    name: userData.firstName + ' ' + userData.lastName
                  });
                }
              }

              if (this.filterDispatchersList.length > 0) {
                this.filterDispatchersList.unshift({ id: 0, name: this.$root.t('All') });
              } else {
                this.filterDispatcher = 0;
              }

              if (this.filterBrokersList.length > 0) {
                this.filterBrokersList.unshift({ id: 0, name: this.$root.t('All') });
              } else {
                this.filterBroker = 0;
              }

              if (this.filterDriversList.length > 0) {
                this.filterDriversList.unshift({ id: 0, name: this.$root.t('All') });
              } else {
                this.filterDriver = 0;
              }
            }
          });

          this.$root.getTrucksList().then(trucksResponse => {
            if (trucksResponse.status === 'ok') {
              this.filterTrucksList = [];
              for (let t = 0; t < trucksResponse.result.length; t++) {
                const truckData = trucksResponse.result[t];
                this.filterTrucksList.push({
                  id: truckData.id,
                  name: truckData.name
                });
              }
              if (this.filterTrucksList.length > 0) {
                this.filterTrucksList.unshift({ id: 0, name: this.$root.t('All') });
              } else {
                this.filterTruck = 0;
              }
            }
          });*/

          for (let i = 0; i < response.result.length; i++) {
            this.returnToHQInListLoading[response.result[i].id] = false;
            const locations = response.result[i].locationsJSON;

            let pickupLocationName = 'N/A';
            let deliveryLocationName = 'N/A';
            for (let j = 1; j < locations.length; j++) {
              if (locations[j].pickup === 1) {
                pickupLocationName = locations[j].name;
                break;
              }
            }
            for (let j = locations.length - 1; j > 0; j--) {
              if (locations[j].delivery === 1) {
                deliveryLocationName = locations[j].name;
                break;
              }
            }

            pickupLocationName = pickupLocationName.replace(/, USA/gi, '');
            if (pickupLocationName.split(',').length > 2) {
              pickupLocationName = pickupLocationName.substring(pickupLocationName.indexOf(',') + 1);
            }
            deliveryLocationName = deliveryLocationName.replace(/, USA/gi, '');
            if (deliveryLocationName.split(',').length > 2) {
              deliveryLocationName = deliveryLocationName.substring(deliveryLocationName.indexOf(',') + 1);
            }

            itemData = {
              id: response.result[i].id,
              type: response.result[i].type,
              name: pickupLocationName + ' -- ' + deliveryLocationName,
              deleted: response.result[i].deleted,
              finished: response.result[i].finished,
              startedPST: response.result[i].startedPST,
              endedPST: response.result[i].endedPST,
              status: this.$root.getLoadStatus(locations, response.result[i].finished),
              statusLocation: this.$root.getLoadStatusLocation(locations, response.result[i].finished),
              bid: response.result[i].bid,
              uid: response.result[i].uid,
              isReturnToHQ: response.result[i].isReturnToHQ,
              loadNumber: response.result[i].loadNumber,
              parent: response.result[i].parent,
              date: response.result[i].datePST,
              dispatcher: response.result[i].dispatcher,
              dispatcherAvatar: response.result[i].dispatcherAvatar,
              dispatcherInitials: response.result[i].dispatcherInitials,
              dispatcherName: response.result[i].dispatcherName,
              broker: response.result[i].broker,
              brokerName: response.result[i].brokerName,
              truck: response.result[i].truck,
              truckName: response.result[i].truckName,
              driver: response.result[i].driver,
              driverInitials: response.result[i].driverInitials,
              driverAvatar: response.result[i].driverAvatar,
              codriver: response.result[i].codriver,
              codriverInitials: response.result[i].codriverInitials,
              codriverAvatar: response.result[i].codriverAvatar,
              pickup: pickupLocationName,
              delivery: deliveryLocationName,
              started: response.result[i].started,
              ended: response.result[i].ended,
              rate: response.result[i].Rate,
              RTLRate: response.result[i].RTLRate,
              ratePerMile: response.result[i].RatePerMile,
              invoiceId: response.result[i].invoiceId,
              invoiceNumber: response.result[i].invoiceNumber,
              invoiceAmountReceived: response.result[i].invoiceAmountReceived,
              invoiceReceivedDate: response.result[i].invoiceReceivedDate,
              invoiceStatus: response.result[i].invoiceStatus,
              isEditAllowed: response.result[i].isEditAllowed,
              invoiceDueLeftSeconds: response.result[i].invoiceDueLeftSeconds,
              drivers: response.result[i].drivers,
              trucks: response.result[i].trucks,
              miles: response.result[i].distanceInMiles,
              additionalPayments: response.result[i].additionalPayments,
              isLoadInvoiceNotMatch: response.result[i].isLoadInvoiceNotMatch,
              invoiceTotal: response.result[i].invoiceTotal,
              locations: locations,
              subItems: [],
              isAllRequiredDocsUploaded: response.result[i].isAllRequiredDocsUploaded,
              loadPhotosUPLOAD: response.result[i].loadPhotosUPLOAD,
              rateConUPLOAD: response.result[i].rateConUPLOAD,
              publicRateConUPLOAD: response.result[i].publicRateConUPLOAD,
              doUPLOAD: response.result[i].doUPLOAD,
              bolUPLOAD: response.result[i].bolUPLOAD,
              podUPLOAD: response.result[i].podUPLOAD,
              driversReportsUPLOAD: response.result[i].driversReportsUPLOAD,
              invoicesArray: response.result[i].invoicesArray,
              isOverrideEditLock: response.result[i].isOverrideEditLock,
              isDriverOwnerOperator: response.result[i].isDriverOwnerOperator,
              truckOOData: response.result[i].truckOOData,
              driverOOData: response.result[i].driverOOData,
              isCloned: response.result[i].isCloned,
              missingDocs: response.result[i].missingDocs,
              verifiedStatus: response.result[i].verifiedStatus,
              refineNotes: response.result[i].refineNotes,
              refinedNotes: response.result[i].refinedNotes,
              nextAction: response.result[i].nextAction,
              mailsCount: response.result[i].mailsCount,
              mailsUnreadCount: response.result[i].mailsUnreadCount,
              expensesJSON: response.result[i].expensesJSON,
              isPendingExpenses: response.result[i].isPendingExpenses,
              isEasyMode: parseInt(response.result[i].isEasyMode),
              notes: response.result[i].notes
            };

            // show flat list is driverId prop is set
            // if (response.result[i].parent === 0 || this.driverId) {
            _newItems.push(itemData);
            /* } else {
              _newItems[_newItems.length - 1].subItems.push(itemData);
            } */
          }
          this.$set(this, 'items', _newItems);
          this.$set(this, 'itemsCount', response.count);

          isFilterDataUpdated = false;
          for (let f = 0; f < this.filtersItems.length; f++) {
            if (this.filtersItems[f].name === 'filterDispatcher') {
              if (!this.filtersItems[f].list || this.filtersItems[f].list.length === 0) {
                this.$set(this.filtersItems[f], 'list', response.dispatchersList);
                isFilterDataUpdated = true;
              }
            }

            if (this.filtersItems[f].name === 'filterDriver') {
              if (!this.filtersItems[f].list || this.filtersItems[f].list.length === 0) {
                this.$set(this.filtersItems[f], 'list', response.driversList);
                isFilterDataUpdated = true;
              }
            }

            if (this.filtersItems[f].name === 'filterTruck') {
              if (!this.filtersItems[f].list || this.filtersItems[f].list.length === 0) {
                this.$set(this.filtersItems[f], 'list', response.trucksList);
                isFilterDataUpdated = true;
              }
            }
          }
          if (isFilterDataUpdated) {
            this.isFilterInitialized = false;
            this.filtersKey = new Date().getTime();
          }

          this.$forceUpdate();
          this.loading = false;
          resolve({ items: this.items, total: this.itemsCount });
        }
      });
    }
  }
};
</script>

<style scoped>
  /*.v-data-table /deep/ .sticky-header {
    position: sticky !important;
    top: 94px;
  }*/

  /*.v-data-table /deep/ .v-data-table__wrapper {
    overflow: unset;
  }*/

</style>
