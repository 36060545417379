<template>
  <v-container fill-height grid-list-md text-xs-center v-if="loading && !hideLoading">
    <div class="lds-facebook" style="margin:auto;"><div></div><div></div><div></div></div>
  </v-container>
  <div v-else-if="error">
    <v-flex class="text-center error pa-2">
      <h1 class="title">{{error}}</h1>
    </v-flex>
  </div>
  <div v-else>
<!--

    {{locations[0]}}
    <hr/>
    {{locations[1]}}
-->

    <!--<hr/>
    {{locations[0].targetDatetimes}}
    <hr/>
    {{locations[1].targetDatetimes}}
    <hr/>
    {{locations[2].targetDatetimes}}
    <hr/>
    {{deliveryStep}}
    -->

    <!--<v-progress-linear
      v-if="!edit"
      :buffer-value="doneProgress"
      :value="doneProgress"
      height="10px"
      stream
      color="green"
    ></v-progress-linear>-->
    <v-card class="ma-1">
      <v-alert v-if="parseInt(onlyTruck) > 0 && trucksInfo.hasOwnProperty(onlyTruck)"
               class="mt-1"
               type="info">
        {{$t('Loads.TruckViewMode')}}&nbsp;{{trucksInfo[onlyTruck].name}}
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="overrideOnlyTruck = !overrideOnlyTruck">
              <v-icon v-if="!overrideOnlyTruck">mdi-eye</v-icon>
              <v-icon v-else>mdi-eye-off</v-icon>
            </v-btn>
          </template>
          <span>{{$t('Loads.ShowHideAllLocations')}}</span>
        </v-tooltip>
        <v-btn icon @click="$root.route('/loads/page/'+loadId)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-alert>
      <v-timeline dense align-top v-if="!locationsHidden" :style="$vuetify.breakpoint.xs ? {marginLeft: '-95px'} : {}">
        <template v-for="(location, index) in locations">
          <!--<template v-if="index === 0 || edit ||
                          (index > 0 && locations[index-1].dispatcherOnTheWayCheck > 0 && location.driverOnTheWayCheck > 0) || isFutureStatusesActivated(index + 1, locations)">-->
          <template v-if="!parseInt(onlyTruck) || (index === 0 &&
                          parseInt(onlyTruck) === location.truck) || (index > 0 &&
                          (parseInt(onlyTruck) === locations[index-1].truck || parseInt(onlyTruck) === location.truck)) ||
                          overrideOnlyTruck">
            <v-slide-y-transition :key="'v-slide-y-transition' + index">
              <v-timeline-item large fill-dot class="text-left mr-2 ml-1" :color="$root.getLocationColor(location, index)">
                  <template v-slot:icon>
                    <v-icon class="white--text" v-if="index === 0">mdi-map-marker</v-icon>

                    <template v-else-if="(location.driverOnSiteCheck || location.driverDoneCheck) && !edit">
                      <v-badge
                        bordered
                        :color="(location.driverDoneCheck || ((location.parking || location.returnToHQ) && location.driverOnSiteCheck)) && location.dispatcherOnTheWayCheck > 0 ? 'success'   : 'warning'"
                        :icon="(location.driverDoneCheck || ((location.parking || location.returnToHQ) && location.driverOnSiteCheck))  && location.dispatcherOnTheWayCheck > 0 ? 'mdi-check' : 'mdi-progress-check'"
                      >
                        <v-icon class="white--text" v-if="location.pickup">fa-people-carry</v-icon>
                        <v-icon class="white--text" v-else-if="location.delivery">fa-truck-loading</v-icon>
                        <v-icon class="white--text" v-else-if="location.parking">mdi-parking</v-icon>
                        <v-icon class="white--text" v-else-if="location.exchange">mdi-swap-horizontal-bold</v-icon>
                        <v-icon class="white--text" v-else-if="location.returnToHQ">mdi-redo-variant</v-icon>
                        <v-icon class="white--text" v-else>mdi-map-marker</v-icon>

                      </v-badge>
                    </template>
                    <template v-else>
                      <v-icon class="white--text" v-if="location.pickup">fa-people-carry</v-icon>
                      <v-icon class="white--text" v-else-if="location.delivery">fa-truck-loading</v-icon>
                      <v-icon class="white--text" v-else-if="location.parking">mdi-parking</v-icon>
                      <v-icon class="white--text" v-else-if="location.exchange">mdi-swap-horizontal-bold</v-icon>
                      <v-icon class="white--text" v-else-if="location.returnToHQ">mdi-redo-variant</v-icon>
                      <v-icon class="white--text" v-else>mdi-map-marker</v-icon>
                    </template>
                  </template>
                <LoadProgressCard :forceShowButtons="isFutureStatusesActivated(index + 1, locations)"
                                  :key="edit ? null : 'progresCard'+index+$store.getters['loads/getLocationsKey']"
                                  :index="index"
                                  :edit="edit"
                                  :loadId="loadId"
                                  :hoursUntilDetention="hoursUntilDetention"
                                  @locationUpdated="$emit('updateMap')"
                                  @delete-location="deleteLocation"
                                  @move-location-up="moveLocationUp"
                                  @move-location-down="moveLocationDown"
                                  @recalc-timings="$emit('recalc-timings', $event)"
                                  :locationsReadOnly="locationsReadOnly"
                                  :locationStatusHidden="locationStatusHidden"
                                  :locationStatusReadOnly="locationStatusReadOnly"
                                  :locationDetailsHidden="locationDetailsHidden"
                                  :locationDetailsReadOnly="locationDetailsReadOnly"
                                  :locationOdometerHidden="locationOdometerHidden"
                                  :locationOdometerReadOnly="locationOdometerReadOnly"
                                  :odometerUPLOADHidden="odometerUPLOADHidden"
                                  :odometerUPLOADReadOnly="odometerUPLOADReadOnly"
                                  :isDriverOwnerOperator="isDriverOwnerOperator"
                                  :shipper="shipper"
                                  :consignee="consignee"
                                  :driversRates="driversRates"
                                  :trucksInfo="trucksInfo"
                                  :driversInfo="driversInfo"
                                  :usersInfo="usersInfo"
                                  :readonly="readonly"
                                  :finished="finished"
                                  :appointmentData="appointmentData"
                                  :isReturnToHQ="isReturnToHQ"
                                  :showDeactivatedDrivers="isLoadFinished"
                                  :childLocations="!edit ? getChildLocations(location.id) : []"
                                  :isSetDriverOORateAllowed="isSetDriverOORateAllowed"
                                  :isEasyMode="isEasyMode"
                />
              </v-timeline-item>
            </v-slide-y-transition>

            <v-timeline-item color="gray" v-if="edit && !locationsReadOnly" :key="'location'+index">
              <template v-slot:icon>
                <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="toggleNewLocationField(index)">
                    <v-icon>mdi-map-marker-plus</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('Loads.NewLocationAddress')}}</span>
                </v-tooltip>
              </template>
              <v-layout v-if="insertNewLocationFieldVisible[index] || $vuetify.breakpoint.xs" wrap>
                <v-flex xs12>
                  <v-subheader v-if="$vuetify.breakpoint.xs">{{$t('Loads.NewLocationAddress')}}</v-subheader><br/>
                </v-flex>
                <v-flex xs10 md6 class="pl-1 mt-1">
                  <v-text-field :id="'newLocation' + index"
                                class="mr-4"
                                v-model="currentSelectedPlaceName"
                                hide-details="true"
                                :label="$t('Loads.LocationAddress')"
                                @click="initInsertedPlaceAutocomplete(index)"
                                dense outlined>
                    <template v-slot:append-outer>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex xs2 md1 class="mt-1">
                  <v-tooltip bottom v-if="currentSelectedPlace !== null">
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" @click="insertNewLocation(index)">
                        <v-icon>
                          mdi-table-row-plus-after
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{$t('Loads.AddSelectedLocation')}}</span>
                  </v-tooltip>
                </v-flex>
                <v-flex class="pr-4 pl-1 mt-1" xs10 md4>
                  <SelectTruck
                    height="30px"
                    :hideIcon="true"
                    :label="$root.t('Loads.UserTruckLocation')"
                    @change="insertTruckLocation($event, index)"
                  ></SelectTruck>
                </v-flex>
                <v-flex xs2 md1 class="mt-1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" @click="insertHQLocation(index)"><v-icon>mdi-home-map-marker</v-icon></v-btn>
                    </template>
                    <span>{{$t('Loads.AddHQLocation')}}</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
            </v-timeline-item>
            <!-- DRIVING ITEM -->
            <!--<template v-if="(location.dispatcherOnTheWayCheck > 0 && (index === 0 || location.driverDoneCheck || location.driverNotDoneCheck || (location.driverOnSiteCheck && (location.parking || location.exchange)))*/) ||
                            edit ||
                            isFutureStatusesActivated(index + 1, locations)">-->
            <!-- <template v-if="!isDriverOwnerOperator &&
                            ( !parseInt(onlyTruck) ||
                              parseInt(onlyTruck) === location.truck ||
                              overrideOnlyTruck
                            )">
              <v-timeline-item v-if="index < locations.length - 1 && locations[index + 1].canceled === 0 && !isEasyMode"
                               :key="'timeline'+index"
                               class="text-left"
                               :color="locations[index+1].driverOnSiteCheck || edit ? $root.doneLocationColor : $root.inProgressColor">
                <template v-slot:icon>
                  <v-icon class="white--text" v-if="locations[index+1].driverOnSiteCheck && !edit">mdi-check</v-icon>
                  <v-icon class="white--text" v-else>mdi-truck-fast</v-icon>
                </template>

                <LoadProgressDrivingCard :key="'progresDrivingCard'+index+$store.getters['loads/getLocationsKey'] + locations[index+1].name"
                                         :loadId="loadId"
                                         :index="index"
                                         :edit="edit"
                                         :currentLat="currentLat"
                                         :currentLng="currentLng"
                                         :locationStatusHidden="locationStatusHidden"
                                         :locationStatusReadOnly="locationStatusReadOnly"
                                         :locationOdometerHidden="locationOdometerHidden"
                                         :locationOdometerReadOnly="locationOdometerReadOnly"
                                         :odometerUPLOADHidden="odometerUPLOADHidden"
                                         :odometerUPLOADReadOnly="odometerUPLOADReadOnly"
                                         :isDriverOwnerOperator="isDriverOwnerOperator"
                                         @calculateProgress="calculateProgress"
                                         @refreshDistanceAndDuration="updateCurrentDistanceAndDuration"
                                         :driversInfo="driversInfo"
                                         :usersInfo="usersInfo"
                                         :readonly="readonly"
                ></LoadProgressDrivingCard>
              </v-timeline-item>
            </template> -->
          </template>
        </template>

        <!-- RETURN TO HQ -->
        <!--<template v-if="returnToHQ.name">
          <v-timeline-item class="text-left"
                           :color="returnToHQ.driverOnSiteCheck ? doneLocationColor : inProgressColor">
            <template v-slot:icon>
              <v-icon v-if="returnToHQ.driverOnSiteCheck">mdi-check</v-icon>
              <v-icon v-else>mdi-truck-fast</v-icon>
            </template>
            <LoadProgressDrivingCard :locations="[returnToHQ,returnToHQ]"
                                     :location="returnToHQ"
                                     :nextLocation="locations[locations.length - 1]"
                                     :index="999"
                                     :edit="edit"
                                     :currentLat="currentLat"
                                     :currentLng="currentLng"
                                     @statusChange="statusChange"
                                     @calculateProgress="calculateProgress"
            ></LoadProgressDrivingCard>
          </v-timeline-item>

          <v-timeline-item large fill-dot class="text-left ma-1 mr-5" :color="getLocationColor(returnToHQ, 999)">
            <template v-slot:icon>
              <v-badge
                bordered
                :color="returnToHQ.driverOnSiteCheck ? 'success'   : 'warning'"
                :icon= "returnToHQ.driverOnSiteCheck ? 'mdi-check' : 'mdi-progress-check'"
              >
                <v-icon>mdi-undo-variant</v-icon>
              </v-badge>
            </template>
            <LoadProgressCard :locations="[returnToHQ,returnToHQ]"
                              :location="returnToHQ"
                              :index="999"
                              :edit="edit"
                              :locationColor="getLocationColor(returnToHQ, 999)"
                              :hoursUntilDetention="hoursUntilDetention"
                              @setAppoinment="setReturnToHQAppoinment"
                              @statusChange="statusChange"
                              @odometerUploaded="odometerUploaded"
                              @odometerValueChanged="odometerValueChanged"
                              @openDocuments="uploadDocumentsModal = true"
            ></LoadProgressCard>
          </v-timeline-item>
        </template>-->
      </v-timeline>
    </v-card>

    <!-- MODALS -->
    <!--
    <v-dialog v-model="uploadDocumentsModal" persistent class="text-center">
      <v-card class="ma-1" width="99%">
        <v-card-text>
          <v-tabs v-model="documentsTab" center-active show-arrows active-class="tabs-active-color">
            <v-tab key="photos">{{ $t('Loads.LoadPhotos') }}</v-tab>
            <v-tab key="do"    >{{ $t('Loads.DeliveryOrder') }}</v-tab>
            <v-tab key="bol"   >{{ $t('Loads.BOL') }}</v-tab>
            <v-tab key="pod"   >{{ $t('Loads.POD') }}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="documentsTab" active-class="tabs-active-color">
            <v-tab-item key="photos">
              <v-card class="pa-1">
                <MultiFileUpload :field="fields.loadPhotosUPLOAD"
                                 @change="fileChange('loadPhotosUPLOAD', $event)"
                                 fieldName="loadPhotosUPLOAD"
                                 :label="$t('Loads.LoadPhotos')"
                                 uploadFolder="loadPhotos"
                                 :downloadAllowed="true"
                                 :readonly="readonly"
                ></MultiFileUpload>
              </v-card>
            </v-tab-item>

            <v-tab-item key="do">
              <v-card class="pa-1">
                <MultiFileUpload :field="fields.doUPLOAD"
                                 @change="fileChange('doUPLOAD', $event)"
                                 fieldName="doUPLOAD"
                                 :label="$t('Loads.DeliveryOrder')"
                                 uploadFolder="deliveryOrder"
                                 :downloadAllowed="true"
                                 :readonly="readonly"
                ></MultiFileUpload>
              </v-card>
            </v-tab-item>

            <v-tab-item key="bol">
              <v-card class="pa-1">
                <MultiFileUpload :field="fields.bolUPLOAD"
                                 @change="fileChange('bolUPLOAD', $event)"
                                 fieldName="bolUPLOAD"
                                 :label="$t('Loads.BOL')"
                                 uploadFolder="billOfLading"
                                 :downloadAllowed="true"
                                 :readonly="readonly"
                ></MultiFileUpload>
              </v-card>
            </v-tab-item>

            <v-tab-item key="pod">
              <v-card class="pa-1">
                <MultiFileUpload :field="fields.podUPLOAD"
                                 @change="fileChange('podUPLOAD', $event)"
                                 fieldName="podUPLOAD"
                                 :label="$t('Loads.POD')"
                                 uploadFolder="proofOfDelivery"
                                 :downloadAllowed="true"
                                 :readonly="readonly"
                ></MultiFileUpload>
              </v-card>
            </v-tab-item>

          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="readonly" @click="$emit('saveDocuments', {field: 'podUPLOAD', value: fields.podUPLOAD})"><v-icon left>fa-save</v-icon>{{$t('Save')}}</v-btn>
          <v-btn text class="ma-1" @click="uploadDocumentsModal = false;"><v-icon left>mdi-close</v-icon>{{$t('Close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->
  </div>
</template>

<script>
import LoadProgressCard from '../components/LoadProgressCard.vue';
import LoadProgressDrivingCard from '../components/LoadProgressDrivingCard.vue';

import userFormsCommonMixin from '../mixins/userFormsCommonMixin';
import SelectTruck from './SelectTruck';

export default {
  name: 'LoadProgress',
  props: [
    'loadId', 'currentLat', 'currentLng', 'hoursUntilDetention', 'started', 'ended', 'finished', 'edit',
    'locationsHidden', 'locationsReadOnly',
    'locationStatusHidden', 'locationStatusReadOnly',
    'locationDetailsHidden', 'locationDetailsReadOnly',
    'locationOdometerHidden', 'locationOdometerReadOnly',
    'odometerUPLOADHidden', 'odometerUPLOADReadOnly',
    'isDriverOwnerOperator',
    'shipper', 'consignee', 'driversRates',
    'trucksInfo', 'driversInfo', 'usersInfo', 'readonly', 'appointmentData', 'isReturnToHQ', 'isLoadFinished', 'onlyTruck', 'isSetDriverOORateAllowed', 'isEasyMode'
  ],
  components: { SelectTruck, LoadProgressCard, LoadProgressDrivingCard },
  mixins: [userFormsCommonMixin],
  data: function () {
    return {
      loading: false,
      error: null,
      polling: null,
      overrideOnlyTruck: false,
      deliveryStep: 0,
      returnToHQ: {},
      doneDeliveryStep: 0,
      documentsTab: 0,
      uploadDocumentsModal: false,
      fields: {
        loadPhotosUPLOAD: '[]',
        rateConUPLOAD: '[]',
        doUPLOAD: '[]',
        bolUPLOAD: '[]',
        podUPLOAD: '[]'
      },
      rules: {
        datetimeUSA: value => new Date(value).getTime() ? true : false || this.$root.t('Loads.DateFormatError')
      },
      /* doneProgress: 0,
      currentProgress: 0,
      currentProgressStep: 0,
      targetProgress: 0, */
      currentSelectedPlace: null,
      currentSelectedPlaceName: '',
      insertNewLocationFieldVisible: []
    };
  },
  watch: {},
  computed: {
    locations () {
      return this.$store.getters['loads/getLocations'];
    },
    pickupSteps () {
      return [
        this.$root.t('Loads.onTheWayForPickupStatus'),
        this.$root.t('Loads.onSiteForPickupStatus'),
        this.$root.t('Loads.loadedStatus')
      ];
    },
    deliverySteps () {
      return [
        this.$root.t('Loads.onTheWayForDeliveryStatus'),
        this.$root.t('Loads.onSiteForDeliveryStatus'),
        this.$root.t('Loads.unloadedStatus')
      ];
    }
  },
  mounted () {
    /* for (let i = this.locations.length - 1; i >= 1 ; i--) {
        if (this.locations[i].driverOnTheWayCheck && this.locations[i].driverOnTheWayCheck > 0 && !this.locations[i].driverOnSiteCheck) {
            this.updateCurrentDistanceAndDuration(i);
            break;
        }
    } */

    this.$nextTick(() => {
      /* if (this.insertNewLocationFieldVisible.length < this.locations.length) {
          this.insertNewLocationFieldVisible = [];
          for (let i = 0; i < this.locations.length; i++) {
              this.insertNewLocationFieldVisible.push(false);
          }
      } */

      /* if (this.loadId) {
        this.fetchData();
        this.polling = setInterval(() => {
          // Do not call interval methods if page not visible
          if (!this.$store.getters.isPageVisible) return;
          this.fetchData();
        }, this.$store.getters.pullInterval);
      } else {
        this.loading = false;
      } */
    });
  },
  beforeDestroy () {
    // clearInterval(this.pooling);
  },
  methods: {
    getChildLocations (id) {
      let i = 0;
      let ret = [];
      const childLocations = this.$store.getters['loads/getChildLocations'];
      for (i = 0; i < childLocations.length; i++) {
        if (parseInt(childLocations[i].parent) === parseInt(id)) {
          ret.push(childLocations[i]);
        }
      }
      return ret;
    },
    insertNewLocation (index) {
      if (this.currentSelectedPlace !== null) {
        this.currentSelectedPlaceName = this.$root.getAddressStrFromComponentsObject(this.currentSelectedPlace.address_components);
        this.$emit('insertLocation', {
          name: this.$root.getAddressStrFromComponentsObject(this.currentSelectedPlace.address_components),
          location: this.currentSelectedPlace.geometry.location
        }, index);
      }
      this.currentSelectedPlace = null;
      this.currentSelectedPlaceName = '';
      this.toggleNewLocationField(index);
    },
    insertHQLocation (index) {
      this.$root.setDefaultHQ = (place) => {
        let data = {
          name: place.name,
          location: {
            lat: () => {
              return place.location.lat;
            },
            lng: () => {
              return place.location.lng;
            }
          }
        }
        this.$emit('insertLocation', data, index);
        this.toggleNewLocationField(index);
      }
      this.$root.getDefaultHQ();
    },
    insertTruckLocation (truckId, index) {
      this.$root.getTruck(truckId).then((response) => {
        if (response.status === 'ok') {
          const result = response.result;
          let data = {
            name: result.location,
            location: {
              lat: () => {
                return result.lat;
              },
              lng: () => {
                return result.lng;
              }
            }
          }
          this.$emit('insertLocation', data, index);
          this.toggleNewLocationField(index);
        }
      });
    },
    isFutureStatusesActivated (index, locations) {
      if (index >= locations.length) return false;

      for (let i = index; i < locations.length; i++) {
        if (locations[i].dispatcherOnTheWayCheck > 0 ||
                locations[i].driverOnTheWayCheck > 0 ||
                locations[i].driverDoneCheck > 0 ||
                locations[i].driverOnSiteCheck > 0) {
          return true;
        }
      }
    },
    toggleNewLocationField (index) {
      this.insertNewLocationFieldVisible[index] = !this.insertNewLocationFieldVisible[index];
      this.$forceUpdate();
    },
    deleteLocation (index) {
      this.$emit('delete-location', index);
      this.$forceUpdate();
    },
    moveLocationUp (index) {
      this.$emit('move-location-up', index);
      this.$forceUpdate();
    },
    moveLocationDown (index) {
      this.$emit('move-location-down', index);
      this.$forceUpdate();
    },
    initInsertedPlaceAutocomplete (index) {
      let autocomplete = new google.maps.places.Autocomplete(document.getElementById('newLocation' + index));
      autocomplete.setFields(['address_components', 'formatted_address', 'geometry', 'icon', 'name']);
      autocomplete.addListener('place_changed', () => {
        this.currentSelectedPlace = autocomplete.getPlace();
        if (this.currentSelectedPlace && this.currentSelectedPlace.geometry && this.currentSelectedPlace.address_components) {
          this.currentSelectedPlaceName = this.$root.getAddressStrFromComponentsObject(this.currentSelectedPlace.address_components);
        } else {
          this.$root.toast(this.$i18n.t('GetLocationError'), { color: 'error' });
        }
      });
    },

    updateCurrentDistanceAndDuration (index) {
      // console.log('updateCurrentDistanceAndDuration', index)
      // Do not update distance if page is not visible
      if (!this.$store.getters.isPageVisible) return;
      // if (parseFloat(this.currentLat) === 0.0 && parseFloat(this.currentLng) === 0.0) return;
      this.$store.commit('loads/setDistanceCalculationLoading');
      this.$forceUpdate();
      /* console.log('calcDistance', { lat: parseFloat(this.currentLat), lng: parseFloat(this.currentLng) },
        { lat: parseFloat(this.locations[index].lat), lng: parseFloat(this.locations[index].lng) }); */
      this.$root.calcDistance([
        { lat: parseFloat(this.currentLat), lng: parseFloat(this.currentLng) },
        { lat: parseFloat(this.locations[index].lat), lng: parseFloat(this.locations[index].lng) }
      ]).then((response) => {
        if (response.routes && response.routes.length > 0 && response.routes[0].legs) {
          const legs = response.routes[0].legs;
          if (legs && legs.length > 0 && legs[0].duration && legs[0].distance) {
            const _duration = this.$root.isUseGoogleMaps ? (legs[0].duration_in_traffic ? legs[0].duration_in_traffic.value : legs[0].duration.value) : legs[0].duration;
            const _distance = this.$root.isUseGoogleMaps ? legs[0].distance.value : legs[0].distance;
            this.$store.dispatch('loads/setLocationField', { index: index, field: 'currentDistance', value: parseInt(_distance) });
            this.$store.dispatch('loads/setLocationField', { index: index, field: 'currentDuration', value: parseInt(_duration) });
            this.$emit('calculateProgress');
            this.$store.commit('loads/clearDistanceCalculationLoading');
            this.$forceUpdate();
          } else {
            this.$root.toast(this.$i18n.t('ErrorCantCalcTheDistance'), { color: 'error' });
          }
        } else {
          this.$root.toast(this.$i18n.t('ErrorCantCalcTheDistance'), { color: 'error' });
        }
      }).catch((e) => {
        this.$store.commit('loads/clearDistanceCalculationLoading');
        this.$forceUpdate();
      });
    },

    calculateProgress () {
      this.$emit('calculateProgress');
    },

    saveStatus () {
      if (this.loadId) {
        let postData = {
          locationsJSON: JSON.stringify(this.locations)
        };
        /* // Set load stated if needed
        if (this.started === 0 &&
          this.locations.length > 1 &&
          this.locations[1]['driverOnTheWayCheck'] > 0) {
          postData['started'] = this.locations[1]['driverOnTheWayCheck'];
        }
        // Set load ended if needed
        if (!this.ended === 0 &&
          this.locations.length > 0 &&
          this.locations[this.locations.length - 1]['driverDoneCheck'] > 0) {
          postData['ended'] = this.locations[1]['driverDoneCheck'];
        } */

        this.$root.saveLoad(postData, this.loadId).then((response) => {
          // this.$emit('changed', this.locations);
          // this.calculateProgress();
          this.$forceUpdate();
        });
      }
    },

    fetchData () {
      /* if (this.locationsData) {
        this.locations = this.locationsData;
        if (this.insertNewLocationFieldVisible.length < this.locations.length) {
            this.insertNewLocationFieldVisible = [];
            for (let i = 0; i < this.locations.length; i++) {
                this.insertNewLocationFieldVisible.push(false);
            }
        }
      }
      this.$root.getLoad(this.loadId).then((response) => {
        if (response.status === 'ok') {
          this.fields.rateConUPLOAD = response.result['rateConUPLOAD'];
          this.fields.doUPLOAD = response.result['doUPLOAD'];
          this.fields.bolUPLOAD = response.result['bolUPLOAD'];
          this.fields.podUPLOAD = response.result['podUPLOAD'];
          this.fields.loadPhotosUPLOAD = response.result['loadPhotosUPLOAD'];

          /!*if (response.result['locationsJSON']) {
            this.locations = JSON.parse(response.result['locationsJSON'].replace(/\n/g, '\\n').replace(/\r/g, '\\r').replace(/\t/g, '\\t'));
            let index = -1;
            for (let i = 0; i < this.locations.length - 1; i++) {
              if (this.locations[i].driverOnTheWayCheck && this.locations[i + 1].driverOnSiteCheck === 0) {
                index = i;
              }
            }
            /!* if (index >= 0) {
              this.updateCurrentDistanceAndDuration(index);
            } *!/
          }*!/
          //this.calculateProgress();
        }
        this.loading = false;
      }); */
    }
  }
};
</script>
