<template>
  <v-container>
    <v-row align="center" justify="center" width="100%" v-if="data.hasOwnProperty('shifts')">
      <v-card light flat style="width: 21cm">
        <v-card-text>
          <v-row>
            <v-col xs="12" class="mt-1 pa-0">
              <v-row>
                <v-col xs="6" class="text-left pr-0 pl-4">
                  <img responsive width="80%" :src="logoPath"/>
                  <br/>
                  <div class="text-left" style="width: 80%;">
                    <span v-html="contactInfo ? $root.newLineToBR(contactInfo) : null"></span>
                  </div>
                </v-col>
                <v-col xs="6" class="pt-5">
                  <div style="border-left: 4px #999 solid" class="pl-2">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                        <tr>
                          <th class="text-left pa-0" colspan="2">
                            <h3>Totals</h3>
                          </th>
                        </tr>
                        <tr>
                          <td class="text-left">Shifts Number</td>
                          <td class="text-left">{{parseInt(data.shiftsCount).toLocaleString()}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Miles Driven</td>
                          <td class="text-left">{{parseInt(data.totalMiles).toLocaleString()}} {{$t('milesShort')}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Rate By Miles</td>
                          <td class="text-left">${{parseFloat(data.totalRate).toLocaleString()}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Detention</td>
                          <td class="text-left">${{parseFloat(data.totalDetention).toLocaleString()}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Compensations</td>
                          <td class="text-left">${{parseFloat(data.toCompensateTotal).toLocaleString()}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Bonuses</td>
                          <td class="text-left">${{parseFloat(data.bonusesTotal).toLocaleString()}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Deductions</td>
                          <td class="text-left">${{parseFloat(data.deductionTotal).toLocaleString()}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Total Salary</td>
                          <td class="text-left">${{parseFloat(data.totalRate + data.totalDetention + data.toCompensateTotal - data.deductionTotal + data.bonusesTotal).toLocaleString()}}</td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-col>
              </v-row>
              <h3 style="line-height: 32px;" class="text-center" v-html="title"></h3>
              <v-simple-table dense
                              style="border: 1px solid #000; background: transparent;"
                              class="text-left">
                <thead style="display: table-header-group;">
                  <tr style="background:#0090D3;color:#fff;page-break-inside: avoid;">
                    <th class="report_font_size text-center white--text">Load #</th>
                    <th class="report_font_size_normal text-center white--text">Started<br/>Ended</th>
                    <th class="report_font_size_normal text-center white--text">Origin/Finish</th>
                    <th class="report_font_size_normal text-center white--text">Miles Driven</th>
                    <th class="report_font_size_normal text-center white--text">RPM</th>
                    <th class="report_font_size_normal text-center white--text">Detent</th>
                    <th class="report_font_size_normal text-center white--text">{{ $t('Shifts.CompensationsShort') }}</th>
                    <th class="report_font_size_normal text-center white--text">Deduc.</th>
                    <th class="report_font_size_normal text-center white--text">Bonus</th>
                    <th class="report_font_size_normal text-center white--text">Rate By Miles</th>
                  </tr>
                </thead>
                <tbody>
                <template v-for="item in data.shifts">
                  <tr style="page-break-inside: avoid;" :key="'item'+item.id">
                    <td class="report_font_size_normal text-center">{{item.id}}</td>
                    <td class="report_font_size_normal text-center" style="width: 70px;">
                      <div class="text-no-wrap">{{item.startedPST ? item.startedPST.substring(0,10) : ''}}</div>
                      <div class="text-no-wrap">{{item.endedPST ? item.endedPST.substring(0,10) : ''}}</div>
                    </td>
                    <td class="report_font_size_normal text-left" style="width: 250px;">
                      <div class="text-no-wrap" style="max-width: 250px; text-overflow: ellipsis; overflow: hidden;">{{item.startLocation}}</div>
                      <div class="text-no-wrap" style="max-width: 250px; text-overflow: ellipsis; overflow: hidden;">{{item.endLocation}}</div>
                    </td>
                    <td class="report_font_size_normal text-center">
                      {{(item.isShift ? item.shiftDistance : item.loadsOdometerDistance).toLocaleString()}}{{$t('milesShort')}}
                    </td>
                    <td class="report_font_size_normal text-center">
                      ${{Object.keys(item.driverRates).join(',$')}}
                    </td>
                    <td class="report_font_size_normal text-center">
                      <template v-if="item.driverDetention > 0">
                        {{$root.showDuration(item.driverDetentionMinutes * 60, true)}}<br/>
                      </template>
                      ${{parseFloat(item.driverDetention).round(2).toLocaleString()}}
                    </td>
                    <td class="report_font_size_normal text-center">
                      ${{parseFloat(item.shiftToCompensate).round(2).toLocaleString()}}
                    </td>
                    <td class="report_font_size_normal text-center">
                      ${{parseFloat(item.shiftDeductions).round(2).toLocaleString()}}
                    </td>
                    <td class="report_font_size_normal text-center">
                      ${{parseFloat(item.shiftBonuses).round(2).toLocaleString()}}
                    </td>
                    <td class="report_font_size_normal text-center">
                      ${{item.isShift ? parseFloat(item.shiftRate).round(2).toLocaleString() : parseFloat(item.loadsOdometerRate).round(2).toLocaleString()}}
                    </td>
                  </tr>

                  <template v-if="!item.loads.hasOwnProperty(item.id) && Object.keys(item.loads).length > 1">
                    <tr class="report_font_size_normal text-left" style="page-break-inside:avoid;">
                      <td colspan="9">Loads</td>
                    </tr>
                    <template v-for="load in item.loads">
                      <tr style="background-color: #eee; page-break-inside:avoid;">
                        <td><v-icon>mdi-dolly</v-icon></td>
                        <td class="report_font_size_normal text-center" style="width: 70px;">
                          <div class="text-no-wrap">{{load.startedPST ? load.startedPST.substring(0,10) : ''}}</div>
                          <div class="text-no-wrap">{{load.endedPST ? load.endedPST.substring(0,10) : ''}}</div>
                        </td>
                        <td class="report_font_size_normal text-left" style="width: 250px;">
                          <div class="text-no-wrap" style="max-width: 250px; text-overflow: ellipsis; overflow: hidden;">{{load.pickupLocation}}</div>
                          <div class="text-no-wrap" style="max-width: 250px; text-overflow: ellipsis; overflow: hidden;">{{load.deliveryLocation}}</div>
                        </td>
                        <td class="report_font_size_normal text-center">
                          {{load.milesByOdometer.toLocaleString()}}{{$t('milesShort')}}
                        </td>
                        <td class="report_font_size_normal text-center">
                          ${{parseFloat(load.driverRatePerMile).round(2)}}
                        </td>
                        <td class="report_font_size_normal text-center">
                          {{$root.showDuration(load.driverDetentionMinutes * 60, true)}}<br/>
                          ${{parseFloat(load.driverDetention).round(2).toLocaleString()}}
                        </td>
                        <td class="report_font_size_normal text-center">
                          ${{parseFloat(load.shiftToCompensate).round(2).toLocaleString()}}
                        </td>
                        <td class="report_font_size_normal text-center">
                          ${{parseFloat(load.shiftDeductions).round(2).toLocaleString()}}
                        </td>
                        <td class="report_font_size_normal text-center">
                          ${{parseFloat(load.shiftBonuses).round(2).toLocaleString()}}
                        </td>
                        <td class="report_font_size_normal text-center">
                          ${{parseFloat(load.loadsOdometerRate).round(2).toLocaleString()}}
                        </td>
                      </tr>
                    </template>
                  </template>

                  <tr v-if="item.toCompensateTotal > 0" style="page-break-inside:avoid;">
                    <td colspan="9" class="report_font_size_normal text-right pr-2">
                      To compensate &mdash; <template v-for="expense in item.expensesArray.filter(el => parseInt(el.compensate) === 1 && parseInt(el.driver) === parseInt(driverId))">{{expense.paymentType}} - {{expense.name}}: ${{parseFloat(expense.total).round(2)}}&nbsp;</template>
                    </td>
                    <td class="report_font_size_normal text-center">${{parseFloat(item.toCompensateTotal).round(2).toLocaleString()}}</td>
                  </tr>
                  <tr v-if="item.bonusesTotal > 0" style="page-break-inside:avoid;">
                    <td colspan="9" class="report_font_size_normal text-right pr-2">
                      Bonuses &mdash; <template v-for="expense in item.expensesArray.filter(el => parseInt(el.compensate) === 2 && parseInt(el.driver) === parseInt(driverId))">{{expense.name}}: ${{parseFloat(expense.total).round(2)}}&nbsp;</template>
                    </td>
                    <td class="report_font_size_normal text-center">${{parseFloat(item.bonusesTotal).round(2).toLocaleString()}}</td>
                  </tr>
                  <tr v-if="item.deductionTotal > 0" style="page-break-inside:avoid;">
                    <td colspan="9" class="report_font_size_normal text-right pr-2">
                      Deductions &mdash; <template v-for="expense in item.expensesArray.filter(el => parseInt(el.compensate) === 3 && parseInt(el.driver) === parseInt(driverId))">{{expense.name}}: ${{parseFloat(expense.total).round(2)}}&nbsp;</template>
                    </td>
                    <td class="report_font_size_normal text-center">${{parseFloat(item.deductionTotal).round(2).toLocaleString()}}</td>
                  </tr>
                  <tr style="background-color: #ddd; page-break-inside:avoid;">
                    <td colspan="9" class="report_font_size_normal text-right pr-2 font-weight-bold" style="border-bottom: 2px solid #000;" >
                      Rate By Miles: ${{item.isShift ? parseFloat(item.shiftRate).round(2) : parseFloat(item.loadsOdometerRate).round(2)}} <template v-if="item.driverDetention > 0"> + Detention: ${{parseFloat(item.driverDetention).round(2)}}</template> <template v-if="item.toCompensateTotal > 0"> + Compensations: ${{parseFloat(item.toCompensateTotal).round(2)}}</template> <template v-if="item.bonusesTotal > 0"> + Bonuses: ${{parseFloat(item.bonusesTotal).round(2)}}</template> <template v-if="item.deductionTotal > 0"> - Deductions: ${{parseFloat(item.deductionTotal).round(2)}}</template> = Total
                    </td>
                    <td class="report_font_size_normal text-center font-weight-bold" style="border-bottom: 2px solid #000;">
                      ${{(item.isShift ? parseFloat(item.shiftRate).round(2) : parseFloat(parseFloat(item.loadsOdometerRate).round(2)) + parseFloat(item.driverDetention).round(2) + parseFloat(item.toCompensateTotal).round(2) - parseFloat(item.deductionTotal).round(2) + parseFloat(item.bonusesTotal).round(2)).round(2)}}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="10" style="height: 2px; page-break-inside: auto;"></td>
                  </tr>
                </template>
                </tbody>
              </v-simple-table>
              <template v-if="reportData && reportData.hasOwnProperty('footerText')">
                {{reportData.footerText}}
              </template>
              <template v-else>
                Report Created at {{currentDateTime}}&nbsp;by&nbsp;{{$store.getters.currentUserName}}
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'DriverShiftsReportTemplate',
  props: ['reportData', 'print', 'from', 'to', 'driverName', 'driverId', 'entries', 'totals', 'currentDateTime', 'companyId'],
  components: {},
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      contactInfo: '',
      data: {},
      title: '',
      logoPath: ''
    };
  },
  mounted () {
    let i = 0;
    if (this.entries) {
      this.$set(this, 'data', this.totals);
      this.$set(this.data, 'shifts', this.entries);
    } else {
      this.$set(this, 'data', this.reportData);
    }
    this.$root.getInvoiceLogoPath(this.companyId).then(path => {
      this.logoPath = path;
    });
    this.data.toCompensateTotal = 0;
    this.data.deductionTotal = 0;
    this.data.bonusesTotal = 0;
    if (this.data.hasOwnProperty('shifts')) {
      for (i = 0; i < this.data.shifts.length; i++) {
        this.data.shifts[i].expensesArray = this.data.shifts[i].expensesJSON ? JSON.parse(this.data.shifts[i].expensesJSON) : []
        this.data.shifts[i].toCompensateTotal = 0;
        this.data.shifts[i].deductionTotal = 0;
        this.data.shifts[i].bonusesTotal = 0;

        if (this.data.shifts[i].expensesArray.length > 0) {
          this.data.shifts[i].expensesArray.forEach((el) => {
            if (el.hasOwnProperty('compensate') && el.hasOwnProperty('driver') && parseInt(el.driver) === parseInt(this.driverId)) {
              if (parseInt(el.compensate) === 1) {
                this.data.shifts[i].toCompensateTotal += parseFloat(el.total);
              }
              if (parseInt(el.compensate) === 2) {
                this.data.shifts[i].bonusesTotal += parseFloat(el.total);
              }
              if (parseInt(el.compensate) === 3) {
                this.data.shifts[i].deductionTotal += parseFloat(el.total);
              }
            }
          });
        }

        this.data.toCompensateTotal += this.data.shifts[i].toCompensateTotal;
        this.data.deductionTotal += this.data.shifts[i].deductionTotal;
        this.data.bonusesTotal += this.data.shifts[i].bonusesTotal;
      }
    }
    this.$root.getGlobalSettingsField('invoiceContactInfo', this.companyId).then((response) => {
      if (response.status === 'ok') {
        this.contactInfo = response.result;
      }
    });
    this.title = this.reportTitle();
    document.title = this.title;
  },
  methods: {
    reportTitle () {
      let result = '';
      if (!this.data.hasOwnProperty('shifts') || this.data.shifts.length === 0) return '';

      result = (this.driverName ? this.driverName : 'All Drivers') + ' ' + this.$root.t('Report') + ' ' + (parseInt(this.$route.params.report_number) > 0 ? '#' + this.$route.params.report_number : '') + ': ';
      this.$root.currentReportFileName = (this.driverName ? this.driverName : 'All Drivers') + ' ' + this.$root.t('Report') + (parseInt(this.$route.params.report_number) > 0 ? '_' + this.$route.params.report_number : '') + '_';

      if (this.data.shifts &&
        this.data.shifts.length > 1 &&
        this.data.shifts[0].hasOwnProperty('startedPST') &&
        this.data.shifts[this.data.shifts.length - 1].hasOwnProperty('endedPST')) {
        result += this.data.shifts[0].startedPST.substring(0, 10) + ' - ' + this.data.shifts[this.data.shifts.length - 1].endedPST.substring(0, 10);
        this.$root.currentReportFileName += this.$root.parseDate(this.data.shifts[0].startedPST.substring(0, 10)) + '-' + this.$root.parseDate(this.data.shifts[this.data.shifts.length - 1].endedPST.substring(0, 10));
      }
      if (this.data.shifts && this.data.shifts.length === 1 && this.data.shifts[0].hasOwnProperty('startedPST')) {
        result += this.data.shifts[0].startedPST.substring(0, 10);
        this.$root.currentReportFileName += this.$root.parseDate(this.data.shifts[0].startedPST.substring(0, 10));
      }
      return result;
    }
  }
};
</script>
