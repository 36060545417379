<template>
  <v-container>
    <v-row align="center" justify="center" width="100%">
      <v-card light :flat="print" style="width: 28cm">
        <v-card-text>
          <v-row>
            <v-col xs="12" class="mt-1">
              <v-row>
                <v-col xs="6" class="text-left pr-0 pl-4">
                  <img responsive width="80%" :src="logoPath"/>
                  <br/>
                  <div class="text-left" style="width: 80%;">
                    <span v-html="contactInfo ? $root.newLineToBR(contactInfo) : null"></span>
                  </div>
                </v-col>
                <v-col xs="6" class="pt-5">
                  <div style="border-left: 4px #999 solid" class="pl-2">
                    <v-simple-table dense class="mb-5">
                      <template v-slot:default>
                        <tbody>
                        <tr>
                          <th class="text-left" colspan="2">
                            <h1>Totals</h1>
                          </th>
                        </tr>
                        <tr>
                          <td class="text-left">Loads Count</td>
                          <td class="text-left">{{parseInt(data.loadsCount).toLocaleString()}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Planned Miles</td>
                          <td class="text-left">{{parseInt(data.totalTruckMilesByMaps).toLocaleString()}} {{$t('milesShort')}} (${{parseFloat(data.totalLoadsRate/data.totalTruckMilesByMaps).round(2)}} per mi.)</td>
                        </tr>
                        <tr>
                          <td class="text-left">Actual Miles</td>
                          <td class="text-left">{{parseInt(data.totalTruckMilesByOdometer).toLocaleString()}} {{$t('milesShort')}} (${{parseFloat(data.totalLoadsRate/data.totalTruckMilesByOdometer).round(2)}} per mi.)</td>
                        </tr>
                        <tr>
                          <td class="text-left">Loads Rate</td>
                          <td class="text-left">${{parseFloat(data.totalLoadsRate).round(2).toLocaleString()}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Additional Rate</td>
                          <td class="text-left">${{parseFloat(data.totalAdditionalPayments).round(2).toLocaleString()}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Total Gross Income</td>
                          <td class="text-left">${{parseFloat(data.totalGrossIncome).round(2).toLocaleString()}}</td>
                        </tr>

                        <template dense v-if="data.expensesByName && Object.keys(data.expensesByName).length > 0">
                          <tr v-for="key in Object.keys(data.expensesByName)" :key="key">
                            <td class="text-left">{{key}}</td>
                            <td class="text-left">
                              ${{parseFloat(data.expensesByName[key]).round(2).toLocaleString()}}
                            </td>
                          </tr>
                        </template>
                        <tr>
                          <td class="text-left">Dispatchers Salary</td>
                          <td class="text-left">${{parseFloat(data.dispatchersSalary).round(2).toLocaleString()}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Drivers Salary</td>
                          <td class="text-left">${{parseFloat(data.driversSalary).round(2).toLocaleString()}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Total Expenses</td>
                          <td class="text-left">${{parseFloat(data.totalExpenses).round(2).toLocaleString()}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Total Net Income</td>
                          <td class="text-left">${{parseFloat(data.totalNetIncome).round(2).toLocaleString()}}</td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-col>
              </v-row>
              <h3 style="line-height: 32px;" class="text-center" v-html="title"></h3>
              <v-simple-table dense
                              style="border: 1px solid #000; background: transparent;"
                              class="text-left">
                <tbody>
                <tr style="background:#0090D3;color:#fff;page-break-inside: avoid;">
                  <th class="report_font_size text-center">#</th>
                  <th class="report_font_size text-center">Started<br/>Ended</th>
                  <th class="report_font_size text-center">Locations</th>
                  <th class="report_font_size text-center">Dispatcher</th>
                  <th class="report_font_size text-center">Drivers</th>
                  <th class="report_font_size text-center">Load Number<br/>Load UID</th>
                  <th class="report_font_size text-center">Percent</th>
                  <th class="report_font_size text-center">Miles</th>
                  <th class="report_font_size text-center">Rate</th>
                  <th class="report_font_size text-center">Per Mile By</th>
                  <th class="report_font_size text-center">Gross</th>
                  <th class="report_font_size text-center">Exp.</th>
                  <th class="report_font_size text-center">Net</th>
                </tr>
                <tr v-for="entry in data.loads" style="page-break-inside: avoid;">
                  <td class="text-center">{{entry.id}}</td>
                  <td class="report_font_size">
                    {{entry.startedPST}}<br/>
                    {{entry.endedPST}}
                  </td>
                  <td class="report_font_size" style="max-width:5cm; overflow: hidden;">
                    <!--<template v-if="truckName">
                      <template v-for="locationEntry in entry.locations">
                        <span class="text-no-wrap">
                          <v-icon small class="mr-1" :color="$root.getLocationColor(locationEntry)">{{$root.getLocationIcon(locationEntry)}}</v-icon>
                          {{locationEntry.name.split(',').length > 2 ? locationEntry.name.substring(locationEntry.name.indexOf(',') + 1) : locationEntry.name}}
                        </span>
                        <br/>
                      </template>
                    </template>
                    <template v-else>-->
                    <div class="text-no-wrap" style="max-width: 150px; text-overflow: ellipsis; overflow: hidden;">
                      {{entry.name.split('--')[0]}}
                    </div>
                    <div class="text-no-wrap" style="max-width: 150px; text-overflow: ellipsis; overflow: hidden;">
                      {{entry.name.split('--')[1]}}
                    </div>
                    <!--</template>-->
                  </td>
                  <td class="report_font_size" v-html="entry.dispatcherName.toString().replace(' ', '<br/>')">
                  </td>
                  <td class="report_font_size">
                    <template v-for="(truck, index) in entry.trucks">
                      <div class="text-no-wrap">
                        <template v-for="(driver, index) in truck.drivers">
                          {{driver.driverName}}<br v-if="truck.drivers.length > 1"/>
                        </template>
                      </div>
                    </template>
                  </td>
                  <td class="report_font_size  text-center">
                    <span class="text-no-wrap">{{entry.loadNumber}}</span>
                    <br/>
                    <span class="text-no-wrap">{{entry.uid}}</span>
                  </td>
                  <td class="report_font_size text-center">
                    {{parseFloat(entry.truckDistancePercentByOdometer*100.0).toFixed(0)}}%
                  </td>
                  <td class="report_font_size text-left text-no-wrap">
                    <v-icon x-small>mdi-map</v-icon>{{entry.milesByMaps.toLocaleString()}}{{$t('milesShort')}}
                    <br/>
                    <v-icon x-small>mdi-counter</v-icon>{{entry.milesByOdometer.toLocaleString()}} {{$t('milesShort')}}
                  </td>
                  <td class="report_font_size text-center">
                    ${{parseFloat(entry.truckRateByOdometer).round(0).toLocaleString()}}
                  </td>
                  <td class="report_font_size text-left text-no-wrap">
                    <v-icon x-small>mdi-map</v-icon>${{parseFloat(entry.truckRatePerMileByMaps).toLocaleString()}}
                    <br/>
                    <v-icon x-small>mdi-counter</v-icon>${{parseFloat(entry.truckRatePerMileByOdometer).toLocaleString()}}
                  </td>
                  <td class="report_font_size text-center">
                    ${{parseFloat(entry.gross).round(0).toLocaleString()}}
                  </td>
                  <td class="report_font_size text-center">
                    ${{parseFloat(entry.expenses).round(0).toLocaleString()}}
                  </td>
                  <td class="report_font_size text-left text-no-wrap">
                    <div style="white-space: nowrap;">
                      <v-icon x-small>mdi-map</v-icon>${{parseFloat(entry.truckNetByMiles).round(0).toLocaleString()}}<br/>
                      <v-icon x-small>mdi-counter</v-icon>${{parseFloat(entry.truckNetByOdometer).round(0).toLocaleString()}}
                    </div>
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
              <template v-if="reportData && reportData.hasOwnProperty('footerText')">
                {{reportData.footerText}}
              </template>
              <template v-else>
                Report Created at {{currentDateTime}}&nbsp;by&nbsp;{{$store.getters.currentUserName}}
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<style scoped>
    .v-data-table__wrapper {
      overflow-x: hidden;
      scrollbar-width: 0;
    }
</style>

<script>
export default {
  name: 'TruckReportTemplate',
  props: ['reportData', 'entries', 'print', 'from', 'to', 'truckName', 'totals', 'currentDateTime', 'companyId'],
  components: {},
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      contactInfo: '',
      title: '',
      logoPath: '',
      data: {}
    };
  },
  mounted () {
    if (this.entries) {
      this.$set(this, 'data', this.totals);
      this.$set(this.data, 'loads', this.entries);
    } else {
      this.$set(this, 'data', this.reportData);
    }

    this.$root.getInvoiceLogoPath(this.companyId).then(path => {
      this.logoPath = path;
    });

    this.$root.getGlobalSettingsField('invoiceContactInfo', this.companyId).then((response) => {
      if (response.status === 'ok') {
        this.contactInfo = response.result;
      }
    });
    this.title = this.reportTitle();
    document.title = this.title;
  },
  methods: {
    reportTitle () {
      const _truckName = this.truckName ? this.truckName : 'All Trucks';
      let result = _truckName + ' ' + this.$root.t('Report') + ' ' + (this.$route.params.report_number ? '#' + this.$route.params.report_number : '') + ': ';
      this.$root.currentReportFileName = _truckName + ' ' + this.$root.t('Report') + (parseInt(this.$route.params.report_number) > 0 ? '_' + this.$route.params.report_number : '') + '_';

      if (this.data.loads &&
        this.data.loads.length > 1 &&
        this.data.loads[0].hasOwnProperty('startedPST') &&
        this.data.loads[this.data.loads.length - 1].hasOwnProperty('endedPST')) {
        result += this.data.loads[0].startedPST.substring(0, 10) + ' - ' + this.data.loads[this.data.loads.length - 1].endedPST.substring(0, 10);
        this.$root.currentReportFileName += this.$root.parseDate(this.data.loads[0].startedPST.substring(0, 10)) + '-' + this.$root.parseDate(this.data.loads[this.data.loads.length - 1].endedPST.substring(0, 10));
      }
      if (this.data.loads && this.data.loads.length === 1 && this.data.loads[0].hasOwnProperty('startedPST')) {
        result += this.data.loads[0].startedPST.substring(0, 10);
        this.$root.currentReportFileName += this.$root.parseDate(this.data.loads[0].startedPST.substring(0, 10));
      }

      return result;
    }
  }
};
























</script>
