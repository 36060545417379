export default {
  namespaced: true,
  state: {
    savedReportsModal: false,
    savedReportsFieldName: '',
    savedReportsType: 0,
    reportsSelectedType: '',
    reportsSelectedItem: '',
    totalsCounts: {},
    totalsCountsNames: {},
    totalsCountsTypes: {},
    reportsGroupsListKey: 'reportsGroupsListKey'
  },
  getters: {
    savedReportsModal (state) {
      return state.savedReportsModal;
    },
    savedReportsFieldName (state) {
      return state.savedReportsFieldName;
    },
    savedReportsType (state) {
      return state.savedReportsType;
    },
    totalsCounts (state) {
      return state.totalsCounts;
    },
    totalsCountsNames (state) {
      return state.totalsCountsNames;
    },
    totalsCountsTypes (state) {
      return state.totalsCountsTypes;
    },
    reportsGroupsListKey (state) {
      return state.reportsGroupsListKey;
    },
    reportsSelectedType (state) {
      return state.reportsSelectedType;
    },
    reportsSelectedItem (state) {
      return state.reportsSelectedItem;
    }
  },
  mutations: {
    setSelectedReportTypeAndItem(state, value) {
      state.reportsSelectedType = value.type;
      state.reportsSelectedItem = value.item;
    },
    updateReportsGroupsListKey (state, value) {
      state.reportsGroupsListKey = 'reportsGroupsListKey' + new Date().getTime();
    },
    showReportsListModal (state, value) {
      state.savedReportsFieldName = value.fieldName;
      state.savedReportsType = value.type;
      state.savedReportsModal = true;
      state.reportsGroupsListKey = 'reportsGroupsListKey' + new Date().getTime();
    },
    closeReportsListModal (state, value) {
      state.savedReportsFieldName = '';
      state.savedReportsType = 0;
      state.savedReportsModal = false;
    }
  },
  actions: {
    set ({ state }, data) {
      state[data.field] = data.value;
    },
    totalsCounts ({ state }, data) {
      state.totalsCounts = data;
    },
    totalsCountsNames ({ state }, data) {
      state.totalsCountsNames = data;
    },
    totalsCountsTypes ({ state }, data) {
      state.totalsCountsTypes = data;
    }
  }
};
