export default {
  data: function () {
    return {
      selectBrokerDialog: false,
      selectBrokerDialogData: {
        bidId: 0,
        email: '',
        newBrokerName: '',
        selectType: 0,
        selectedBroker: 0,
        addEmailToSelectedBroker: false
      }
    };
  },
  methods: {
    getLoadByNumber (loadNumber) {
      return this.post('/api/loads/getLoadByNumber/', {
        loadNumber: loadNumber
      });
    },
    getLoadList (deletedFilter = 0, page = 0, itemsPerPage = 10, dispatcher = 0, broker = 0, driver = 0, truck = 0, isFinished = -1, orderByStarted = false, status = 'all', isShowOnlyToday = 0, search = '') {
      const deletedParam = deletedFilter === '' ? 0 : deletedFilter;
      const dispatcherParam = dispatcher === '' ? 0 : dispatcher;
      const brokerParam = broker === '' ? 0 : broker;
      const driverParam = driver === '' ? 0 : driver;
      const truckParam = truck === '' ? 0 : truck;
      return this.post('/api/loads/list', {
        deletedFilter: deletedParam,
        page: page,
        itemsPerPage: itemsPerPage,
        dispatcherFilter: dispatcherParam,
        brokerFilter: brokerParam,
        driverFilter: driverParam,
        truckFilter: truckParam,
        isFinished: isFinished,
        orderByStarted: orderByStarted ? 1 : 0,
        statusFilter: status,
        isShowOnlyToday: isShowOnlyToday,
        search: search,
        creatorFilter: null
      });
    },
    getCurrentLoadsLocationsList () {
      return this.get('/api/loads/getCurrentLoadsLocationsList');
    },
    getTrucksAvailability (startDate = 0, endDate = 0, offset = 0) {
      return this.get('/api/loads/getTrucksAvailability/' + startDate + '/' + endDate + '/' + offset);
    },
    getLoadListByStartDateAndDriver (date, driverId, isFullMonth = false) {
      return this.get('/api/loads/listByStartDateAndDriver/' + date + '/' + driverId + '/' + isFullMonth);
    },
    getLoadListForCalendar (date) {
      return this.get('/api/loads/listForCalendar/' + date);
    },
    getLoadsFinishedButNotEnded (dispatcherId) {
      return this.get('/api/loads/notEndedByDispatcher/' + dispatcherId);
    },
    getLoadDocuments (id) {
      return this.get('/api/loads/getLoadDocuments/' + id);
    },
    getLoad (id, driverId = 0) {
      if (this.$root.lastListPath && this.$root.lastListPath !== '') {
        return this.get('/api/loads/get/' + id + '/' + driverId + '/' + encodeURIComponent(this.$root.lastListPath));
      }
      return this.get('/api/loads/get/' + id + '/' + driverId);
    },
    getAvailableLocationsList (driver, shiftId = 0) {
      return this.get('/api/loads/getAvailableLocationsList/' + driver + '/' + shiftId);
    },
    getLoadLocations (id) {
      return this.get('/api/loads/getLocations/' + id);
    },
    getLoadNotes (id) {
      return this.get('/api/loads/getNotes/' + id);
    },
    getLoadTruckOdometer (truckId) {
      return this.get('/api/loads/getLoadTruckOdometer/' + truckId);
    },
    getLoadUpdated (id) {
      return this.get('/api/loads/getUpdated/' + id);
    },
    searchLoad (searchValue, excludeLoad) {
      if (excludeLoad > 0) {
        return this.get('/api/loads/search/' + searchValue + '/' + excludeLoad);
      }
      return this.get('/api/loads/search/' + searchValue);
    },
    listLoadsByTruck (truckId, finished = null, type = null) {
      if (finished !== null && type !== null) {
        return this.get('/api/loads/listByTruck/' + truckId + '/' + finished + '/' + type);
      }
      return this.get('/api/loads/listByTruck/' + truckId);
    },
    onCreateLoadBtn (bidId) {
      this.selectBrokerDialogData.bidId = bidId;
      this.$root.getBid(bidId).then((response) => {
        if (response.status === 'ok') {
          if (parseInt(response.result.broker) === 0) {
            this.selectBrokerDialog = true;
            this.selectBrokerDialogData.email = response.result.recipient;
          } else {
            this.$root.route('/loads/create/' + bidId);
          }
        }
      });
    },
    resetSelectedBrokerDialog () {
      this.selectBrokerDialogData = {
        bidId: 0,
        email: '',
        newBrokerName: '',
        selectType: 1,
        selectedBroker: 0,
        addEmailToSelectedBroker: false
      };
    },
    selectBrokerBeforeLoad () {
      if (this.selectBrokerDialogData.bidId > 0 && this.selectBrokerDialogData.selectedBroker > 0) {
        if (this.selectBrokerDialogData.addEmailToSelectedBroker) {
          this.selectBrokerDialog = false;
          this.$root.route('/loads/create/' + this.selectBrokerDialogData.bidId + '/' + this.selectBrokerDialogData.selectedBroker + '/' + encodeURIComponent(this.selectBrokerDialogData.email));
          this.resetSelectedBrokerDialog();
        } else {
          this.selectBrokerDialog = false;
          this.$root.route('/loads/create/' + this.selectBrokerDialogData.bidId + '/' + this.selectBrokerDialogData.selectedBroker);
          this.resetSelectedBrokerDialog();
        }
      }
    },
    createBrokerBeforeLoad () {
      if (this.selectBrokerDialogData.newBrokerName !== '' && this.selectBrokerDialogData.email !== '') {
        this.$root.createUser(this.selectBrokerDialogData.email || '', '', 'Broker', { firstName: this.selectBrokerDialogData.newBrokerName,
          brokerManagersJSON: '[]' }).then((response) => {
          if (response.status === 'ok') {
            const insertId = response.result['insertId'];
            this.selectBrokerDialog = false;
            this.$root.route('/loads/create/' + this.selectBrokerDialogData.bidId + '/' + insertId);
            this.resetSelectedBrokerDialog();
          } else if (response.status === 'error') {
            this.$root.toast(response.msg, { color: 'error' });
          }
        });
      } else {
        this.$root.toast(this.$i18n.t('Bids.brokerNameOrEmailUnavailable'), { color: 'error' });
      }
    },
    createLoad (fields) {
      return new Promise((resolve, reject) => {
        this.post('/api/loads/save', { fields: fields }).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Loads.LoadDataCreated'), { color: 'success' });
            resolve(response);
          } else {
            this.$root.toast(this.$i18n.t('Loads.LoadDataCreateError'), { color: 'error' });
            reject(response);
          }
        }, (error) => {
          reject(error);
        });
      });
    },
    saveLoad (fields, id) {
      return new Promise((resolve, reject) => {
        this.post('/api/loads/save/' + id, { fields: fields }).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Loads.LoadDataSaved'), { color: 'success' });
            resolve(response);
          } else {
            this.$root.toast(this.$i18n.t('Loads.LoadDataSaveError') + ': ' + response.msg, { color: 'error' });
            reject(response);
          }
        }, (error) => {
          reject(error);
        });
      });
    },
    cloneLoad (id, clonedAppointmentDetails) {
      return new Promise((resolve, reject) => {
        this.post('/api/loads/clone/' + id, {clonedAppointmentDetails: clonedAppointmentDetails}).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Loads.CloneSuccess'), { color: 'success' });
            resolve(response);
          } else {
            this.$root.toast(this.$i18n.t('Loads.CloneError'), { color: 'error' });
            resolve(response);
          }
        }, (error) => {
          resolve(error);
        });
      });
    },
    clearLoadStatus (name, id) {
      return new Promise((resolve, reject) => {
        this.post('/api/loads/clearStatus/' + id, { name: name }).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Loads.LoadDataSaved'), { color: 'success' });
            resolve(response);
          } else {
            this.$root.toast(this.$i18n.t('Loads.LoadDataSaveError') + ': ' + response.msg, { color: 'error' });
            reject(response);
          }
        }, (error) => {
          reject(error);
        });
      });
    },
    setupTrackingNotifications (id, isEnabled, intervalSec, enableSendByRequest, recipients, subject, message, in_copy = []) {
      return new Promise((resolve, reject) => {
        this.post('/api/loads/trackingNotifications/' + id, {
          is_enabled: isEnabled,
          interval_sec: intervalSec,
          enable_send_by_request: enableSendByRequest,
          recipients: JSON.stringify(recipients),
          subject: subject,
          message: message,
          in_copy: JSON.stringify(in_copy)
        }).then((response) => {
          if (response.status === 'ok') {
            resolve(response);
            this.$root.toast(this.$i18n.t('Loads.LoadTrackingNotificationsSetupSuccess'), { color: 'success' });
          } else {
            reject(response);
            this.$root.toast(this.$i18n.t('Loads.LoadTrackingNotificationsSetupError'), { color: 'error' });
          }
        }, (error) => {
          this.$root.toast(error, { color: 'error' });
          reject(error);
        });
      });
    },
    restoreLoad (id) {
      return new Promise((resolve, reject) => {
        this.post('/api/loads/save/' + id, { fields: { deleted: 0 } }).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Loads.LoadRestored'), { color: 'success' });
            resolve(response);
          } else {
            this.$root.toast(this.$i18n.t('Loads.LoadRestoreError'), { color: 'error' });
            reject(response);
          }
        }, (error) => {
          reject(error);
        });
      });
    },
    deleteLoad (id) {
      return new Promise((resolve, reject) => {
        this.post('/api/loads/save/' + id, { fields: { deleted: 1 } }).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Loads.LoadDeleted'), { color: 'success' });
            resolve(response);
          } else {
            this.$root.toast(this.$i18n.t('Loads.LoadDeleteError'), { color: 'error' });
            reject(response);
          }
        }, (error) => {
          reject(error);
        });
      });
    },
    finishLoad (id, value = 1, isReturnToHQEnabled = false) {
      return new Promise(async (resolve, reject) => {
        let addReturnToHQLocation = false;
        let fields = { finished: value };
        if (value === 1) {
          fields['isTrackingNotifications'] = 0;
        }
        // Enable exckude dispatcher payment if load is canceled
        if (value === 3) {
          fields['isExcludeDispatcherPayment'] = 1;
        }
        // Always ask where truck go next
        if (!isReturnToHQEnabled && value !== 0) {
          try {
            await this.$dialog.confirm(this.$root.t('Loads.onReturnToHQSwitchConfirmMsg'), {
              okText: 'Return to Home',
              cancelText: 'Do not know'
            });
            addReturnToHQLocation = true;
          } catch (e) {
            this.$root.setNextLoadTruckRouteUnknown(id);
          };
        }

        this.post('/api/loads/save/' + id, { fields: fields }).then((response) => {
          if (response.status === 'ok') {
            response.addReturnToHQLocation = addReturnToHQLocation;
            resolve(response);
          } else {
            reject(response);
          }
        }, (error) => {
          reject(error);
        });
      });
    },
    checkIsLoadFinished (loadId, locations, isReturnToHQ = false) {
      return new Promise(async (resolve, reject) => {
        let response = {};
        if (this.isLoadFinished(locations)) {
          this.$dialog.confirm(this.$root.t('Loads.finishConfirmationMessage')).then( async dialog => {
            response = await this.finishLoad(loadId, 1, isReturnToHQ);
            resolve(response);
            /* this.$dialog.confirm(this.$root.t('Loads.LoadIsFinishedCreateInvoice')).then(dialog => {
              this.$root.route('/invoices/create/' + load_id);
            }).catch(() => {
            }); */
          }).catch(e => {
            resolve({ status: 'error' });
          });
        } else {
          response = await this.finishLoad(loadId, 0);
          resolve(response);
        }
      });
    },
    processToNextLoadStatus (loadid, nextAction) {
      return this.post('/api/loads/nextAction/' + loadid, { nextAction: nextAction });
    },
    getLoadPhase (locations, finished = 0) {
      if (finished === 1) return 2;
      if (finished === 2) return 3;

      for (let i = locations.length - 1; i >= 0; i--) {
        /*if (i === locations.length - 1 && locations[i]['driverDoneCheck'] > 0) {
          return 2;
        }*/
        if (locations[i]['driverDoneCheck'] > 0 || locations[i]['driverOnSiteCheck'] > 0 || locations[i]['dispatcherOnTheWayCheck'] > 0) {
          return 1;
        }
      }
      return 0;
    },
    getLoadStatus (locations, finished) {
      let ret = {
        status: this.$root.t('Loads.Idle'),
        phaseIcon: 'mdi-progress-clock',
        phaseColor: 'yellow',
        stageIcon: false,
        stageColor: false
      };
      let i = 0;
      let dispatcherAproved = true;
      if (finished === 1) {
        ret.status = this.$root.t('Loads.finishedStatus');
        ret.phaseIcon = 'mdi-check-bold';
        ret.phaseColor = 'success';
        return ret;
      }
      if (finished === 2) {
        ret.status = this.$root.t('Loads.finishedWithTONUStatus');
        ret.phaseIcon = 'mdi-flag-checkered';
        ret.phaseColor = 'success';
        return ret;
      }
      if (finished === 3) {
        ret.status = this.$root.t('Loads.Canceled');
        ret.phaseIcon = 'mdi-cancel';
        ret.phaseColor = 'error';
        return ret;
      }
      // Detect last status
      /*for (i = 0; i < locations.length; i++) {
        if (!locations[i].dispatcherOnTheWayCheck || locations[i].dispatcherOnTheWayCheck === 0) {
          dispatcherAproved = false;
          break;
        }
      }*/

      /*if (dispatcherAproved) {
        ret.status = this.$root.t('Loads.finishedStatus');
        ret.phaseIcon = 'mdi-check-bold';
        ret.phaseColor = 'success';
        return ret;
      }*/

      if (locations.length > 1) {
        for (i = locations.length - 1; i > 0; i--) {
          ret.stageIcon = this.$root.getLocationIcon(locations[i]);
          ret.stageColor = this.$root.getLocationColor(locations[i], i);

          if (locations[i].returnToHQ) {
            if (locations[i]['driverOnSiteCheck'] > 0) {
              ret.status = this.$root.t('Loads.onParkingStatus');
              ret.phaseIcon = 'mdi-truck-delivery';
              ret.phaseColor = 'primary';
              return ret;
            }
            if (locations[i]['driverOnTheWayCheck'] > 0) {
              ret.status = this.$root.t('Loads.onTheWayToParkingStatus');
              ret.phaseIcon = 'mdi-truck-fast';
              ret.phaseColor = 'primary';
              return ret;
            }
          }

          if (locations[i].parking) {
            if (locations[i]['driverOnSiteCheck'] > 0) {
              ret.status = this.$root.t('Loads.onParkingStatus');
              ret.phaseIcon = 'mdi-truck-delivery';
              ret.phaseColor = 'primary';
              return ret;
            }
            if (locations[i]['driverOnTheWayCheck'] > 0) {
              ret.status = this.$root.t('Loads.onTheWayToParkingStatus');
              ret.phaseIcon = 'mdi-truck-fast';
              ret.phaseColor = 'primary';
              return ret;
            }
          }

          if (locations[i].exchange) {
            if (locations[i]['driverOnSiteCheck'] > 0) {
              ret.status = this.$root.t('Loads.onExchangeStatus');
              ret.phaseIcon = 'mdi-truck-delivery';
              ret.phaseColor = 'primary';
              return ret;
            }
            if (locations[i]['driverOnTheWayCheck'] > 0) {
              ret.status = this.$root.t('Loads.onTheWayToExchangeStatus');
              ret.phaseIcon = 'mdi-truck-fast';
              ret.phaseColor = 'primary';
              return ret;
            }
          }

          if (locations[i].pickup) {
            if (locations[i]['driverDoneCheck'] > 0) {
              ret.status = this.$root.t('Loads.loadedStatus');
              ret.phaseIcon = 'mdi-truck-check';
              ret.phaseColor = 'primary';
              return ret;
            } else if (locations[i]['notDoneByDriverTS'] > 0) {
              ret.status = this.$root.t('Loads.notLoadedStatus');
              ret.phaseIcon = 'mdi-close-thick';
              ret.phaseColor = 'error';
              return ret;
            }
            if (locations[i]['driverOnSiteCheck'] > 0) {
              ret.status = this.$root.t('Loads.onSiteToPickupStatus');
              ret.phaseIcon = 'mdi-truck-delivery';
              ret.phaseColor = 'primary';
              return ret;
            }
            if (locations[i]['driverOnTheWayCheck'] > 0) {
              ret.status = this.$root.t('Loads.onTheWayToPickupStatus');
              ret.phaseIcon = 'mdi-truck-fast';
              ret.phaseColor = 'primary';
              return ret;
            }
          }

          if (locations[i].delivery) {
            if (locations[i]['driverDoneCheck'] > 0) {
              ret.status = this.$root.t('Loads.unloadedStatus');
              ret.phaseIcon = 'mdi-truck-check';
              ret.phaseColor = 'primary';
              return ret;
            } else if (locations[i]['notDoneByDriverTS'] > 0) {
              ret.status = this.$root.t('Loads.notUnloadedStatus');
              ret.phaseIcon = 'mdi-close-thick';
              ret.phaseColor = 'error';
              return ret;
            }

            if (locations[i]['driverOnSiteCheck'] > 0) {
              ret.status = this.$root.t('Loads.onSiteToDeliveryStatus');
              ret.phaseIcon = 'mdi-truck-delivery';
              ret.phaseColor = 'primary';
              return ret;
            }
            if (locations[i]['driverOnTheWayCheck'] > 0) {
              ret.status = this.$root.t('Loads.onTheWayToDeliveryStatus');
              ret.phaseIcon = 'mdi-truck-fast';
              ret.phaseColor = 'primary';
              return ret;
            }
          }
        }
      }
      /*if (locations[0]['driverOnTheWayCheck'] > 0) {
        ret.status = this.$root.t('Loads.onTheWayToDeliveryStatus');
        return ret;
      }*/
      return ret;
    },
    isLoadFinished (locations) {
      let i = 0;
      for (i = 0; i < locations.length; i++) {
        if (!locations[i].dispatcherOnTheWayCheck) {
          return false;
        }
      }
      return true;
    },
    getNextLoadStatusName (locations) {
      let i = 0;
      for (i = 0; i < locations.length; i++) {
        if (!locations[i].dispatcherOnTheWayCheck) {
          return false;
        }
      }
      return true;
    },
    getDetention (locations, maxWaitingHoursUntilDetentionInSeconds, detentionPrice) {
      let i = 0;
      let detention = 0.0;
      for (i = 0; i < locations.length; i++) {
        const location = locations[i];
        if (location.driverDoneCheck > 0 && location.driverOnSiteCheck > 0) {
          const stopSeconds = location.driverDoneCheck - location.driverOnSiteCheck;
          if (stopSeconds > maxWaitingHoursUntilDetentionInSeconds) {
            detention += Math.floor((stopSeconds - maxWaitingHoursUntilDetentionInSeconds) / 60 / 60) * detentionPrice;
          }
        }
      }
      return Math.round(detention);
    },
    isLocationsTypesValid (locations) {
      let i = 0;
      for (i = 0; i < locations.length; i++) {
        if (i > 0 && !locations[i].parking && !locations[i].exchange && !locations[i].pickup && !locations[i].delivery && !locations[i].returnToHQ) {
          return false;
        }
      }
      return true;
    },
    getLoadStatusLocation (locations, finished) {
      // Detect last status
      let i = 0;
      let dispatcherAproved = true;
      for (i = 0; i < locations.length; i++) {
        if (!locations[i].dispatcherOnTheWayCheck || locations[i].dispatcherOnTheWayCheck === 0) {
          dispatcherAproved = false;
          break;
        }
      }

      if (dispatcherAproved || finished > 0) {
        return '';
      }

      if (locations.length > 1) {
        for (let i = locations.length - 1; i > 0; i--) {
          if (locations[i].parking) {
            if (locations[i]['driverOnSiteCheck'] > 0) {
              return locations[i].name;
            }
            if (locations[i]['driverOnTheWayCheck'] > 0) {
              return locations[i].name;
            }
          }

          if (locations[i].exchange) {
            if (locations[i]['driverOnSiteCheck'] > 0) {
              return locations[i].name;
            }
            if (locations[i]['driverOnTheWayCheck'] > 0) {
              return locations[i].name;
            }
          }

          if (locations[i].pickup) {
            if (locations[i]['driverDoneCheck'] > 0) {
              return locations[i].name;
            }
            if (locations[i]['driverOnSiteCheck'] > 0) {
              return locations[i].name;
            }
            if (locations[i]['driverOnTheWayCheck'] > 0) {
              return locations[i].name;
            }
          }

          if (locations[i].delivery) {
            if (locations[i]['driverDoneCheck'] > 0) {
              return locations[i].name;
            }
            if (locations[i]['driverOnSiteCheck'] > 0) {
              return locations[i].name;
            }
            if (locations[i]['driverOnTheWayCheck'] > 0) {
              return locations[i].name;
            }
          }
        }
      }
      if (locations[0]['driverOnTheWayCheck'] > 0) {
        return locations[1].name;
      }
      return locations[0].name;
    },
    generateShipTo (locations) {
      let ret = '';
      for (let i = 1; i < locations.length; i++) {
        const location = locations[i];
        if (location.delivery) {
          ret += location.name + '\n\n';
        }
      }
      return ret;
    },
    generateShipper (locations) {
      let ret = '';
      for (let i = 1; i < locations.length; i++) {
        const location = locations[i];
        if (location.pickup) {
          ret += location.name + '\n\n';
        }
      }
      return ret;
    },
    calculateTimings (locations, startTime = 0, drive_seconds_left = 0, startIndex = 1, endIndex = 0) {
      const stopDuration = 2 * 60 * 60;
      const maxDriveTime = 11 * 60 * 60;
      const maxRestTime = 13 * 60 * 60;
      let duration = 0;
      let i = 0;
      let d = 0;

      let drivingTime = maxDriveTime;
      let restTime = maxRestTime;
      let initialTS = startTime === 0 ? parseInt(new Date().getTime() / 1000) : startTime;
      for (i = startIndex; i < locations.length; i++) {
        if (endIndex > 0 && i >= endIndex) continue;

        duration = locations[i - 1] && locations[i - 1].duration ? parseInt(locations[i - 1].duration) : 0;

        /*for (d = 0; d < 100; d++) {
          if (drivingTime > duration) {
            drivingTime -= duration;
            initialTS += duration;
            break;
          } else {
            duration -= drivingTime;
            initialTS += drivingTime;
            initialTS += restTime;
            // Reset available time
            drivingTime = maxDriveTime;
            restTime = maxRestTime;
          }
        }*/

        initialTS += duration;

        this.$store.dispatch('loads/setLocationDateTime', { index: i, value: initialTS });
        this.$store.dispatch('loads/setLocationEndDateTime', { index: i, value: initialTS });
      }
      this.$forceUpdate();
    },
    activeDriverLoads (driverId) {
      return this.get('/api/loads/activeCountByDriver/' + driverId);
    },
    unfinishedDriverLoads (driverId) {
      return this.get('/api/loads/notFinishedCountByDriver/' + driverId);
    },
    getDriverRate (driverRates, type, name, isTeam = false) {
      let i = 0;
      for (i = 0; i < driverRates.length; i++) {
        if ((type === 0 || driverRates[i].type === type) && driverRates[i].name === name) {
          return {
            rate: isTeam ? driverRates[i].teamRate : driverRates[i].soloRate,
            rateFixed: isTeam ? driverRates[i].teamRateFixed : driverRates[i].soloRateFixed,
            rateMiles: driverRates[i].minMiles
          };
        }
      }
      return false;
    },
    getRateFromListByDriverAndName (name, ratesArray) {
      if (name === this.$store.getters.currentRateName && ratesArray.length > 1) {
        let curRate = ratesArray.find((el) => { return el.name === name; });
        if (!curRate.soloRate || !curRate.teamRate) {
          return ratesArray.find((el) => { return el.name === this.$store.getters.defaultRateName; });
        }
      }
      return ratesArray.find((el) => { return el.name === name; });
    },
    processReturnLocation (driverRates = {}) {
      return new Promise((resolve, reject) => {
        // this.setDefaultReturnRates();
        // TODO rework this
        // Check they is no return to HQ locations and exit if its already exists
        for (let i = 0; i < this.locations.length; i++) {
          if (parseInt(this.locations[i].returnToHQ) === 1) {
            resolve({ duration: 0, distance: 0 });
            return;
          }
        }
        let lastLocation = this.locations[this.locations.length - 1];
        for (let i = this.locations.length - 1; i > 0; i++) {
          if (parseInt(this.locations[i].returnToHQ) !== 1) {
            lastLocation = this.locations[i];
            break;
          }
        }

        // calc distance from last point to HQ
        this.$root.calcDistance([lastLocation, { lat: this.defaultHQPlaceCoords.lat, lng: this.defaultHQPlaceCoords.lng }], null, false, this.$refs.map ? this.$refs.map.trafficModel : null).then((response2) => {
          if (response2.routes && response2.routes.length > 0 && response2.routes[0].legs) {
            const legs = response2.routes[0].legs;
            if (legs && legs.length > 0 && legs[0].duration && legs[0].distance) {

              const _duration = this.$root.isUseGoogleMaps ? (legs[0].duration_in_traffic ? legs[0].duration_in_traffic.value : legs[0].duration.value) : legs[0].duration;
              const _distance = this.$root.isUseGoogleMaps ? legs[0].distance.value : legs[0].distance;

              //const _duration = legs[0].duration_in_traffic ? legs[0].duration_in_traffic.value : legs[0].duration.value;
              //const _distance = legs[0].distance.value;

              const lastLocationsIndex = this.locations.length - 1;

              this.$store.dispatch('loads/pushLocations', {
                lat: this.defaultHQPlaceCoords.lat,
                lng: this.defaultHQPlaceCoords.lng,
                name: this.defaultHQPlace,
                pickup: false,
                delivery: false,
                returnToHQ: true,
                timestamp: 0,
                details: null,
                status: 0,
                targetDatetimes: [(new Date().getTime() / 1000) + _duration, (new Date().getTime() / 1000) + _duration]
              });
              this.$store.dispatch('loads/setLocationField', { index: lastLocationsIndex, field: 'duration', value: _duration });
              this.$store.dispatch('loads/setLocationField', { index: lastLocationsIndex, field: 'distance', value: _distance });
              this.$store.dispatch('loads/populateLocationSettingsDown', { index: lastLocationsIndex - 1, driverRates: driverRates });
              this.isReturnToHQLoading = false;
              resolve({ duration: _duration, distance: _distance });
            } else {
              this.$root.toast(this.$i18n.t('ErrorCantCalcTheDistance'), { color: 'error' });
              reject(this.$i18n.t('ErrorCantCalcTheDistance'));
            }
          } else {
            this.$root.toast(this.$i18n.t('ErrorCantCalcTheDistance'), { color: 'error' });
            reject(this.$i18n.t('ErrorCantCalcTheDistance'));
          }
        }).catch((e) => {
          reject(e);
        });
      });
    },
    proccedOverrideSalary (userId, name, amount, loadId, notes) {
      return new Promise((resolve, reject) => {
        this.post('/api/loads/overrideSalary', { userId: userId, name: name, amount: amount, loadId: loadId, notes: notes }).then((response) => {
          if (response.status === 'ok') {
            this.$root.toast(this.$i18n.t('Loads.LoadDataSaved'), { color: 'success' });
            resolve(response);
          } else {
            this.$root.toast(this.$i18n.t('Loads.LoadDataSaveError') + ': ' + response.msg, { color: 'error' });
            reject(response);
          }
        }, (error) => {
          reject(error);
        });
      });
    },
    isLocationDontHaveDoneBtn (location) {
      return location.parking || location.exchange || location.returnToHQ;
    },
    setLoadLocationsField (loadId, number, field, value) {
      return this.$root.post('/api/loads/locationSetField', { loadId: loadId, number: number, field: field, value: value });
    },
    setLoadLocationFieldsById (loadId, locationId, fields) {
      return this.$root.post('/api/loads/loadLocationFieldsById', { loadId: loadId, locationId: locationId, fields: fields });
    },
    setLoadLocationsStart (loadId, number) {
      return this.$root.post('/api/loads/locationDriverSetStatus', { loadId: loadId, number: number, type: 'start' });
    },
    cancelLoadLocationsStart (loadId, number) {
      return this.$root.post('/api/loads/locationDriverCancelStatus', { loadId: loadId, number: number, type: 'start' });
    },
    setLoadLocationsOnSite (loadId, number) {
      return this.$root.post('/api/loads/locationDriverSetStatus', { loadId: loadId, number: number, type: 'onSite' });
    },
    cancelLoadLocationsOnSite (loadId, number) {
      return this.$root.post('/api/loads/locationDriverCancelStatus', { loadId: loadId, number: number, type: 'onSite' });
    },
    setLoadLocationsDone (loadId, number) {
      return this.$root.post('/api/loads/locationDriverSetStatus', { loadId: loadId, number: number, type: 'done' });
    },
    cancelLoadLocationsDone (loadId, number) {
      return this.$root.post('/api/loads/locationDriverCancelStatus', { loadId: loadId, number: number, type: 'done' });
    },
    setLoadLocationsNotDone (loadId, number) {
      return this.$root.post('/api/loads/locationDriverSetStatus', { loadId: loadId, number: number, type: 'notDone' });
    },
    cancelLoadLocationsNotDone (loadId, number) {
      return this.$root.post('/api/loads/locationDriverCancelStatus', { loadId: loadId, number: number, type: 'notDone' });
    },
    setLoadLocationsApprove (loadId, number) {
      return this.$root.post('/api/loads/locationDriverSetStatus', { loadId: loadId, number: number, type: 'approve' });
    },
    cancelLoadLocationsApprove (loadId, number) {
      return this.$root.post('/api/loads/locationDriverCancelStatus', { loadId: loadId, number: number, type: 'approve' });
    },
    addReturnLocation (loadId, fields) {
      return this.$root.post('/api/loads/locationAddReturnLocation', { loadId: loadId, fields: fields });
    },
    continueLoadLocations (loadId, number) {
      return this.$root.post('/api/loads/continueLoad', { loadId: loadId, number: number });
    },
    getLoadsToRefine () {
      return this.$root.get('/api/loads/getLoadsToRefine');
    },
    getLoadsWithoutInvoice () {
      return this.$root.get('/api/loads/getLoadsWithoutInvoice');
    },
    getMissMatchLoads () {
      return this.$root.get('/api/loads/getMissMatchLoads');
    },
    addLocationsDetails (loadsLocationId, text) {
      return this.$root.post('/api/loads/addLocationsDetails/' + loadsLocationId, {
        details: text
      });
    },
    setLocationsDetailsVisibility (loadsLocationId, value) {
      return this.$root.post('/api/loads/setLocationsDetailsVisibility/' + loadsLocationId, {
        value: value
      });
    },
    cancelAllNextLocations (loadId, number) {
      return this.$root.post('/api/loads/cancelAllNextLocations', { loadId: loadId, number: number });
    },
    addVerifiedNote (userId, loadId, status = 1, note = '') {
      return this.$root.post('/api/reports/markAsVerified', {
        userId: userId,
        loadId: loadId,
        status: status,
        note: note
      });
    },
    editVerifiedNote (itemId, note) {
      return this.$root.post('/api/reports/editVerifiedNote', {
        itemId: itemId,
        note: note
      });
    },
    insertLocation (parentLocation, newLocation, newLocationName, duration, distance) {
      return this.post('/api/loads/insertLocation', {
        parentLocation: parentLocation,
        newLocation: newLocation,
        newLocationName: newLocationName,
        duration: duration,
        distance: distance
      });
    },
    resetLocationStatusAndOdometer (locationId) {
      return this.post('/api/loads/resetLocationStatusAndOdometer', { locationId: locationId });
    },
    updateOdometersFromLogs (loadId, locationId = 0) {
      return new Promise((resolve, reject) => {
        this.$dialog.confirm(this.$i18n.t('Loads.ConfirmUpdateOdometersFromLogs')).then(dialog => {
          this.post('/api/loads/setOdometersFromLogs', {
            loadId: loadId,
            locationId: locationId
          }).then(response => {
            if (response.status === 'ok') {
              this.$root.toast(this.$root.t('Updated'), { color: 'success' });
              resolve();
            }
          }).catch(e => {
            console.error(e);
            this.$root.toast(this.$root.t('Error'), { color: 'error' });
            resolve();
          })
        }).catch(() => {
          resolve();
        });
      });
    },
    setNextLoadTruckRouteUnknown (loadId) {
      this.post('/api/loads/setNextLoadTruckRouteUnknown', {
        loadId: loadId
      }).then(response => {
        if (response.status === 'ok') {
          this.$root.toast(this.$root.t('Updated'), {color: 'success'});
        }
      }).catch(e => {
        console.error(e);
        this.$root.toast(this.$root.t('Error'), {color: 'error'});
      })
    },
    setEditOverride (loadId, value) {
      this.saveLoad({
        isOverrideEditLock: value
      }, loadId);
    },
    editOverride (loadId) {
      this.saveLoad({
        isOverrideEditLock: 1
      }, loadId);
    },
    clearEditOverride (loadId) {
      this.saveLoad({
        isOverrideEditLock: 0
      }, loadId);
    },
    blockEditingForDirver (loadId) {
      this.saveLoad({
        isOverrideEditLock: 10
      }, loadId);
    },
    blockEditingForDispatchers (loadId) {
      this.saveLoad({
        isOverrideEditLock: 20
      }, loadId);
    },
    blockEditingForAll (loadId) {
      this.saveLoad({
        isOverrideEditLock: -1
      }, loadId);
    },
    removeReturnToHQFromLoad (loadId) {
      return this.$root.post('/api/loads/removeReturnToHQLocation', {
        loadId: loadId
      });
    },
    getLoadAppointment (loadId) {
      return this.$root.get('/api/loads/getLoadAppointment/' + loadId);
    },
    addReturnToHQToLoad (loadId, place, startAfterAdded = false) {
      return new Promise(async (resolve, reject) => {
        let i = 0;
        let locations = [];
        if (!place.hasOwnProperty('name') || !place.hasOwnProperty('location')) {
          reject(this.$i18n.t('GetLocationError'));
          return;
        }
        let defaultHQPlace = place.name;
        let defaultHQPlaceCoords = place.location;
        let lastLocation = {};

        let response = await this.$root.getLoadLocations(loadId);
        if (response.status === 'ok') {
          locations = response.results;
        }

        lastLocation = locations[locations.length - 1];
        for (i = 0; i < locations.length; i++) {
          if (parseInt(locations[i].returnToHQ) !== 1) {
            lastLocation = locations[i];
          }
        }
        // calc distance from last point to HQ
        response = await this.$root.calcDistance([lastLocation, { lat: defaultHQPlaceCoords.lat, lng: defaultHQPlaceCoords.lng }]);
        if (response.routes && response.routes.length > 0 && response.routes[0].legs) {
          const legs = response.routes[0].legs;
          if (legs && legs.length > 0 && legs[0].duration && legs[0].distance) {
            const duration = this.$root.isUseGoogleMaps ? (legs[0].duration_in_traffic ? legs[0].duration_in_traffic.value : legs[0].duration.value) : legs[0].duration;
            const distance = this.$root.isUseGoogleMaps ? legs[0].distance.value : legs[0].distance;
            this.$root.post('/api/loads/addReturnToHQLocation', {
              loadId: loadId,
              duration: duration,
              distance: distance,
              name: defaultHQPlace,
              lat: defaultHQPlaceCoords.lat,
              lng: defaultHQPlaceCoords.lng,
              startAfterAdded: startAfterAdded
            }).then( response => {
              if (response.status === 'ok') {
                this.$set(this.$root, 'loadModalKey', new Date().getTime());
                resolve({status: 'ok', duration: duration, distance: distance});
              }
            });
          } else {
            this.$root.toast(this.$i18n.t('ErrorCantCalcTheDistance'), { color: 'error' });
            reject(this.$i18n.t('ErrorCantCalcTheDistance'));
          }
        } else {
          this.$root.toast(this.$i18n.t('ErrorCantCalcTheDistance'), { color: 'error' });
          reject(this.$i18n.t('ErrorCantCalcTheDistance'));
        }
      });
    },
    approveExpense (loadId, timestamp) {
      return this.$root.post('/api/loads/approveExpense', {
        loadId: loadId,
        timestamp: timestamp
      });
    },
    getTruckNextLoad (truckId, currentLoadId) {
      return this.$root.get('/api/loads/getTruckNextLoad/' + truckId + '/' + currentLoadId);
    },
    getTruckPrevLoad (truckId, currentLoadId) {
      return this.$root.get('/api/loads/getTruckPrevLoad/' + truckId + '/' + currentLoadId);
    },
    resetReturnToHQLocation (loadId, locationNumber, name, lat, lng) {
      return this.$root.post('/api/loads/resetReturnToHQLocation', {
        loadId: loadId,
        locationNumber: locationNumber,
        name: name,
        lat: lat,
        lng: lng
      });
    }
  }
};
