<template>
  <div>
    <v-overlay :value="loading" :absolute="true">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <TruckMaintenanceReportTemplate v-if="$route.name === 'reportPrint'"
                                   :entries="$route.params.report_number > 0 ? null : items"
                                   :totalsCounts="totalsCounts"
                                   :reportData="reportData"
                                   :from="from"
                                   :to="to"
                                   :key="'reportTemplateKey' + items.length"
                                   :companyId="companyId"
    />
    <v-card v-else>
      <v-card-title :style="{ backgroundColor: $root.currentTheme.background }">
        {{$t('Reports.TruckMaintenance')}}
      </v-card-title>

     <!-- <v-layout>
        <v-flex xs12>
          <v-card class="ma-2">
            <ReportsGroupsList :type="$route.params.type" :userId="0" :totalsCountsNames="totalsCountsNames" :totalsCounts="totalsCounts" :key="reportsGroupsListKey"></ReportsGroupsList>
          </v-card>
        </v-flex>
      </v-layout>-->

      <v-layout v-if="$root.isFullFinancialDataAllowedToView">
        <v-flex xs12 class="text-right">
          <ReportActions
            :userId="0"
            :createDOTReport="true"
            :hide-actions-btn="true"
            paramsType="truckMaintenance"
            :from="from"
            :to="to"
            :selectedItems="selectedItems"
            @create-report-group="createReport"
            @create-report-group-dot="(selectedItems, userId, typeValue) => createReport(selectedItems, userId, typeValue, true)"
            @show-saved-reports="$root.onShowReportsModal"
          />
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex shrink>
          <FiltersPanel
            :loading="loading"
            :isExpansionPanel="$root.isMobile"
            ref="filtersPanel"
            prefix="truckMaintenanceReportFilter"
            :items="truckId ? [userFilter] : [userFilter, truckFilter, truckTrailerFilter, typeFilter]"
            :key="userFilter.name + userFilter.list.length + truckFilter.name + truckFilter.list.length + truckTrailerFilter.name + truckTrailerFilter.list.length + typeFilter.name + typeFilter.list.length"
            outlined
            @change="onFilterChanged"
          />
        </v-flex>
        <v-flex grow>
          <v-text-field
            class="mt-1"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            single-line
            outlined dense
            hide-details
          />
        </v-flex>
        <v-flex shrink v-if="$root.isMobile">
          <v-btn class="mt-1 ml-1"
                  @click="$refs.filtersPanel.showFiltersDrawer()">
                  <v-icon>mdi-filter</v-icon>
          </v-btn>
        </v-flex>
        <!--<v-flex shrink>
          <v-btn class="ma-1" :disabled="selectedItems.length === 0" @click="createReportGroup(0, lastReportsGroupNumber + 1, $route.params.type, from, to)"><v-icon left>mdi-clipboard-check-outline</v-icon> {{$t('Reports.CreateReportsGroup')}}</v-btn>
        </v-flex>-->
      </v-layout>

      <v-list dense v-if="$root.isMobile">
        <v-list-item-group
          v-model="selectedItems"
          multiple
          active-class=""
        >
          <template v-for="item in items">
            <v-list-item :key="item.id" :value="item">
              <template v-slot:default="{ active }">
                <v-list-item-icon>
                  <v-checkbox :input-value="active"/>
                </v-list-item-icon>
                <v-list-item-content>
                  <div class="text-nowrap">
                    {{ $root.formatDate(item.maintenanceDATE) }}
                    <a href="#" @click.stop="$root.onShowTruckPreview(item.truckId)">
                      {{item.truckName}}
                      <v-icon small right v-if="item.isTrailer === 0">fa-truck-moving</v-icon>
                      <v-icon right v-if="item.isTrailer === 1">mdi-truck-trailer</v-icon>
                    </a>
                  </div>
                  {{ item.maintenanceType }}
                  <p v-if="item.maintenanceType === 'Oil Replacement' && item.nextOilReplacementIntervalMiles">
                    <small>Each:&nbsp;{{ item.nextOilReplacementIntervalMiles }}{{ $t('milesShort') }}</small>
                  </p>
                  <template v-if="item.isAnnual === 1 || item.isQuaterly === 1">
                    (
                    <span v-if="item.isAnnual === 1">{{ $t('Trucks.AnnualInspectionsAnnual') }}&nbsp;</span>
                    <span v-if="item.isQuaterly === 1">{{ $t('Trucks.AnnualInspectionsQuarterly') }}</span>
                    )
                  </template>
                  <v-btn v-if="item.maintenanceType === 'Annual Inspection'" small class="d-block"
                        @click.stop="downloadAnnualInspectionReport(item.fileUID, item.reportNumber, item.truckName)">
                    <v-icon left>mdi-file-pdf-box</v-icon>{{$t('Download')}}
                  </v-btn>
                  <br/>
                  <div class="text-nowrap">
                    <UserSnippet :key="item.maintenanceDATE + item.createdByUser"
                                :avatar-only="true"
                                :user-data="{
                                  id: item.createdByUser,
                                  avatarUPLOAD: item.userCreatedAvatarUPLOAD,
                                  firstName: item.userCreatedFirstName,
                                  lastName: item.userCreatedLastName
                                }"
                                :small="false"
                                :override-tooltip="item.userCreatedFirstName + ' ' + item.userCreatedLastName"
                    />
                    {{$t('At')}}&nbsp;{{item.createdPST}}
                  </div>
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-expansion-panels flat dense>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        {{ $t('Details') }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <template v-for="(part, index) in item.parts">
                          <template v-if="part.partName">&nbsp;{{part.partName}}</template>
                          <template v-if="part.partCode">&nbsp;({{part.partCode}})</template>
                          <template v-if="part.partAmount">&nbsp;x{{part.partAmount}}</template>
                          <template v-if="part.partWear">&nbsp;<b>{{$t('Trucks.maintenancePartWear')}}:</b>&nbsp;{{item.partWear}}</template>
                          <template v-if="part.partSize">&nbsp;<b>{{$t('Trucks.maintenancePartSize')}}:</b>&nbsp;{{item.partSize}}</template>
                          <template v-if="part.isReplaced"><br/>&nbsp;<b>{{$t('Trucks.maintenanceIsReplaced')}}:</b></template>
                          <template v-if="part.newPartName">&nbsp;{{part.newPartName}}</template>
                          <template v-if="part.newPartCode">&nbsp;({{part.newPartCode}})</template>
                          <template v-if="index < item.parts.length - 1">,</template>
                        </template>

                        <template v-if="item.notes">
                          <v-divider/>
                          {{ $t('Notes') }}:<br/>
                          {{item.notes}}
                        </template>
                        <template v-if="item.attUPLOAD !== '' && item.attUPLOAD !== '[]'">
                          <v-divider/>
                          <v-btn icon v-on="on" @click.stop="showAttModal(item.attUPLOAD)">
                            <v-icon>mdi-file-image</v-icon>
                          </v-btn>
                        </template>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
            <v-divider/>

          </template>
        </v-list-item-group>
      </v-list>

      <v-data-table v-else
        :headers="headers"
        :items="items"
        :search="search"
        :items-per-page="100"
        :footer-props="footer_props"
        fixed-header
        v-model="selectedItems"
        show-select
        @item-selected="onItemSelected"
        @toggle-select-all="onItemSelected"
      >
        <template v-slot:item.truck="{ item }">
          <a href="#" @click.stop="$root.onShowTruckPreview(item.truckId)">{{item.truckName}}</a>
          <v-icon small right v-if="item.isTrailer === 0">fa-truck-moving</v-icon>
          <v-icon right v-if="item.isTrailer === 1">mdi-truck-trailer</v-icon>
        </template>
        <template v-slot:item.maintenanceDATE="{ item }">
          {{$root.formatDate(item.maintenanceDATE)}}
        </template>
        <template v-slot:item.maintenanceType="{ item }">
          {{ item.maintenanceType }}
          <p v-if="item.maintenanceType === 'Oil Replacement' && item.nextOilReplacementIntervalMiles">
            <small>Each:&nbsp;{{ item.nextOilReplacementIntervalMiles }}{{ $t('milesShort') }}</small>
          </p>
          <template v-if="item.isAnnual === 1 || item.isQuaterly === 1">
            (
            <span v-if="item.isAnnual === 1">{{ $t('Trucks.AnnualInspectionsAnnual') }}&nbsp;</span>
            <span v-if="item.isQuaterly === 1">{{ $t('Trucks.AnnualInspectionsQuarterly') }}</span>
            )
          </template>
          <v-btn v-if="item.maintenanceType === 'Annual Inspection'" x-small class="d-block"
                 @click.stop="downloadAnnualInspectionReport(item.fileUID, item.reportNumber, item.truckName)">
            <v-icon left>mdi-file-pdf-box</v-icon>{{$t('Download')}}
          </v-btn>
        </template>
        <template v-slot:item.part="{ item }">
          <template v-for="(part, index) in item.parts">
            <template v-if="part.partName">&nbsp;{{part.partName}}</template>
            <template v-if="part.partCode">&nbsp;({{part.partCode}})</template>
            <template v-if="part.partAmount">&nbsp;x{{part.partAmount}}</template>
            <template v-if="part.partWear">&nbsp;<b>{{$t('Trucks.maintenancePartWear')}}:</b>&nbsp;{{item.partWear}}</template>
            <template v-if="part.partSize">&nbsp;<b>{{$t('Trucks.maintenancePartSize')}}:</b>&nbsp;{{item.partSize}}</template>
            <template v-if="part.isReplaced"><br/>&nbsp;<b>{{$t('Trucks.maintenanceIsReplaced')}}:</b></template>
            <template v-if="part.newPartName">&nbsp;{{part.newPartName}}</template>
            <template v-if="part.newPartCode">&nbsp;({{part.newPartCode}})</template>
            <template v-if="index < item.parts.length - 1">,</template>
          </template>
        </template>
        <template v-slot:item.created="{ item }">
          {{$t('CreatedBy')}}:
          <UserSnippet :key="item.maintenanceDATE + item.createdByUser"
                       :avatar-only="true"
                       :user-data="{
                         id: item.createdByUser,
                         avatarUPLOAD: item.userCreatedAvatarUPLOAD,
                         firstName: item.userCreatedFirstName,
                         lastName: item.userCreatedLastName
                       }"
                       :small="true"
                       :override-tooltip="item.userCreatedFirstName + ' ' + item.userCreatedLastName"
          />
          <br/>{{$t('At')}}&nbsp;{{item.createdPST}}
        </template>
        <template v-slot:item.notes="{ item }">
          <v-tooltip bottom v-if="item.notes !== ''">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-information</v-icon>
            </template>
            {{item.notes}}
          </v-tooltip>
          <v-tooltip bottom v-if="item.attUPLOAD !== '' && item.attUPLOAD !== '[]'">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click.stop="showAttModal(item.attUPLOAD)">
                <v-icon>mdi-file-image</v-icon>
              </v-btn>
            </template>
            {{$t('Trucks.maintenanceAtt')}}
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="attModal">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="attModal = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <MultiFileUpload :field="attUPLOAD"
                           :key="attUPLOAD"
                           height="600px"
                           :readonly="true"
                           fieldName="attUPLOAD"
                           uploadFolder="maintenanceAtt"
                           :label="$t('Trucks.maintenanceAtt')"
                           :deleteAllowed="false"
                           :downloadAllowed="true"
                           :addBtnWidth="'90%'"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import reportsMixin from '../../mixins/reportsMixin.js';
import UserSnippet from '../../components/UserSnippet';
import MultiFileUpload from './../../components/MultiFileUpload';
import FiltersPanel from '../../components/FiltersPanel.vue';
import TruckMaintenanceReportTemplate from './TruckMaintenanceReportTemplate';
import ReportActions from '../../components/ReportActions';

export default {
  name: 'TruckMaintenanceReport',
  props: ['from', 'to', 'selectedStr', 'companyId', 'reportData', 'type', 'item', 'truckId'],
  components: { FiltersPanel, UserSnippet, MultiFileUpload, TruckMaintenanceReportTemplate, ReportActions },
  mixins: [reportsMixin],
  data: function () {
    return {
      loading: false,
      items: [],
      search: '',
      selectedItems: [],
      attModal: false,
      attUPLOAD: '',
      totalsCounts: {
        count: 0
      },
      totalsCountsNames: {
        count: 'Number of maintenances'
      },
      userFilter: {
        name: 'filterUser',
        label: 'User',
        field: '',
        list: []
      },
      truckFilter: {
        name: 'filterTruck',
        label: 'Truck',
        field: '',
        list: [],
        multiple: true
      },
      typeFilter: {
        name: 'filterType',
        label: 'Type',
        field: '',
        list: []
      },
      truckTrailerFilter: {
        name: 'filterTruckTrailer',
        label: 'Truck/Trailer',
        field: '',
        list: [{ id: 'All', name: 'All' },
               { id: 'Truck', name: 'Truck'},
               { id: 'Trailer', name: 'Trailer'}]
      },
      filterUserId: 0,
      filterTruckId: 0,
      filterType: 'All',
      filterTruckTrailer: 'All',
      headers: [
        { text: 'Created', value: 'created' },
        { text: 'Truck', value: 'truck' },
        { text: 'Date', value: 'maintenanceDATE' },
        { text: 'Type', value: 'maintenanceType' },
        { text: 'Part Info', value: 'part' },
        { text: 'Notes', value: 'notes' }
        /* { text: '', value: 'actions', sortable: false } */
      ],
      footer_props: {
        'items-per-page-options': [10, 25, 50, 100],
        'items-per-page-text': '',
        'disable-items-per-page': false
      }
    };
  },
  computed: {
  },
  mounted () {
    // Check if type and item not passed as url params set them from component properties
    if (!this.$route.params.type && !this.$route.params.item && this.type && this.item) {
      this.$store.commit('reports/setSelectedReportTypeAndItem', { type: this.type, item: 0 });
    }

    this.setTruckMaintenanceTotalsHeaders();
    this.$root.setLastListPath(this.$route.path);
    this.$root.getTrucksList(true).then((response) => {
      if (response.status === 'ok') {
        this.truckFilter.list = [{ id: '', name: 'All' }];
        for (let k in response.result) {
          this.truckFilter.list.push({
            id: response.result[k].id,
            name: response.result[k].name
          });
        }
      }
    });
    this.$root.getGlobalSettingsField('trucksMaintenanceTypes').then((response) => {
      if (response.status === 'ok') {
        this.typeFilter.list = JSON.parse(response.result);
        this.typeFilter.list.unshift({ id: 'Annual Inspection', name: 'Annual Inspection' });
        this.typeFilter.list.unshift({ id: '', name: 'All' });
      }
    });
    this.$nextTick(() => {
      //this.updateLastReportsGroupNumber(0, this.$route.params.type);
      this.fetchData();
    });
  },
  methods: {
    onItemSelected () {
      this.$nextTick(() => {
        let i = 0;
        let selected = [];
        for (i = 0; i < this.selectedItems.length; i++) {
          selected.push(parseInt(this.selectedItems[i]['id']));
        }
        this.$emit('selected', selected);
      });
    },
    onFilterChanged (e) {
      this.filterUser = 0;
      this.filterTruck = 0;
      this.filterType = 'All';
      this.filterTruckTrailer = 'All';
      if (e.filterUser !== '') {
        this.filterUser = parseInt(e.filterUser);
      }
      if (e.filterTruck !== '') {
        this.filterTruck = parseInt(e.filterTruck);
      }
      if (e.filterType !== '') {
        this.filterType = e.filterType;
      }
      if (e.filterTruckTrailer !== '') {
        this.filterTruckTrailer = e.filterTruckTrailer;
      }
      this.fetchData();
    },
    showAttModal (field) {
      this.attUPLOAD = field;
      this.attModal = true;
    },
    downloadAnnualInspectionReport (fileUID, reportNumber, truckName) {
      const url = this.$root.getImageUrl('annualInspection', fileUID, truckName.replace(/\s+/g, '_') + '_AnnualInspection_' + reportNumber + '.pdf');
      window.open(url, '_blank');
    },
    fetchData (skipSelected = false, selected = []) {
      if (!this.from || !this.to) return;
      return new Promise((resolve, reject) => {
        let i = 0;
        let trucksSet = {};
        this.loading = true;
        this.totalsCounts.count = 0;
        this.totalsCounts.trucksCount = 0;
        if (this.truckId) {
          this.filterTruck = this.truckId;
        }
        this.getReportTrucksMaintenance(this.from, this.to, this.filterUser, this.$route.params.item || this.filterTruck, this.filterType, this.filterTruckTrailer).then((response) => {
          if (response.status === 'ok') {

            let selectedArray = [];
            if (!skipSelected && this.selectedStr) {
              selectedArray = this.selectedStr.split(',').map(v => {
                return parseInt(v);
              });
            }
            else if (!skipSelected && selected.length > 0) {
              for (let i = 0; i < selected.length; i++) {
                selectedArray.push(selected[i]);
              }
            }
            this.selectedItems = [];

            this.items = [];
            for (i = 0; i < response.results.length; i++) {
              if (selectedArray.length > 0 && selectedArray.indexOf(parseInt(response.results[i].id)) < 0) continue;
              this.totalsCounts.count++;
              trucksSet[response.results[i].truckId] = 1;

              if (response.results[i].maintenanceType === 'Annual Inspection') {
                response.results[i].maintenanceDATE = this.$root.parseDate(response.results[i].maintenanceDATE);
              }

              this.items.push(Object.assign({startedPST: response.results[i].createdPST, endedPST: response.results[i].createdPST}, response.results[i]));
            }
            this.userFilter.list = response.users;
            this.userFilter.list.unshift({ id: '', name: 'All' });
          }
          this.reportsGroupsListKey = 'reportsGroupsListKey' + new Date().getTime();
          this.$forceUpdate();
          this.onItemSelected();
          resolve({
            count: this.totalsCounts.count,
            trucksCount: Object.keys(trucksSet).length,
            items: this.items
          });
        });
      }).finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>
