export default {
  namespaced: true,
  state: {
    loading: false,
    threads: [],
    items: [],
    selectedThreadId: 0,
    selectedWithUserId: 0,
    error: null
  },
  getters: {
    getItems (state) {
      return state.items;
    },
    getThreads (state) {
      return state.threads;
    }
  },
  mutations: {
    setThreads (state, value) {
      state.threads = value;
    },
    setItems (state, value) {
      state.items = value;
    },
    setSelectedThread (state, value) {
      state.selectedThreadId = value.threadId ? value.threadId : 0;
      state.selectedWithUserId = value.withUserId ? value.withUserId : 0;
    },
    decreaseUnreadCount (state) {
      for (let i = 0; i < state.threads.length; i++) {
        if (state.threads[i].unreadCount > 0 && (state.threads[i].threadId === state.selectedThreadId || state.threads[i].threadId === state.selectedThreadId)) {
          state.threads[i].unreadCount -= 1;
        }
      }
    }
  },
  actions: {
    loadThread ({ commit, state }, data) {
      return new Promise(resolve => {
        data.threadsListFunc().then((response) => {
          if (response.status === 'ok') {
            commit('setThreads', response.results);
          }
        });

        data.messagesListFunc(state.selectedThreadId, state.selectedWithUserId).then((response) => {
          if (response.status === 'ok') {
            if (state.items.length === 0 || state.items.length !== response.results.length) {
              commit('setItems', response.results);
              resolve();
            }
          } else {
            if (response.status === 'error') {
              state.error = response.msg;
              return;
            }
            state.error = 'Unknown error';
          }
        });
      });
    }
  }
}
